import React, { memo, useState } from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ControlRelationDataFetcher from "./ControlRelationDataFetcher";
import { useGraphContext } from "../graph/context";

const RelationBlock = ({
    width,
    controlRelationId,
    detailviewId,
    tableitemId,
    tableId,
}) => {
    const { currentUser } = useGraphContext();

    const [inlineEditMode, setInlineEditMode] = useState(false);

    return (
        <Col lg={width} md={12} className={`bl-col-padding`}>
            <section className={`bl-detail-column bl-card ${inlineEditMode && "inlineEditMode"}`}>

                <ControlRelationDataFetcher
                    detailviewId={detailviewId}
                    controlRelationId={controlRelationId}
                    tableItemId={tableitemId}
                    inlineEditMode={inlineEditMode}
                    setInlineEditMode={setInlineEditMode}
                />
                {currentUser?.admin === 1 && (
                    <Link
                        to={`/configurator/dataset/${tableId}/1/set/${controlRelationId}/`}
                    >
                        <div className="control-property-configurator-icon"><i className={"fa-regular fa-cog"} /></div>

                    </Link>
                )}
            </section>
        </Col>);
};

export default memo(RelationBlock);
