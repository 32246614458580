import React, { memo, useCallback } from "react";
import { NumberBox, Button as NumberBoxButton } from "devextreme-react/number-box";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tooltip } from 'devextreme-react/tooltip';
import { ColumnValidationCurrency } from "../../enums/columnValidation";
import FieldLabel from "../detailview/FieldLabel";

import {
    getFormat,
    fromBackendValue,
    toBackendValue,
    stepIncrement,
} from '../formats';

const NUMBER = ({
    value,
    alias,
    readonly,
    mandatory,
    columnId,
    displayFormat,
    registerLoaded,
    onControlUpdate,
    isPercentage = false,
    isEuro = false,
    memoField,
    columnValidationId,
}) => {
    if (alias === 'Korting') {

    }
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const valid = Boolean(value);

    const handleValueChange = useCallback((e) => {
        if (isNaN(e.value)) {
            return;
        }
        //culture on server changed, toBackendValue removed
        //onControlUpdate(columnId, toBackendValue(e.value));
        onControlUpdate(columnId, e.value);
    }, [onControlUpdate, columnId]);

    let format;
    if (isPercentage) {
        format = "PERC";
    } else if (isEuro) {
        if (columnValidationId === ColumnValidationCurrency.Precision2) {
            format = "EURO"
        } else {
            format = "EURO3"
        }
    } else {
        format = displayFormat;
    }

    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={alias}
                        columnId={columnId}
                        memoField={memoField}
                        mandatory={mandatory}

                    />
                </Col>
                <Col md="8">
                    {/* <div className={`number-field-wrapper ${isEuro ? "" : ""}`}> */}

                    <NumberBox
                        showClearButton={true}
                        showSpinButtons={true}
                        format={getFormat(format)}
                        stylingMode={readonly ? 'filled' : 'outlined'}
                        placeholder={alias}
                        //value={value ? fromBackendValue(value) : undefined}
                        value={value}
                        onValueChanged={handleValueChange}
                        readOnly={readonly}
                        valueChangeEvent="input"
                        step={stepIncrement(format)}
                        onInitialized={onInitialized}
                    // className={`${isEuro ? "" : "number-input"} ${(mandatory && !valid) ? "bl-mandatory-field" : ""} ${readonly ? "bl-readonly-field" : ""}`}
                    >
                        <NumberBoxButton
                            name="spins"
                        />
                        <NumberBoxButton
                            name="clear"
                        />

                    </NumberBox>
                    {/* </div> */}
                </Col>
            </Row>
        </Form.Group >
    );
};

export default memo(NUMBER);
