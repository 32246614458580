import { Column, RequiredRule, } from "devextreme-react/data-grid";

const renderStringColumn = (name, alias, width, key, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, allowHeaderFiltering, alignment, groupIndex) => {
    return (
        <Column
            key={key}
            dataField={name.toLowerCase()}
            caption={alias}
            fixed={false}
            allowSearch={true}
            dataType="string"
            allowEditing={!readonly}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            groupIndex={groupIndex}
            allowGrouping={true}
            showWhenGrouped={true}
            width={width}
            mandatory={mandatory}
            allowHeaderFiltering={allowHeaderFiltering}
            alignment={alignment}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
        >
            {mandatory && <RequiredRule />}
        </Column>)
};

export default renderStringColumn;
