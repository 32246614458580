export const QueryVisualColumnArea = {
    Row:        1,
    Column:     2,
    Data:       3
}

export const QueryVisualColumnSummaryType = {
    Sum:        1,
    Average:    2
}

export const QueryVisualDataType = {
    Date:       1,
    Number:     2, 
    String:     3
}

export const QueryVisualDataFormat = {
    Datum:      1,
    Integer:    2,
    Decimaal:   3
}