import React, { memo, useCallback } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tooltip } from 'devextreme-react/tooltip';
import { TextBox } from "devextreme-react";
import FieldLabel from "../detailview/FieldLabel";

const AUTONUMMERING = ({
    label,
    registerLoaded,
    columnId,
    value,
    memoField,
}) => {
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={label}
                        columnId={columnId}
                        memoField={memoField}

                    />
                </Col>
                <Col md="8">
                    <TextBox
                        value={value}
                        readOnly={true}
                        stylingMode="filled"
                        onInitialized={onInitialized}
                    />
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(AUTONUMMERING);
