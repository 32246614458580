import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import React, { useMemo, useCallback, useState, useRef, useEffect } from "react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";
import { DataGrid } from "devextreme-react";
import { Button } from 'devextreme-react/button';
import toast from "react-hot-toast";

import {
    Column,
    FilterRow,
    Scrolling,
    Grouping,
    GroupPanel,
    Summary,
    TotalItem,
    GroupItem,
    Editing,
    Paging,
    Pager,
    Position,
    HeaderFilter,
    SortByGroupSummaryInfo,
    ColumnChooser,
    Item,
    Toolbar,
    Button as DataGridButton,
    Search,
} from "devextreme-react/data-grid";

const ActionLog = () => {
    const gridRef = useRef();
    const [loading, setLoading] = useState(true);

    const loadActionLogs = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/action/listactionlogs`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Action] getUsers response was not ok: ${data.message}`);
            }
            setLoading(false);
            return data;
        } catch (error) {
            throw error;
        }
    }, []);

    const dataSourceActionLogs = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: loadActionLogs,

        })
    }), [loadActionLogs]);

    const refreshGrid = useCallback(() => { gridRef.current.instance().refresh(true); }, []);
    const refreshClick = useCallback(() => {
        setLoading(true);
        refreshGrid();
    }, [refreshGrid]);

    const onFireClick = useCallback(async (e) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/action/run/${e.row.data.actionId}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" }
                }
            );
            const data = await res.json();

            if (data.isRestarted) {
                toast.success('De taak is opgestart');
                refreshGrid();
            } else {
                toast.error('Taak draaide reeds en is niet gestart');
            }

        } catch (error) {
            throw error;
        }
    }, [refreshGrid]);

    const statusRender = (data) => {

        const color = data.data.statusColor;

        if (!data.value) {
            return null;
        }
        return (

            <div
                className="select-search-color-container"

            >

                <i
                    className="fas fa-square coloricon"
                    style={{
                        color,
                    }}
                />

                <p className="color-labeltext" >{data.data.status}</p>
            </div>
        );
    };
    const allowedPageSizes = [10, 20, 50, "all"];
    const onCancelClick = useCallback(async (e) => {
        try {

            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/action/cancelrunningtask/${e.row.data.actionId}`);
            const data = await res.json();
            if (data.isCancelled) {
                toast.success('De taak is afgebroken');
                refreshGrid();
            } else {
                toast.error('De taak draaide niet en is niet afgebroken');
            }
        } catch (error) {
            throw error;
        }
    }, [refreshGrid]);

    return (
        <main className="bl-content">
            <header className=" bl-title-header">
                <h2>Recente acties</h2>
            </header>
            <div className="bl-card">
                <DataGrid
                    dataSource={dataSourceActionLogs}
                    showBorders={true}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    showColumnLines={false}
                    wordWrapEnabled={false}
                    width={"auto"}
                    loadPanel={true}
                    ref={gridRef}

                    className="bl-news"
                >

                    <Paging defaultPageSize={100} />
                    <Pager
                        visible={true}
                        allowedPageSizes={allowedPageSizes}
                        displayMode="full"
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                    />
                    <HeaderFilter visible={true}>
                        <Search enabled={true} />
                    </HeaderFilter>
                    <Scrolling
                        mode="standard"
                        preloadEnabled="true"
                        showScrollbar="always"
                    />
                    <FilterRow visible={true} showAllText="Alle" />
                    <Grouping autoExpandAll={true} contextMenuEnabled allowCollapsing={true} />
                    <GroupPanel visible={true} />

                    <Column
                        dataField="name"
                        dataType="string"
                        width={"200px"}
                        caption="Actie"

                    />
                    <Column caption="Start/ Stop" type="buttons" width={90} >
                        <DataGridButton icon="fas fa-bolt" hint="start actie" onClick={onFireClick} visible={(e) => { return !e.row.data.isRunning }} />
                        <DataGridButton icon="fas fa-xmark" hint="stop actie" onClick={onCancelClick} visible={(e) => { return e.row.data.isRunning }} />

                    </Column>
                    <Column
                        dataField="executionDate"
                        dataType="datetime"
                        caption="Start"
                        format="dd-MM-yyyy HH:mm"
                        width={"130px"}
                        defaultSortOrder="desc"
                    />
                    <Column
                        dataField="status"
                        dataType="string"
                        caption="Status"
                        groupIndex={0}
                        width={"180px"}
                        showWhenGrouped
                        cellRender={statusRender}
                    />
                    <Column
                        dataField="executionDuration"
                        dataType="number"
                        caption="Duur (sec.)"
                    />


                    <Column
                        dataField="nrOfRecordsMassQuery"
                        dataType="number"
                        caption="# gestart"
                    />
                    <Column
                        dataField="nrOfRecordsHandled"
                        dataType="number"
                        caption="# update"
                    />


                    <Summary>
                        <GroupItem
                            column="name"
                            summaryType="count"
                            displayFormat="{0}"
                        />
                        <GroupItem
                            column="nrOfRecordsMassQuery"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                        />
                        <GroupItem
                            column="executionDuration"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                        />
                        <GroupItem
                            column="nrOfRecordsHandled"
                            summaryType="sum"
                            displayFormat="{0}"
                            alignByColumn
                        />
                        <TotalItem
                            column="isRunning"
                            summaryType="count" />
                        <TotalItem
                            column="executionDuration"
                            summaryType="sum" />
                        <TotalItem
                            column="nrOfRecordsMassQuery"
                            summaryType="sum" />
                        <TotalItem
                            column="nrOfRecordsHandled"
                            summaryType="sum" />

                    </Summary>

                    <Toolbar>
                        <Item location="before" name="groupPanel" />
                        <Item location="after">
                            <Button
                                icon={loading ? "fas fa-pulse fa-spinner" : "fa fa-sync-alt"}
                                onClick={refreshClick}
                            />
                        </Item>
                    </Toolbar>
                </DataGrid>
            </div>
        </main>
    );
};
export default ActionLog;
