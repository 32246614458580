import React, { memo, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TagBox } from "devextreme-react";
import DataSource from "devextreme/data/data_source";

import FieldLabel from "../detailview/FieldLabel";

const MULTIPLESELECT = ({
    label,
    mandatory,
    options,
    values,
    columnId,
    registerLoaded,
    onControlUpdate,
    memoField,


}) => {
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const dataSource = useMemo(() => new DataSource({
        store: options,
    }), [options]);


    const handleSelectChange = useCallback((e) => {
        onControlUpdate(columnId, e.value, { multi: true });
    }, [onControlUpdate, columnId]);

    const onSelectionChanged = useCallback((e) => {
        // I am not responsible for this - Laurens Kling, 2022
        e.component.field().value = "";
    }, []);

    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <FieldLabel
                        value={registerLoaded}
                        label={label}

                        memoField={memoField}
                        mandatory={mandatory}
                    />
                </Col>
                <Col md="8">
                    <TagBox
                        value={values || []}
                        dataSource={dataSource}
                        valueExpr="value"
                        displayExpr="label"
                        showDropDownButton={true}
                        showClearButton={true}
                        searchEnabled={true}
                        showSelectionControls={true}
                        hideSelectedItems={true}
                        onSelectionChanged={onSelectionChanged}
                        onValueChanged={handleSelectChange}
                        onInitialized={onInitialized}
                    />
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(MULTIPLESELECT);
