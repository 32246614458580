import React, { memo, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import calculate from '../calculate';
import VOID from './VOID';
import { Tooltip } from 'devextreme-react/tooltip';
import { NumberBox, Button as NumberBoxButton } from "devextreme-react/number-box";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import FieldLabel from "../detailview/FieldLabel";

import {
    getFormat,
    fromBackendValue,
    toBackendValue,
    stepIncrement,
} from '../formats';

const CALCULATED = ({
    value,
    defaultValue,
    alias,
    columnId,
    displayFormat,
    registerLoaded,
    getValueByColumnName,
    asString = false,
    memoField,
    columnTypeCode,
}) => {
    useEffect(() => {
        registerLoaded(columnId);
    }, [registerLoaded, columnId]);

    let outcome;
    let outcomeComputedValue;
    try {
        outcome = calculate({
            alias,
            getValueByColumnName,
            defaultValue,
            displayFormat,
            asString,
        });
        outcomeComputedValue = calculate({
            alias,
            getValueByColumnName,
            defaultValue,
            displayFormat,
            asString,
            returnComputedValue: true,
        });
    } catch (err) {
        return <VOID label={alias} placeholder={`Opgeslagen waarde: ${value}`} />;
    }


    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={alias}
                        columnId={columnId}
                        memoField={memoField}
                    />
                </Col>
                <Col md="8">

                    {(columnTypeCode === "COMPOSITE" && (
                        <TextBox
                            placeholder={alias}
                            value={outcome}
                            readOnly={true}
                            valueChangeEvent="input"
                        />
                    ))}
                    {(columnTypeCode === "CALCULATED" && (
                        <NumberBox
                            format={getFormat(displayFormat)}
                            placeholder={alias}
                            value={outcomeComputedValue}
                            readOnly={true}
                            stylingMode={"filled"}
                            isValid={true}
                            valueChangeEvent="input"
                        />
                    ))}


                </Col>
            </Row>
        </Form.Group >
    );
};

export default memo(CALCULATED);
