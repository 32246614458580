import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import msalFetch from "../../../../../api/MsalFetch.js";
import appSettings from "../../../../../appsettings";
import Resizable from 'devextreme-react/resizable';
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

import {
  CheckBox,
  DateBox,
  NumberBox,
  SelectBox,
  TextArea,
} from "devextreme-react";
import Loading from "../../../../loading/Loading";
import moment from "moment";

const ActionOly = ({ fieldValue, setFieldValue, checkChanges, isCreate }) => {
  const msal = useMsal();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);

  const [recurring, setRecurring] = useState(
    fieldValue.action && fieldValue.action.recurring
      ? fieldValue.action.recurring
      : false
  );
  const [startDate, setStartDate] = useState();
  const [displayNextLabel, setDisplayNextLabel] = useState();
  const [nextExecution, setNextExecution] = useState({
    start: "",
    interval: "",
    specification: "",
    output: "",
  });

  const intervalSpecifications = [
    { v: "minute", d: "Kwartier" },
    { v: "hour", d: "Uur" },
    { v: "day", d: "Dag" },
    { v: "week", d: "Week" },
    { v: "month", d: "Maand" },
  ];

  const styleObj = { height: '300px' }
  const calculateNextDate = (startDateTime, interval, specification) => {
    let tempStartDate = new Date(startDateTime);

    let nextDate;

    if (specification === "minute") {
      nextDate = new Date(tempStartDate.getTime() + interval * 15 * 60000);
    } else if (specification === "hour") {
      nextDate = new Date(tempStartDate.getTime() + interval * 60 * 60 * 1000);
    } else if (specification === "day") {
      nextDate = new Date(
        tempStartDate.getTime() + interval * 24 * 60 * 60 * 1000
      );
    } else if (specification === "week") {
      nextDate = new Date(
        tempStartDate.getTime() + interval * 7 * 24 * 60 * 60 * 1000
      );
    } else if (specification === "month") {
      nextDate = tempStartDate;
      nextDate.setMonth(nextDate.getMonth() + interval);
    }

    updateDisplayLabel(nextDate);
  };
  const now = new Date();
  const getUsers = async () => {
    let tempUsers = [{ name: "Ingelogde gebruiker", id: null }];
    setLoading(true);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/user`
      );
      const data = await response.json();
      data.map((user) => {
        return (tempUsers = [
          ...tempUsers,
          { name: `${user.firstname} ${user.name}`, id: user.id },
        ]);
      });

      setUsers(tempUsers);

      setLoading(false);
      return tempUsers;
    } catch (error) {
      setLoading(false);
      throw "Data Loading Error";
    }
  };

  useEffect(() => {
    if (fieldValue.action && fieldValue.action.nextExecution) {
      let tempString = fieldValue.action.nextExecution.replace("T", " ");

      const nextDate = new Date(tempString);

      const specification = fieldValue.action.intervalSpecification;
      const interval = fieldValue.action.interval;
      // if (!recurring) return;
      setStartDate(fieldValue.action.nextExecution);

      calculateNextDate(nextDate, interval, specification);
    }

    getUsers();
  }, []);

  const updateDisplayLabel = (output) => {
    const dt = moment(output).format('DD-MM-yyyy HH:mm');

    setDisplayNextLabel(
      output
    );
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <header className="bl-config-section-header">
          <h2>Queries</h2>
        </header>

        <Form.Group>
          <Row>
            <Col lg="12">
              <Form.Label>Query</Form.Label>
            </Col>
            <Col lg="12">
              <Resizable
                minHeight={100}
                style={styleObj}
                maxHeight={600}
                handles={"bottom"}
                area='.widget-container .dx-field'
              >
                <TextArea
                  height={"100%"}
                  defaultValue={
                    fieldValue.action &&
                    fieldValue.action.actionQuery &&
                    fieldValue.action.actionQuery
                  }
                  className={
                    isCreate ||
                      fieldValue.action.actionQuery === null ||
                      fieldValue.action.actionQuery === ""
                      ? "bl-mandatory-field"
                      : ""
                  }
                  placeholder="SELECT Id, Naam FROM Table WHERE Id = [Id]"
                  valueChangeEvent="keyup"
                  onValueChanged={(e) => {
                    if (
                      e.value.trim() === "" &&
                      !e.element.classList.contains("bl-mandatory-field")
                    ) {

                      e.element.classList.add("bl-mandatory-field");
                    } else {
                      e.element.classList.remove("bl-mandatory-field");
                    }
                    let tempFieldValue = fieldValue;
                    tempFieldValue.action["actionQuery"] = e.value;
                    setFieldValue(tempFieldValue);

                    checkChanges();
                  }}
                />
              </Resizable>
              <footer className="bl-label-footer">
                LET OP! Deze query moet 1 resultaat opleveren. Dit is te
                bereiken door `WHERE Id = [Id]` toe te voegen.
              </footer>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col lg="12">
              <Form.Label>Query na actie</Form.Label>
            </Col>
            <Col lg="12">
              <Resizable
                minHeight={100}
                style={styleObj}
                maxHeight={600}
                handles={"bottom"}
                area='.widget-container .dx-field'
              >
                <TextArea
                  height={"100%"}

                  defaultValue={
                    fieldValue.action &&
                    fieldValue.action.actionQueryNaActie &&
                    fieldValue.action.actionQueryNaActie
                  }
                  placeholder="UPDATE Table SET Status = 9 WHERE Id = [Id]"
                  valueChangeEvent="keyup"
                  onValueChanged={(e) => {
                    let tempFieldValue = fieldValue;
                    tempFieldValue.action["actionQueryNaActie"] = e.value;
                    setFieldValue(tempFieldValue);

                    checkChanges();
                  }}
                />
              </Resizable>
              <footer className="bl-label-footer">
                LET OP! Deze query moet 1 resultaat opleveren. Dit is te
                bereiken door `WHERE Id = [Id]` toe te voegen.
              </footer>
            </Col>
          </Row>
        </Form.Group>

        <header className="bl-config-section-header">
          <h2> Achtergrond taak</h2>
        </header>

        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Recurring</Form.Label>
            </Col>
            <Col lg="9">
              <CheckBox
                defaultValue={fieldValue.action && fieldValue.action.recurring}
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue.action["recurring"] = e.value;
                  setFieldValue(tempFieldValue);
                  setRecurring(e.value);

                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <section
          key={recurring}
          className={`bl-accordion ${recurring && "bl-accordion-expanded"}`}
        >
          <div className="bl-accordion-content">
            <Form.Group>
              <Row>
                <Col lg="12">
                  <Form.Label>Massa query</Form.Label>
                </Col>
                <Col lg="12">
                  <Resizable
                    minHeight={100}
                    style={styleObj}
                    maxHeight={600}
                    handles={"bottom"}
                    area='.widget-container .dx-field'
                  >

                    <TextArea
                      height={"100%"}
                      defaultValue={
                        fieldValue.action &&
                        fieldValue.action.actionMassaQuery &&
                        fieldValue.action.actionMassaQuery
                      }
                      className={
                        recurring &&
                          (isCreate ||
                            fieldValue.action.actionMassaQuery === null ||
                            fieldValue.action.actionMassaQuery === "")
                          ? "bl-mandatory-field"
                          : ""
                      }
                      placeholder="SELECT Id FROM Table WHERE &#10;bl_modified > getdate()"
                      valueChangeEvent="keyup"
                      onValueChanged={(e) => {
                        if (
                          e.value.trim() === "" &&
                          !e.element.classList.contains("bl-mandatory-field")
                        ) {
                          e.element.classList.add("bl-mandatory-field");
                        } else {
                          e.element.classList.remove("bl-mandatory-field");
                        }
                        let tempFieldValue = fieldValue;
                        tempFieldValue.action["actionMassaQuery"] = e.value;
                        setFieldValue(tempFieldValue);

                        checkChanges();
                      }}
                    />
                  </Resizable>
                  <footer className="bl-label-footer">
                    LET OP! Deze query levert de Id's aan voor de query bovenaan. Verder wordt er niets met de velden gedaan.
                  </footer>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col lg="2">
                  <Form.Label>Startmoment</Form.Label>
                </Col>
                <Col lg="2">
                  <DateBox
                    calendarOptions={{
                      showWeekNumbers: true,
                    }}
                    type="datetime"
                    displayFormat="dd-MM-yyy HH:mm"
                    min={now}
                    className={
                      recurring && (isCreate || nextExecution) === ""
                        ? "bl-mandatory-field"
                        : ""
                    }
                    defaultValue={
                      fieldValue.action.nextExecution &&
                      fieldValue.action.nextExecution
                    }
                    onValueChanged={(e) => {
                      const newValue = moment.parseZone(e.value).utc(true);
                      const newValueIso = newValue.format('YYYY-MM-DDTHH:mm:ss');
                      let tempFieldValue = fieldValue;
                      calculateNextDate(
                        e.value,
                        fieldValue.action.interval,
                        fieldValue.action.intervalSpecification
                      );
                      e.element.classList.remove("bl-mandatory-field");
                      tempFieldValue.action["nextExecution"] = newValueIso;
                      setFieldValue(tempFieldValue);

                      setStartDate(e.value);
                      checkChanges();
                    }}
                  />
                </Col>
                <Col lg="1">
                  <Form.Label>Volgend</Form.Label>
                </Col>
                <Col lg="2">
                  <DateBox
                    displayFormat="dd-MM-yyy HH:mm"
                    value={displayNextLabel}
                    readOnly

                  />
                </Col>
                <Col lg="2">
                  <Form.Label>Herhaal na</Form.Label>
                </Col>

                <Col lg="1">
                  <NumberBox

                    defaultValue={
                      fieldValue.action ? fieldValue.action.interval : 1
                    }
                    className={
                      recurring &&
                        (isCreate || fieldValue.action.interval === "")
                        ? "bl-mandatory-field"
                        : ""
                    }
                    onValueChanged={(e) => {
                      let tempFieldValue = fieldValue;
                      tempFieldValue.action["interval"] = e.value;
                      if (e.value > 0) {
                        e.element.classList.remove("bl-mandatory-field");
                      } else {
                        e.element.classList.add("bl-mandatory-field");
                      }
                      setFieldValue(tempFieldValue);

                      calculateNextDate(
                        startDate,
                        e.value,
                        tempFieldValue.action.intervalSpecification
                      );
                      checkChanges();
                    }}
                  />
                </Col>
                <Col lg="2">
                  <SelectBox
                    searchEnabled
                    className={
                      recurring &&
                        (isCreate ||
                          fieldValue.action.intervalSpecification === "")
                        ? "bl-mandatory-field"
                        : ""
                    }
                    items={intervalSpecifications}
                    valueExpr="v"
                    displayExpr="d"
                    defaultValue={
                      fieldValue.action &&
                      fieldValue.action.intervalSpecification
                    }
                    onValueChanged={(e) => {
                      let tempFieldValue = fieldValue;
                      tempFieldValue.action["intervalSpecification"] = e.value;

                      e.element.classList.remove("bl-mandatory-field");
                      calculateNextDate(
                        startDate,
                        tempFieldValue.action.interval,
                        e.value
                      );
                      setFieldValue(tempFieldValue);
                      checkChanges();
                      setNextExecution((e) => {
                        let temp = nextExecution;
                        return (temp["specification"] = e);
                      });
                    }}
                  />
                </Col>

              </Row>
            </Form.Group>
          </div>
        </section>
      </>
    );
  }
};
export default ActionOly;
