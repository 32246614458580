import Scheduler from "./Scheduler";
import { useGlobalContext } from "../../context/context";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useGraphContext } from "../graph/context.js";

const SchedulerWrapper = ({
    schedulerId,
    title,
    users,
    dataStore,
    selectedUsers,
    setSelectedUsers,
    filterOnOnlineUser,
    usersOfScheduler,
    preferredDetailViewId,
    cellDuration,
    startDayHour,
    endDayHour,
    tableId,
    draggableSelectFieldId,
    draggableItems,
    draggableSelectFieldAlias,
}) => {
    const { isMobile } = useGlobalContext();
    const { currentUser } = useGraphContext();
    return (users &&
        <main className={"bl-content"}>
            <Helmet>
                <title>
                    {`${title}`}
                </title>

            </Helmet>

            <h3 className="detailview-header">{title}</h3>
            <section >

                <Scheduler
                    title={title}
                    schedulerId={schedulerId}
                    users={users}
                    dataStore={dataStore}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    filterOnOnlineUser={filterOnOnlineUser}
                    usersOfScheduler={usersOfScheduler}
                    preferredDetailViewId={preferredDetailViewId}
                    cellDuration={cellDuration}
                    startDayHour={startDayHour}
                    endDayHour={endDayHour}
                    draggableSelectFieldId={draggableSelectFieldId}
                    draggableItems={draggableItems}
                    draggableSelectFieldAlias={draggableSelectFieldAlias}
                /></section>

            <header className="bloom-list-header">


                {currentUser?.admin === 1 && (
                    <Link
                        to={`/configurator/dataset/${tableId}/4/list/${schedulerId}/`}
                    >
                        <div title="Bewerk lijst" className="control-configurator-icon"><i className={"fa-regular fa-list"} /></div>
                    </Link>
                )}
                {currentUser?.admin === 1 && (
                    <Link
                        to={`/configurator/dataset/${tableId}/`}
                    >
                        <div title="Bewerk tabel" className="control-configurator-icon"><i className={"fa-regular fa-table"} /></div>
                    </Link>
                )}
            </header>
        </main>
    )
};

export default SchedulerWrapper;
