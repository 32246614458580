import React, { useState, useCallback } from "react";
import { Column } from "devextreme-react/data-grid";
import appSettings from "../../../appsettings";
import toast from "react-hot-toast";
import msalFetch, { acquireTokenRequest } from "../../../api/MsalFetch";
import { saveAs } from "file-saver";
import Button from 'devextreme-react/button';
import base64ToBlob from "base64toblob";
import moment from "moment";


const RenderActionColumn = (
    name,
    alias,
    width,
    key,
    columnId,
    actionId,
    actionType,
    oneTime,
    tableId,
    actionRefresh,
    refreshGrid,
    actionIcon,
    sortIndex,
    sortDirection,
    groupIndex,
) => {
    const [actionsRunning, setActionsRunning] = useState([]);
    moment.locale('nl');
    const postAction = useCallback(async (tableItemId) => {
        if (!actionId) {
            toast.error("Actie kon niet worden uitgevoerd");
            return;
        }

        if (actionType === "Preview") {
            const tokenRequest = await acquireTokenRequest();
            const token = tokenRequest.accessToken;

            window.open(
                `${appSettings.api.endpoint}/api/action/${actionId}/${tableId}/${tableItemId}/?access_token=${token}`
            );
            return;
        }

        setActionsRunning((state) => [...state, tableItemId]);
        try {
            const res = await msalFetch(
                null,
                `${appSettings.api.endpoint}/api/action/${actionId}/${tableId}/${tableItemId}`,
                {
                    method: "POST",
                    //   headers: { "Content-type": "application/json" },
                    //   body: JSON.stringify({}),
                }
            );
            const data = await res.json();

            // check if succesfull and deal with action
            if (data.warning) {
                toast(data.result, {
                    style: {
                        border: "1px solid orange",
                        background: "white",
                    },
                });
            } else if (data.success) {
                if (actionType === "Download") {
                    saveAs(base64ToBlob(data.result), data.filename);
                }
                toast.success(data.result);
                if (actionRefresh) {
                    refreshGrid();
                }
            } else {
                toast.error(
                    data.result ? data.result : "Oeps er ging even iets mis...."
                );
            }
            setActionsRunning((state) => state.filter((actionRunningId) => actionRunningId !== tableItemId));
            return data;

        } catch (error) {
            setActionsRunning((state) => state.filter((actionRunningId) => actionRunningId !== tableItemId));
            toast.error("Oeps er ging even iets mis...");
        }
    }, [actionId, actionRefresh, actionType, refreshGrid, tableId]);

    const onClick = useCallback((columnData) => (e) => {
        e.stopPropagation();
        return postAction(columnData.key);
    }, [postAction]);

    const renderActionButton = useCallback((columnData, title, actionIcon, value) => {
        const disabled = oneTime && columnData.value;
        let datum = moment(columnData.value).format('D-MM-yyyy H:mm');

        const currentActionRunning = actionsRunning.includes(columnData.key);
        return (
            <div className="bl-inline-btn-wrapper">


                <div
                    className={`bloom-inline-action`}
                    onClick={!disabled ? onClick(columnData) : () => { }}
                    title={title}
                >
                    <Button
                        className='bloom-action-button'
                        stylingMode='text'
                        type="default"
                        width={"auto"}

                        hint={`${title}
${columnData.value ? (`laatst: ${moment(columnData.value).format('D-MM-yyyy H:mm')} `) : ''}`}
                        disabled={(disabled || currentActionRunning)}
                        icon={`fas ${currentActionRunning ? 'fa-pulse fa-spinner' : (actionIcon ? actionIcon : "fa-bolt")}`}
                        onClick={!disabled ? onClick(columnData) : () => { }}
                    />
                </div>
            </div>
        );
    }, [actionsRunning, onClick, oneTime]);

    return (
        <Column
            key={key}
            dataField={name.toLowerCase()}
            caption={alias}
            allowSearch={false}
            allowEditing={false}
            width={width}
            cellRender={(columnData) => renderActionButton(columnData, alias, actionIcon)}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={false}
            groupIndex={groupIndex}
        />
    );
};

export default RenderActionColumn;
