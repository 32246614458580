import { useState, useEffect, useCallback, memo } from "react";
import msalFetch from "../../api/MsalFetch.js";
import appSettings from "../../appsettings";
import getControl from './get-control';
import VOID from './VOID';
import NUMBER from './NUMBER';
import TEXT from './TEXT';
import { toBackendValue } from '../formats';
import FieldLabel from "../detailview/FieldLabel";

const AVEV = ({
    value,
    values,
    label,
    registerLoaded,
    onControlUpdate,
    getValueByColumnId,
    columnId,
    readonly,
    mandatory,
    dependColumnId,
    dependParentColumnId,
    args,
    memoField,
}) => {
    const [loaded, setLoaded] = useState(false);
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const dependedValue = getValueByColumnId(dependParentColumnId);
    const [columnFromDependedTable, setColumnFromDependedTable] = useState(null);

    const doGetDependedColumn = useCallback(async (val, forced) => {
        if (!val) {
            setLoaded(true); // if initially empty, still set loaded
            setColumnFromDependedTable(null);
            if (forced) {
                onControlUpdate(columnId, null);
            }
            return null;
        }

        let res;
        try {
            res = await msalFetch(null, `${appSettings.api.endpoint}/api/table/column/${val}/${dependColumnId}`)
            if (!res.ok) {
                throw new Error(`[AVEV] getDependedColumn response was not ok`);
            }
        } catch (err) {
            throw err;
        }
        let data;
        try {
            data = await res.json();
        } catch (err) {
            throw err;
        }

        setLoaded(true);
        setColumnFromDependedTable(data);
        if (forced) {
            // we need to use toBackendValue because this endpoint will give 100.000
            // and not 100,000, like a endpoint with columns does - laurens
            // like a boss - ruud
            // culture changed on the sever, toBackendValue removed
            //onControlUpdate(columnId, toBackendValue(data.value));
            onControlUpdate(columnId, data.value);
        }
        return data;
    }, [onControlUpdate, columnId, dependColumnId]);

    // we create this function to set the forced boolean after initial loaded is set to true
    const getDependedColumn = useCallback(
        (val) => doGetDependedColumn(val, loaded),
        [doGetDependedColumn, loaded],
    );

    useEffect(() => {
        getDependedColumn(dependedValue);
    }, [dependedValue]);

    useEffect(() => {
        onInitialized();
    }, [onInitialized]);

    const registerLoadedControl = useCallback(() => false, []);
    const handleControlUpdate = useCallback((_, value, options) => onControlUpdate(columnId, value, options), [onControlUpdate, columnId]);

    if (!columnFromDependedTable) {
        return <VOID label={label} />;
    }

    if (columnFromDependedTable.columnTypeCode === 'CALCULATED') {
        return (
            <NUMBER
                value={value}
                alias={label}
                readonly={readonly}
                mandatory={mandatory}
                displayFormat={columnFromDependedTable.displayFormat}
                registerLoaded={registerLoadedControl}
                onControlUpdate={handleControlUpdate}
                isPercentage={false}
                isEuro={columnFromDependedTable.displayFormat === 'EURO'}
                memoField={memoField}
            />
        );
    }
    if (columnFromDependedTable.columnTypeCode === 'COMPOSITE') {
        return (
            <TEXT
                matchesTheRegex={!columnFromDependedTable.regex ? true : (value || "").match(columnFromDependedTable.regex)}
                value={value}
                label={label}
                readonly={readonly}
                mandatory={mandatory}
                stylingMode={readonly ? "filled" : "outlined"}
                columnId={columnFromDependedTable.id}
                registerLoaded={registerLoadedControl}
                onControlUpdate={handleControlUpdate}
                memoField={memoField}
            />
        )
    }

    return getControl({
        ...args,
        column: columnFromDependedTable,
        index: 0,
        columnIndex: 0,
        rowIndex: 0,
        registerLoaded: registerLoadedControl,
        handleControlUpdate,
    }, {
        alias: label,
        readonly,
        mandatory,
        value,
        values,
    });
}

export default memo(AVEV);
