import React, { memo, useState, useRef, useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SignatureCanvas from 'react-signature-canvas';
import { Tooltip } from 'devextreme-react/tooltip';
import FieldLabel from "../detailview/FieldLabel";
// known issue:
// the signature will stay max initial width on window resize,
// the signature will scale smaller on window resize,
// but the pen area will remain the same width.
// resulting in a disjoint mouse event and penstroke,
// when the user resizes the browser smaller and starts penning.

const ratio = 1.4;

const getSize = ($el) => {
    const wrapperComputedStyle = window.getComputedStyle($el, null);
    return $el.clientWidth - (
        parseFloat(wrapperComputedStyle.paddingLeft) +
        parseFloat(wrapperComputedStyle.paddingRight)
    )
}

const SIGNATURE = ({
    value,
    label,
    // readonly,
    registerLoaded,
    onControlUpdate,
    columnId,
    memoField,
}) => {
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    useEffect(() => { onInitialized() }, [onInitialized]);
    const [initialWidth, setInitialWidth] = useState(null);
    const wrapperRef = useRef();
    useEffect(() => {
        if (wrapperRef.current) {
            setInitialWidth(getSize(wrapperRef.current));
        }
    }, [wrapperRef]);


    const ref = useRef();
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        if (loaded) {
            return undefined;
        }
        if (ref.current && value && initialWidth) {
            ref.current.fromDataURL(value, { width: initialWidth, height: initialWidth / ratio });
            setLoaded(true);
        }
    }, [ref, value, loaded, initialWidth]);

    const handleValueChange = useCallback((e) => {
        const base64 = ref.current.toDataURL();
        onControlUpdate(columnId, base64);
    }, [onControlUpdate, columnId]);

    const onDeleteClick = useCallback(() => {
        ref.current.clear();
    }, []);

    return (
        <Form.Group>
            <Row>
                <Col md="4" className="bl-justify-top">
                    <FieldLabel
                        value={value}
                        label={label}
                        columnId={columnId}
                        memoField={memoField}

                    />
                </Col>
                <Col md="12" className="dx-show-clear-button">
                    <div
                        className="dx-texteditor dx-editor-outlined dx-texteditor-input"
                        style={{ position: 'relative', display: 'inline-block', width: loaded ? 'auto' : undefined }}
                        ref={wrapperRef}
                    >
                        {!initialWidth && (
                            /* we do this, to force a scrollbar, so the initialWidth is correct with a scrollbar */
                            <div style={{ height: '100vw', width: 'auto' }} />
                        )}
                        {initialWidth && (
                            <SignatureCanvas
                                ref={ref}
                                penColor='black'
                                canvasProps={{
                                    width: initialWidth,
                                    height: initialWidth / ratio,
                                    style: {
                                        maxWidth: '100%',
                                        height: 'auto',
                                    },
                                    className: 'sigCanvas',
                                }}
                                backgroundColor="#ffffff"
                                onEnd={handleValueChange}
                            />
                        )}
                        <button
                            style={{
                                position: 'absolute',
                                right: '28px',
                                top: '17px',
                                border: '0',
                                background: 'none',
                            }}
                            onClick={onDeleteClick}
                        >
                            <span className="dx-icon dx-icon-clear"></span>
                        </button>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(SIGNATURE);
