import React, { memo, useCallback } from "react";
import { CheckBox } from "devextreme-react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tooltip } from 'devextreme-react/tooltip';
import FieldLabel from "../detailview/FieldLabel";

const CHECKBOX = ({
    value,
    alias,
    mandatory,
    registerLoaded,
    onControlUpdate,
    readonly,
    columnId,
    memoField,
}) => {
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const valid = Boolean(value === true || value === false);
    const valueFilled = (!value ? false : value)
    const handleCheckBox = useCallback((e) => {
        onControlUpdate(columnId, e.value);
    }, [onControlUpdate, columnId]);

    return (
        <Form.Group>
            <Row className="property-row">
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={alias}
                        columnId={columnId}
                        memoField={memoField}

                    />
                </Col>
                <Col md="8">
                    <CheckBox
                        value={valueFilled}
                        className={`${mandatory && !valid ? "bl-mandatory-field" : ""}`}
                        onValueChanged={handleCheckBox}
                        onInitialized={onInitialized}
                        readOnly={readonly}
                    />
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(CHECKBOX);
