import SchedulerWrapper from './SchedulerWrapper';
import React, { useMemo, memo, useState, useEffect, useCallback, useRef } from "react";
import CustomStore from "devextreme/data/custom_store";
import appSettings from "../../appsettings.js";
import msalFetch from "../../api/MsalFetch.js";
import { useGraphContext } from "../graph/context.js";
const SchedulerDataStore = ({
    schedulerId,
    title,
    users,
    filterOnOnlineUser,
    usersOfScheduler,
    preferredDetailViewId,
    cellDuration,
    startDayHour,
    endDayHour,
    tableId,
    draggableSelectFieldId, 
    draggableItems,
    draggableSelectFieldAlias,
}) => {
    const { currentUser } = useGraphContext();
    const [selectedUsers, setSelectedUsers] = useState(filterOnOnlineUser ? [currentUser?.id] : usersOfScheduler);
    
    const load = useCallback(async (e) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/scheduler/${schedulerId}/getevents`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({ ...e, selectedUsers }),
                }
            );

            const data = await res.json();
            return data;
        } catch (error) {
            throw error;
        }
    }, [schedulerId, selectedUsers]);

    const update = useCallback(async (key, e) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/scheduler/${schedulerId}/update/${key}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(e),
                }
            );
            const json = await res.json();
        } catch (error) {
            throw error;
        }
    }, [schedulerId]);

    const remove = useCallback(async (key, e) => {
        const res = await msalFetch(null,
            `${appSettings.api.endpoint}/api/scheduler/${schedulerId}/delete/${key}`,
            {
                method: "DELETE",
                headers: { "Content-type": "application/json" }
            }
        );
        const json = await res.json();
    }, [schedulerId]);

    const insert = useCallback(async (e) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/scheduler/${schedulerId}/insert`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(e),
                }
            );

        } catch (error) {
            throw error;
        }
    }, [schedulerId]);

    const dataStore = useMemo(() => {
        return new CustomStore({
            key: 'id',
            load: load,
            update: update,
            insert: insert,
            remove: remove
        });
    }, [insert, load, remove, update]);



    //return wrapper
    return (
        <SchedulerWrapper
            schedulerId={schedulerId}
            title={title}
            users={users}
            dataStore={dataStore}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            filterOnOnlineUser={filterOnOnlineUser}
            usersOfScheduler={usersOfScheduler}
            preferredDetailViewId={preferredDetailViewId}
            cellDuration={cellDuration}
            startDayHour={startDayHour}
            endDayHour={endDayHour}
            tableId={tableId}
            draggableSelectFieldId={draggableSelectFieldId}
            draggableItems={draggableItems}
            draggableSelectFieldAlias={draggableSelectFieldAlias}
        />
    );
}

export default SchedulerDataStore;
