import { Column, RequiredRule, } from "devextreme-react/data-grid";
import { getFormatter } from '../../formats';

//const percentageFormat = {
//    style: "percent",
//    minimumFractionDigits: 0,
//    maximumFractionDigits: 0
//};

const percentageFormat = getFormatter('PERC');

const renderPercentageColumn = (name, alias, width, key, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex) => {
    return (
        <Column
            key={key}
            dataField={name.toLowerCase()}
            format={percentageFormat}
            alignment="right"
            caption={alias}
            allowSearch={true}
            allowEditing={!readonly}
            width={width}
            editorOptions={{ format: percentageFormat }}
            mandatory={mandatory}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={true}
            groupIndex={groupIndex}
        >
            {mandatory && <RequiredRule />}
        </Column>
    );
};
export default renderPercentageColumn;
