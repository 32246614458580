import { Column, RequiredRule, } from "devextreme-react/data-grid";
import { ColumnValidationCurrency } from "../../../enums/columnValidation";
import { getFormatter } from '../../formats';

const renderCurrencyColumn = (name, alias, width, key, readonly, mandatory, inlineEditMode, displayFormat, columnValidationId, sortIndex, sortDirection, groupIndex) => {
    const currencyFormat = getFormatter(columnValidationId === ColumnValidationCurrency.Precision3 ? "EURO3" : "EURO2");
    const editorOptions = { format: currencyFormat };

    return (
        <Column
            key={key}
            dataField={name.toLowerCase()}
            format={currencyFormat}
            alignment="right"
            caption={alias}
            allowSearch={true}
            allowEditing={!readonly}
            mandatory={mandatory}
            width={width}
            editorOptions={editorOptions}
            dataType="number"
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={false}
            groupIndex={groupIndex}
        >
            {mandatory && <RequiredRule />}
        </Column>

    );
};

export default renderCurrencyColumn;
