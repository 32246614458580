import React from "react";

export default function ActionExpression(cellData) {
  const action = cellData.key.action;

  if (action === "Bijgewerkt") {
    return <i className="fas fa-pencil-alt action-icon" />;
  } else if (action === "Toegevoegd") {
    return <i className="fas fa-plus action-icon" />;
  } else if (action === "Verwijderd") {
    return <i className="fas fa-times action-icon" />;
  } else {
    return <i className="fas fa-circle action-icon" />;
  }
}
