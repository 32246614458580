import { Column, RequiredRule, } from "devextreme-react/data-grid";

const renderNumberColumn = (name, alias, width, key, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex) => {
    return (
        <Column
            key={key}
            dataField={name.toLowerCase()}
            caption={alias}
            fixed={false}
            allowSearch={true}
            dataType="number"
            allowEditing={!readonly}
            width={width}
            mandatory={mandatory}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={true}
            groupIndex={groupIndex}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}        >
            {mandatory && <RequiredRule />}
        </Column>
    );
};

export default renderNumberColumn;
