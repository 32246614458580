import appSettings from "./appsettings";

export const msalConfig = {
    auth: {
        clientId: appSettings.default.app.clientid,
        authority: "https://login.microsoftonline.com/common/",
        knownAuthorities: [/*"https://login.microsoftonline.com/common/oauth2/v2.0/authorize/"*/]
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge

        /*@Laurens: seems to be a non-existing setting*/
        enablePersistence: true,
        clientId: appSettings.default.app.clientid
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                switch (level) {
                    case level.Error:
                        console.error(message);
                        return;
                    case level.Info:
                        console.info(message);
                        return;
                    case level.Verbose:
                        console.debug(message);
                        return;
                    case level.Warning:
                        console.warn(message);
                        return;
                    default:
                        // console.info(message);
                        return;
                }
            }
        }
    }
};

export const loginRequest = {
    scopes: ["openid", "profile", "email"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
