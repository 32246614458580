export const ColumnValidationEmail = {
    Email: 2
}

export const ColumnValidationCurrency = {
    Precision2: 5,
    Precision3: 11
}

export const ColumnValidationDec = {
    Precision1: 6,
    Precision2: 7,
    Precision3: 8
}

export const ColumnValidationNumber = {
    Number: 4
}
