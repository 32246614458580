import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import Loading from "../../loading/Loading";
import { DataGrid } from "devextreme-react";
import notify from 'devextreme/ui/notify';
import {
    Column,
    FilterRow,
    Editing,
    Texts,
    Scrolling,
    RowDragging
} from "devextreme-react/data-grid";
import { useModalContext } from "../../../context/modalContext";
import BloomFooter from "../../footer/BloomFooter";

const Search = () => {
    const [loading, setLoading] = useState(true);
    const [searchList, setSearchList] = useState();

    const {
        setIsEditing,
        setModal,
        isEditing,
        setModalContent,
        setDeleteProceedNotification,
        setNotificationData,
        deleteProceedNotification,
    } = useModalContext();

    const getSearchList = useCallback(async () => {
        setLoading(true);
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/search`
            );
            const data = await response.json();

            setSearchList(data);
            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    }, []);

    const displayString = (data) => {

        if (data.data.display) {
            return "Ja";
        } else {
            return "Nee";
        }
    };

    useEffect(() => {
        getSearchList();
    }, [isEditing, getSearchList]);

    useEffect(() => {
        if (!deleteProceedNotification) {
            getSearchList();
        }
    }, [deleteProceedNotification, getSearchList]);

    const handleRowUpdating = useCallback(async (e) => {
        const res = await msalFetch(null,
            `${appSettings.api.endpoint}/api/configurator/search/update`, // 100 is the limit
            {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({ id: e.key, ...e.newData }),
            }
        );
        const data = await res.json();
        if (data.success) {
            getSearchList();
            notify("Zoekveld geüpdated", 'success');
        }
    }, [getSearchList])

    const updateGridOrder = useCallback(async (e) => {
        const res = await msalFetch(null,
            `${appSettings.api.endpoint}/api/configurator/search/update`, // 100 is the limit
            {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({ id: e.itemData.id, orderNr: e.toIndex }),
            }
        );
        const data = await res.json();
        if (data.success) {
            getSearchList();
            notify("Zoekveld geüpdated", 'success');
        }
    }, [getSearchList]);

    const handleRowRemoving = useCallback(async (e) => {
        const res = await msalFetch(null,
            `${appSettings.api.endpoint}/api/configurator/search/delete/${e.key}`,
            {
                method: "DELETE",
                headers: { "Content-type": "application/json" }
            }
        );
        const data = await res.json();
        if (data.success) {
            //getSearchList();
            notify("Zoekveld verwijderd", 'success');
        }
    }, [/* getSearchList */]);

    const handleRowInserting = (e) => {
        console.log("handleRowInserting", e);
        setIsEditing(true);
        setModal("ADDSEARCH");
    };

    const handleToolbarPreparing = (e) => {
        e.toolbarOptions.items[0].options.onClick = function () {
            console.log("handleRowInserting", e);
            setIsEditing(true);
            setModal("ADDSEARCH");
            //implement your logic here
        }
    }

    return (
        <main className="bl-content">
            <header className="bl-configurator-title-header">
                <h2>Zoeken</h2>
            </header>
            {loading && <Loading />}
            {!loading && (
                <section className="bl-card">
                    <DataGrid
                        dataSource={searchList}
                        showRowLines={false}
                        rowAlternationEnabled={true}
                        showColumnLines={false}
                        onRowUpdating={handleRowUpdating}
                        onRowRemoving={handleRowRemoving}
                        //onRowInserting={handleRowInserting}
                        onToolbarPreparing={handleToolbarPreparing}
                        keyExpr="id"
                    >
                        <RowDragging
                            allowReordering={true}
                            onReorder={updateGridOrder}
                            showDragIcons={true}
                            autoScroll={true}
                        />
                        <FilterRow visible={true} />
                        <Scrolling mode="virtual" />
                        <Column caption="Tabel" dataField="tableName" dataType="string" />
                        <Column caption="Kolom" dataField="columnName" dataType="string" />
                        <Column caption="Titel" dataField="title" width={250} dataType="string" />
                        <Column caption="Where" dataField="where" dataType="string" width={250} />
                        <Column caption="OrderBy" dataField="orderBy" dataType="string" width={250} />
                        <Column caption="OrderNr" visible={false} dataField="orderNr" dataType="number" width={90} alignment={"center"} sortIndex={0} sortOrder={"asc"} />
                        <Column
                            caption="Weergeven"
                            dataField="display"
                            width={40}
                            allowSorting={false}
                            cellRender={displayString}
                            allowFiltering={false}
                        ></Column>
                        <Column
                            width={60}
                            alignment="center"
                        />
                        <Editing
                            mode="row"
                            allowAdding={true}
                            allowUpdating={true}
                            allowDeleting={true}
                            selectTextOnEditStart
                            useIcons={true}
                        >
                            <Texts
                                confirmDeleteTitle="Item verwijderen"
                                confirmDeleteMessage="Weet u zeker dat u dit item wilt verwijderen?"
                            />
                        </Editing>
                    </DataGrid>
                </section>
            )}
            <BloomFooter />
        </main>
    );
};

export default Search;
