import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react";

const Samengesteld = ({
  fieldValue,
  setFieldValue,
  checkChanges,
  isCreate,
}) => {
  useEffect(() => {
    let tempFieldValue = fieldValue;
    if (isCreate) {
      tempFieldValue["readonly"] = true;
      setFieldValue(tempFieldValue);
    }
  }, []);

  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Alleen lezen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox defaultValue={true} readOnly={true} />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Samengestelde kolommen</Form.Label>
          </Col>
          <Col lg="12">
            <TextBox
              defaultValue={fieldValue.defaultValue && fieldValue.defaultValue}
              valueChangeEvent="keyup"
              placeholder="[Kolom] tekst [Kolom]"
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["defaultValue"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default Samengesteld;
