import React from "react";

import logoGrey from "../../assets/images/logo_grey.svg";

const PoweredByBloom = () => {
  return (
    <section className="powered-by-bloom">
      <p>Powered by Bloom</p>

      <img src={logoGrey} className="bl-footer-logo" alt="Bloom-logo-grey" />
    </section>
  );
};

export default PoweredByBloom;
