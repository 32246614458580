import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import appSettings from "../../../appsettings";
import { acquireTokenRequest } from "../../../api/MsalFetch";
import BloomFooter from "../../footer/BloomFooter";
import "./reports.scss";

const ReportDesigner = () => {
    const [token, setToken] = useState();
    const { push } = useHistory();
    //const hostname  = useLocation();
    const hostname = window.location.hostname
    console.log("ReportDesigner.js - hostname", hostname);
    useEffect(() => {
        (async () => {
            const tokenRequest = await acquireTokenRequest();
            setToken(tokenRequest.accessToken);
        })();
    }, []);

    return (
        <div className="bl-report-designer">
            <main className="bl-report-designer-content">
                <header className="bl-report-controls">
                    <h2>Report designer</h2>{" "}

                </header>
                <iframe
                    title="report"
                    id="report"
                    className="bl-iframe-report"
                    src={`${appSettings.reporting.endpoint}/home/designer?access_token=${token}&domain=${hostname}`}
                ></iframe>
                <BloomFooter />
            </main>
        </div>
    );
};

export default ReportDesigner;
