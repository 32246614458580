import React, { useState, useEffect } from "react";

import { useHistory } from "react-router";

const ConfigPageControls = ({
  saveFunction,
  changeInForm,
  customCancelClick,
  hasDuplicate,
  duplicateFunction,
  haseExitFunction,
  exitPageFunction,
  hasRefreshButton,
  refreshFunction,
  setChangeInForm,
  noExitButton,
}) => {
  const link = useHistory();

  return (
    <div className="bl-config-page-controls">
      <div
        className={`bl-btn bl-save-btn bl-btn-with-label ${
          changeInForm ? "" : "bl-disabled-btn"
        }`}
        onClick={saveFunction}
      >
        <i className="fas fa-save" /> <span> Opslaan</span>
      </div>

      {hasDuplicate && (
        <div
          className={`bl-btn bl-duplicate-btn bl-btn-with-label `}
          onClick={duplicateFunction}
        >
          <i className="fas fa-clone" /> <span> Dupliceren</span>
        </div>
      )}

      {hasRefreshButton && (
        <div
          className={`bl-btn bl-refresh-btn `}
          onClick={() => refreshFunction(true)}
        >
          <i className="fas fa-sync" />
        </div>
      )}

      {!noExitButton && (
        <div
          className={`bl-btn bl-exit-btn `}
          onClick={() => (haseExitFunction ? exitPageFunction : link.goBack())}
        >
          <i className="fas fa-times" />
        </div>
      )}
    </div>
  );
};

export default ConfigPageControls;
