import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import ModalControls from "../parts/modalControls/ModalControls";
import { Form, Row, Col } from "react-bootstrap";
import { TextBox } from "devextreme-react";
import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings";
import { Popup } from 'devextreme-react/popup';
import toast from "react-hot-toast";

const EditFtpConfiguration = ({
    isCreate,
    setIsEditing,
    isEditing,
    data,
    throwNotification,
    setThrowNotification
}) => {
    console.log("EditFtpConfiguration", data);
    const msal = useMsal();
    const [changeInForm, setChangeInForm] = useState(false);

    const [form, setForm] = useState({ ...data });

    const handleExitClick = () => {
        if (changeInForm) {
            setThrowNotification(true);
        } else {
            setIsEditing(false);
        }
    };

    const checkChanges = () => {
        if (!changeInForm) {
            setChangeInForm(true);
        }
    };

    const checkMandatoryField = (value, target) => {
        if (value.trim() !== "") {
            target.remove("bl-mandatory-field");
        } else {
            target.add("bl-mandatory-field");
        }
    };

    const handleSaveClick = async () => {
        /*setIsEditing(false);
        //alert('moetnog');
        return;

        */
        //var url = `${appSettings.api.endpoint}/api/configurator/organisationSettings/ftp/update/${form.id}`;
        const url = isCreate ? `${appSettings.api.endpoint}/api/configurator/organisationSettings/ftp/insert` : `${appSettings.api.endpoint}/api/configurator/organisationSettings/ftp/update`

        console.log("EditFtpConfiguration - handleSaveClick - url", url);
        const res = await msalFetch(msal, url, {
            method: "PUT",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(form),
        });
        if (res.ok) {
            setIsEditing(false);
            toast.success("De gegevens zijn successvol opgeslagen...");
        } else {
            toast.error("Oeps er ging iets mis...");
        }
    }

    useEffect(() => {
        console.log("FTP useEffect", form, data);
        if (isEditing) {
            setForm({ ...data })

            //if (!isCreate) {
            //    //setForm(data);
            //    setForm({ ...data });
            //} else if (isCreate) {
            //    ;
            //}
        }
    }, [isEditing]);

    //useEffect(() => {

    //})

    return (<Popup
        className="bl-popup"
        showTitle={true}
        title={isCreate ? "Toevoegen" : "Wijzigen"}
        resizeEnabled={true}
        visible={true}
        showCloseButton={true}
        onHiding={(e) => { setIsEditing(false); }}

    >
        <div className="bl-modal-inner">
            <ModalControls
                handleSaveClick={handleSaveClick}
                handleExitClick={handleExitClick}
                hasExitFunction={true}
                modalTitle={"FTP-configuratiegegevens"}
                changeInForm={changeInForm}
                setThrowNotification={setThrowNotification}
                throwNotification={throwNotification}
                hasPreview={false}
            />
            <div className="bl-scroll-box active-scroll-box">
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Naam</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Naam..."
                                className={isCreate && "bl-mandatory-field"}
                                defaultValue={form.name}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    console.log("Data before:", form, data);
                                    let tempForm = form;
                                    tempForm["name"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                    checkMandatoryField(e.value, e.element.classList);
                                    console.log("Data after:", form, data);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Host</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Host..."
                                className={isCreate && "bl-mandatory-field"}
                                defaultValue={form.server}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm["server"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                    checkMandatoryField(e.value, e.element.classList);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Login</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Login..."
                                className={isCreate && "bl-mandatory-field"}
                                defaultValue={form.login}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm["login"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                    checkMandatoryField(e.value, e.element.classList);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Port</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Port..."
                                className={isCreate && "bl-mandatory-field"}
                                defaultValue={form.port}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm["port"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                    checkMandatoryField(e.value, e.element.classList);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Password</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Password..."
                                className={isCreate && "bl-mandatory-field"}
                                defaultValue={form.password}
                                valueChangeEvent="keyup"
                                mode="password"
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm["password"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                    checkMandatoryField(e.value, e.element.classList);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </div>
        </div>
    </Popup>)
};


export default EditFtpConfiguration
