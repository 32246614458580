import React, { useEffect, useState, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, SelectBox } from "devextreme-react";
import Loading from "../../../loading/Loading";
import appSettings from "../../../../appsettings";
import msalFetch from "../../../../api/MsalFetch.js";
import { ColumnType } from "../../../../enums/columnType.js";

const Gebruiker = ({ fieldValue, setFieldValue, checkChanges, isCreate, userTableId }) => {
    console.log("Gebruiker.js - userTableId", userTableId);
    const [weergaves, setWeergaves] = useState([]);
    const [colourColumns, setColourColumns] = useState([]);
    const [weergaveLoading, setWeergaveLoading] = useState(!isCreate);
    const [hasWeergave, setHasWeergave] = useState(!isCreate);
    const [groupFields, setGroupFields] = useState([]);
    const _defaultValueNone = 16; //to Enum in CS
    const _defaultValueLoggedInUser = 17; ////to Enum in CS

    const getWeergaves = useCallback(async () => {
        let kleurenKolommenFilter = [];
        setWeergaveLoading(true);
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/dataset/${userTableId}/column/`);
            const data = await response.json();

            const weergaveFilter = data.filter(
                (t) =>
                    t.primairyKey === false &&
                    t.type !== "uniqueidentifier" &&
                    t.columnType !== null &&
                    t.columnType.code !== "COLOR"
            );

            if (
                data.filter(
                    (t) =>
                        t.columnType && t.columnType.code && t.columnType.code === "COLOR"
                )
            ) {
                data.map((t) => {
                    if (
                        t.columnType &&
                        t.columnType.code &&
                        t.columnType.code === "COLOR"
                    ) {
                        return kleurenKolommenFilter.push(t);
                    }
                });
            }

            const waardeKollommenFilter = data.filter(
                (t) => t.type === "int" || t.type === "uniqueidentifier"
            );

            console.log(waardeKollommenFilter);
            setWeergaves(weergaveFilter);
            //setWaardes(waardeKollommenFilter);

            if (kleurenKolommenFilter.length > 0) {
                setColourColumns(kleurenKolommenFilter);
            }

            setWeergaveLoading(false);

            return data;
        } catch (error) {
            console.log(error);
            setWeergaveLoading(false);
            throw "Data Loading Error";
        }
    }, [userTableId]);

    const loadDatasourceGroupFields = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/configurator/dataset/${fieldValue.inputTableId}/column`;
        try {
            const res = await msalFetch(null, url);
            const data = await res.json();
            const items = data.filter(item => item.columnTypeId === ColumnType.SELECT);
            console.log("[SL] loadDatasourceGroupFields", items);
            // debugger;
            setGroupFields(items);
        } catch (error) {
            throw error;
        }
    }, [fieldValue.inputTableId]);

    useEffect(() => {
        //getColumn();
        //getDatasets();
        //if (!isCreate) {
        //getWeergaves(fieldValue.inputTableId);
        //checkVerwijzing(fieldValue.inputTableId);

        /*if (fieldValue.inputTableId) {
            setHasAfhankelijkKolom(true);
        }*/

        //}
        getWeergaves(userTableId);
        loadDatasourceGroupFields();
    }, [userTableId, getWeergaves, loadDatasourceGroupFields /*getColumn, getDatasets, checkVerwijzing, fieldValue.inputTableId, isCreate*/]);

    return (
        <>
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Alleen lezen</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={fieldValue.readonly ? fieldValue.readonly : false}
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["readonly"] = e.value;
                                setFieldValue(tempFieldValue);
                                console.log(fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Verplicht</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={fieldValue.mandatory ? fieldValue.mandatory : false}
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["mandatory"] = e.value;
                                setFieldValue(tempFieldValue);
                                console.log(fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Standaard waarde</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            searchEnabled
                            defaultValue={isCreate ? _defaultValueNone : (fieldValue.columnDefaultValue == null ? _defaultValueNone : fieldValue.columnDefaultValue.id)}
                            //className={isCreate && "bl-mandatory-field"}
                            valueExpr="id"
                            displayExpr="name"
                            items={fieldValue.columnType.columnDefaultValues}
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["columnDefaultValue"] =
                                    fieldValue.columnType.columnDefaultValues.filter(
                                        (item) => item.id === e.value
                                    )[0];
                                setFieldValue(tempFieldValue);
                                console.log(fieldValue);
                                e.element.classList.remove("bl-mandatory-field");
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col lg="3">
                        <Form.Label>Kleur</Form.Label>
                    </Col>
                    <Col lg="3">
                        <SelectBox
                            searchEnabled
                            items={colourColumns}
                            valueExpr="id"
                            displayExpr="name"
                            defaultValue={
                                fieldValue.inputColorColumnId && fieldValue.inputColorColumnId
                            }
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["inputColorColumnId"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                    <Col lg="3">Weergave</Col>
                    <Col lg="3">
                        <SelectBox
                            searchEnabled
                            items={weergaves}
                            valueExpr="id"
                            displayExpr="alias"
                            className={`${isCreate && "bl-mandatory-field"} ${weergaveLoading ? "bl-readonly-field" : ""}`}
                            defaultValue={
                                fieldValue.inputDisplayColumnId &&
                                fieldValue.inputDisplayColumnId
                            }
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                e.element.classList.remove("bl-mandatory-field");

                                tempFieldValue["inputDisplayColumnId"] = e.value;

                                setFieldValue(tempFieldValue);
                                if (!hasWeergave) {
                                    setHasWeergave(true);
                                }

                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col lg="3">Afbeelding tonen</Col>
                    <Col lg="3">
                        <CheckBox
                            defaultValue={fieldValue.extraField1 === 'true'}
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["extraField1"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}

                        />
                    </Col>
                    <Col lg="3">
                            <Form.Label>Label </Form.Label>
                        </Col>
                        <Col lg="3">
                            <TextBox
                                type="input"
                                placeholder="Bijvoorbeeld: [Naam] <br />[AdressLine1]"
                                defaultValue={fieldValue.inputLabel}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["inputLabel"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    checkChanges();
                                }}
                            />
                        </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col lg="3">
                        <Form.Label>Groepering </Form.Label>
                    </Col>
                    <Col lg="3">
                        {groupFields &&
                            <SelectBox
                                searchEnabled
                                items={groupFields}
                                valueExpr="id"
                                displayExpr="alias"
                                showClearButton={true}
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["groupColumnId"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    checkChanges();
                                }}
                                defaultValue={fieldValue.groupColumnId}

                            />}

                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Voldoet aan</Form.Label>
                                </Col>
                                <Col lg="9">
                                    <TextBox
                                        type="input"
                                        placeholder="Voldoet aan (SQL WHERE)"
                                        defaultValue={
                                            fieldValue.inputTableQueryWhere &&
                                            fieldValue.inputTableQueryWhere
                                        }
                                        valueChangeEvent="keyup"
                                        onValueChanged={(e) => {
                                            let tempFieldValue = fieldValue;
                                            tempFieldValue["inputTableQueryWhere"] = e.value;
                                            setFieldValue(tempFieldValue);
                                            //console.log(fieldValue);
                                            checkChanges();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>Sorteren op</Form.Label>
                                </Col>
                                <Col lg="9">
                                    <TextBox
                                        type="input"
                                        placeholder="Sorteren op (SQL ORDER)"
                                        defaultValue={
                                            fieldValue.inputTableQueryOrderBy &&
                                            fieldValue.inputTableQueryOrderBy
                                        }
                                        valueChangeEvent="keyup"
                                        onValueChanged={(e) => {
                                            let tempFieldValue = fieldValue;
                                            tempFieldValue["inputTableQueryOrderBy"] = e.value;
                                            setFieldValue(tempFieldValue);
                                            //console.log(fieldValue);
                                            checkChanges();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
        </>
    );
};

export default Gebruiker;
