import React, { memo, useState, useRef, useEffect, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TextBox } from "devextreme-react";
import { encode as base64_encode } from "base-64";
import toast from "react-hot-toast";
import Loading from "../loading/Loading";
import PreviewImage from "../modals/Previews/PreviewImage";
import { Spinner } from "react-bootstrap";
import { confirm } from 'devextreme/ui/dialog';

import FieldLabel from "../detailview/FieldLabel";

const strEndsWith = str => ending => (str || "").toLowerCase().endsWith(ending);

const getFileType = (str) => {
    const endsWith = strEndsWith(str);
    switch (true) {
        case endsWith('.jpg'):
        case endsWith('.jpeg'):
        case endsWith('.png'):
        case endsWith('.gif'):
        case endsWith('.webp'):
        case endsWith('.bmp'):
        case endsWith('.tiff'):
            return 'image';
        case endsWith('.xls'):
        case endsWith('.csv'):
            return 'excel';
        case endsWith('.pdf'):
            return 'pdf';
        case endsWith('.ppt'):
            return 'powerpoint';
        case endsWith('.doc'):
        case endsWith('.docx'):
        case endsWith('.odt'):
            return 'word';
        case endsWith('.html'):
        case endsWith('.htm'):
            return 'code';
        case endsWith('.msg'):
            return 'lines';
        default:
            return null;
    }
}

const getDownload = (fileType) => {
    switch (fileType) {
        case 'pdf':
            return false;
        case 'code':
            return false;
        case 'image':
            return true;
        default:
            return true;
    }
}

const FILE = ({
    label,
    value,
    mandatory,
    readonly,
    columnId,
    clickOnImageOpensPreview = true,
    openExternalInsteadOfDownload = false,
    registerLoaded,
    onControlUpdate,
    httpFilePath,
    ftp,
    memoField,
    thumbnailLarge,
    forceReload,
    handleSaveClick,
    isCreate
}) => {
    const msal = useMsal();
    console.log("thumbnailLarge", thumbnailLarge);
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const dragCount = useRef(0); // we need this because every element will trigger a leave
    const fileUploadRef = useRef();
    const [fileUploading, setFileUploading] = useState(false);
    const [fileDownloading, setFileDownloadLoading] = useState(false);
    const [previewLoading, setPreviewLoading] = useState(false);
    // const [imageUrl, setImageUrl] = useState(thumbnailLarge ? `${thumbnailLarge}` : null);
    const [imageUrl, setImageUrl] = useState(thumbnailLarge ? (thumbnailLarge.startsWith('data') ? `${thumbnailLarge}` : (`data:image/png;base64, ${thumbnailLarge}`)) : null);
    const [openPreviewImage, setOpenPreviewImage] = useState(false);
    const [dropzoneActive, setDropzoneActive] = useState(false);
    const fileType = getFileType(value);
    const urlUpload = ftp ? `${appSettings.api.endpoint}/api/ftp/upload/${columnId}` : `${appSettings.api.endpoint}/api/file/upload`;

    const uploadFile = useCallback(async (file) => {
        if (!file) {
            return;
        }

        setFileUploading(true);
        const formData = new FormData();
        formData.append('file', file);
        let data;
        try {
            const res = await msalFetch(msal,
                urlUpload,
                {
                    method: "POST",
                    //headers: { "Content-type": "application/json" },
                    body: formData,
                }
            );
            data = await res.json();
            if (data && data.fileName) {
                toast.success("Bestand is succesvol opgeslagen");
            } else {
                toast.success("Oeps er ging even iets mis...");
            }
            setFileUploading(false);
            return data;
        } catch (error) {
            console.error(error);
            setFileUploading(false);
            throw error;
        }
    }, [msal, urlUpload]);

    const getFileName = (path) => {
        let pathname = new URL(path).pathname;
        let filename = pathname.substring(pathname.lastIndexOf('/') + 1);
        return filename;
    }

    const handleFileUpload = useCallback(async (file) => {
        const uploadedFileData = await uploadFile(file);
        setImageUrl(null);
        let filepath = ftp ? (httpFilePath + '/' + uploadedFileData.fileName) : uploadedFileData.fileName;
        await onControlUpdate(columnId, filepath);
    }, [onControlUpdate, columnId, uploadFile, ftp, httpFilePath, /*forceReload, handleSaveClick*/]);

    const handleFileUploadInput = useCallback(async (e) => {
        await handleFileUpload(e.target.files[0]);
    }, [handleFileUpload]);

    const handleDeleteClick = useCallback(() => {
        //let filename = uploadedFileData.fileName; //next line deletes it again
        onControlUpdate(columnId, null);
        fileUploadRef.current.value = null;
        if (ftp) {
            let filename = getFileName(value); //next line deletes it again
            try {
                const encodedFileName = base64_encode(filename);
                msalFetch(msal, `${appSettings.api.endpoint}/api/ftp/delete/${columnId}/${encodedFileName}`, {
                    method: "DELETE",
                    headers: { "Content-type": "application/json" },
                });
            } catch (error) {
                console.log("handleDeleteClick - FTP delete");
            }
        }
        // we have to do this to unlink the files[0] from the input
        // if we don't, the file input will still contain a file even after deleting it
        // reuploading the same file will not work in that case

    }, [onControlUpdate, columnId, value, ftp, msal]);

    const getPreview = useCallback(async (downloadPass) => {
        const download = downloadPass ? true : getDownload(fileType);
        setPreviewLoading(true);
        let imageObjectURL;

        try {
            if (ftp) {
                setPreviewLoading(false);
                return value;
            }
            else {
                const res = await msalFetch(null,
                    `${appSettings.api.endpoint}/api/table/downloadfile/${value.replace('&', '[AMPERSAND]')}/?download=${download}` // I dont know what this download boolean is for. It does not seem to change the output, Laurens Kling 2022
                );

                const resData = await res.blob();
                imageObjectURL = URL.createObjectURL(resData);
                setPreviewLoading(false);
                return imageObjectURL;
            }
        } catch (error) {
            setPreviewLoading(false);
            console.error(error);
            throw error;
        }


    }, [value, fileType, ftp]);

    const handleFileDownload = useCallback(async (e) => {
        console.log("handleFileDownload", handleFileDownload);
        setFileDownloadLoading(true);

        // use the image we have or fetch a new one
        // but we would always have on probably, so we never do the `true` forced thing
        // I dont know what its for, so i don't know if we should enforce it, Laurens Kling 2022
        const imageObjectURL = await getPreview(true);

        const link = document.createElement("a");
        link.style.display = 'none';
        link.href = imageObjectURL;
        link.setAttribute("download", `${value}`);
        if (typeof link.download === 'undefined') {
            link.setAttribute('target', '_blank');
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setFileDownloadLoading(false);
    }, [getPreview, value]);

    useEffect(() => {
        if (value && !thumbnailLarge) {
            (async () => {
                const imageObjectURL = await getPreview();
                console.log("imageObjectURL", imageObjectURL)
                setImageUrl(imageObjectURL);
            })();
        }
    }, [value, getPreview, thumbnailLarge]);

    const handleUploadButtonClick = useCallback(async (e) => {
        e.stopPropagation();
        fileUploadRef.current.click();
    }, []);

    const closePreview = useCallback(() => {
        setOpenPreviewImage(false);
    }, []);

    const openPreview = useCallback(() => {
        setOpenPreviewImage(true);
    }, []);

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCount.current++;
        setDropzoneActive(true);
    }, []);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDropzoneActive(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCount.current--;
        if (dragCount.current > 0) {
            return;
        }
        setDropzoneActive(false);
    }, []);

    const handleDrop = useCallback(async (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCount.current = 0;
        setDropzoneActive(false);
        const files = [...e.dataTransfer.files];
        handleFileUpload(files[0]);
    }, [handleFileUpload]);

    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={label}
                        columnId={columnId}
                        memoField={memoField}
                        mandatory={mandatory}

                    />
                </Col>

                <Col md="12">
                    <section
                        className={`bl-file-box ${dropzoneActive ? "dropzone-active" : ""} ${(mandatory && !value) ? "bl-mandatory-field" : ""}`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                    >
                        <div className="file-preview">
                            {previewLoading ? (
                                <Loading />
                            ) : (
                                <div
                                    className="preview-container"
                                    onClick={openPreview}
                                >
                                    {(fileType === "image" && imageUrl) ? (
                                        <div className="image-container">
                                            <img src={imageUrl} alt={`${label} is geen geldige afbeelding`} />
                                        </div>
                                    ) : (value ? (
                                        <span className="file-preview-file">
                                            <i
                                                className={`fa fa-file${fileType ? `-${fileType}` : ""}`}
                                            />
                                        </span>
                                    ) : (
                                        <div
                                            onClick={handleUploadButtonClick}
                                            className="bl-content-footer"
                                        >
                                            <i className="fa fa-upload" />
                                            <p>Voeg een bestand toe via de knop of door in dit vak te slepen</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {openPreviewImage && (
                                <PreviewImage
                                    url={imageUrl}
                                    title={value}
                                    closeFunction={closePreview}
                                    ftp={ftp}
                                />
                            )}
                        </div>

                        <Form.Group className="bl-file-field">
                            <TextBox
                                value={value}
                                readOnly={true}
                                stylingMode="filled"
                                // className="bl-readonly-field bl-file-label-text"
                                onInitialized={onInitialized}
                            />

                            {clickOnImageOpensPreview && (
                                <div
                                    className="bl-view-btn bl-btn"
                                    onClick={openPreview}
                                >
                                    <i className="fas fa-magnifying-glass" />
                                </div>
                            )}

                            <div
                                className={`bl-upload-btn bl-btn  ${value ? " bl-disabled-btn" : ""}`}
                                onClick={handleUploadButtonClick}
                            >
                                {fileUploading ? (
                                    <Spinner
                                        className="bl-spinner"
                                        animation="border"
                                        role="status"
                                        size="sm"
                                    />
                                ) : (
                                    <i className="fas fa-upload" />
                                )}
                            </div>


                            {!openExternalInsteadOfDownload && (
                                <div
                                    className={`bl-btn bl-download-file ${!value ? "bl-disabled-btn" : ""}`}
                                    onClick={handleFileDownload}
                                >
                                    {fileDownloading ? (
                                        <Spinner
                                            className="bl-spinner"
                                            animation="border"
                                            role="status"
                                            size="sm"
                                        />
                                    ) : (
                                        <i className="fas fa-download" />
                                    )}
                                </div>
                            )}
                            {openExternalInsteadOfDownload && (
                                <div className={`bl-btn bl-download-file ${!value ? "bl-disabled-btn" : ""}`}>
                                    <a href={imageUrl} target="_blank" rel="noreferrer">
                                        <i className="fas fa-arrow-up-right-from-square" />
                                    </a>
                                </div>
                            )}

                            <div
                                className={`bl-btn bl-delete-file ${!value ? "bl-disabled-btn" : ""}`}
                                onClick={async () => {
                                    if (ftp) {
                                        let result = await confirm("Weet u zeker dat u dit item wilt verwijderen? <br /><br /><em>Let op:</em> het bestand wordt <em>direct</em> van de FTP-server verwijderd!", "Item verwijderen?");
                                        if (result)
                                            handleDeleteClick();
                                    } else {
                                        handleDeleteClick();
                                    }
                                }}
                            >
                                <i className="fas fa-trash" />
                            </div>

                            <Form.File
                                ref={fileUploadRef}
                                onChange={handleFileUploadInput}
                            />
                        </Form.Group>
                    </section>
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(FILE);
