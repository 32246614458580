import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";
import { CheckBox, DataGrid } from "devextreme-react";
import toast from "react-hot-toast";
import { useGraphContext } from "../graph/context";
import { Button } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import {
    Column,
    FilterRow,
    Scrolling,
    Grouping,
    GroupPanel,
    Summary,
    TotalItem,
    GroupItem,
    Editing,
    Paging,
    Pager,
    Position,
    SortByGroupSummaryInfo,
    ColumnChooser,
    Item,
    Toolbar,
} from "devextreme-react/data-grid";

const Meldingen = ({
    checkChanges,
    form,
    setForm,
    overviews,
    overviewLoading,
}) => {
    const { setNrOfUnprocessedNotifications, nrOfUnprocessedNotifications } = useGraphContext();
    const [meldingen, setMeldingen] = useState();




    const getNotifications = useCallback(async () => {
        //setLoading(true);
        try {
            const query = await msalFetch(null,
                `${appSettings.api.endpoint}/api/notifications/all`
            );
            const data = await query.json();
            setMeldingen(formatDataRecord(data));
            setNrOfUnprocessedNotifications(data.length);
            //  setLoading(false);
            return data;
        } catch (error) {
            throw error;
        }
    }, []);

    const onCheckClick = useCallback(async (e) => {

        try {
            let result = await confirm("Weet u zeker dat u alle notificaties op 'gelezen' wilt zetten?", "Notificaties gelezen");
            if (!result) {
                return;
            }
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/notifications/processed`);
            if (!res.ok) {
                throw new Error(`[Meldingen] handleInlineCheckChange response was not ok: ${res.statusText}`);
            }
            getNotifications();
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        }
    }, [getNotifications]);

    const formatDataRecord = (data) => {
        const formattedData = data.map(rec => {
            //return rec.message = `${rec.message} - ${rec.id}`;
            console.log(rec)
            rec.formattedMessage = `${rec.message} (id: ${rec.key})`;
            rec.hasBeenProcessed = rec.processedOn !== null
            return rec;
        })
        return formattedData;
    };
    const allowedPageSizes = [10, 20, 50, "all"];

    const onProcessNotification = useCallback(async (e) => {
        try {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/notifications/processed/${e.key.id}`);
            if (!res.ok) {
                throw new Error(`[Meldingen] handleInlineCheckChange response was not ok: ${res.statusText}`);
            }
            getNotifications();
        } catch (error) {
            toast.error("Oeps er ging even iets mis...");
            throw error;
        }

    }, [getNotifications]);

    useEffect(() => {
        getNotifications();
    }, [getNotifications]);


    return (
        <main className="bl-content">
            <header className="">
                <h2>Meldingen van acties</h2>
            </header>
            <div className="bl-card">
                <DataGrid
                    dataSource={meldingen}
                    showBorders={true}
                    showRowLines={false}
                    showColumnLines={true}
                    allowColumnResizing
                    wordWrapEnabled={false}
                    rowAlternationEnabled={true}
                    // allowSearch={true}
                    // remoteOperations={true}
                    //wordWrapEnabled={true}
                    onRowUpdating={onProcessNotification}
                    repaintChangesOnly={true}

                >
                    <Toolbar>
                        <Item name="columnChooserButton" location="after" />
                        <Item location="after">
                            <Button
                                type="default"
                                stylingMode="outlined"
                                icon="fa fa-check"
                                onClick={onCheckClick}
                            />
                        </Item>
                    </Toolbar>
                    <ColumnChooser enabled={true}>
                        {/* <Position
                            my="right top"
                            at="right bottom"
                            of=".dx-datagrid-column-chooser-button"
                            offset="0 50"
                        /> */}

                    </ColumnChooser>


                    <Editing
                        mode="cell"
                        refreshMode={"reshapw"}
                        allowUpdating={true}
                    />

                    <Paging defaultPageSize={100} />
                    <Pager
                        visible={true}
                        allowedPageSizes={allowedPageSizes}
                        displayMode="full"
                        showPageSizeSelector={true}
                        showInfo={true}
                        showNavigationButtons={true}
                    />
                    <Grouping autoExpandAll={true} contextMenuEnabled allowCollapsing={true} />
                    <GroupPanel visible={false} />
                    <Scrolling mode="infinitye" />
                    <FilterRow visible={true} showAllText="Alle" />



                    <Column
                        dataField="createdOn"
                        dataType="datetime"
                        caption="Tijdstip"
                        allowSearch={true}
                        format="dd-MM-yyyy HH:mm"
                        sortIndex={0}
                        sortOrder="desc"
                        allowEditing={false}
                        width={130}
                    />
                    <Column
                        dataField="type"
                        dataType="string"
                        caption="Actie"
                        allowSearch={true}
                        groupIndex={0}
                        allowEditing={false}


                    ></Column>
                    <Column
                        dataField="tableName"
                        caption="Tabel"
                        dataType="string"
                        width={"10%"}
                        allowSearch={true}
                        allowEditing={false}
                    />
                    <Column
                        dataField="type"
                        caption="Actie/ kolom"
                        dataType="string"
                        width={"15%"}
                        allowSearch={true}
                        allowEditing={false}
                    />
                    <Column
                        dataField='formattedMessage'
                        caption="Melding"
                        dataType="string"
                        width={"50%"}
                        allowSearch={true}
                        allowEditing={false}
                    />
                    <Column

                        dataField="hasBeenProcessed"
                        dataType="boolean"
                        caption="Gelezen"
                        //cellRender={inlineCheckBox}
                        alignment="center"
                        allowSorting={false}
                        allowSearch={false}

                    >
                    </Column>

                    <Summary>
                        <GroupItem
                            column="type"
                            summaryType="count"
                            displayFormat="{0}"
                        />
                        <TotalItem
                            column="type"
                            summaryType="count" />

                    </Summary>
                    <SortByGroupSummaryInfo summaryItem="sum" sortOrder="desc" />
                </DataGrid>

            </div>
        </main >
    );
};

export default Meldingen;
