import React, { memo, useState, useEffect, useCallback } from 'react';
import appSettings from "../../../appsettings";
import msalFetch from '../../../api/MsalFetch';
import Menu from './menu';
import logo from "../../../../src/assets/images/logo_grey.svg";
import { useGraphContext } from "../../../components/graph/context"
import { confirm } from 'devextreme/ui/dialog';
import toast from "react-hot-toast";

const createPath = ({ overviewId, schedulerId, menuItems }) => {
    if (overviewId) {
        return `/overview/${overviewId}`;
    }
    if (schedulerId) {
        return `/scheduler/${schedulerId}`;
    }
    if (menuItems?.length === 1) {
        return createPath(menuItems[0]);
    }
    return null;
}


const createChildren = ({ menuItems }) => {
    if (menuItems.length > 1) {
        return menuItems.map(createMenuItems);
    }
    return null;
}

const createMenuItems = (item) => {

    return ({
        text: item.name,
        icon: item.icon ? `fa ${item.icon}` : null,
        path: createPath(item),
        items: item.menuItems ? createChildren(item) : null,
    })
};

export const ContentMenu = (props) => {
    const [menu, setMenu] = useState(null);

    useEffect(() => {
        const getMenu = async () => {
            const url = `${appSettings.api.endpoint}/api/Menu`;

            const response = await msalFetch(null, url);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Menu] /api/Menu response was not ok: ${data.message}`);
            }
            setMenu(data.menus);
        };
        getMenu();
    }, []);

    const onInsertFileThumbnailsClick = useCallback(async () => {
        let result = await confirm("Weet u zeker dat u alle bestaande FILE images wilt omzetten naar thumbnails?", "Bevestig omzetting");
        if (result) {
            try {
                const res = await msalFetch(null,
                    `${appSettings.api.endpoint}/api/thumbnail/ConvertAllFileImagesToThumbnail`);
                if (res.ok) {
                    toast.success('Thumbnails omgezet');
                } else {
                    const json = await res.json();
                    toast.error(json);
                }
            } catch (error) {
                toast.error("Oeps er ging even iets mis...");
                throw error;
            }
        };
    }, []);

    const onInsertFtpThumbnailsClick = useCallback(async () => {
        let result = await confirm("Weet u zeker dat u alle bestaande FTP images wilt omzetten naar thumbnails?", "Bevestig omzetting");
        if (result) {
            try {
                const res = await msalFetch(null,
                    `${appSettings.api.endpoint}/api/thumbnail/ConvertAllFtpImagesToThumbnail`);
                if (res.ok) {
                    toast.success('Thumbnails omgezet');
                } else {
                    const json = await res.json();
                    toast.error(json);
                }
            } catch (error) {
                toast.error("Oeps er ging even iets mis...");
                throw error;
            }
        };
    }, []);

    const { currentUser } = useGraphContext();
    const { version } = require('../../../../package.json');
    const items = [{
        text: "Dashboard",
        path: "/",
        icon: "fas fa-tachometer-alt",
    }].concat(menu ? menu.map(createMenuItems) : []).concat(currentUser?.admin === 1 && (
        {
            text: "Bloom beheer",
            icon: logo,

            items: [
                {
                    text: "Tabellen",
                    path: "/configurator/datasets",
                    icon: "fas fa-table",
                },
                {
                    text: "Menu",
                    path: "/configurator/menu",
                    icon: "fas fa-list",
                }

                , {
                    text: "Zoekbalk",
                    path: "/configurator/search",
                    icon: "fas fa-search",
                },
                // {
                //     text: "Gebruikers",
                //     path: "/configurator/users",
                //     icon: "fas fa-users",
                // },
                {
                    text: "Rollen",
                    path: "/configurator/roles",
                    icon: "fas fa-lock",
                },
                {
                    text: "Report Designer",
                    path: "/reports",

                    icon: "fas fa-file"
                }, {
                    text: "FTP",
                    path: "/configurator/organisationSettings",
                    icon: "fas fa-sliders-up",
                }, {
                    visible: currentUser.email.toLowerCase().endsWith("hallomorgen.nl"),
                    text: 'Thumbnails maken (FTP)',
                    icon: "fas fa-plus",
                    onClick: onInsertFtpThumbnailsClick
                }, {
                    visible: currentUser.email.toLowerCase().endsWith("hallomorgen.nl"),
                    text: 'Thumbnails maken (File)',
                    icon: "fas fa-plus",
                    onClick: onInsertFileThumbnailsClick
                }, {
                    text: "Connectors",
                    path: "/configurator/connectors",
                    icon: "fas fa-link",
                }, {
                    text: "Dashboards",
                    path: "/configurator/dashboards",
                    icon: "fas fa-table-columns"
                },{
                    text: version,
                    icon: logo,
                }


            ],
        }
    )
    ).filter(Boolean).concat(currentUser?.overruleAdmin && (
        {
            text: "Roltester",
            icon: logo,
            path: "/configurator/roles",

        }
    )
    ).filter(Boolean)

        ;

    return (
        <Menu
            {...props}
            items={items}
        />
    );
};

// use memo to prevent it from collapsing on route changes
export default memo(ContentMenu);
