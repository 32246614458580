import PivotGrid from "./PivotGrid";

const PivotGridWrapper = ({ 
    dataSource,
    showChart,
    showPivotGrid,
    showFieldChooser,
 }) => {
    console.log("[PIVOTGRIDWRAPPER]", dataSource);
    return (<PivotGrid
        showChart={showChart}
        showPivotGrid={showPivotGrid}
        showFieldChooser={showFieldChooser}
        dataSource={dataSource}
    />)
};
export default PivotGridWrapper