import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import appSettings from "../../../appsettings";
import ModalControls from "../parts/modalControls/ModalControls";
import { Col, Row } from "react-bootstrap";

import Form from "react-bootstrap/Form";

import { useHistory } from "react-router";
import msalFetch from "../../../api/MsalFetch.js";
import toast, { Toaster } from "react-hot-toast";

import { useModalContext } from "../../../context/modalContext";
import { TextBox } from "devextreme-react";

const AddRole = () => {
    const msal = useMsal();
    const link = useHistory();
    const [changeInForm, setChangeInForm] = useState(false);
    const [form, setForm] = useState();

    const {
        setIsEditing,
        setFormInValidNotification,
        setNotificationData,
        isEditing,
    } = useModalContext();
    console.log(form);
    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const saveFunction = async () => {
        let allDocument = document.getElementsByClassName("bl-mandatory-field");
        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);

            return null;
        }

        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/role`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(form),
                }
            );

            const response = await res.json();
            if (res.ok) {
                toast.success("De gegevens zijn successvol opgeslagen...");
                console.log(response);
                link.push(`/configurator/roles/${response.id}`);
                setChangeInForm(false);
                setIsEditing(false);
            } else {
                toast.error("Oeps er ging iets mis...");
            }
            return;
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    useEffect(() => {
        setForm({ name: "", roleTable: [] });
    }, [isEditing]);

    return (
        <main className="bl-modal-inner">
            <ModalControls
                modalTitle="Rol"
                changeInForm={changeInForm}
                handleSaveClick={saveFunction}
            />
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Naam</Form.Label>
                    </Col>
                    <Col lg="12" key={isEditing}>
                        <TextBox
                            placeholder="Naam..."
                            className="bl-mandatory-field"
                            valueChangeEvent="keyup"
                            onValueChanged={(e) => {
                                let tempForm = form;
                                tempForm["name"] = e.value;
                                checkChanges();
                                setForm(tempForm);

                                e.element.classList.remove("bl-mandatory-field");
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </main>
    );
};
export default AddRole;
