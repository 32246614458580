import msalFetch from "../../../api/MsalFetch.js";
import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "devextreme-react";
import appSettings from "../../../appsettings";
import { Container } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import {
    Column,
    FilterRow,
    Lookup,
    Scrolling,
    Editing,
    Paging,
    LoadPanel,
} from "devextreme-react/data-grid";
import { useModalContext } from "../../../context/modalContext";
import toast from "react-hot-toast";
import DeleteItem from "../../notifications/userLeaveNotifications/notifications/DeleteItem";
import BloomFooter from "../../footer/BloomFooter";
import Dataset from "../dataset/Dataset";

const Users = () => {
    const msal = useMsal();
    const [loadingActiveUsers, setLoadingActiveUsers] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingInactiveUsers, setLoadingInactiveUsers] = useState(true);
    const [activeUsers, setActiveUsers] = useState();
    const [inactiveUsers, setInactiveUsers] = useState();
    const [roleLoading, setRoleLoading] = useState(true);
    const [roles, setRoles] = useState();
    const [deleteNotification, setDeleteNotification] = useState(false);
    const [deleteTargetId, setDeleteTargetId] = useState();
    const [activeTab, setActiveTab] = useState(0);

    const {
        isEditing,
        setIsEditing,
        setModal,
        setModalContent,
    } = useModalContext();

    const getUsers = useCallback(async (active) => {
        active && setLoadingActiveUsers(true);
        !active && setLoadingInactiveUsers(true);
        setLoading(true);
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/user`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Users] getUsers response was not ok: ${data.message}`);
            }

            const filteredData = data.filter((item) => item.active === active);
            console.log("Users - filteredData", filteredData)
            setActiveUsers(data.filter((item) => item.active));
            setInactiveUsers(data.filter((item) => !item.active));

            if (active) {
                setLoadingActiveUsers(false);
            } else {
                setLoadingInactiveUsers(false);
            }
            setLoading(false);
        } catch (error) {
            throw error;
        }
    }, []);

    const refreshUsers = useCallback(() => {
        const active = activeTab === 0 ? true : false;
        console.log("Users.js - refreshUsers - active", active);
        return getUsers(active)
    }, [activeTab, getUsers]);

    const getRoles = useCallback(async () => {
        setRoleLoading(true);
        try {
            const response = await msalFetch(msal, `${appSettings.api.endpoint}/api/configurator/role`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Users] getRoles response was not ok: ${data.message}`);
            }
            setRoles(data);
            setRoleLoading(false);
        } catch (error) {
            setRoleLoading(false);
            throw error;
        }
    }, []);

    const updateUser = useCallback(async (user) => {
        try {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/user/${user.id}`, {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify({
                    ...user
                    //admin: user.admin === true ? 1 : 0,
                }),
            });
            if (!res.ok) {
                throw new Error(`[Users] updateUser response was not ok`);
            }
            toast.success("Gebruiker " + user.firstname + " " + user.name + " aangepast");
            refreshUsers();
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.error(error);
            throw error;
        }
    }, [refreshUsers]);

    const handleEditClick = useCallback((data) => {
        setModalContent(data.data);
        setIsEditing(true);
        setModal("EDITUSER");
    }, [setIsEditing, setModal, setModalContent]);

    const handleDeleteClick = useCallback((data) => {
        setDeleteTargetId(data.data.id);
        setDeleteNotification(true);
    }, []);

    const editButton = useCallback((data) => {
        return (
            <div className="bl-column-controls" >
                <i
                    className="fas fa-pencil-alt bl-column-control-btn bl-column-edit-btn"
                    onClick={() => handleEditClick(data)}
                />
                {activeTab === 0 && (
                    <i
                        className="fas fa-trash-alt bl-column-control-btn bl-column-delete-btn"
                        onClick={() => handleDeleteClick(data)}
                    />
                )}
            </div >
        );
    }, [handleEditClick, handleDeleteClick, activeTab]);


    const handleAddClick = useCallback(() => {
        setModalContent(null);
        setIsEditing(true);
        setModal("ADDUSER");
    }, [setIsEditing, setModal, setModalContent]);

    const addNewButton = useCallback(() => {
        return (
            <div className="bl-config-btn-wrapper" >
                <div
                    className="bl-btn bl-add-btn bl-config-header-btn"
                    onClick={handleAddClick}
                    title="Nieuwe gebruiker toevoegen"
                >
                    <i className="fas fa-plus" />
                </div >
            </div >
        );
    }, [handleAddClick]);

    const handleDeleteFunction = useCallback(async () => {
        try {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/user/${deleteTargetId}`, {
                method: "DELETE",
                headers: { "Content-type": "application/json" },
            });
            if (!res.ok) {
                throw new Error(`[Users] handleDeleteFunction response was not ok`);
            }
            toast.success("Het item is successvol verwijderd...");
            setDeleteNotification(false);
            refreshUsers();
        } catch (error) {
            toast.error("Gebruiker kon niet worden verwijderd...");
            setDeleteNotification(false);
            console.log(error);
        }
    }, [deleteTargetId, refreshUsers]);

    const onRowUpdated = useCallback(async (e) => {

        const user = {
            ...e.data,
            admin: e.data.admin ? 1 : 0,
            documentarian: e.data.documentarian ? 1 : 0,
            overruleAdmin: e.data.overruleAdmin
        };
        await updateUser(user);
    }, [updateUser]);

    const renderDataset = (datasource) => {
        console.log("Users - inactiveUsers", inactiveUsers)
        return <Container fluid>
            <DataGrid
                dataSource={datasource}
                allowColumnReordering={true}
                allowColumnResizing={true}
                rowAlternationEnabled={true}
                showColumnLines={false}
                showRowLines={true}
                columnAutoWidth={false}
                onRowUpdated={onRowUpdated}
                remoteOperations={false}
            >
                <FilterRow visible={true} showAllText="Alle" />
                <Scrolling
                    mode="virtual"
                    preloadEnable="false"
                    showScrollbar="always"
                />
                <Paging defaultPageSize={250} />
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={false}
                    allowDeleting={false}
                />
                <LoadPanel enabled={true} />
                <Column
                    caption="Voornaam"
                    dataField="firstname"
                    dataType="string"
                    sortIndex={0}
                    sortOrder="asc"
                />
                <Column
                    caption="Achternaam"
                    dataField="name"
                    dataType="string"
                    sortIndex={1}
                    sortOrder="asc"
                    minWidth={120}
                />
                <Column
                    caption="Email"
                    dataField="email"
                    dataType="string"
                    minWidth={200}
                />
                <Column
                    caption="Admin"
                    dataField="admin"
                    alignment="center"
                    dataType="boolean"

                />
                <Column
                    caption="Manuals"
                    dataField="documentarian"
                    alignment="center"
                    dataType="boolean"

                />
                <Column
                    caption="Roltester"
                    dataField="overruleAdmin"
                    alignment="center"
                ></Column>
                <Column
                    caption="Actief"
                    dataField="active"
                    alignment="center"
                >
                </Column>
                <Column
                    alignment="left"
                    caption="Rol"
                    dataField="role"
                    minWidth={70}

                >
                    <Lookup
                        dataSource={roles}
                        valueExpr="id"
                        displayExpr="name"
                    />
                </Column>
                <Column cssClass="config-readonly-field"
                    caption="Laatste login"
                    dataField="lastLogin"
                    dataType="datetime"
                    format="dd-MM-yyyy HH:mm"
                    alignment="right"
                    allowEditing={false}
                />
                <Column
                    alignment="center"
                    width={80}
                    cellRender={editButton}
                    headerCellRender={addNewButton}
                />
            </DataGrid>
        </Container>
    }

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    useEffect(() => {
        console.log("Users.js - useEffect(..., [setIsEditing])", isEditing)
        if (!isEditing)
            refreshUsers();
    }, [isEditing, refreshUsers])

    return (
        <main className="bl-content">
            <header className="bl-configurator-title-header">
                <h1>Gebruikers</h1>
            </header>
            <header className="bl-tab-control" id="tabTarget">
                <nav className="bl-tab-nav">
                    <div className="bl-tab-wrapper">
                        <ul className="bl-tab-list">
                            <li
                                className={`bl-tab ${activeTab === 0 && "bl-active-tab"}`}
                                onClick={(e) => setActiveTab(0)}
                            >
                                Actief
                            </li>
                            <li
                                className={`bl-tab ${activeTab === 1 && "bl-active-tab"}`}
                                onClick={(e) => setActiveTab(1)}
                            >
                                Inactief
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <section className="bl-card">
                {activeTab === 0 && !loading && renderDataset(activeUsers)}
                {activeTab === 1 && !loading && renderDataset(inactiveUsers)}

                {deleteNotification && (
                    <DeleteItem
                        handleDeleteFunction={handleDeleteFunction}
                        setOpendDeleteNotification={setDeleteNotification}
                    />
                )}
            </section>
            <BloomFooter />
        </main>
    );
};

export default Users;
