import React, { useState, useContext, useEffect, useCallback } from "react";

//creates context/helpers/services for the entire app
export const AppContext = React.createContext();

//Provider
export const AppProvider = ({ children }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [changeInForm, setChangeInForm] = useState(false);

    //   menu context
    const [menuToggle, setMenuToggle] = useState(true);
    const toggleMenu = useCallback(() => setMenuToggle(!menuToggle), [menuToggle]);

    //theme context
    const [theme, setTheme] = useState("classic-theme");

    const [showUserpanelSubmenu, setShowUserpanelSubmenu] = useState(false);

    //set global search modal
    const [globalSearch, setGlobalSearch] = useState(false);

    const [activeMenuLink, setActiveMenuLink] = useState(1000);
    const [mobileMenuToggle, setMobileMenuToggle] = useState(false);


    useEffect(() => {
        const updateSize = () => {
            if (window.innerWidth < 769) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
            setScreenWidth(window.innerWidth);
        }
        window.addEventListener("resize", updateSize);
        return () => {
            window.removeEventListener("resize", updateSize)
        };
    }, []);

    return (
        <AppContext.Provider
            value={{
                toggleMenu,
                menuToggle,
                setMenuToggle,
                showUserpanelSubmenu,
                setShowUserpanelSubmenu,
                theme,
                setTheme,
                globalSearch,
                setGlobalSearch,
                activeMenuLink,
                setActiveMenuLink,
                isMobile,
                screenWidth,
                mobileMenuToggle,
                setMobileMenuToggle,
                changeInForm,
                setChangeInForm,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useGlobalContext = () => {
    return useContext(AppContext);
};
