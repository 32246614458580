import React, { useCallback, useRef, useState, useMemo } from "react";
import appSettings from "../../../appsettings";
import DataGrid, {
    Column,
    Selection,
    Toolbar,
    Button as DataGridButton,
    Item
} from "devextreme-react/data-grid";
import { Button as ToolbarButton } from 'devextreme-react/button';
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import msalFetch from "../../../../src/api/MsalFetch.js";
import { Popup } from 'devextreme-react/popup';
import { Lookup, TextBox } from "devextreme-react";
import { DropDownOptions } from "devextreme-react/lookup";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PopupEditConnector from "./PopupEditConnector.js";
import PopupAddConnector from "./PopupAddConnector.js";
import { confirm } from 'devextreme/ui/dialog';
import toast from "react-hot-toast";

const SchedulerFields = ['Subject', 'StartDate', 'EndDate', 'UserId', 'Location', 'Key', 'Importance', 'Body']

const IndexConnectors = () => {
    const [selectedTableId, setSelectedTableId] = useState();
    const [selectedConnectorId, setSelectedConnectorId] = useState(null);
    const [selectedValues, setSelectedValues] = useState({});
    //const [tables, setTables] = useState();
    const [addConnector, setAddConnector] = useState(false);

    const [columns, setColumns] = useState();

    // const columnsRef = useRef();

    const submit = async (name) => {
        const url = `${appSettings.api.endpoint}/api/configurator/connector/${selectedConnectorId.id}/update/${name}`;
        const entries = Object.entries(selectedValues);
        let columns = [];
        for (let c = 0; c < entries.length; c++) {
            let value = {};
            value['propertyName'] = entries[c][0];
            value['columnId'] = entries[c][1];
            columns.push(value);
        }
        const res = await msalFetch(null,
            url,
            {
                method: "POST",
                headers: { "Content-type": "application/json" },
                //body: JSON.stringify({ name: name, ...selectedValues }),
                body: JSON.stringify({ name: name, connectorColumns: columns }),
            }
        );

    };



    const onEditConnector = useCallback((e) => {
        console.log("🚀 ~ file: indexConnectors.js:67 ~ onEditConnector ~ e:", selectedTableId, Number(e.row.data.tableId))
        setSelectedConnectorId(e.row.data);
        setSelectedTableId(Number(e.row.data.tableId));
    }, [selectedTableId]);

    const loadConnectors = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/configurator/connector/index`;
        const response = await msalFetch(null, url);
        const json = await response.json();
        return json;
    }, []);

    const dataSource = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: loadConnectors,
            // update: updateServerRecord,
            // remove: deleteServerRecord,
        })
    }), [loadConnectors]);

    const refresh = useCallback(() => {
        dataSource.reload();
    }, [dataSource]);

    const onRowClick = useCallback((e) => {
        setSelectedConnectorId(e.data);
        setSelectedTableId(Number(e.data.tableId));
    }, []);

    const onDeleteConnector = useCallback(async (e) => {
        console.log("onDeleteConnector", e);
        let result = await confirm("Weet u zeker dat u deze connector wilt verwijderen?", "Connector verwijderen?");
        if (!result) {
            return;
        }
        const url = `${appSettings.api.endpoint}/api/configurator/connector/delete/${e.row.key}`;
        const res = await msalFetch(null,
            url,
            {
                method: "DELETE",
                headers: { "Content-type": "application/json" }
            }
        );
        const data = await res.json();
        if (data.success) {
            toast.success("De connector is verwijderd");
            dataSource.reload();
        } else {
            toast.error("Er is iets misgegaan");
        }
    }, [dataSource])

    return (<>
        <main className={"bl-content"}>
            <header >
                <h2 className="listHeader">Connectoren</h2>
            </header>
            {/* <section className={`bl-overview ${isOverview && !isMobile && "bl-card"} ${isMobile && "bl-mobile-card"} ${inlineEditMode && isOverview && "inlineEditMode"}`} ></section> */}
            <DataGrid
                dataSource={dataSource}
                showBorders={true}
                showRowLines={false}
                rowAlternationEnabled={true}
                showColumnLines={false}
                onRowClick={onRowClick}
            >

                <Toolbar>
                    <Item location="after">
                        <ToolbarButton
                            icon="fas fa-plus"
                            onClick={() => { setAddConnector(true); }}
                        />
                    </Item>
                </Toolbar>
                <Selection mode="single" />
                <Column
                    caption="Naam"
                    dataField="name"
                    dataType="string"
                />
                <Column
                    caption="Type"
                    dataField="type"
                    dataType="string"
                />
                <Column
                    caption="Entity"
                    dataField="entity"
                    dataType="string"
                />
                <Column
                    caption="Tabel"
                    dataField="tableName"
                    dataType="string"
                />
                <Column
                    caption="Laatste sync."
                    dataField="lastSyncDeleted"
                    dataType="datetime"
                />
                <Column
                    caption="Gewijzigd door"
                    dataField="bl_modifiedName"
                    dataType="string"
                />
                <Column
                    caption="Gewijzigd op"
                    dataField="bl_modified"
                    dataType="datetime"
                />

                <Column type="buttons" width="auto" caption="Acties">
                    <DataGridButton hint="Bewerken" icon="fas fas fa-pen-to-square" onClick={onEditConnector} />
                    <DataGridButton hint="Verwijder dit record" icon="trash" /* cssClass="alert" */ onClick={onDeleteConnector} />
                    {/* 
            <DataGridButton hint="Verwijder dit record" icon="trash" cssClass="trashButton" onClick={handleDeleteClick } />
            <DataGridButton hint="Test connectie" icon="fas fa-plug-circle-bolt" onClick={onConnectionTestClick} /> */}
                </Column>
            </DataGrid>
        </main>
        {selectedConnectorId && <PopupEditConnector
            selectedConnectorId={selectedConnectorId}
            setSelectedConnectorId={setSelectedConnectorId}
            schedulerFields={SchedulerFields}
            submit={submit}
            //dataSourceColumns={dataSourceColumns}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            selectedTableId={selectedTableId}
            refresh={refresh}
        />}
        {addConnector && <PopupAddConnector
            setAddConnector={setAddConnector}
            selectedTableId={selectedTableId}
            setSelectedTableId={setSelectedTableId}
            loadConnectors={loadConnectors}
        />}
    </>


    );
};

export default IndexConnectors;
