import React, { useState, useEffect } from "react";
import appSettings from "../../../appsettings";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import msalFetch from "../../../api/MsalFetch.js";
import Loading from "../../loading/Loading";
import ColumnGrid from "./datasetGrids/ColumnGrid";
import PageGrid from "./datasetGrids/PageGrid";
import SetGrid from "./datasetGrids/SetGrid";
import ListGrid from "./datasetGrids/ListGrid";
import VisualsGrid from "./datasetGrids/VisualsGrid.js";

const tabs = ["1. Velden", "2. Sets", `3. Pagina's`, "4. Lijsten", "5. Visuals"];

const TabGrid = ({ currentDataset }) => {
    const { id, activeGrid } = useParams();
    const activeTab = Number(activeGrid) || 0;
    const [columnData, setColumnData] = useState();
    const [loading, setLoading] = useState(true);
    const [columnTypes, setColumnTypes] = useState();

    //gets data for de 'Velden' tab
    const getDatasetColumn = async () => {
        setLoading(true);
        try {
            const response = await msalFetch(undefined,
                `${appSettings.api.endpoint}/api/configurator/dataset/${id}/column`
            );
            const data = await response.json();

            setColumnData(data);

            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    };

    const getColumnTypes = async () => {
        try {
            const response = await msalFetch(undefined,
                `${appSettings.api.endpoint}/api/configurator/column/type`
            );
            const data = await response.json();

            //Take out type 'Geen'

            let newTypes = data.filter((t) => t.name !== "Geen");

            setColumnTypes(newTypes);

            return data;
        } catch (error) {
            throw "Data Loading Error";
        }
    };

    const refreshFunction = () => {
        getDatasetColumn();
    };

    useEffect(() => {
        getDatasetColumn();
        getColumnTypes();
    }, []);

    if (loading) {
        return <Loading />;
    } else {
        return (
            <div className="bl-tabgrid-wrapper">
                <header className="bl-tab-control">
                    <nav className="bl-tab-nav">
                        <ul className="bl-tab-list">
                            {tabs.map((tab, index) => {
                                return (
                                    <Link
                                        key={index}
                                        to={`/configurator/dataset/${id}/${index}`}
                                        className={`
                                            bl-tab
                                            ${index === activeTab ? "bl-active-tab" : ""}
                                        `}
                                    >
                                        {tab}
                                    </Link>
                                );
                            })}
                        </ul>
                    </nav>
                </header>

                {activeTab === 0 && (
                    <ColumnGrid
                        columnData={columnData}
                        columnTypes={columnTypes}
                        getDatasetColumn={refreshFunction} // im not sure if this is needed from here..
                        currentDataset={currentDataset}
                    />
                )}

                {activeTab === 1 && (
                    <SetGrid
                        columns={columnData}
                    />
                )}

                {activeTab === 2 && (
                    <PageGrid
                        tableId={id}
                    />
                )}

                {activeTab === 3 && (
                    <ListGrid
                        columnData={columnData}
                    />
                )}
                {activeTab === 4 && <VisualsGrid
                    tableId={id}
                />}
            </div>
        );
    }
};

export default TabGrid;
