import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, SelectBox } from "devextreme-react";

const Berekend = ({ fieldValue, setFieldValue, checkChanges, isCreate }) => {
  const numeriekValidationOptions = ["Numeriek (AutoNummering)"];
  const [standaardWaarde, setStandaardWaarde] = useState(
    fieldValue.defaultValue
  );
  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Alleen lezen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox readOnly={true} value={true} />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Berekende Kolommen</Form.Label>
          </Col>
          <Col lg="12">
            <TextBox
              className={isCreate && "bl-mandatory-field"}
              type="input"
              defaultValue={fieldValue.defaultValue && fieldValue.defaultValue}
              placeholder="([Kolom] - [Kolom]) * [Kolom]"
              valueChangeEvent="keyup"
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["defaultValue"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                // updateStandaardWaarde(e.value);
                setStandaardWaarde(e.value);

                checkChanges();
                if (e.value.trim() === "") {
                  e.element.classList.add("bl-mandatory-field");
                } else {
                  e.element.classList.remove("bl-mandatory-field");
                }
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Standaard waarde</Form.Label>
          </Col>
          <Col lg="12">
            <TextBox
              key={standaardWaarde}
              type="input"
              readOnly={true}
              value={standaardWaarde}
              placeholder="Standaard waarde"
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Weergave</Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              items={fieldValue.columnType.columnDisplayValues}
              valueExpr="id"
              className={isCreate && "bl-mandatory-field"}
              displayExpr="name"
              defaultValue={!isCreate && fieldValue.columnDisplayValue.id}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                if (isCreate) {
                  tempFieldValue.columnDisplayValue = { id: "" };
                  tempFieldValue.columnDisplayValue.id = e.value;
                } else {
                  tempFieldValue.columnDisplayValue =
                    fieldValue.columnType.columnDisplayValues.filter(
                      (item) => item.id === e.value
                    )[0];
                }

                console.log(tempFieldValue);
                e.element.classList.remove("bl-mandatory-field");
                setFieldValue(tempFieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default Berekend;
