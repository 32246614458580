import { Popup } from 'devextreme-react/popup';
import toast from "react-hot-toast";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import "../news/News.scss";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";
import { useGraphContext } from '../graph/context';
import HtmlEditor, {
    Toolbar,
    Item,
} from "devextreme-react/html-editor";

const headerValues = [false, 1, 2, 3, 4, 5];
const sizeValues = ["8pt", "9pt", "10pt", "11pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Courier New", "Georgia", "Impact", "Lucida"];

const Instructions = ({
    name,
    detailviewId,
    onClose,
}) => {
    const { currentUser } = useGraphContext();
    const isDocumentarian = currentUser?.documentarian === 1;

    const [fullScreen, setFullScreen] = useState(false);
    const [instructions, setInstructions] = useState();
    const [detailviewName, setDetailviewName] = useState(null);

    const fetchInstructions = useCallback(async () => {
        const response = await msalFetch(undefined,
            `${appSettings.api.endpoint}/api/instructions/detailview/${detailviewId}/get`
        );
        const data = await response.json();
        // @TODO: fail?
        setInstructions(data.instructions);
        setDetailviewName(data.detailviewName);
    }, [detailviewId]);

    const updateInstructions = useCallback(async (instructionsToUpdate) => {
        const res = await msalFetch(null,
            `${appSettings.api.endpoint}/api/instructions/detailview/${detailviewId}/update`,
            {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(instructionsToUpdate),
            });
        if (res.ok) {
            toast.success('Wijziging opgeslagen');
            onClose(true);
        } else {
            toast.error("Oeps er ging iets mis...");
        }

    }, [detailviewId, onClose]);

    const fullScreenIcon = (!fullScreen ? 'fas fa-expand-arrows-alt' : 'fas fa-window-minimize');
    const onInstructionsChanged = useCallback((e) => {
        setInstructions(e.value);
    }, []);

    const onHiding = useCallback((e) => {
        e.cancel = true;
        onClose();
    }, [onClose]);

    const toolbarItems = useMemo(() => [
        {
            toolbar: "top",
            widget: "dxButton",
            location: "after",
            // className: "Button",
            options:
            {
                icon: fullScreenIcon,
                text: 'Volledig scherm',
                stylingMode: 'text',
                type: 'default',

                onClick: (e) => {

                    setFullScreen(!fullScreen);
                }
            }
        },
        {
            toolbar: "bottom",
            widget: "dxButton",
            location: "center",
            visible: isDocumentarian,
            options: {
                text: 'Opslaan',
                onClick: (e) => {
                    e.cancel = true;
                    updateInstructions(instructions);
                }
            }
        }
    ], [fullScreen, isDocumentarian, instructions, updateInstructions]);

    useEffect(() => {
        fetchInstructions();
    }, [fetchInstructions]);

    if (!detailviewName) {
        return null;
    }

    return (
        <Popup
            className="bl-popup"
            showTitle={true}
            title={`Gebruiksaanwijzing ${detailviewName}`}
            resizeEnabled={true}
            dragEnabled={true}
            animation={false}
            visible={true}
            showCloseButton={true}
            onHiding={onHiding}
            fullScreen={fullScreen}
            toolbarItems={toolbarItems}
        >
            <HtmlEditor
                className="bl-html-editor bl-html-news"
                value={instructions}
                valueType="html"
                readOnly={!isDocumentarian}
                stylingMode="outlined"
                onValueChanged={onInstructionsChanged}
            >
                {isDocumentarian && (
                    <Toolbar multiline={true}>
                        <Item name="undo" />
                        <Item name="redo" />
                        <Item name="separator" />
                        <Item name="size" acceptedValues={sizeValues} />
                        <Item name="font" acceptedValues={fontValues} />
                        <Item name="separator" />
                        <Item name="bold" />
                        <Item name="italic" />
                        <Item name="strike" />
                        <Item name="underline" />
                        <Item name="separator" />
                        <Item name="alignLeft" />
                        <Item name="alignCenter" />
                        <Item name="alignRight" />
                        <Item name="alignJustify" />
                        <Item name="separator" />
                        <Item name="orderedList" />
                        <Item name="bulletList" />
                        <Item name="separator" />
                        <Item name="header" acceptedValues={headerValues} />
                        <Item name="separator" />
                        <Item name="color" />
                        <Item name="background" />
                        <Item name="separator" />
                        <Item name="link" />
                        <Item name="image" />
                        <Item name="separator" />
                        <Item name="clear" />
                        <Item name="codeBlock" />
                        <Item name="blockquote" />
                        <Item name="separator" />
                        <Item name="insertTable" />
                        <Item name="deleteTable" />
                        <Item name="insertRowAbove" />
                        <Item name="insertRowBelow" />
                        <Item name="deleteRow" />
                        <Item name="insertColumnLeft" />
                        <Item name="insertColumnRight" />
                        <Item name="deleteColumn" />
                    </Toolbar>
                )}
            </HtmlEditor>
        </Popup>
    )
}

export default Instructions;
