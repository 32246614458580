import React, { useState, useEffect, useRef, useCallback } from 'react';
import MsgReader from '@kenjiuno/msgreader';
import saveAs from "file-saver";
// import { decompressRTF } from '@kenjiuno/decompressrtf';

const Msg = ({ url }) => {
  const info = useRef(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch(url).then(r => r.arrayBuffer()).then((blob) => {
      const msg = new MsgReader(blob);
      info.current = msg;
      setData(msg.getFileData());
    });
  }, [url]);

  const onDownloadClick = useCallback((attachment) => (e) => {
    const { content } = info.current.getAttachment(attachment);
    saveAs(
      new Blob([content], { type: "application/octet-stream" }),
      attachment.fileName,
    );
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div>
          <div id="msgrow">
              <div id="msglabel"><strong>Van:</strong></div><div id="msgcontent"> {data.senderName} [{data.senderSmtpAddress}]</div>
              </div>
          <div id="msgrow">
              <div id="msglabel"><strong> Aan:</strong></div><div id="msgcontent">
        <ul>
          {data.recipients?.map(({ name, smtpAddress }) => (
            <li key={name}>
              {name} [{smtpAddress}]
            </li>
          ))}
        </ul>
              </div></div>

          <div id="msgrow">
          <div id="msglabel"><strong>Op:</strong>  </div>
              <div id="msgcontent">{data.messageDeliveryTime}</div>
              </div>

          <div id="msgrow">
              <div id="msglabel"
                 ><strong>Onderwerp:</strong></div><div id="msgcontent"> {data.subject}</div>
          </div>
          <div id="msgrow">
              <div id="msglabel"><strong>Bijlagen:</strong></div>

              {data.attachments.filter(({ attachmentHidden }) => !attachmentHidden).map((att) => {
                  return (

                      <button id="msgattachments" key={att.dataId}
                          className="bl-btn"
                          onClick={onDownloadClick(att)}
                      >
                          <i className="fa fa-download" style={{ marginRight: '6px' }} />
                          {att.fileName}
                      </button>

                  )
              })}

          </div>
          <div id="msgrow"><strong>_____________________________________________________________________________</strong></div>
          <div id="msgrow"><strong>Inhoud:</strong></div>
          <div id="msgrow">
              <div id="msgcontent" dangerouslySetInnerHTML={{ __html: data.body.replaceAll('\r\n\r\n', '<br />') }} />
              </div>



    </div>
  );
}

export default Msg;
