import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import toast, { Toaster } from "react-hot-toast";
import msalFetch from "../../../../api/MsalFetch.js";
import appSettings from "../../../../appsettings";
import { alert } from 'devextreme/ui/dialog';

import DataGrid, {
    Column,
    Lookup,
    FilterRow,
    Paging,
    Pager,
    Sorting,
    Button as DataGridButton,
    Grouping,
    GroupPanel,
    HeaderFilter,
    LoadPanel,
    ColumnFixing,
    Scrolling,
} from "devextreme-react/data-grid";
import EditVeld from "../../../modals/editVeld/EditVeld";

import DeleteItem from "../../../notifications/userLeaveNotifications/notifications/DeleteItem";

const ColumnGrid = ({
    columnData,
    columnTypes,
    getDatasetColumn,
    currentDataset,
}) => {
    const msal = useMsal();
    const datasetId = useParams();

    const [deleteTarget, setDeleteTarget] = useState();

    const [isEditing, setIsEditing] = useState(false);
    const [editData, setEditData] = useState();

    const [deleteNotification, setDeleteNotification] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const displayType = (data) => {
        let formattedDisplayType = data.value;
        if (data.value === "Geen") {
            formattedDisplayType = "";
        }
        return formattedDisplayType;
    };

    let deleteButtonHover = false;

    const boolOptions = [
        { value: false, displayValue: "Nee" },
        { value: true, displayValue: "Ja" },
    ];

    const allowedPageSizes = [10, 20, 50, "all"];

    const callibrateRow = (rowData) => {
        if (rowData.rowType === "data") {
            if (rowData.data.name.includes("bl_cre") || rowData.data.name.includes("bl_mod") || rowData.data.name === "Id") {
                rowData.rowElement.classList.add("bl-muted-row");
            }
        }
    };

    const handleAddNewClick = () => {
        setEditData({ isCreate: true, copyable: true });
        setIsEditing(true);
    };

    function addNewButton() {
        return (
            <div className="bl-config-btn-wrapper">
                <div
                    className="bl-btn bl-add-btn bl-config-header-btn"
                    onClick={handleAddNewClick}
                >
                    <i className="fas fa-plus" />
                </div>
            </div>
        );
    }
    // const handleRowClick = useCallback(async (columnData) => {
    //     // if (columnData.column.cssClass && columnData.column.cssClass === "bl-column-controls" && columnData.rowType !== "filter" && columnData.rowType !== "header" && columnData.rowType !== "group") {

    //     //     return;
    //     // }
    //     if (columnData.data.name === 'Id') {
    //         await alert('Deze kolom is een systeemkolom en kan niet worden bewerkt', 'Systeemkolom');
    //     }

    // }, []);

    const handleEditColumn = useCallback(async (columnData) => {
        if (columnData.rowType && (columnData.rowType === "header" || columnData.rowType === "group" || columnData.rowType === "filter")) {
            return;
        } else if (columnData.data.name === 'Id') {
            await alert('Deze kolom is een systeemkolom en kan niet worden bewerkt', 'Systeemkolom');
        } else {
            setEditData(columnData.data);
            setIsEditing(true);
        }
    }, []);

    const handleDeleteColumn = (columnData) => {
        setDeleteNotification(true);
        setDeleteTarget(columnData.key);
    };

    const handleDeleteFunction = async () => {
        const url = `${appSettings.api.endpoint}/api/configurator/dataset/${deleteTarget.tableId}/column/${deleteTarget.id}`;
        try {
            const res = await msalFetch(msal, url, {
                method: "DELETE",
                headers: { "Content-type": "application/json" },
            });
            if (res.ok) {
                toast.success("Het item is successvol verwijderd...");
                setDeleteNotification(false);
                getDatasetColumn();
            } else {
                toast.error("De kolom kan niet worden verwijderd. Zorg ervoor dat de gerelateerde sets, pagina's en lijsten zijn verwijderd, of selecteer een andere kolom.");
                setDeleteNotification(false);
            }
            return;
        } catch (error) {
            setDeleteNotification(false);

            toast.error("Oeps er ging even iets mis...");
        }
    };

    const columnEditControl = (columnData) => {
        if (columnData.data.isSystemColumn && columnData.data.name === 'Id') {
            return (
                <div className="bl-column-controls" style={{ height: "28px" }}></div>
            );
        } else {
            return (
                <div className="bl-column-controls">
                    <i
                        className="fas fa-pencil-alt bl-column-control-btn bl-column-edit-btn"
                        onClick={() => handleEditColumn(columnData)}
                    />
                    {!columnData.data.isSystemColumn &&
                        <i
                            onMouseOver={() => (deleteButtonHover = true)}
                            className="fas fa-trash bl-column-control-btn bl-column-delete-btn"
                            onClick={() => handleDeleteColumn(columnData)}
                        />}
                </div>
            );
        }
    };

    return (

        <div>
            <DataGrid
                dataSource={columnData}
                showBorders={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                onRowPrepared={callibrateRow}
                onRowClick={handleEditColumn}
                columnAutoWidth={false}
                allowColumnResizing={true}

            >
                <Paging defaultPageSize={200} />
                <Sorting mode="multiple" />
                <GroupPanel visible={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={true} expandMode="rowClick" />
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                />

                <FilterRow visible={true} />
                <ColumnFixing enabled={true} />
                <LoadPanel enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
                <Scrolling mode="standard" />

                <Column
                    dataField="alias"
                    fixed={true}
                    fixedPosition="left"
                    width={250}
                    sortIndex={0}
                    sortOrder="asc"
                />
                <Column dataField="name"
                    width={250}>
                </Column>

                <Column
                    dataField="columnType.name"
                    caption="Type"
                    // groupIndex={0}
                    width={175}
                    cellRender={displayType}
                    dataType="string"
                >

                </Column>
                <Column
                    dataField="isSystemColumn"
                    caption="Systeem"
                    width={130}
                />
                <Column
                    dataField="readonly"
                    caption="Alleen lezen"
                    width={130}>

                </Column>
                <Column
                    dataField="mandatory"
                    caption="Verplicht"
                    // displayExpr={boolOptions}
                    width={130}
                >

                </Column>
                <Column
                    dataField="copyable"

                    caption="Kopieerbaar"
                    width={130}
                >

                </Column>
                <Column
                    dataField="defaultValueDisplayValue"
                    caption="Standaard"
                    dataType="string"
                    width={130}
                ></Column>

                <Column
                    caption="Regex"
                    width={130}
                    dataField={"regex"}
                />
                <Column
                    width={80}
                    fixed={true}
                    fixedPosition="right"
                    cssClass="bl-column-controls"
                    alignment="right"
                    cellRender={columnEditControl}
                    headerCellRender={addNewButton}
                />
            </DataGrid>
            {isEditing && (

                <EditVeld
                    getDatasetColumn={getDatasetColumn}
                    currentDataset={currentDataset}
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    editData={editData}
                    datasetId={datasetId}
                    setData={columnData}
                    isCreate={isCreate}
                />
            )}

            {deleteNotification && (
                <DeleteItem
                    handleDeleteFunction={handleDeleteFunction}
                    refreshFunction={getDatasetColumn}
                    setOpendDeleteNotification={setDeleteNotification}
                />
            )}
        </div>
    );
};

export default ColumnGrid;
