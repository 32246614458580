import React, { useEffect, useState, useCallback, useMemo } from "react";
import Loading from "../../../loading/Loading";
import appSettings from "../../../../appsettings";
import msalFetch from "../../../../api/MsalFetch.js";
import Form from "react-bootstrap/Form";
import { Col, Row, Spinner } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, SelectBox } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store"
import { ColumnType } from "../../../../enums/columnType.js";

const Selectielijst = ({
    fieldValue,
    setFieldValue,
    checkChanges,
    isCreate,
    datasetId,
    currentDataset,
    groupColumn,
    userTableId,
    isSystemColumn,
}) => {
    console.log("[Selectielijst]", fieldValue, datasetId);
    const defaultValueDependent = false;
    const _defaultValueNone = 16; //to Enum in CS
    //console.log("fieldValue.dependent", fieldValue.dependent, fieldValue.dependent ?? defaultValueDependent);
    //console.log("fieldValue.defaultValue", fieldValue);
    const [columnLoading, setColumnLoading] = useState(true);
    const [datasetsLoading, setDatasetsLoading] = useState(false);
    const [weergaveLoading, setWeergaveLoading] = useState(!isCreate);
    const [refereceColumnLoading, setReferecenCOlumnLoading] = useState(!isCreate);

    // data states
    const [datasets, setDatasets] = useState([]);
    const [dependendColumns, setDependendColumns] = useState([]);
    const [weergaves, setWeergaves] = useState([]);
    const [waardes, setWaardes] = useState([]);
    const [colourColumns, setColourColumns] = useState([]);
    const [referenceColumns, setReferenceColumns] = useState([]);
    const [groupFields, setGroupFields] = useState([]);
    const [list, setList] = useState([]);
    const [imageColumns, setImageColumns] = useState([]);
    //bools
    const [hasWeergave, setHasWeergave] = useState(isCreate ? false : true);
    const [hasWaarde, setHasWaarde] = useState(false);
    const [hasAfhankelijkKolom, setHasAfhankelijkKolom] = useState(false);
    const [showDependent, setShowDependent] = useState(fieldValue.cascadingColumnId == null || fieldValue.cascadingColumnId === 0 ? defaultValueDependent : true)

    const getImageColumns = useCallback(async (inputTableId) => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/dataset/${inputTableId}/column/`);
            const data = await response.json();
            const filteredData = data.filter(column => column.columnTypeId === ColumnType.FILE);
            console.log("imageColumns", filteredData);
            setImageColumns(filteredData);

            return data;
        } catch (error) {
            throw "Data Loading Error";
        }
    }, []);

    const getSetList = useCallback(async (inputTableId) => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${inputTableId}/list/`
            );
            const data = await response.json();
            console.log(data);
            setList(data);

            return data;
        } catch (error) {
            throw "Data Loading Error";
        }
    }, []);

    const loadDatasourceGroupFields = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/configurator/dataset/${fieldValue.inputTableId}/column`;
        try {
            const res = await msalFetch(null, url);
            const data = await res.json();
            const items = data.filter(item => item.columnTypeId === ColumnType.SELECT);
            //console.log("[SL] loadDatasourceGroupFields", items);
            // debugger;
            setGroupFields(items);
        } catch (error) {
            throw error;
        }
    }, [fieldValue.inputTableId]);

    useEffect(() => {
        if (fieldValue?.inputTableId != null) {
            getSetList(fieldValue?.inputTableId);
            loadDatasourceGroupFields(fieldValue?.inputTableId);

            getImageColumns(fieldValue?.inputTableId);
        }

    }, [getSetList, fieldValue, loadDatasourceGroupFields, getImageColumns]);


    const handleStandardValueChanged = useCallback((e) => {
        setFieldValue(prevFieldValue => {
            const tempFieldValue = { ...prevFieldValue, defaultValue: e.value };
            checkChanges(tempFieldValue);
            return tempFieldValue;
        });
    }, [checkChanges, setFieldValue]);

    //main column query
    const getColumn = useCallback(async () => {
        setColumnLoading(true);
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId.id}/column/`
            );
            const data = await response.json();
            const afhankelijkvanKolommenFilter = data.filter(
                (t) =>
                    t.columnType !== null &&
                    // !t.name.includes("bl_") &&    --I don't think this is necessary anymore
                    (
                        t.columnType.id === 63)
            );
            //console.log("datasetId/column:");
            //console.log(data);

            setDependendColumns(afhankelijkvanKolommenFilter);
            setColumnLoading(false);
            return data;
        } catch (error) {
            setColumnLoading(false);
            throw "Data Loading Error";
        }
    }, [datasetId.id]);

    const getDatasets = useCallback(async () => {
        setColumnLoading(true);
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/`
            );
            const data = await response.json();
            setDatasets(data);

            //console.log("configurator/dataset");
            //console.log(data);
            setDatasetsLoading(false);
            return data;
        } catch (error) {
            setDatasetsLoading(false);
            throw "Data Loading Error";
        }
    }, []);

    const getWeergaves = useCallback(async (tableId) => {
        let kleurenKolommenFilter = [];
        //console.log(tableId);
        setWeergaveLoading(true);
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${tableId}/column/`
            );
            const data = await response.json();

            const weergaveFilter = data.filter(
                (t) =>
                    t.primairyKey === false &&
                    t.type !== "uniqueidentifier" &&
                    t.columnType !== null &&
                    t.columnType.code !== "COLOR"
            );

            if (
                data.filter(
                    (t) =>
                        t.columnType && t.columnType.code && t.columnType.code === "COLOR"
                )
            ) {
                data.map((t) => {
                    if (
                        t.columnType &&
                        t.columnType.code &&
                        t.columnType.code === "COLOR"
                    ) {
                        return kleurenKolommenFilter.push(t);
                    }
                });
            }

            const waardeKollommenFilter = data.filter(
                (t) => t.type === "int" || t.type === "uniqueidentifier"
            );

            //console.log(waardeKollommenFilter);
            setWeergaves(weergaveFilter);
            setWaardes(waardeKollommenFilter);

            if (kleurenKolommenFilter.length > 0) {
                setColourColumns(kleurenKolommenFilter);
            }

            setWeergaveLoading(false);

            return data;
        } catch (error) {
            console.log(error);
            setWeergaveLoading(false);
            throw "Data Loading Error";
        }
    }, []);

    const checkVerwijzing = useCallback(async (passedInputTableId) => {
        //console.log(passedInputTableId);
        if (!passedInputTableId) return;

        setReferecenCOlumnLoading(true);
        //console.log(fieldValue);

        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${passedInputTableId}/column`
            );
            const data = await response.json();
            //console.log(data);

            let tempVerwijzingKolommen = data.filter(
                (t) =>
                    t.columnType !== null &&
                    !t.name.includes("bl_") &&
                    t.columnType.id !== 12 &&
                    t.columnType.id !== 14 &&
                    t.columnType.id !== 15 &&
                    t.columnType.id !== 19 &&
                    t.columnType.id !== 21 &&
                    t.columnType.id !== 23 &&
                    t.columnType.id !== 24 &&
                    t.type === "int"
            );

            //console.log(tempVerwijzingKolommen);

            setReferenceColumns(tempVerwijzingKolommen);
            setReferecenCOlumnLoading(false);
        } catch (error) {
            setReferecenCOlumnLoading(false);
        }


    }, []);

    useEffect(() => {
        getColumn();
        getDatasets();
        if (!isCreate) {
            getWeergaves(fieldValue.inputTableId);
            checkVerwijzing(fieldValue.inputTableId);

            if (fieldValue.inputTableId) {
                setHasAfhankelijkKolom(true);
            }

        }
    }, [getColumn, getDatasets, getWeergaves, checkVerwijzing, fieldValue.inputTableId, isCreate]);

    const clearDependentValues = useCallback(() => {
        let tempFieldValue = fieldValue;
        tempFieldValue.inputTableId = null;
        tempFieldValue.inputDisplayColumnId = null;
        tempFieldValue.inputValueColumnId = null;
        tempFieldValue.columnDefaultValue = null;
        tempFieldValue.inputColorColumnId = null;
        tempFieldValue.cascadingColumnId = null;
        tempFieldValue.cascadingParentColumnId = null;
        tempFieldValue.inputTableQueryWhere = null;
        tempFieldValue.inputTableQueryOrderBy = null;
        setFieldValue(tempFieldValue);
    }, [fieldValue, setFieldValue])

    const typeAheadNumbers = useMemo(() => {
        return [
            { text: "Toont alles", value: null },
            { text: "1", value: 1 },
            { text: "2", value: 2 },
            { text: "3", value: 3 },
            { text: "4", value: 4 }
        ];
    }, []);

    if (datasetsLoading) {
        return <Loading />;
    } else {
        return (
            <div>
                {/* Alleen lezen veld */}
                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Alleen lezen</Form.Label>
                        </Col>
                        <Col lg="3">
                            <CheckBox
                                defaultValue={
                                    !isCreate && fieldValue.readonly ? fieldValue.readonly : false
                                }
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["readonly"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    //console.log(fieldValue);
                                    checkChanges();
                                }}
                                readOnly={isSystemColumn}
                            />
                        </Col>

                        <Col lg="3">
                            <Form.Label>Verplicht</Form.Label>
                        </Col>
                        <Col lg="3">
                            <CheckBox
                                defaultValue={fieldValue.mandatory ? fieldValue.mandatory : false}
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["mandatory"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    // console.log(fieldValue);
                                    checkChanges();
                                }}
                                readOnly={isSystemColumn}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Afhankelijk</Form.Label>
                        </Col>
                        <Col lg="3">
                            <CheckBox
                                defaultValue={showDependent}
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue.dependent = e.value;
                                    setFieldValue(tempFieldValue);
                                    setShowDependent(e.value); //seperate, fieldValue doesn't always rerender
                                    if (e.value === false) {
                                        clearDependentValues();
                                    }
                                    checkChanges();
                                }}
                                readOnly={isSystemColumn}
                            />
                        </Col>
                        <Col lg="3">
                            <Form.Label>Minimum aantal zoektekens</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                items={typeAheadNumbers}
                                defaultValue={fieldValue.typeAheadCharacters}
                                valueExpr="value"
                                displayExpr="text"
                                placeholder="Minimum aantal zoektekens"
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["typeAheadCharacters"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    //console.log(fieldValue);
                                    checkChanges();
                                }
                                }
                                readOnly={isSystemColumn}
                            />


                        </Col>
                    </Row>
                </Form.Group>
                <header className="bl-config-section-header">
                    <h2> Selecteer een tabel</h2>
                </header>
                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Tabel</Form.Label>
                        </Col>
                        <Col lg="9">
                            <SelectBox
                                readOnly={!isCreate}
                                searchEnabled
                                items={datasets}
                                valueExpr="id"
                                displayExpr="tableName"
                                defaultValue={fieldValue.inputTableId}
                                onValueChanged={async (e) => {
                                    // console.log(e.value);
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["inputTableId"] = e.value;
                                    tempFieldValue["inputDisplayColumnId"] = null;
                                    tempFieldValue["inputValueColumnId"] = null;
                                    tempFieldValue["defaultValue"] = null;
                                    tempFieldValue["columnDefaultValue"] = null;
                                    tempFieldValue["inputColorColumnId"] = null;
                                    tempFieldValue["cascadingColumnId"] = null;
                                    tempFieldValue["cascadingParentColumnId"] = null;
                                    setFieldValue(tempFieldValue);

                                    getWeergaves(e.value);
                                    setHasWeergave(false);
                                    checkVerwijzing(e.value);
                                    checkChanges();
                                    getSetList(e.value);
                                    loadDatasourceGroupFields();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                {/* Weergave Dropdown veld */}
                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Weergave</Form.Label>

                            {weergaveLoading && (
                                <Spinner
                                    className="bl-spinner selectbox-spinner"
                                    animation="border"
                                    role="status"
                                    size="md"
                                />
                            )}
                        </Col>
                        <Col lg="3">
                            <SelectBox

                                searchEnabled
                                items={weergaves}
                                valueExpr="id"
                                displayExpr="alias"
                                className={`${isCreate && "bl-mandatory-field"} ${weergaveLoading ? "bl-readonly-field" : ""}`}
                                defaultValue={
                                    fieldValue.inputDisplayColumnId &&
                                    fieldValue.inputDisplayColumnId
                                }
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    e.element.classList.remove("bl-mandatory-field");

                                    tempFieldValue["inputDisplayColumnId"] = e.value;

                                    setFieldValue(tempFieldValue);
                                    if (!hasWeergave) {
                                        setHasWeergave(true);
                                    }

                                    checkChanges();
                                }}
                            />
                        </Col>
                        <Col lg="3">
                            <Form.Label>Waarde</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                readOnly={!isCreate}
                                searchEnabled
                                items={waardes}
                                valueExpr="id"
                                displayExpr="name"
                                className={`${isCreate && "bl-mandatory-field"} ${(weergaveLoading || !hasWeergave) &&
                                    "bl-readonly-field"
                                    }`}
                                defaultValue={
                                    fieldValue.inputValueColumnId && fieldValue.inputValueColumnId
                                }
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    e.element.classList.remove("bl-mandatory-field");

                                    tempFieldValue["inputValueColumnId"] = e.value;

                                    setFieldValue(tempFieldValue);
                                    if (!hasWaarde) {
                                        setHasWaarde(true);
                                    }

                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>


                {/* Label input veld */}
                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Label </Form.Label>
                        </Col>
                        <Col lg="3">
                            <TextBox
                                type="input"
                                placeholder="Bijvoorbeeld: [Naam] <br />[AdressLine1]"
                                defaultValue={fieldValue.inputLabel}
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["inputLabel"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    checkChanges();
                                }}
                            />
                        </Col>
                        <Col lg="3">
                            <Form.Label>Kleur</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                searchEnabled
                                items={colourColumns}
                                valueExpr="id"
                                showClearButton={true}
                                displayExpr="name"
                                defaultValue={
                                    fieldValue.inputColorColumnId && fieldValue.inputColorColumnId
                                }
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["inputColorColumnId"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    checkChanges();
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Groepering </Form.Label>
                        </Col>
                        <Col lg="3">
                            {datasetId &&
                                <SelectBox
                                    searchEnabled
                                    items={groupFields}
                                    valueExpr="id"
                                    displayExpr="alias"
                                    showClearButton={true}
                                    onValueChanged={(e) => {
                                        let tempFieldValue = fieldValue;
                                        tempFieldValue["groupColumnId"] = e.value;
                                        setFieldValue(tempFieldValue);
                                        checkChanges();
                                    }}
                                    defaultValue={fieldValue.groupColumnId}

                                />}

                        </Col>
                        <Col lg="3">
                            <Form.Label>Lijst</Form.Label>
                        </Col>
                        <Col lg="3">
                            <SelectBox
                                searchEnabled
                                items={list}
                                valueExpr="id"
                                displayExpr="name"
                                showClearButton={true}
                                defaultValue={fieldValue.inputListId ? fieldValue.inputListId : ""}
                                onValueChanged={(e) => {
                                    let tempFieldValue = { ...fieldValue };
                                    tempFieldValue["inputListId"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    checkChanges();

                                    e.element.classList.remove("bl-mandatory-field");
                                }}
                                readOnly={isSystemColumn}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Afbeelding </Form.Label>
                        </Col>
                        <Col lg="3">
                            {datasetId &&
                                <SelectBox
                                    searchEnabled
                                    items={imageColumns}
                                    valueExpr="id"
                                    displayExpr="alias"
                                    showClearButton={true}
                                    onValueChanged={(e) => {
                                        //let tempFieldValue = {...fieldValue, imageLinkColumnId:e.value};
                                        //tempFieldValue["groupColumnId"] = e.value;
                                        setFieldValue({ ...fieldValue, imageLinkColumnId: e.value });
                                        checkChanges();
                                    }}
                                    defaultValue={fieldValue.imageLinkColumnId}

                                />}

                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Voldoet aan</Form.Label>
                        </Col>
                        <Col lg="9">
                            <TextBox
                                type="input"
                                placeholder="Voldoet aan (SQL WHERE)"
                                defaultValue={
                                    fieldValue.inputTableQueryWhere &&
                                    fieldValue.inputTableQueryWhere
                                }
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["inputTableQueryWhere"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    //console.log(fieldValue);
                                    checkChanges();
                                }}
                                readOnly={isSystemColumn}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Sorteren op</Form.Label>
                        </Col>
                        <Col lg="9">
                            <TextBox
                                type="input"
                                placeholder="Sorteren op (SQL ORDER)"
                                defaultValue={
                                    fieldValue.inputTableQueryOrderBy &&
                                    fieldValue.inputTableQueryOrderBy
                                }
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["inputTableQueryOrderBy"] = e.value;
                                    setFieldValue(tempFieldValue);
                                    //console.log(fieldValue);
                                    checkChanges();
                                }}
                                readOnly={isSystemColumn}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="3">
                            <Form.Label>Standaard waarde</Form.Label>
                        </Col>
                        <Col lg="9">
                            {fieldValue.inputTableId !== userTableId &&
                                <TextBox
                                    type="input"
                                    //defaultValue={fieldValue.defaultValue ?? ''}
                                    value={fieldValue.defaultValue}
                                    valueChangeEvent="keyup"
                                    onValueChanged={handleStandardValueChanged}
                                    readOnly={isSystemColumn}
                                />
                            }
                            {fieldValue.inputTableId === userTableId && <SelectBox
                                searchEnabled
                                defaultValue={isCreate ? _defaultValueNone : (fieldValue.columnDefaultValue == null ? _defaultValueNone : fieldValue.columnDefaultValue.id)}
                                //value={fieldValue?.columnDefaultValue == null ? _defaultValueNone : fieldValue.columnDefaultValue }
                                //className={isCreate && "bl-mandatory-field"}
                                valueExpr="id"
                                displayExpr="name"
                                items={fieldValue.columnType.columnDefaultValues}
                                onValueChanged={(e) => {
                                    let tempFieldValue = fieldValue;
                                    tempFieldValue["columnDefaultValue"] =
                                        fieldValue.columnType.columnDefaultValues.filter(
                                            (item) => item.id === e.value
                                        )[0];
                                    setFieldValue(tempFieldValue);
                                    // console.log(fieldValue);
                                    e.element.classList.remove("bl-mandatory-field");
                                    checkChanges();
                                }}
                                readOnly={isSystemColumn}
                            />}
                        </Col>
                    </Row>
                </Form.Group>
                {showDependent &&
                    <>


                        <header className="bl-config-section-header">
                            <h2> Afhankelijk van</h2>
                        </header>

                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>
                                        Kolom in tabel: {currentDataset.tableName}
                                    </Form.Label>
                                </Col>
                                <Col lg="9">
                                    <SelectBox
                                        searchEnabled
                                        items={dependendColumns}
                                        valueExpr="id"
                                        displayExpr="alias"

                                        defaultValue={
                                            fieldValue.cascadingParentColumnId &&
                                            fieldValue.cascadingParentColumnId


                                        }
                                        onValueChanged={(e) => {
                                            let tempFieldValue = fieldValue;
                                            e.element.classList.remove("bl-mandatory-field");

                                            tempFieldValue["cascadingParentColumnId"] = e.value;

                                            setFieldValue(tempFieldValue);
                                            if (!hasAfhankelijkKolom) {
                                                setHasAfhankelijkKolom(true);
                                            }

                                            checkChanges();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        {/* Verwijzing kolommen dropdown veld */}
                        <Form.Group>
                            <Row>
                                <Col lg="3">
                                    <Form.Label>
                                        Verwijzing kolom in tabel:{" "}
                                        {fieldValue.inputTableId &&
                                            datasets.find((t) => t.id === fieldValue.inputTableId)
                                            ? datasets.find((t) => t.id === fieldValue.inputTableId)
                                                .tableName
                                            : "..."}{" "}
                                    </Form.Label>
                                    {refereceColumnLoading && (
                                        <Spinner
                                            className="bl-spinner selectbox-spinner"
                                            animation="border"
                                            role="status"
                                            size="md"
                                        />
                                    )}
                                </Col>
                                <Col lg="9">
                                    <SelectBox
                                        searchEnabled
                                        items={referenceColumns}
                                        valueExpr="id"
                                        displayExpr="name"
                                        className={`${isCreate && "bl-mandatory-field"} ${((isCreate && !hasAfhankelijkKolom) ||
                                            refereceColumnLoading) &&
                                            "bl-readonly-field"
                                            }`}
                                        defaultValue={
                                            fieldValue.cascadingColumnId && fieldValue.cascadingColumnId
                                        }
                                        onValueChanged={(e) => {
                                            let tempFieldValue = fieldValue;
                                            e.element.classList.remove("bl-mandatory-field");

                                            tempFieldValue["cascadingColumnId"] = e.value;

                                            setFieldValue(tempFieldValue);
                                            // console.log(fieldValue);

                                            checkChanges();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                    </>}



            </div>
        );
    }
}

export default Selectielijst;