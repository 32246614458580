import "./forbidden.scss";
import React from "react";

import image from "../../../assets/images/sad-no-access.png";
import { Link } from "react-router-dom";

const Forbidden = () => {
    return (
        <main className="bl-content bl-forbidden-page">
            <div className="bl-forbidden-image-wrapper">
                <img src={image} alt="access denied" />
            </div>
            <div className="bl-forbidden-message-wrapper bl-card">
                <h1>Geen toegang</h1>
                <p>
                    Sorry het lijkt erop dat je bent uitgelogd of geen rechten hebt voor deze pagina. Neem
                    contact op met de beheerder.
                </p>
                <Link to="/" className="bl-btn bl-btn-with-label">
                    <i className="fas fa-tachometer-alt" /> <span>Naar dashboard</span>
                </Link>
            </div>
        </main>
    );
};

export default Forbidden;
