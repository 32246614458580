import React from "react";

import logoGrey from "../../assets/images/logo_grey.svg";
import "./footer.scss";

const BloomFooter = () => {
  return (
    <footer className="bl-footer">
      <section className="powered-by-bloom">
        <p>Powered by Bloom</p>

        <img src={logoGrey} className="bl-footer-logo" alt="Bloom-logo-grey" />
      </section>
    </footer>
  );
};

export default BloomFooter;
