import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { ColumnDisplayValueMemo } from "../../../../enums/columnDisplayValue"
import { CheckBox, NumberBox, SelectBox } from "devextreme-react";
import { ColumnType } from "../../../../enums/columnType";

const Memo = ({ fieldValue, setFieldValue, checkChanges, isCreate, columnTypes }) => {
    const defaultDisplayValueId = ColumnDisplayValueMemo.Text;
    const [nrOfRows, setNrOfRows] = useState();
    const [columnDisplayValueId, setColumnDisplayValueId] = useState(fieldValue.columnDisplayValue?.id);

    return (
        <>
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Alleen lezen</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={fieldValue.readonly ? fieldValue.readonly : false}
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["readonly"] = e.value;
                                setFieldValue(tempFieldValue);
                                console.log(fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Verplicht</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={fieldValue.mandatory ? fieldValue.mandatory : false}
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["mandatory"] = e.value;
                                setFieldValue(tempFieldValue);
                                console.log(fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Weergave</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            searchEnabled
                            defaultValue={columnDisplayValueId}
                            items={fieldValue.columnType.columnDisplayValues}
                            valueExpr="id"
                            displayExpr="name"
                            onValueChanged={(e) => {
                                console.log("onValueChanged", e);
                                setColumnDisplayValueId(e.value);
                                let tempFieldValue = fieldValue;
                                tempFieldValue["columnDisplayValue"] = columnTypes.find(ct => ct.id === ColumnType.MEMO).columnDisplayValues.find(cdv => cdv.id === e.value);
                                tempFieldValue["displayValueId"] = e.value;
                                tempFieldValue["extraField1"] = null;
                                setNrOfRows(null);
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
            {columnDisplayValueId === ColumnDisplayValueMemo.Text &&  <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Aantal rijen</Form.Label>
                    </Col>
                    <Col lg="12">
                        <NumberBox
                            value={fieldValue.extraField1}
                            // value={nrOfRows}
                            valueChangeEvent="keyup"
                            onValueChanged={(e) => {
                                setNrOfRows(e.value);
                                let tempFieldValue = fieldValue;
                                tempFieldValue["extraField1"] = e.value;
                                setFieldValue(tempFieldValue);
                                console.log("Aantal rijen", fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>}



        </>
    );
};

export default Memo;
