import { DataGrid } from "devextreme-react";
import { Helmet } from "react-helmet";
import {
    Column,
    Lookup,
    FilterRow,
    Paging,
    Pager,
    HeaderFilter,
    LoadPanel,
    ColumnFixing,
    Scrolling,
    Editing
} from "devextreme-react/data-grid";
import { CheckBox } from "devextreme-react";
import React, { useCallback, useState, useEffect, useRef } from "react";
import Loading from "../../../loading/Loading";

const SchedulerGrid = (/* {
    checkChanges,
    form,
    setForm,
    overviews,
    overviewLoading,
} */
    { checkChanges,
        form,
        setForm,
        schedulers,
        schedulerLoading,
        tables
    }
) => {
    console.log("SchedulerGrid - schedulers - form", schedulers, form);
    //console.log("useEffect passing", !schedulerLoading && form && schedulers && setForm )
    const schedulerGridRef = useRef();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("SchedulerGrid - schedulers - form", schedulers, form);
        let tempLists = [];
        let tempForm = form;

        if (schedulerLoading) {
            console.log("useEffect passing schedulerLoading", schedulerLoading);
            return;
        } else if (!form || !schedulers) {
            console.log("useEffect passing schedulerLoading, form, schedulers",  (!form || !schedulers));
            return;
        } else {
            console.log("useEffect passing",  (!form || !schedulers));
            if (schedulers.length > 0) {
                schedulers.map(item => {
                    /* if (form.overview.find((t) => t.id === item.id)) {
                        let target = form.overview.find((t) => t.id === item.id);
                        target["tableName"] = item.table.tableName;
                        target["overviewName"] = item.name;
                        tempLists.push(target);
                    } else {
                        tempLists.push({
                            id: item.id,
                            visible: 0,
                            defaultOverview: false,
                            tableName: item.table.tableName,
                            overviewName: item.name,
                        });
                    }

                    return tempLists; */

                    let target = { title: item.title };
                    const hasRights = form.scheduler.find(scheduler => scheduler.id === item.id) != null;
                    target.id = item.id;
                    target.hasRights = /* true */  hasRights;
                    console.log("useEffect hasRights", hasRights);
                    tempLists.push(target);
                });

                
                tempForm["scheduler"] = tempLists;
                setForm(tempForm);
                console.log("useEffect setForm(...)", tempForm, tempLists);
                setLoading(false);
            } else {
                // setLoading(false);
                // setInvalidOverviews(true);
            }
        }
    }, [schedulerLoading, form, schedulers, setForm]);

    const onRowUpdated = useCallback((e) => {
        console.log("onRowUpdated", e);
        //const scheduler = form.scheduler.find(item => item.title === e.key.title);
        // scheduler.hasRights = e.newData.hasRights;
        const schedulerId = schedulers.find(s => s.title == e.key.title).id;
        let tempForm = form;
        tempForm.scheduler.find(item => item.title === e.key.title).hasRights =  e.newData.hasRights;
        // tempForm.hasRights = e.newData.hasRights;
         setForm(tempForm);
         checkChanges();
         console.log("tempForm", tempForm);
        /*let binair;
                    if (e.value) {
                        binair = 1;
                    } else {
                        binair = 0;
                    }
                    let tempForm = form;
                    tempForm.overview.find((t) => t.id === data.data.id)[
                        data.column.dataField
                    ] = binair;
                    console.log(tempForm);
                    checkChanges();
                    setForm(tempForm);*/
    }, [form, setForm, checkChanges, schedulers]);

    /* const renderCheckboxVisible = (data) => {
        const value = data.key.id === form["defaultOverviewId"];
        return (
            <CheckBox
                defaultValue={value}
                readOnly={data.key.visible === 0}
                onValueChanged={(e) => {
                    let tempForm = form;

                    if (e.value) {
                        // console.log("HIERO! cb", data.key.id);
                        tempForm["defaultOverviewId"] = data.key.id;
                        //setDefaultPageId(data.key.id);
                    } else {
                        tempForm["defaultOverviewId"] = null;
                    }
                    checkChanges();
                    setForm(tempForm);
                }}
            />
            // </div>
        );
    }; */

    if (loading) {
        return <Loading />;
    } else {
        return <>
            <Helmet>
                <title>
                    {"HC"}
                </title>
            </Helmet>
            <DataGrid
                dataSource={form.scheduler}
                ref={schedulerGridRef}
                columnAutoWidth={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                showRowLines={true}
                wordWrapEnabled={true}
                onRowUpdating={onRowUpdated}
            >
                <Editing
                    mode="cell"
                    allowUpdating={true}
                />
                <Column
                    caption="Naam"
                    dataType="string"
                    dataField="title"
                />
                <Column
                    // key={key}
                    // dataField={name.toLowerCase()}
                    caption="Zichtbaar"
                    // fixed={true}
                    allowSearch={false}
                    dataType="boolean"
                    allowEditing={true}
                    dataField="hasRights"
                />

            </DataGrid>
        </>
    }
}

export default SchedulerGrid;
