import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import appSettings from "../../../appsettings";
import ConfigPageControls from "../parts/ConfigPageControls";
import msalFetch from "../../../api/MsalFetch.js";
import { useParams } from "react-router";
import TableGrid from "./tabs/TableGrid";
import Loading from "../../loading/Loading";
import "./roles.scss";
import DetailviewsGrid from "./tabs/DetailviewsGrid";
import ListGrid from "./tabs/ListGrid";
import { useModalContext } from "../../../context/modalContext";

import toast from "react-hot-toast";
import { useHistory } from "react-router";
import BloomFooter from "../../footer/BloomFooter";
import UserList from "./userList/UserList";
import SchedulerGrid from "./tabs/SchedulerGrid.js";

const Role = () => {
    const msal = useMsal();
  const [showUserList, setShowUserList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [detailviewLoading, setDetailviewLoading] = useState(true);
  const [changeInForm, setChangeInForm] = useState(false);
  const [form, setForm] = useState();
  const [detailviews, setDetailviews] = useState();
  const [overviews, setOverviews] = useState([]);
  const [schedulers, setSchedulers] = useState([]);
  const [overviewLoading, setOverviewLoading] = useState(true);
  const [schedulerLoading, setSchedulerLoading] = useState(true);
  const [tables, setTables] = useState();
  const [isDuplicate, setIsDuplicate] = useState(true);
  const link = useHistory();

  const [activeTab, setActiveTab] = useState(0);

  const { id } = useParams();

  const tabs = ["Tabellen", "Pagina's", "Lijsten", "Agenda's"];

  const { setIsEditing, setModalContent, setModal } = useModalContext();

  const handleSaveClick = async () => {
    console.log("form", form);
    let postObject = form;
    let allDetailviews = detailviews;

    let overviewArray = form.overview;
    let cleanOverviewArray = [];

    let cleanDetailviewArray = [];

    let cleanRoleTable = [];

    console.log(overviewArray);

    overviewArray.map((item) => {
      let tempOverview = {};
      tempOverview["id"] = item.id;
      tempOverview["visible"] = item.visible;
      if (form.defaultOverviewId === item.id) {
        console.log(item);
        tempOverview["defaultOverview"] = 1;
      } else {
        tempOverview["defaultOverview"] = 0;
      }
      cleanOverviewArray.push(tempOverview);
      return cleanOverviewArray;
    });

    cleanOverviewArray.sort((a, b) => a.id - b.id);

    console.log("clean array", cleanOverviewArray);

    detailviews.map((item) => {
      let tempItem = {};
      if (form.detailview.find((t) => t.id === item.id)) {
        tempItem = form.detailview.find((t) => t.id === item.id);
      } else {
        tempItem = {
          id: item.id,
          visible: 0,
        };
      }
      cleanDetailviewArray.push(tempItem);
    });

    cleanDetailviewArray.sort((a, b) => a.id - b.id);
    console.log(cleanDetailviewArray);

    // form.detailview.map((item) => {
    //   let tempItem = {};
    //   tempItem["id"] = item.id;
    //   tempItem["visible"] = item.visible;
    //   console.log(item);
    //   if (item.defaultOverview) {
    //     tempItem["defaultOverview"] = item.defaultOverview;
    //   }

    //   cleanDetailviewArray.push(tempItem);
    //   return cleanDetailviewArray;
    // });

    form.roleTable.map((item) => {
      cleanRoleTable.push({
        tableId: item.tableId,
        create: item.create,
        read: item.read,
        update: item.update,
        delete: item.delete,
      });
      return cleanRoleTable;
    });

    cleanRoleTable.sort((a, b) => a.tableId - b.tableId);

    postObject.roleTable = cleanRoleTable;

    postObject.overview = cleanOverviewArray;

    postObject.overview.sort((a, b) => a.id - b.id);

    console.log(postObject);

    postObject.detailview = cleanDetailviewArray;
    console.log("post object", postObject);
    try {
      const res = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/role/${id}`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify(postObject),
        }
      );
      // const data = await res.json();

      if (res.ok) {
        console.log(res);
        toast.success("De gegevens zijn successvol opgeslagen...");
      } else {
        toast.error("Oeps er is iets mis gegaan...");
      }

      //   console.log(data);
      return;
    } catch (error) {
      toast.error("Oeps er is iets mis gegaan...");

      throw error;
    } finally {
      setChangeInForm(false);
      getRole();
      getOverviews();
      getTables();
    }
  };

  const getTables = async () => {
    setTableLoading(true);
    try {
      const res = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/table`
      );
      const data = await res.json();
      console.log(data);
      setTables(data);

      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
      throw error;
    }
  };

  const getRole = async () => {
    setLoading(true);
      try {
          const res = await msalFetch(msal,
              `${appSettings.api.endpoint}/api/configurator/role/${id}`
          );
          const data = await res.json();
          console.log("getRole", data);
          setForm(data);
          getDetailview(data);

      } catch (error) {

          throw error;
      } finally {
          setLoading(false);
      }
  };

  const getOverviews = async () => {
    setOverviewLoading(true);
    try {
      const res = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/overview`
      );
      const data = await res.json();
      console.log(data);
      setOverviews(data);
      setOverviewLoading(false);
    } catch (error) {
      setOverviewLoading(false);
      throw error;
    }
  };

  const getSchedulers = async () => {
    setSchedulerLoading(true);
    try {
      const res = await msalFetch(null,
        `${appSettings.api.endpoint}/api/scheduler/listconfigurator`
      );
      const data = await res.json();
      setSchedulers(data);
      setSchedulerLoading(false);
    } catch (error) {
      setSchedulerLoading(false);
      throw error;
    }
  };

  const getDetailview = async (tempForm) => {
    setDetailviewLoading(true);
    // let tempDetailViews = tempForm.detailview;
    // let tempSort = [];

    try {
      const res = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/detailview`
      );
      const data = await res.json();
      console.log("detailviews", data);

      // tempDetailViews.map((detailTarget) => {
      //   let tempItem = detailTarget;
      //   tempItem["detailviewName"] = data.find(
      //     (i) => i.id === tempItem.id
      //   ).name;
      //   tempItem["tableName"] = data.find(
      //     (i) => i.id === tempItem.id
      //   ).table.tableName;

      //   return (tempSort = [...tempDetailViews, tempItem]);
      // });

      setDetailviews(data);
      //   let tempForm = form;
      //   let tempRoleTable = [];

      //   form.roleTable.map((item) => {
      //     let tempItem = item;
      //     tempItem["tableName"] = data.filter(
      //       (target) => target.id === item.tableId
      //     )[0].tableName;
      //     tempItem["readonly"] = data.filter(
      //       (target) => target.id === item.tableId
      //     )[0].readonly;
      //     return (tempRoleTable = [...tempRoleTable, tempItem]);
      //   });
      //   tempForm["roleTable"] = tempRoleTable;
      //   console.log(tempForm);

      //   setForm(tempForm);
      setDetailviewLoading(false);
    } catch (error) {
      setDetailviewLoading(false);
      throw error;
    }
  };

  const checkChanges = () => {
    if (changeInForm) {
      return;
    } else {
      setChangeInForm(true);
    }
  };

  const duplicateFunction = () => {
    setIsEditing(true);
    setModalContent(form);
    setModal("DUPLICATEROLE");
  };

  useEffect(() => {
    getRole();
    getOverviews();
    getTables();
    getSchedulers()
    var currentUrl = window.location.href;
    if (currentUrl.includes("duplicate")) {
      setIsDuplicate(true);
    }
  }, []);

  return (
    <main className="bl-content">
      <header className="bl-config-page-header">
        {/* <TextBox
          className={`bl-title-textbox ${isDuplicate && "bl-mandatory-field"}`}
          defaultValue={loading || isDuplicate ? "" : form.name}
          readOnly={isDuplicate ? false : true}
          onValueChanged={(e) => {
            let tempForm = form;
            tempForm["name"] = e.value;

            if (e.value.trim() !== "") {
              e.element.classList.remove("bl-mandatory-field");
            } else {
              e.element.classList.add("bl-mandatory-field");
            }
            checkChanges();
          }}
        /> */}
        <h1>{loading ? "Rol ..." : `Rol: ${form.name}`}</h1>
        <div className="user-list bl-btn" onClick={() => setShowUserList(true)}>
          <i className="fas fa-users" />
          <span>
            <p>Gebruikers lijst</p>
          </span>
        </div>
        <ConfigPageControls
          saveFunction={handleSaveClick}
          hasDuplicate={true}
          hasRefreshFunction={false}
          duplicateFunction={duplicateFunction}
          changeInForm={changeInForm}
        />
      </header>
      <section className="bl-card">
        <header>
          <h2>Rechten</h2>
        </header>

        <div className="bl-tabgrid-wrapper">
          <header className="bl-tab-control">
            <nav className="bl-tab-nav">
              <ul className="bl-tab-list">
                {tabs.map((tab, index) => {
                  return (
                    <li
                      key={index}
                      className={`bl-tab ${
                        index === activeTab && "bl-active-tab"
                      }`}
                      onClick={() => setActiveTab(index)}
                    >
                      {tab}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </header>
          {(tableLoading || loading) && <Loading />}
          {!tableLoading && !loading &&(
            <div>
              {activeTab === 0 && (
                <TableGrid
                  checkChanges={checkChanges}
                  form={form}
                  setForm={setForm}
                  tables={tables}
                />
              )}

              {activeTab === 1 && !detailviewLoading && (
                <DetailviewsGrid
                  checkChanges={checkChanges}
                  form={form}
                  setForm={setForm}
                  detailviews={detailviews}
                  detailviewLoading={detailviewLoading}
                />
              )}

              {activeTab === 2 && (
                <ListGrid
                  checkChanges={checkChanges}
                  form={form}
                  setForm={setForm}
                  overviews={overviews}
                  overviewLoading={overviewLoading}
                  tables={tables}
                  detailviews={detailviews}
                />
              )}
              {activeTab === 3 && (
                <SchedulerGrid
                  checkChanges={checkChanges}
                  form={form}
                  setForm={setForm}
                  schedulers={schedulers}
                  schedulerLoading={schedulerLoading}
                  tables={tables}
                  //detailviews={detailviews}
                />
              )}
            </div>
          )}
        </div>
      </section>
      <BloomFooter />

      {showUserList && (
        <UserList
          users={form.user}
          setShowUserList={setShowUserList}
          roleName={form.name}
        />
      )}
    </main>
  );
};

export default Role;
