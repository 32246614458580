import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../../../appsettings";
import msalFetch from "../../../../api/MsalFetch.js";
import Form from "react-bootstrap/Form";
import { Col, Row, Spinner } from "react-bootstrap";
import { CheckBox, SelectBox } from "devextreme-react";

const DependendOnSingleColumn = ({
  fieldValue,
  setFieldValue,
  checkChanges,
  isCreate,
  datasetId,
}) => {
    const msal = useMsal();
  const [columnLoading, setColumnLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [verwijzingen, setVerwijzingen] = useState();
  const [verwijzingLoading, setVerwijzingLoading] = useState(true);
  const [hasVerwijzingen, setHasVerwijzingen] = useState(true);

  const datasetIdId = datasetId.id;
  const { dependParentColumnId } = fieldValue;

  const checkVerwijzing = useCallback(async (id) => {
    setHasVerwijzingen(false);
    const target = columns.find((t) => t.id === id);
    if (!target) {
      return;
    }

    setVerwijzingLoading(true);
    try {
      const response = await msalFetch(msal, 
        `${appSettings.api.endpoint}/api/configurator/dataset/${target.inputTableId}/column/`
      );
      const data = await response.json();
      setVerwijzingen(data);
      setVerwijzingLoading(false);
      return data;
    } catch (error) {
      setVerwijzingLoading(false);

      throw new Error("Data Loading Error");
    }
  }, [columns]);

  const getColumns = useCallback(async () => {
    try {
      const response = await msalFetch(msal, 
        `${appSettings.api.endpoint}/api/configurator/dataset/${datasetIdId}/column/`
      );
      const data = await response.json();
      const possibleColumns = data.filter((column) => {
        if (
          column.columnType && (
            column.columnType.id === 13 || // ZKL
            column.columnType.id === 11 || // KL
            column.columnType.id === 14 || // AVAV
            column.columnType.id === 15 // AVAKK
          )
        ) {
          return true;
        }
        return false;
      });
      setColumns(possibleColumns);
      setColumnLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      setColumnLoading(false);
      throw new Error("Data Loading Error");
    }
  }, [datasetIdId]);

  useEffect(() => {
    getColumns();
  }, [getColumns]);

  useEffect(() => {
    checkVerwijzing(dependParentColumnId);
  }, [checkVerwijzing, dependParentColumnId]);

  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Alleen lezen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={fieldValue.readonly ? fieldValue.readonly : false}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["readonly"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Verplicht</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={fieldValue.mandatory ? fieldValue.mandatory : false}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["mandatory"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <header className="bl-config-section-header">
        <h2>Afhankelijk van</h2>
      </header>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>
              Kolom{" "}
              {columnLoading && (
                <Spinner
                  className="bl-spinner selectbox-spinner"
                  animation="border"
                  role="status"
                  size="md"
                />
              )}
            </Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              key={columnLoading}
              items={columns}
              valueExpr="id"
              displayExpr="name"
              defaultValue={fieldValue.dependParentColumnId}
              //   readOnly={columnLoading || !isCreate ? true : false}
              className={
                fieldValue.dependParentColumnId !== ""
                  ? ""
                  : "bl-mandatory-field"
              }
              onValueChanged={(e) => {
                checkVerwijzing(e.value);
                let tempFieldValue = fieldValue;
                fieldValue["dependParentColumnId"] = e.value;
                fieldValue["dependColumnId"] = null;
                setFieldValue(tempFieldValue);
                console.log(tempFieldValue);
                checkChanges();
                e.element.classList.remove("bl-mandatory-field");
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>
              Verwijzing{" "}
              {verwijzingLoading && (
                <Spinner
                  className="bl-spinner selectbox-spinner"
                  animation="border"
                  role="status"
                  size="md"
                />
              )}
            </Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              key={verwijzingLoading}
              items={verwijzingen}
              valueExpr="id"
              displayExpr="alias"
              defaultValue={fieldValue.dependColumnId}
              // readOnly={columnLoading || !isCreate ? true : false}
              className={
                fieldValue.dependColumnId !== ""
                  ? ""
                  : "bl-mandatory-field"
              }
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                fieldValue["dependColumnId"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(tempFieldValue);
                checkChanges();
                e.element.classList.remove("bl-mandatory-field");
              }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default DependendOnSingleColumn;
