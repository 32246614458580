import React, { useState, useEffect } from "react";

const UserLeaveConfirmation = ({
  nessage,
  callback,
  confirmOpen,
  setConfirmOpen,
}) => {
  return (
    <main className="bl-global-search-modal">
      <h1>witse t zeker</h1>
    </main>
  );
};

export default UserLeaveConfirmation;
