import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import Logo from "../../assets/images/logo.svg";
import "./dashboard.scss";
import { useGraphContext } from "../../components/graph/context";

const Dashboard = () => {
    const {
        currentUser,
        profilePic,
        fullName,
    } = useGraphContext();
    const { replace } = useHistory();
    const { defaultOverviewId } = currentUser || {};
    useEffect(() => {
        if (defaultOverviewId) {
            replace(`/overview/${defaultOverviewId}`);
        }
    }, [defaultOverviewId, replace]);

    return (
        <main className="bl-content">
            {currentUser?.organisation?.name && (
                <Helmet>
                    <title>
                        {currentUser?.organisation?.name}
                    </title>
                </Helmet>
            )}
            <div>
                <div className="panel">
                    <div className="dashboard-card">
                        <article className="welcome-user">
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="user-dashboard">
                                {profilePic && (
                                    <img alt={fullName || 'Your profile picture'} src={profilePic} />
                                )}
                                <h2>{fullName}</h2>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="dashboard-brand">
                                <img src={Logo} alt="logo" />
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Dashboard;
