import React from "react";

const BlModalFooter = ({ eventButtons }) => {
  console.log(eventButtons);
  return (
    <footer className="bl-modal-controls">
      <div className={`button-wrapper ${eventButtons ? "disabled" : ""}`}>
        <div className="bl-btn bl-modal-save-btn">
          <i className="fas fa-save" />
        </div>

        <div className="bl-btn bl-modal-exit-btn">
          <i className="fas fa-times" />
        </div>
      </div>
    </footer>
  );
};

export default BlModalFooter;
