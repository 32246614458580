import React from "react";
import HtmlEditor, {
    MediaResizing,
} from "devextreme-react/html-editor";
import FieldLabel from "../detailview/FieldLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tooltip } from 'devextreme-react/tooltip';

const HTMLVIEWER = ({
    value,
    alias,
    columnId,
    label,
    // readonly,
    registerLoaded,
    onControlUpdate,
    memoField,

}) => {
    return (
        <Form.Group>
            <Row>
                <Col md="12" className="bl-justify-top">
                    <FieldLabel
                        value={value}
                        label={alias}
                        columnId={columnId}
                        memoField={memoField}


                    />
                </Col>
                <Col md="12">
                    <div className="widget-container">
                        <HtmlEditor
                            defaultValue={value}
                            valueType="html"
                            readOnly={true}

                            className="bl-html-editor"

                        >
                            <MediaResizing enabled={true} />
                        </HtmlEditor>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
};

export default HTMLVIEWER;
