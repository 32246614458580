import { Column, RequiredRule, } from "devextreme-react/data-grid";
import FileField from "../components/fileField";

const renderFileColumnCell = (columnId, ftp, tableId, overviewId, inlineEditMode, refreshGrid) => (data) => {
    return (
        <FileField
            value={data.value}
            binary={data.data[`thumbnail_${columnId}`]}
            ftp={ftp}
            columnId={columnId}
            tableItemId={data.data.id}
            tableId={tableId}
            overviewId={overviewId}
            inlineEditMode={inlineEditMode}
            refreshGrid={refreshGrid}
        />
    );
};

const renderFileColumn = (name, alias, width, key, columnId, readonly, mandatory, ftp, inlineEditMode, tableId, overviewId, refreshGrid, sortIndex, sortDirection, groupIndex) => {
    return (
        <Column
            alignment="center"
            key={key}
            dataField={name.toLowerCase()}
            cellRender={renderFileColumnCell(columnId, ftp, tableId, overviewId, inlineEditMode, refreshGrid)}
            caption={alias}
            allowSearch={true}
            width={width}
            allowEditing={false}
            mandatory={mandatory}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={false}
            groupIndex={groupIndex}
        >
            {mandatory && <RequiredRule />}
        </Column>
    );
};

export default renderFileColumn;
