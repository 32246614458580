import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { CheckBox, NumberBox, SelectBox } from "devextreme-react";
import { formatNumber, parseNumber } from "devextreme/localization";

const Percentage = ({ fieldValue, setFieldValue, checkChanges, isCreate }) => {
    useEffect(() => {
        let tempFieldValue = fieldValue;
        console.log(tempFieldValue);
        if (isCreate) {
            console.log("hoi");
            tempFieldValue["columnValidation"] =
                tempFieldValue.columnType.columnValidations[0];
            tempFieldValue["columnDefaultValue"] =
                tempFieldValue.columnType.columnDefaultValues[0];
            setFieldValue(tempFieldValue);
            console.log(tempFieldValue);
        }
    }, []);

    return (
        <>
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Alleen lezen</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={fieldValue.readonly ? fieldValue.readonly : false}
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["readonly"] = e.value;
                                setFieldValue(tempFieldValue);
                                console.log(fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Verplicht</Form.Label>
                    </Col>
                    <Col lg="12">
                        <CheckBox
                            defaultValue={fieldValue.mandatory ? fieldValue.mandatory : false}
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["mandatory"] = e.value;
                                setFieldValue(tempFieldValue);
                                console.log(fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Standaard waarde</Form.Label>
                    </Col>
                    <Col lg="12">
                        <NumberBox
                            type="input"
                            defaultValue={fieldValue.defaultValue}
                            valueChangeEvent="keyup"
                            format={{
                                formatter: (val) => {
                                    return formatNumber(val, '#0.0%');
                                },
                                parser: (val) => {
                                    return parseNumber(val,
                                        {
                                            type: 'percent',
                                            precision: 1,
                                        });
                                }
                            }}
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["defaultValue"] = Math.round(e.value * 100) / 100;
                                setFieldValue(tempFieldValue);
                                console.log(fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
};

export default Percentage;
