import React, { memo } from "react";
import { Col } from "react-bootstrap";

function FallbackBlock({
    columnIndex,
    rowIndex,
    width,
    children,
}) {
    return (
        <Col
            lg={width}
            md={12}
            className={`bl-col-padding`}
        >
            <section className="bl-detail-column bl-card">
                {children}
            </section>
        </Col>
    );
}

export default memo(FallbackBlock);
