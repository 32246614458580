import React from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, SelectBox } from "devextreme-react";

const OnOffField = ({ fieldValue, setFieldValue, checkChanges, isCreate, defaultValue }) => {
  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Alleen lezen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={fieldValue.readonly ? fieldValue.readonly : false}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["readonly"] = e.value;
                setFieldValue(tempFieldValue);

                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Verplicht</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={fieldValue.mandatory ? fieldValue.mandatory : false}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["mandatory"] = e.value;
                setFieldValue(tempFieldValue);

                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Standaard waarde</Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              items={fieldValue.columnType.columnDefaultValues}
              valueExpr="id"
              displayExpr="name"
              className={isCreate && "bl-mandatory-field"}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["columnDefaultValue"] = { id: e.value };
                setFieldValue(tempFieldValue);

                checkChanges();

                e.element.classList.remove("bl-mandatory-field");
              }}
              defaultValue={ fieldValue.defaultValue === 'True' ? 3 : fieldValue.defaultValue === 'False' ? 4 : null }
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default OnOffField;
