import Draggable from 'devextreme-react/draggable';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import { TextBox } from 'devextreme-react/text-box';


const SchedulerDraggableItems = ({ draggingGroupName, draggableItems, title }) => {
    const [searchQuery, setSearchQuery] = useState('');


    const filteredItems = useMemo(() => draggableItems.filter(item =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
    ), [draggableItems, searchQuery]);

    return <>
        <div >
            <TextBox
                placeholder={`Zoeken binnen ${title.toLowerCase()}`}
                value={searchQuery}
                className='bl-draggable-search'
                onValueChanged={(e) => setSearchQuery(e.value)}
                valueChangeEvent="keyup"
                showClearButton={true}
            ><i className="dx-icon dx-icon-search" /></TextBox>
        </div>
        <ScrollView id="scroll" height={"70vh"}>
            <Draggable
                id="list"
                group={draggingGroupName}
                className='bl-draggable'
                dragDirection="vertical"

            >
                {filteredItems.map(item =>
                    <Draggable
                        key={item.value}
                        group={draggingGroupName}
                        className="item dx-card bl-draggable-item"
                        clone={true}
                        data={item}

                        onDragStart={(e) => { console.log("onDragStart", e); }}
                        onDragEnd={(e) => { console.log("onDragEnd", e); }}
                    >
                        <i
                            className="fa-solid fa-grip-lines-vertical coloricon"
                            style={{ color: item.color, opacity: 0.5 }} // Use 'color' or another relevant CSS property
                        /> {item.label}
                    </Draggable>
                )}

            </Draggable>
        </ScrollView>
    </>
}
export default SchedulerDraggableItems;
