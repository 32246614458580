import React, { useState, useEffect, useRef, useCallback } from "react";
import toast from "react-hot-toast";
import { useParams, useHistory, useLocation } from "react-router-dom";
import msalFetch from "../../../../api/MsalFetch.js";
import appSettings from "../../../../appsettings";
import DataGrid, {
    Column,
    FilterRow,
    Scrolling,
    ColumnFixing,
    HeaderFilter,
    LoadPanel,
    Paging,
    Pager,
    RowDragging,
} from "devextreme-react/data-grid";
import EditPage from "../../../modals/configurator/EditPage";
import DeleteItem from "../../../notifications/userLeaveNotifications/notifications/DeleteItem";

const PageGrid = () => {
    const gridRef = useRef();
    const [pageData, setPageData] = useState();
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { id: dataSetId, activePage } = useParams();
    const isCreate = activePage === '0';
    const singleItem = pageData?.find(({ id }) => id === Number(activePage));

    const [deleteTarget, setDeleteTarget] = useState();
    const [deleteButtonHover, setDeleteButtonHover] = useState(false);
    const [deleteNotification, setDeleteNotification] = useState(false);

    const setIsEditing = useCallback(() => {
        push('../../');
    }, [push]);

    const pushToSingle = useCallback((id) => {
        push(`${pathname}${pathname.endsWith('/') ? '' : '/'}page/${id}/`);
    }, [push, pathname]);

    const refreshFunction = useCallback(async () => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/page`
            );
            const data = await response.json();
            setPageData(data);
            return data;
        } catch (error) {
            throw "Data Loading Error at PageGrid";
        }
    }, [dataSetId]);

    function addNewButton() {
        return (
            <div className="bl-config-btn-wrapper">
                <div
                    className="bl-btn bl-add-btn bl-config-header-btn "
                    onClick={() => {
                        pushToSingle(0);
                    }}
                >
                    <i className="fas fa-plus" />
                </div>
            </div>
        );
    }

    const handleEditColumn = (columnData) => {
        if (deleteButtonHover) {
            return;
        }
        pushToSingle(columnData.data.id);
    };

    const handleDeleteFunction = async () => {
        let url = `${appSettings.api.endpoint}/api/configurator/page/${deleteTarget.id}`;

        try {
            const res = await msalFetch(null, url, {
                method: "DELETE",
                headers: { "Content-type": "application/json" },
            });
            if (res.ok) {
                toast.success("Het item is successvol verwijderd...");
                setDeleteNotification(false);
                refreshFunction();
            } else {
                toast.error("De kolom kan niet worden verwijderd. Zorg ervoor dat de gerelateerde sets, pagina's en lijsten zijn verwijderd, of selecteer een andere kolom.");
                setDeleteNotification(false);
            }
            return;
        } catch (error) {
            setDeleteNotification(false);
            toast.error("Oeps er ging even iets mis...");
        }
    };

    const handleDeleteClick = (column) => {
        setDeleteNotification(true);
        setDeleteTarget(column);
    };

    const columnEditControl = (columnData) => {
        if (columnData.data.name.includes("bl_") || columnData.data.name === "Id") {
            return null;
        } else {
            return (
                <div className="bl-column-controls">
                    <i
                        className="fas fa-pencil-alt bl-column-control-btn bl-column-edit-btn"
                        onClick={() => handleEditColumn(columnData)}
                    />

                    <i
                        onMouseEnter={() => setDeleteButtonHover(true)}
                        onMouseLeave={() => setDeleteButtonHover(false)}
                        className={`fas fa-trash bl-column-control-btn bl-column-delete-btn`}
                        onClick={() => {
                            handleDeleteClick(columnData.data);
                        }}
                    />
                </div>
            );
        }
    };

    // mutate pageData to make sure DataGrid listens to the changes
    const handleReorder = async (e) => {
        pageData.splice(e.fromIndex, 1);
        pageData.splice(e.toIndex, 0, e.itemData);
        const promises = pageData.map(async (page, index) => {
            page.sort = index;
            try {
                const res = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/page`, {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(page),
                });
                if (!res.ok)
                    toast.error("Oeps er ging iets mis...")
            } catch (error) {
                toast.error("Oeps er ging iets mis...");
            }
        });
        await Promise.all(promises);
        toast.success("Gegevens succesvol opgeslagen...");
        gridRef.current.instance().refresh(true);
    };

    useEffect(() => {
        refreshFunction();
    }, [refreshFunction]);

    return (
        <div>
            <DataGrid
                ref={gridRef}
                dataSource={pageData}
                showBorders={true}
                showColumnLines={true}
                rowAlternationEnabled={true}
                onRowClick={handleEditColumn}
            >
                <Paging defaultPageSize={50} />
                <Pager
                    visible={true}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                />

                <FilterRow visible={true} />
                <ColumnFixing enabled={true} />
                <LoadPanel enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
                <Scrolling mode="virtual" />
                <FilterRow visible={true} />
                <Column
                    dataField="name"
                    caption="Naam"
                    dataType="string"
                />
                <Column
                    dataField="visibleAsTab"
                    caption="Als tab"

                />
                <Column
                    dataField="quickCreate"
                    caption="Quickcreate menu"

                />
                <Column
                    dataField="queryWhere"
                    caption="Voldoet aan"

                />
                <RowDragging
                    allowReordering={true}
                    onReorder={handleReorder}
                    showDragIcons={true}
                />
                <Column
                    dataField="sort"
                    sortOrder="asc"
                    width={1}
                    //className="hidden-column"
                />
                <Column
                    width={80}
                    fixed={true}
                    fixedPosition="right"
                    cellRender={columnEditControl}
                    headerCellRender={addNewButton}
                    alignment="right"
                />
            </DataGrid>
            {activePage && (isCreate || singleItem) && (
                <EditPage
                    isEditing={activePage}
                    refreshFunction={refreshFunction}
                    setIsEditing={setIsEditing}
                    isCreate={isCreate}
                    singlePageData={singleItem}
                    pages={pageData}
                    dataSetId={dataSetId}
                />
            )}

            {deleteNotification && (
                <DeleteItem
                    handleDeleteFunction={handleDeleteFunction}
                    setOpendDeleteNotification={setDeleteNotification}
                    refreshFunction={refreshFunction}
                />
            )}
        </div>
    );
};

export default PageGrid;
