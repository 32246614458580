import React, { useState } from "react";
import "./iconsSelect.scss";
import validatedIcons from "./validatedIcons";
import Select, { components } from "react-select";

const IconSelect = ({
  mandatory,
  loading,
  dataSource,
  handleValueChange,
  defaultValue,
  mandatoryCheck,
}) => {
  const [selectSearchFocus, setSelectSearchFocus] = useState(false);

  const { Option } = components;

  const IconOption = (props) => (
    <Option {...props} className="icon-select-list-item">
      <div className="icon-wrapper">
        <i
          className={`fas ${props.data.value}`}
          style={{ paddingRight: "30px", width: "50px" }}
        />
        <span>{props.data.label}</span>
      </div>
    </Option>
  );

  return (
    <div   >
      <Select
        key={dataSource}
        options={dataSource}
        className={`bl-select-search`}
        classNamePrefix="bl-icon-select-box"
        onChange={handleValueChange}
        components={{ Option: IconOption }}
        onFocus={() => setSelectSearchFocus(true)}
        onBlur={() => setSelectSearchFocus(false)}
        placeholder="Maak een keuze"
        defaultValue={validatedIcons.find((t) => t.value === defaultValue)}
      />
    </div>
  );
};

export default IconSelect;
