import React, { memo } from "react";
import { TextBox } from "devextreme-react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FieldLabel from "../detailview/FieldLabel";

const VOID = ({
    label,
    placeholder = '',
}) => {
    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <Form.Label>{label}</Form.Label>
                </Col>
                <Col md="8">
                    <TextBox
                        placeholder={placeholder}
                        value=""
                        readOnly
                    />
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(VOID);
