import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import appSettings from "../../../../appsettings";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { SelectBox } from "devextreme-react";
import Webhook from "./actionTypes/Webhook";
import msalFetch from "../../../../api/MsalFetch.js";
import EMail from "./actionTypes/EMail";
import ActionOly from "./actionTypes/ActionOnly";
import DownloadAndPreview from "./actionTypes/DownloadAndPreview";
import { CheckBox } from "devextreme-react";
import IconSelect from "../../editMenuConfig/iconsSelect/IconsSelect"
import validatedIcons from "../../editMenuConfig/iconsSelect/validatedIcons";

const ActionButtonField = ({
  fieldValue,
  setFieldValue,
  checkChanges,
  isCreate,
  datasetId,
}) => {
    const msal = useMsal();
  const [prossedColumnList, setProssedColumnList] = useState();
  const [actionType, setActionType] = useState(
    fieldValue.action && fieldValue.action.actionType
  );

    const [selectedIcon, setSelectedIcon] = useState(fieldValue.action ? fieldValue.action.icon : '')

  const actionTypes = [
    { value: "Webhook", display: "Webhook" },
    { value: "Mail", display: "E-Mail" },
    { value: "Download", display: "Download" },
    { value: "Preview", display: "Preview" },
    { value: "ActionOnly", display: "Action only" },
  ];

  const getProcessColumns = async () => {
    let tempColumnList = [{ id: 1, name: "..." }];
    try {
      const response = await msalFetch(msal, 
        `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId.id}/column`
      );
      const data = await response.json();

      data.map((item) => {
          if (item.type === "date" || item.type === "datetime") {
          tempColumnList = [
            ...tempColumnList,
            { id: item.id, name: item.name },
          ];
        }
        return tempColumnList;
      });

      setProssedColumnList(tempColumnList);

      return;
    } catch (error) {
      throw "Data Loading Error";
    }
  };
  useEffect(() => {
    getProcessColumns();
  }, []);

  const checkActionType = (e) => {
    setActionType(e.value);
  };
  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Actie type</Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              defaultValue={fieldValue.action && fieldValue.action.actionType}
              displayExpr="display"
              valueExpr="value"
              className={isCreate ? "bl-mandatory-field" : ""}
              items={actionTypes}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["action"] = {};

                tempFieldValue.action["actionType"] = e.value;
                e.element.classList.remove("bl-mandatory-field");

                setFieldValue(tempFieldValue);

                checkActionType(e);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group>
        <Row>
          <Col lg="2">
            <Form.Label>Icoon <span className="bl-icon-preview">
              <i className={`fas ${selectedIcon}`} /></span>
            </Form.Label>
          </Col>
          <Col lg="10">
            <IconSelect
              mandatory={false}
              mandatoryCheck={false}
              loading={false}
              dataSource={validatedIcons}
              defaultValue={fieldValue.action && fieldValue.action.icon}
              handleValueChange={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue.action["icon"] = e.value;
                setFieldValue(tempFieldValue);
                setSelectedIcon(e.value)

                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Eenmalig</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
                          defaultValue={!isCreate ? fieldValue.action.oneTime : false}
               
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue.action["oneTime"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);

                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Overzicht / pagina herladen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={
                fieldValue.action && fieldValue.action.autoRefresh
                  ? fieldValue.action.autoRefresh
                  : false
              }
              onValueChanged={(e) => {
                console.log(e);
                let tempFieldValue = fieldValue;
                console.log(tempFieldValue);
                if (tempFieldValue.action) {
                  tempFieldValue.action["refresh"] = e.value;
                } else {
                  tempFieldValue["action"] = {};
                  tempFieldValue.action["refresh"] = e.value;
                }
                console.log(tempFieldValue);

                setFieldValue(tempFieldValue);

                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>
              Datum / tijd uitvoeren van de actie opslaan in kolom
            </Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              defaultValue={
                fieldValue.action && fieldValue.action.processedColumnId
              }
              displayExpr="name"
              valueExpr="id"
              items={prossedColumnList}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;

                if (e.value === 1) {
                  tempFieldValue.action["processedColumnId"] = null;
                } else {
                  tempFieldValue.action["processedColumnId"] = e.value;
                }

                setFieldValue(tempFieldValue);

                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <div key={actionType}>
        {actionType === "Webhook" && (
          <Webhook
            fieldValue={fieldValue}
            setFieldValue={setFieldValue}
            checkChanges={checkChanges}
          />
        )}

        {actionType === "Mail" && (
          <EMail
            fieldValue={fieldValue}
            setFieldValue={setFieldValue}
            checkChanges={checkChanges}
            isCreate={isCreate}
          />
        )}

        {actionType === "ActionOnly" && (
          <ActionOly
            fieldValue={fieldValue}
            setFieldValue={setFieldValue}
            checkChanges={checkChanges}
            isCreate={isCreate}
          />
        )}

        {(actionType === "Preview" || actionType === "Download") && (
          <DownloadAndPreview
            fieldValue={fieldValue}
            setFieldValue={setFieldValue}
            checkChanges={checkChanges}
            isCreate={isCreate}
          />
        )}
      </div>
    </>
  );
};

export default ActionButtonField;
