import React, { useState, useRef, useEffect, useCallback } from "react";
import { PivotGrid as DynamicGrid, FieldChooser, Search, HeaderFilter, FieldPanel, } from 'devextreme-react/pivot-grid';
import Chart, { AdaptiveLayout, CommonSeriesSettings, Size, Tooltip, } from 'devextreme-react/chart';
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store"

import {
    DataGrid,
    Column,
    Scrolling,
    Grouping,
    GroupPanel,
    GroupItem,
    Summary,
    Button as DataGridButton,
    RemoteOperations,
} from "devextreme-react/data-grid";

const PivotGrid = ({
    dataSource,
    showChart,
    showPivotGrid,
    showFieldChooser,
}) => {
    console.log("showChart", showChart, "showPivotGrid", showPivotGrid, "showFieldChooser", showFieldChooser);
    const [drillDownDataSource, setDrillDownDataSource] = useState(null);
    const chartRef = useRef(null);
    const pivotGridRef = useRef(null);

    useEffect(() => {
        if (showChart) {
            pivotGridRef.current.instance().bindChart(chartRef.current.instance(), {
                dataFieldsDisplayMode: 'splitPanes',
                alternateDataFields: false,
            });
        }
        /*setTimeout(() => {
            dataSource.expandHeaderItem('row', ['North America']);
            dataSource.expandHeaderItem('column', [2013]);
        });*/
    }, [dataSource, showChart]);

    const handleCellPrepared = useCallback(async ({ cell, area, cellElement, component }) => {
        const displayedFields = component.getDataSource().getAreaFields('row')

        if (area === 'row' && cell.type === 'D') {
            const rowPathLength = cell.path.length;
            const field = displayedFields[rowPathLength - 1]
            cellElement.innerHTML = `<div style="font-weight: bold; color: blue;">${cell.text} ${field.detailViewId == null ? 'no click' : '<a href="https://www.nu.nl" rel="noopener noreferrer" target="_blank">link</a>'}
            </div>`;
        }
    }, [])

    /*const CHECK = useCallback(async (e) => {
        if (e.area === 'row') {
            console.log(e);
            if (e.cell.isLast) {
                e.cellElement.innerHTML = `<div style="font-weight: bold; color: blue;">${e.cell.text} </div>`;
            }
        }

    }, []);*/

    const handleCellClick = useCallback(async (e) => {
        console.log("handleCellClick", e/*, pivotGridRef.current().instance()*/);
        if (e.area === 'row') {
            const displayedFields = e.component.getDataSource().getAreaFields('row')
            const rowPathLength = e.cell.path.length;
            const field = displayedFields[rowPathLength - 1]
            console.log("field", field);
            if (field.detailviewId == null)
                return;
        //debugger;

            const ddds = e.component.getDataSource().createDrillDownDataSource()
            ddds.paginate(false);
            const data = await ddds.load();
            // debugger;
            const recordsOfClickedValue = data.filter(rec => rec[field.dataField] === e.cell.path[e.cell.path.length - 1]);
            const detailviewId = field.detailviewId;
            const detailviewName = field.detailviewName;
            const recordId = recordsOfClickedValue.map(rec => rec[field.keyPropertyName])[0];
            console.log("redirect detailviewId - recordId - detailviewName", field.detailviewId, recordId, detailviewName);

        }
        // if (e.area === "data") {
        //     const pivotGridDataSource = e.component.getDataSource();
        //     const rowPathLength = e.cell.rowPath.length;
        //     const rowPathName = e.cell.rowPath[rowPathLength - 1];
        //     //setPopupTitle(`${rowPathName || 'Total'} Drill Down Data`);
        //     var ken = pivotGridDataSource.createDrillDownDataSource(e.cell);
        //     console.log("drillDownDataSource", ken);
        //     ken.paginate(false);
        //     const data = await ken.load();
        //     console.log("handleCellClick", e.area, data);
        //     setDrillDownDataSource(ken);
        // }
        // else if (e.area === "row") {
        //     const ddds = e.component.getDataSource().createDrillDownDataSource()
        //     ddds.paginate(false);
        //     const data = await ddds.load();
        //     console.log("fields", /*e.component.getDataSource().fields(), */e.component.getDataSource().getAreaFields('row'));
        //     if (e.cell.path.length < e.rowFields.length) {
        //         //not the last column clicked, return
        //         return;
        //     }
        //     const columnIndex = e.cell.path.length - 1;
        //     const field = e.rowFields[columnIndex];
        //     if (field.detailViewId == null)
        //         return;
        //     console.log("field.detailViewId", field.detailViewId);
        //     const recordsOfClickedValue = data.filter(rec => rec[field.dataField] === e.cell.path[columnIndex]);
        //     const recordId = recordsOfClickedValue.map(rec => rec['factuurdebiteur_Id'])[0];
        //     //console.log("handleCellClick", e.area, data);
        //     //console.log("columnIndex", e.columnIndex);
        //     console.log("redirect detailviewId - recordId", field.detailViewId, recordId);
        // }
    }, [])

    const handleFieldChooserOptionChanged = useCallback((e) => { console.log("handleFieldChooserOptionChanged", e); }, []);

    return (<>
        {showChart && <Chart ref={chartRef}>
            <Size height={400} />
            <Tooltip
                enabled={showChart}
            //   customizeTooltip={customizeTooltip}
            />
            <CommonSeriesSettings type="bar" />
            <AdaptiveLayout width={450} />
        </Chart>}
        <DynamicGrid
            id="dynamicgrid"
            dataSource={dataSource}
            allowSorting={true}
            allowSortingBySummary={true}
            allowFiltering={true}
            showBorders={true}
            showColumnTotals={true}
            showColumnGrandTotals={true}
            showRowTotals={true}
            allowExpandAll={true}
            showRowGrandTotals={true}
            ref={pivotGridRef}
            onCellClick={handleCellClick}
            onCellPrepared={handleCellPrepared}
        >
            <HeaderFilter
                showRelevantValues={true}
                width={300}
                height={400}
            >
                <Search enabled={true} />
            </HeaderFilter>
            {showFieldChooser && <FieldChooser
                allowSearch={true}
                onContentReady={handleFieldChooserOptionChanged}
            />}
            <FieldPanel visible={true} />
            {/* <FieldChooser enabled={true} height={400} /> */}
        </DynamicGrid></>)
};
export default PivotGrid;