const getQueryString = ({
    parentTableitemId,
    parentControlRelationId,
    timestamp,
}) => {
  const data = {
    ...(parentTableitemId ? { parentTableItemId: parentTableitemId, } : {}),
    ...(parentControlRelationId ? { relationControlId: parentControlRelationId, } : {}),
    ...(timestamp ? { timestamp } : {}),
  };

  const params = new URLSearchParams(data);
  return params.toString();
}

export default getQueryString;
