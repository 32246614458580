import React, { useState, useContext } from "react";

//creates context/helpers/services for the entire app
const ModalContext = React.createContext();

//Provider
const ModalProvider = ({ children }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [modal, setModal] = useState({});
  const [modalTitle, setModalTitle] = useState("Title");
  const [modalContent, setModalContent] = useState({
    // detailviewHtml: {},
    // tableitemId: 0,
    // detailviewId: 0,
  });
  const [deleteNotification, setDeleteNotification] = useState(false);
  const [deleteProceedNotification, setDeleteProceedNotification] = useState(false);
  const [formInValidNotification, setFormInValidNotification] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const [userLeaveNotifictaion, setUserLeaveNotification] = useState(false);
  const [actionTargetKey, setActionTargetKey] = useState();
  const [reload, setReload] = useState(false);
  const [saveChangesNotification, setSaveChangesNotification] = useState(false);
  const [changesThroughModal, setChangesThroughModal] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [successful, setSuccessful] = useState(false);
  const [dirty, setDirty] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        isEditing,
        setIsEditing,
        modal,
        setModal,
        modalTitle,
        setModalTitle,
        modalContent,
        setModalContent,
        deleteNotification,
        setDeleteNotification,
        notificationData,
        setNotificationData,
        actionTargetKey,
        setActionTargetKey,
        reload,
        setReload,
        changesThroughModal,
        setChangesThroughModal,
        modalAction,
        setModalAction,
        setSuccessful,
        successful,
        formInValidNotification,
        setFormInValidNotification,
        deleteProceedNotification,
        setDeleteProceedNotification,
        userLeaveNotifictaion,
        setUserLeaveNotification,
        dirty,
        setDirty,
        saveChangesNotification,
        setSaveChangesNotification,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

//export
export const useModalContext = () => {
  return useContext(ModalContext);
};

export { ModalContext, ModalProvider };
