import React from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

import { CheckBox, NumberBox, SelectBox } from "devextreme-react";

const Decimal = ({ fieldValue, setFieldValue, checkChanges, isCreate }) => {
  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Alleen lezen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={
                !isCreate && fieldValue.readonly ? fieldValue.readonly : false
              }
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["readonly"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Verplicht</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={
                !isCreate && fieldValue.mandatory ? fieldValue.mandatory : false
              }
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["mandatory"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Standaard waarde</Form.Label>
          </Col>
          <Col lg="12">
            <NumberBox
              defaultValue={fieldValue.defaultValue && fieldValue.defaultValue}
              valueChangeEvent="keyup"
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["defaultValue"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Weergave</Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              className={isCreate && "bl-mandatory-field"}
              defaultValue={
                fieldValue.columnDisplayValue &&
                fieldValue.columnDisplayValue.id
                  ? fieldValue.columnDisplayValue.id
                  : ""
              }
              items={fieldValue.columnType.columnDisplayValues}
              valueExpr="id"
              displayExpr="name"
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["columnDisplayValue"] =
                  fieldValue.columnType.columnDisplayValues.filter(
                    (item) => item.id === e.value
                  )[0];
                e.element.classList.remove("bl-mandatory-field");
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Validatie</Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              className={isCreate && "bl-mandatory-field"}
              defaultValue={
                fieldValue.columnValidation && fieldValue.columnValidation.id
                  ? fieldValue.columnValidation.id
                  : ""
              }
              items={fieldValue.columnType.columnValidations}
              valueExpr="id"
              displayExpr="name"
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["columnValidation"] =
                  fieldValue.columnType.columnValidations.filter(
                    (item) => item.id === e.value
                  )[0];
                e.element.classList.remove("bl-mandatory-field");
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default Decimal;
