import { useMsal } from "@azure/msal-react";
import React, { memo, useState, useEffect, useCallback, useRef, useMemo } from "react";

import { Col } from "react-bootstrap";

import { ReactComponent as LogoGrey } from "../../assets/images/logo_grey.svg";
import Loading from "../../components/loading/Loading";

import Diagram from 'devextreme-react/diagram';
import "../../../node_modules/devexpress-diagram/dist/dx-diagram.min.css";

const FlowchartBlock = ({
    width,
    name,
    controlFlowchartId,
    handleControlUpdate,
    flowchartColumnId,
    columnNumber,
    tableitemId,
    addColumnsToState,
    getColumnByColumnId,
}) => {
    const msal = useMsal();
    const [loading, setLoading] = useState(false);

    const flowchartRef = useRef();

    const update = useCallback(() => {

    }, [/* handleControlUpdate */, flowchartColumnId])

    const onSelectionChanged = useCallback((e) => {
        //console.log("onSelectionChanged", flowchartRef.current);

        if (flowchartRef.current != null && e.component.option("hasChanges")) {

            console.log("onSelectionChanged");
            update();
        }
    }, [update/* flowchartColumnId, handleControlUpdate */]);

    const onItemClick = useCallback((e) => {
        if (e.component.option("hasChanges")) {
            console.log("onItemClick");
            update();
        }
    }, [update]);

    const onOptionChanged = useCallback((e) => {
        if (e.name === "hasChanges") {
            // handle the property change here
            console.log("hasChanges", e, e.component.export());
        }
    }, [])



    if (!loading) {
        return (
            <Col lg={width} md={12} className={`bl-col-padding`}>
                <section className="bl-detail-column bl-card">
                    <header className="bl-card-header">
                        <h3>{name}</h3>
                        <Diagram
                            id={`flowchart_${controlFlowchartId}`}
                            ref={flowchartRef}
                            // onRequestEditOperation={(e) => { console.log("onRequestEditOperation", e.component.option("hasChanges"))}}
                            onRequestLayoutUpdate={(e) => { console.log("onRequestLayoutUpdate", e.component.option("hasChanges")) }}
                            //onOptionChanged={(e) => { console.log("onOptionChanged", e.component.option("hasChanges"))}}
                            // onItemClick={(e) => { console.log("onItemClick", 'haschanges', e.component.option("hasChanges"))}}
                            onItemClick={onItemClick}
                            // onSelectionChanged={(e) => { console.log("onSelectionChanged", e, 'haschanges', e.component.option("hasChanges"))}}
                            onSelectionChanged={onSelectionChanged}
                            //onSelectionChanged={onSelectionChanged}
                            onOptionChanged={onOptionChanged}
                        />
                    </header>

                </section>
            </Col>
        );
    } else {
        return <Loading />;
    }
};

export default memo(FlowchartBlock);
