import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import appSettings from "../../../appsettings";
import ModalControls from "../parts/modalControls/ModalControls";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import Form from "react-bootstrap/Form";
import FALink from "../../configurator/parts/FALink";
import { useHistory } from "react-router";
import msalFetch from "../../../api/MsalFetch.js";
import toast, { Toaster } from "react-hot-toast";
import validatedIcons from "../editMenuConfig/iconsSelect/validatedIcons";

import { useModalContext } from "../../../context/modalContext";
import IconSelect from "../editMenuConfig/iconsSelect/IconsSelect";

const AddNewMenuItem = () => {
    const msal = useMsal();
    const link = useHistory();
    const [changeInForm, setChangeInForm] = useState(false);
    const [form, setForm] = useState({ icon: "fa-dot-circle", name: "" });
    const [selectedIcon, setSelectedIcon] = useState("fa-dot-circle");

    const { setIsEditing, setFormInValidNotification, setNotificationData } =
        useModalContext();
    console.log(form);
    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const saveFunction = async () => {
        console.log("saven maar");
        let allDocument = document.getElementsByClassName("bl-mandatory-field");
        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);

            return null;
        }

        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/menu`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(form),
                }
            );
            if (res.ok) {
                const data = await res.json();

                setChangeInForm(false);
                setIsEditing(false);

                console.log(data);
                toast.success("De gegevens zijn successvol opgeslagen...");
                link.push(`/configurator/menuitem/${data.id}`);
            } else {
                toast.error("Oeps er is iets mis gegaan...");
            }
            
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    const handleExitClick = () => {
        if (changeInForm) {
            console.log("change");
        } else {
            setIsEditing(false);
            setChangeInForm(false);
        }
    };
    return (
        <main className="bl-modal-inner">
            <ModalControls
                modalTitle="Menu"
                changeInForm={changeInForm}
                handleSaveClick={saveFunction}
                hasExitFunction={true}
                handleExitClick={handleExitClick}
            />
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Naam</Form.Label>
                    </Col>
                    <Col lg="12">
                        <TextBox
                            className={"bl-mandatory-field"}
                            placeholder="Menu naam"
                            valueChangeEvent="keyup"
                            onValueChanged={(e) => {
                                if (e.value.trim() === "") {
                                    e.element.classList.add("bl-mandatory-field");
                                } else {
                                    e.element.classList.remove("bl-mandatory-field");
                                }
                                let tempForm = form;
                                tempForm["name"] = e.value;
                                tempForm["icon"] = "fa-circle-o";
                                checkChanges();
                                setForm(tempForm);
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <div className="dx-field-label">Menu icoon </div>
                    </Col>
                    <Col lg="12">
                        <div className="menu-icon-preview-wrapper">
                            <div className="bl-icon-preview">
                                <i className={`fas ${selectedIcon}`} />
                            </div>

                            <IconSelect
                                mandatory={false}
                                loading={false}
                                dataSource={validatedIcons}
                                handleValueChange={(e) => {
                                    let tempFormValue = form;
                                    tempFormValue["icon"] = e.value;
                                    setForm(tempFormValue);

                                    setSelectedIcon(e.value);
                                    setChangeInForm(true);
                                }}
                                defaultValue={
                                    form.icon
                                        ? validatedIcons.find((t) => t.value === form.icon)
                                            ? form.icon
                                            : "fa-dot-circle"
                                        : "fa-dot-circle"
                                }
                            />
                        </div>
                        {/* <TextBox
              placeholder="Bijv. fa-address-book"
              valueChangeEvent="keyup"
              onValueChanged={(e) => {
                let tempForm = form;
                tempForm["icon"] = e.value;
                setForm(tempForm);
                checkChanges();
              }}
            />
            <FALink /> */}
                    </Col>
                </Row>
            </Form.Group>
        </main>
    );
};
export default AddNewMenuItem;
