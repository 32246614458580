import React from "react";

import { useModalContext } from "../../context/modalContext";

const FormInValidNotification = () => {
  const {
    formInValidNotification,
    setFormInValidNotification,
    notificationData,
  } = useModalContext();

  console.log(notificationData);
  return (
    <div className={`bl-notify-modal`}>
      <div className="bl-notify-modal-inner">
        <header className="bl-notify-modal-header">
          <h3>Let op!</h3>
          <div
            className="header-action"
            onClick={() => {
              setFormInValidNotification(false);
              notificationData[0].scrollIntoView({ behavior: "smooth" });
            }}
          >
            <i className="fas fa-times" />
          </div>
        </header>
        <article>
          <p>Niet alle velden zijn correct ingevuld.</p>
          <footer className="bl-notify-controls">
            <div
              className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
              onClick={() => {
                setFormInValidNotification(false);
                notificationData[0].scrollIntoView({ behavior: "smooth" });
              }}
              //   onClick={() => {
              //     setDeleteNotification(false);
              //     setSuccessful(false);
              //   }}
            >
              OK
            </div>
            {/* <div
                  className="bl-notify-modal-btn bl-btn bl-notify-succes-btn"
                  onClick={handleDeleteButtonClick}
                >
                  Verwijderen
                </div> */}
          </footer>
        </article>
      </div>
    </div>
  );
};

export default FormInValidNotification;
