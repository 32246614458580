import "./ControlRelationDataFetcher";
import ListWrapper from "../list/ListWrapper.js";
import React, { useState, useEffect } from "react";
import appSettings from "../../appsettings"
import msalFetch from "../../api/MsalFetch.js";

const ControlRelationDataFetcher = ({
    detailviewId,
    controlRelationId,
    tableItemId,
    inlineEditMode,
    setInlineEditMode,
}) => {
    const [scrollHorizontal, setScrollHorizontal] = useState();
    const [gridName, setGridName] = useState();
    const [columns, setColumns] = useState([]);
    const [columnFunctions, setColumnFunctions] = useState();
    const [tableId, setTableId] = useState();
    const [keyExpression, setKeyExpression] = useState();
    const [expandDetailViewId, setExpandDetailViewId] = useState();
    const [manualSortColumnId, setManualSortColumnId] = useState();
    const [detailViewIdAdd, setDetailViewIdAdd] = useState();
    const [preferredDetailViewId, setPreferredDetailViewId] = useState();
    const [preferredDetailViewIdField, setPreferredDetailViewIdField] = useState();
    const [preferredDetailViewIdEdit, setPreferredDetailViewIdEdit] = useState();
    const [overviewOrControlRelationName, setOverviewOrControlRelationName] = useState();
    const [excelFilename, setExcelFilename] = useState();
    const [renderGrid, setRenderGrid] = useState();
    const [allowInlineEditing, setAllowInlineEditing] = useState();
    const [allowCopy, setAllowCopy] = useState();
    const [allowDelete, setAllowDelete] = useState();
    const [allowCreate, setAllowCreate] = useState();
    const [allowRead, setAllowRead] = useState();
    const [allowUpdate, setAllowUpdate] = useState();
    const [allowExportExcel, setAllowExportExcel] = useState();
    const [verticalHeight, setVerticalHeight] = useState();

    useEffect(() => {
        // make sure we unmount the grid, when we switch to another controlRelationId
        setRenderGrid(false);
    }, [controlRelationId]);

    // WARNING: this effect can run twice in develop because of StrictMode
    useEffect(() => {
        const getData = async () => {
            try {
                //const response = await msalFetch(null, `${appSettings.api.endpoint}/api/overview/${overviewId}/`);
                const response = await msalFetch(null, `${appSettings.api.endpoint}/api/${detailviewId}/relation/${controlRelationId}/`);
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(`[Overview] getOverviewData response was not ok: ${data.message}`);
                }
                setScrollHorizontal(data.scrollHorizontal);
                setGridName(data.name);
                setColumnFunctions(data.columnFunctions);
                setTableId(data.relationTable.id); //relationTableId
                setManualSortColumnId(data.manualChangeOrderByColumnId)
                setAllowCopy(data.allowCopy);
                setPreferredDetailViewId(data.preferredDetailViewId);
                setPreferredDetailViewIdEdit(data.preferredDetailViewIdEdit);
                setPreferredDetailViewIdField(data.preferredDetailViewIdField == null ? null : data.preferredDetailViewIdField.toLowerCase());
                setDetailViewIdAdd(data.preferredDetailViewIdAdd ?? data.preferredDetailViewId ?? data.table.preferredDetailViewId);
                setOverviewOrControlRelationName(data.name);
                setExcelFilename(data.name + '.xlsx');
                setColumns(data.columns);
                setExpandDetailViewId(data.expandDetailviewId);
                setKeyExpression(data.columns.find((t) => t.primairyKey).name.toLowerCase());
                setRenderGrid(true);
                setAllowInlineEditing(data.inlineEditing);
                setVerticalHeight(data.verticalHeight);
                setAllowDelete(data.relationTable.permission.delete === 1)
                setAllowCreate(data.relationTable.permission.create === 1)
                setAllowRead(data.relationTable.permission.read === 1)
                setAllowUpdate(data.relationTable.permission.update === 1)
                setAllowExportExcel(data.allowExportToExcel);
                //table readonly
            } catch (error) {
                // @TODO: do something...
                throw error;
            }
        };
        getData();
    }, [detailviewId, controlRelationId/* , columns */]);

    if (!renderGrid) {
        return null;
    }

    return (
        <ListWrapper
            columns={columns}
            controlRelationId={controlRelationId}
            scrollHorizontal={scrollHorizontal}
            columnFunctions={columnFunctions}
            tableId={tableId}
            keyExpression={keyExpression}
            isControlRelation={true}
            isOverview={false}
            expandDetailViewId={expandDetailViewId}
            manualSortColumnId={manualSortColumnId}
            detailviewId={detailviewId}
            detailViewIdAdd={detailViewIdAdd}
            preferredDetailViewId={preferredDetailViewId}
            preferredDetailViewIdEdit={preferredDetailViewIdEdit}
            preferredDetailViewIdField={preferredDetailViewIdField}
            tableItemId={tableItemId}
            excelFilename={excelFilename}
            overviewOrControlRelationName={overviewOrControlRelationName}
            gridName={gridName}
            allowInlineEditing={allowInlineEditing}
            inlineEditMode={inlineEditMode}
            setInlineEditMode={setInlineEditMode}
            verticalHeight={verticalHeight}
            allowCopy={allowCopy}
            allowDelete={allowDelete}
            allowCreate={allowCreate}
            allowRead={allowRead}
            allowUpdate={allowUpdate}
            allowExportExcel={allowExportExcel}
        />
    );
};

export default ControlRelationDataFetcher;
