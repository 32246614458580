import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import Loading from "../../loading/Loading";
import DataGrid, {
  Column,
  FilterRow,
  SearchPanel,
} from "devextreme-react/data-grid";
import Wijzigingen from "./Wijzigingen";
import ActionExpression from "./ActionExpression";
import { Popup } from 'devextreme-react/popup';
import "./logging.scss";

const LOGGING = ({ setOpenLogging, tableId, tableitemId, tableName }) => {
  const msal = useMsal();
  const [loading, setLoading] = useState(true);
  const [logData, setLogData] = useState();
  const [noData, setNoData] = useState(false);

  const getLogData = async () => {
    let tempLogData = [];

    setLoading(true);

    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/table/${tableId}/logging/${tableitemId}`
      );
      const data = await response.json();
      console.log(data);
      if (data.data && data.data.length > 0) {
        data.data.map((item, index) => {
          const { action, timeStamp, wijzigingen, username, tableName } = item;
          tempLogData = [
            ...tempLogData,
            {
              action: action,
              timeStamp: timeStamp,
              wijzigingen: wijzigingen,
              username: username,
              tableName: tableName,
            },
          ];

          return tempLogData;
        });

        setLogData(tempLogData);
      } else {
        setNoData(true);
      }

      setLoading(false);
      return tempLogData;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    getLogData();

    console.group(tableitemId);
  }, []);

  return (


    <Popup
      className="bl-popup"
      showTitle={true}
      title={`Logboek ${tableName}`}
      resizeEnabled={true}
      dragEnabled={true}
      animation={false}
      visible={true}
      showCloseButton={true}
      onHiding={(e) => { setOpenLogging(false); e.cancel = true; }}
      fullScreen={false}

    >
      <DataGrid
        className="bl-card"
        dataSource={logData}
        showBorders={true}
        showRowLines={true}
        rowAlternationEnabled={true}
        noDataText="Geen resultaten..."
      >
        <FilterRow visible={true} />
        <SearchPanel
          visible={true}
          highlightCaseSensitive={false}
          placeholder="Zoeken..."
        />
        <Column cellRender={ActionExpression} width="40px" />
        <Column
          width="150px"
          dataField="timeStamp"
          dataType="datetime"
          caption="Datum / tijd"
          format="dd-MM-yyyy HH:mm"
        />
        <Column
          caption="Mutatie(s)"
          cellRender={Wijzigingen}
          dataType="string"
          dataField="wijzigingen"
          className="word-wrap"
          allowSearch={true}
        />
        <Column
          width="200px"
          dataField="username"
          dataType="string"
          caption="Gebruiker"
        />

      </DataGrid>

    </Popup>


    // <div className={`bl-modal show-modal`}>
    //   <article className="bl-modal-content">
    //     <header className="bl-modal-header">
    //       <h3>Logboek</h3>
    //       <div
    //         className="bl-has-exit-modal"
    //         onClick={() => setOpenLogging(false)}
    //       >
    //         <i className="fas fa-times" />
    //       </div>
    //     </header>

    //     <section className="bl-modal-inner bl-log">
    //       {loading && <Loading />}
    //       {!loading && (
    //         <div className="bl-scroll-box active-scroll-box ">
    //           {!noData ? (
    //             <DataGrid
    //               dataSource={logData}
    //               showBorders={true}
    //               showRowLines={true}
    //               rowAlternationEnabled={true}
    //               noDataText="Geen resultaten..."
    //             >
    //               <FilterRow visible={true} />
    //               <SearchPanel
    //                 visible={true}
    //                 highlightCaseSensitive={false}
    //                 placeholder="Zoeken..."
    //               />
    //               <Column cellRender={ActionExpression} width="40px" />
    //               <Column
    //                 width="150px"
    //                 dataField="timeStamp"
    //                 dataType="datetime"
    //                 caption="Datum / tijd"
    //                 format="dd-MM-yyyy HH:mm"
    //               />
    //               <Column
    //                 caption="Mutatie(s)"
    //                 cellRender={Wijzigingen}
    //                 dataType="string"
    //                 dataField="wijzigingen"
    //                 className="word-wrap"
    //                 allowSearch={true}
    //               />
    //               <Column
    //                 width="200px"
    //                 dataField="username"
    //                 dataType="string"
    //                 caption="Gebruiker"
    //               />

    //             </DataGrid>
    //           ) : (
    //             <div className="no-data-message">
    //               {" "}
    //               <i className="fas fa-indo"></i>{" "}
    //               <span> Geen data om weer te geven.</span>
    //             </div>
    //           )}
    //         </div>
    //       )}
    //     </section>
    //   </article>
    // </div>
  );
};

export default LOGGING;
