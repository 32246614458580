import React, { useCallback } from "react";
import { SelectBox } from "devextreme-react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useGlobalContext } from "../../context/context";

const Settings = () => {
  const themas = ["classic-theme", "colour-theme", "light-theme"];
  const { setTheme } = useGlobalContext();
  const handleThemeChange = useCallback((e) => {
    setTheme(e.value);
  }, [setTheme]);

  return (
    <main className="bl-content">
      <section className="bl-card">
        <h1>Instellingen</h1>

        <Form.Group>
          <Row>
            <Col md="4">
              <Form.Label>Thema</Form.Label>
            </Col>
            <Col md="8">
              <SelectBox
                searchEnabled
                dataSource={themas}
                defaultValue={themas[0]}
                placeholder="Maak een keuze"
                onValueChanged={handleThemeChange}
              />
            </Col>
          </Row>
        </Form.Group>
      </section>
    </main>
  );
};

export default Settings;
