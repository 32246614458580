import React, { memo } from "react";
import Tabs from 'devextreme-react/tabs';

const DetailviewTabs = ({
    allDetailviewData,
    activeDetailviewId,
    transitionToTab,
}) => {
    const items = allDetailviewData.map(({ id, name }) => ({
        id,
        text: name,
    }));

    const currentItem = items.find(({ id }) => id === activeDetailviewId);
    return (
        <header className="bloom-tabs" id="tabTarget">
            <Tabs
                id="withText"
                selectedItem={currentItem}
                items={items}
                keyExpr="id"
                stylingMode="primary"
                onItemClick={({ itemData }) => {
                    transitionToTab(itemData.id);
                }}
                scrollByContent
            />

        </header>
    );
};

export default memo(DetailviewTabs);
