import React from "react";

import { useModalContext } from "../../context/modalContext";

const SaveChangesNotification = () => {
  const {
    formInValidNotification,
    setSaveChangesNotification,
    notificationData,
  } = useModalContext();

  console.log(notificationData);
  return (
    <div className={`bl-notify-modal`}>
      <div className="bl-notify-modal-inner">
        <header className="bl-notify-modal-header">
          <div
            className="header-action"
            onClick={() => {
              setSaveChangesNotification(false);
            }}
          >
            <i className="fas fa-times" />
          </div>
        </header>
        <article>
          <p>Wilt u de wijzigingen opslaan?</p>
          <footer className="bl-notify-controls">
            <div
              className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
              onClick={() => {
                setSaveChangesNotification(false);
                console.log("saveFunction en modal sluiten");
              }}
            >
              Opslaan
            </div>

            <div
              className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
              onClick={() => {
                setSaveChangesNotification(false);
                console.log("modal sluiten");
              }}
            >
              Niet opslaan
            </div>
          </footer>
        </article>
      </div>
    </div>
  );
};

export default SaveChangesNotification;
