import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import Loading from "../../components/loading/Loading";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Scheduler as DxScheduler, Resource, AppointmentDragging } from 'devextreme-react/scheduler';
import Switch from 'devextreme-react/switch';
import { useGlobalContext } from "../../context/context.js";
import { TagBox } from 'devextreme-react/tag-box';
import QuickEdit from "../modals/EditDetailview/QuickEdit.js";
import QuickDetailviewAdd from "../modals/AddDetailview/quickDetailviewAdd/QuickDetailviewAdd.js";
import Draggable, { DraggableTypes } from 'devextreme-react/draggable';
import ScrollView from 'devextreme-react/scroll-view';
import "./Scheduler.scss";
import moment from "moment";
import msalFetch from "../../api/MsalFetch.js";
import appSettings from "../../appsettings.js";
import SchedulerDraggableItems from '../scheduler/SchedulerDraggableItems.js';
import Splitter, { Item } from 'devextreme-react/splitter';

const Scheduler = ({
    title,
    schedulerId,
    value,
    users,
    dataStore,
    selectedUsers,
    setSelectedUsers,
    filterOnOnlineUser,
    usersOfScheduler,
    preferredDetailViewId,
    cellDuration,
    startDayHour,
    endDayHour,
    tableId,
    draggableSelectFieldId,
    draggableItems,
    draggableSelectFieldAlias,
}) => {

    const schedulerRef = useRef();
    const [loading, setLoading] = useState(false);
    const [groupByDate, setGroupByDate] = useState(false);
    const [orientation, setOrientation] = useState(false);
    const [groupByDraggableField, setGroupByDraggableField] = useState(false);
    const [editRecordId, setEditRecordId] = useState();
    const [insertRecord, setInsertRecord] = useState(false);
    const [newAppointmentStartDate, setNewAppointmentStartDate] = useState();
    const [newAppointmentEndDate, setNewAppointmentEndDate] = useState();
    const [newAppointmentUserId, setNewAppointmentUserId] = useState();
    const [draggableSelectFieldValue, setDraggableSelectFieldValue] = useState()

    const groups = ['userId'/*, 'draggableFieldId'*/]
    const UsersForResource = users.filter(user => selectedUsers.includes(user.id));
    const draggingGroupName = ['naam', 'naam2'];
    const onOrientationChanged = useCallback((e) => {
        setOrientation(e.value);
    }, []);

    const onGroupByDateChanged = useCallback((e) => {
        setGroupByDate(e.value);
    }, []);

    const onGroupByDraggableField = useCallback((e) => {
        setGroupByDraggableField(e.value);
    }, []);

    const onUserSelectionChanged = useCallback((e) => {
        if (e.removedItems.length > 0) {
            const removedUsers = e.removedItems.map(item => item.id);
            setSelectedUsers(selectedUsers.filter(item => removedUsers.indexOf(item) === -1));
        } else {
            const addedUsers = e.addedItems.map(item => item.id);
            const uniqueAddedUsers = addedUsers.filter(item => !selectedUsers.includes(item));
            setSelectedUsers([...selectedUsers, ...uniqueAddedUsers]);

        }
    }, [setSelectedUsers, selectedUsers]);


    const views = useMemo(() => [
        {
            name: 'Week', type: 'week', intervalCount: 1, groupOrientation: orientation ? 'vertical' : 'horizontal', startDate: new Date()
        },
        {
            name: '2 weken', type: 'week', intervalCount: 2, groupOrientation: orientation ? 'vertical' : 'horizontal', startDate: new Date()
        },
        {
            name: 'Vlg 7', type: 'day', intervalCount: 7, groupOrientation: orientation ? 'vertical' : 'horizontal'
        },


        { name: 'Maand', type: 'month', intervalCount: 1, groupOrientation: orientation ? 'vertical' : 'horizontal' },

    ], [orientation]);

    const [reRender, setReRender] = useState([false]);


    const onAppointmentClick = useCallback((e) => {
        setEditRecordId(e.appointmentData.id);
    }, []);

    const onCellClick = useCallback((e) => {
        console.log("onCellClick", e);
        const newValueStart = moment.parseZone(e.cellData.startDate).utc(true);
        const newValueEnd = moment.parseZone(e.cellData.startDate.getTime() + 1 * 60 * 60 * 1000).utc(true);
        const newValueStartIso = newValueStart.format('YYYY-MM-DDTHH:mm:ss');
        const newValueEndIso = newValueEnd.format('YYYY-MM-DDTHH:mm:ss');
        setNewAppointmentStartDate(newValueStartIso);
        setNewAppointmentEndDate(newValueEndIso);
        setNewAppointmentUserId(e.cellData.groups.userId)
        setInsertRecord(true);
    }, []);

    const refreshGrid = useCallback(() => { schedulerRef.current.instance().getDataSource().reload(); }, []);


    const { isMobile } = useGlobalContext();

    const resourceCellComponent = useCallback((e) => {
        const user = users.find(user => user.id === e.data.id);
        const styleObj = {
            background: user.color ? user.color : "#ff9727", borderwidth: "5px"
        }
        const styleObj2 = {
            borderwidth: "5px", borderColor: "red"
        }
        return (
            <div className="dx-template-wrapper">
                <div className="name" style={styleObj} >
                    <h2>{user.firstname} {user.name}</h2>
                </div>
                <div className="avatar">
                    <img style={styleObj2} className="avatarpic" src={user.profilePicSmall && (user.profilePicSmall)} />
                </div>

            </div >)
            ;
    }, [users]);

    const AppointmentTemplate = useCallback((props) => {
        const { appointmentData } = props.data;
        let appStart = moment(appointmentData.startDate).format('H:mm')
        let appEnd = moment(appointmentData.endDate).format('H:mm')


        return (
            <div className="appointment">
                <div >{appointmentData.text}</div>
                {appointmentData.draggableFieldItemId && (<div >{draggableSelectFieldAlias}: {/*appointmentData.draggableFieldItemId*/appointmentData.draggableFieldDisplayName}</div>)}
                {appointmentData.location && <div className="location"><a href={`https://maps.google.com/maps?q=${appointmentData.location}`} target="blank">{appointmentData.location}</a></div>}
                <div className="date">{appStart} - {appEnd}</div>
                <div className="description" dangerouslySetInnerHTML={{ __html: appointmentData.description }}></div>
            </div>
        );
    }, [draggableSelectFieldAlias]);

    const handleSave = useCallback((recordId) => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        setEditRecordId(recordId); // open edit
        refreshGrid();
    }, [refreshGrid]);

    const handleUserLeaveContinue = useCallback(() => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);

    const handleSaveAndCreateNew = useCallback(() => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        // close the first, wait a milisecond for the Content to unmount, re-mount a fresh Content for fresh data fetching
        setTimeout(() => {
            setInsertRecord(true);
        }, 1);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);


    const handleClose = useCallback(() => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);

    const handleUserLeaveSave = useCallback(() => {
        setInsertRecord(false);
        setDraggableSelectFieldValue(null);
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);

    const handleSaveEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
    }, [refreshGrid]);

    const handleSaveAndCreateNewEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
        setTimeout(() => {
            setInsertRecord(true);
        }, 1);
    }, [refreshGrid]);

    const handleUserLeaveContinueEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
    }, [refreshGrid])

    const handleCloseEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
    }, [refreshGrid]);

    const handleUserLeaveSaveEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
    }, [refreshGrid]);

    const handleDeleteEdit = useCallback(() => {
        refreshGrid(); // always do this, we might have done SaveAndCreateNew
        setEditRecordId(null);
    }, [refreshGrid]);

    const dropItem = useCallback(async (draggableItemId, userId, startDateTime, endDateTime) => {
        console.log("dropItem", startDateTime, endDateTime);
        try {
            const params = {
                userId: userId,
                draggableItemId: draggableItemId,
                startDateTime: startDateTime,
                endDateTIme: endDateTime
            }
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/scheduler/${schedulerId}/drop-item`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(params),
                }
            );

        } catch (error) {
            throw error;
        }
    }, [schedulerId])

    const onDraggableDrop = useCallback(async (e) => {
        const dateStartDate = new Date(e.itemData.startDate);
        const dateEndDate = new Date((new Date(e.itemData.startDate)).getTime() + 3600000);
        const parsedDateStart = `${dateStartDate.getFullYear()}/${String(dateStartDate.getMonth() + 1).padStart(2, '0')}/${String(dateStartDate.getDate()).padStart(2, '0')} ${String(dateStartDate.getHours()).padStart(2, '0')}:${String(dateStartDate.getMinutes()).padStart(2, '0')}`;
        const parsedDateEnd = `${dateEndDate.getFullYear()}/${String(dateEndDate.getMonth() + 1).padStart(2, '0')}/${String(dateEndDate.getDate()).padStart(2, '0')} ${String(dateEndDate.getHours()).padStart(2, '0')}:${String(dateEndDate.getMinutes()).padStart(2, '0')}`;
        await dropItem(e.fromData.primairyKeyValue, e.itemData.userId, parsedDateStart, parsedDateEnd);
        refreshGrid();
    }, [dropItem, refreshGrid])

    if (!loading) {
        return (
            <>
                <Splitter

                    onResizeEnd={setReRender}
                    onItemCollapsed={setReRender}
                    onItemExpanded={setReRender}
                >
                    <Item
                        size={draggableSelectFieldId ? "75%" : "100%"}
                        resizable={true}
                        collapsible={false}
                        className="bl-card"
                    >
                        {/* <Col md={draggableSelectFieldId ? "8" : "12"} className={`bl-card ${isMobile && "bl-mobile-card"}`}> */}
                        {/* <h3>{title}</h3> */}
                        <DxScheduler
                            className={`cellduration${cellDuration}`}
                            remoteFiltering={true}
                            showCurrentTimeIndicator={true}
                            shadeUntilCurrentTime={true}
                            showAllDayPanel={false}
                            startDayHour={startDayHour}
                            endDayHour={endDayHour}
                            id={`scheduler_${schedulerId}`}
                            ref={schedulerRef}
                            views={views}
                            adaptivityEnabled={isMobile}
                            height={"70vh"}

                            appointmentComponent={AppointmentTemplate}
                            dataSource={dataStore}
                            groupByDate={groupByDate}
                            allDayPanelMode="allDay"
                            //let's make this optional per scheduler visual
                            cellDuration={cellDuration}
                            groups={groups}
                            onAppointmentDblClick={onAppointmentClick}
                            onCellClick={onCellClick}
                            resourceCellComponent={resourceCellComponent}
                            onAppointmentUpdating={(e) => { console.log("onAppointmentUpdating", e) }}
                            onAppointmentUpdated={(e) => { console.log("onAppointmentUpdated", e) }}
                        >
                            {!groupByDraggableField && <Resource
                                fieldExpr="userId"
                                dataSource={UsersForResource}
                                allowMultiple={false}
                                displayExpr="firstname"
                                label="firstname"
                                colorExpr="color"
                                useColorAsDefault={true}

                            />}
                            {draggableSelectFieldId && groupByDraggableField &&
                                <Resource
                                    fieldExpr="draggableFieldId"
                                    dataSource={draggableItems}
                                    allowMultiple={false}
                                    displayExpr="label"
                                    label="label"
                                //colorExpr="color"
                                //useColorAsDefault={true}

                                />
                            }
                            <AppointmentDragging
                                group={draggingGroupName}
                                onRemove={(e) => { console.log("AppointmentDragging - onRemove", e) }}
                                onAdd={onDraggableDrop}
                            />
                        </DxScheduler>
                    </Item>
                    <Item

                        resizable={true}
                        collapsible={true}

                        className="bl-card"
                    >
                        {draggableSelectFieldId &&
                            <>


                                <h3>{draggableSelectFieldAlias}</h3>
                                {draggableItems && <SchedulerDraggableItems
                                    draggingGroupName={draggingGroupName}
                                    draggableItems={draggableItems}
                                    title={draggableSelectFieldAlias}
                                />
                                }
                            </>}
                    </Item>
                </Splitter >
                <Form.Group>
                    <div className="dx-fieldset">
                        <Row className={`bl-card ${isMobile && "bl-mobile-card"}`}>
                            <Col md="12">
                                <h3>Opties</h3>
                            </Col>
                            <Col md="12" >

                                <div className="dx-field">
                                    <div className="dx-field-label">Agendaselectie</div>
                                    <div className="dx-field-value">

                                        {selectedUsers && (

                                            <TagBox
                                                defaultValue={selectedUsers}
                                                items={users}

                                                labelMode="outside"
                                                selectAllMode=""
                                                showSelectionControls={true}
                                                showClearButton={true}
                                                searchEnabled={true}
                                                showDropDownButton={true}
                                                valueExpr="id"
                                                displayExpr="firstname"
                                                onSelectionChanged={onUserSelectionChanged}
                                            />

                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col md="6" >
                                <div className="dx-field">
                                    <div className="dx-field-label">Gebruiker/ datum prioriteit</div>
                                    <div className="dx-field-value">
                                        <Switch
                                            defaultValue={groupByDate}
                                            onValueChanged={onGroupByDateChanged}
                                            switchedOffText="Geb"
                                            switchedOnText="Dat"

                                        />
                                    </div>
                                </div>
                            </Col>
                            {/* <Col md="2">
                                <div className="dx-field">
                                    <div className="dx-field-label">Richting</div>
                                    <div className="dx-field-value">
                                        <Switch
                                            defaultValue={orientation}
                                            onValueChanged={onOrientationChanged}
                                            switchedOnText="Hor"
                                            switchedOffText="Ver"

                                        />
                                    </div>

                                </div>
                            </Col> */}
                            {draggableSelectFieldId && <Col md="6">
                                <div className="dx-field">
                                    <div className="dx-field-label">{`${draggableSelectFieldAlias}filter`}</div>
                                    <div className="dx-field-value">

                                        <Switch
                                            defaultValue={groupByDraggableField}
                                            onValueChanged={onGroupByDraggableField}
                                            switchedOffText={`Uit`}
                                            switchedOnText={`Aan`}

                                        />

                                    </div>
                                </div>

                            </Col>}
                        </Row >

                    </div >
                </Form.Group >
                {
                    editRecordId && (
                        <QuickEdit
                            targetId={editRecordId}
                            isRelation={false}
                            setIsEditing={setEditRecordId}
                            isEditing={true}
                            currentOverview={{ preferredDetailViewId: preferredDetailViewId }}
                            refreshGrid={refreshGrid}
                            controlRelationId={null}
                            overrideDetailViewId={null}
                            handleSave={handleSaveEdit}
                            handleSaveAndCreateNew={handleSaveAndCreateNewEdit}
                            handleUserLeaveContinue={handleUserLeaveContinueEdit}
                            handleClose={handleCloseEdit}
                            handleUserLeaveSave={handleUserLeaveSaveEdit}
                            handleDelete={handleDeleteEdit}
                        />
                    )
                }

                {
                    insertRecord && newAppointmentStartDate && newAppointmentUserId && newAppointmentEndDate && (
                        <QuickDetailviewAdd
                            setOpenQuickAddModal={setInsertRecord}
                            openQuickAddModal={insertRecord}
                            refreshGrid={refreshGrid}
                            controlRelationId={null}
                            targetId={preferredDetailViewId}
                            connectorStartDate={newAppointmentStartDate}
                            connectorEndDate={newAppointmentEndDate}
                            connectorUserId={newAppointmentUserId}
                            handleSaveAndCreateNew={handleSaveAndCreateNew}
                            handleSave={handleSave}
                            handleUserLeaveContinue={handleUserLeaveContinue}
                            handleClose={handleClose}
                            handleUserLeaveSave={handleUserLeaveSave}
                            draggableSelectFieldId={draggableSelectFieldId}
                            draggableSelectFieldValue={draggableSelectFieldValue}
                        />
                    )
                }
            </>
        );
    } else {
        return <Loading />;
    }
};

export default Scheduler;
