import { Column } from "devextreme-react/data-grid";
import { ColumnDisplayValueCalculated } from "../../../enums/columnDisplayValue"
import { getFormatter } from '../../formats';

const renderCalculatedColumn = (name, alias, width, key, inlineEditMode, displayValueId, sortIndex, sortDirection, groupIndex) => {
    let format;
    switch (displayValueId) {
        case ColumnDisplayValueCalculated.NUM:
            {
                break;
            }
        case ColumnDisplayValueCalculated.Precision1:
            {
                //format = ',##0.0';
                format = getFormatter('1');
                break;
            }
        case ColumnDisplayValueCalculated.Precision2:
            {
                //format = ',##0.00';
                format = getFormatter('2');
                break;
            }
        case ColumnDisplayValueCalculated.Precision3:
            {
                //format = ',##0.000';
                format = getFormatter('3');
                break;
            }
        case ColumnDisplayValueCalculated.EUPrecison2:
            {
                format = getFormatter('EURO2');
                break;
            }
        case ColumnDisplayValueCalculated.EUPrecison3:
            {
                format = getFormatter('EURO3');
                break;
            }
        default:
            format = null;
    }
    return (
        <Column
            key={key}
            dataField={name.toLowerCase()}
            format={format}
            alignment="right"
            caption={alias}
            allowSearch={true}
            dataType="number"
            allowEditing={false}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={false}
            groupIndex={groupIndex}
            width={width}
            cssClass={inlineEditMode ? "disabled-column-controls" : ""}
        />
    );
};

export default renderCalculatedColumn;
