import React, { useState, useEffect, useCallback } from "react";
import { useGlobalContext } from "../../../../context/context";
import appSettings from "../../../../appsettings";
import msalFetch from "../../../../api/MsalFetch.js";
import Loading from "../../../loading/Loading";
import Content from "../../../detailview/Content";
import { Popup } from 'devextreme-react/popup';

const QuickDetailviewAdd = ({
    targetId: detailviewId, // this is a detailviewId
    setOpenQuickAddModal,
    controlRelationId,
    relationTableItemId, // the tableitemId the Content is using
    connectorStartDate,
    connectorUserId,
    connectorEndDate,
    handleSaveAndCreateNew,
    handleSave,
    handleUserLeaveContinue,
    handleClose,
    handleUserLeaveSave,
    handleAddNew,
    draggableSelectFieldId,
    draggableSelectFieldValue
}) => {
    console.log("QuickDetailviewAdd", connectorStartDate, connectorEndDate);
    const { isMobile } = useGlobalContext();
    const [loading, setLoading] = useState(true);
    const [allDetailviewData, setAllDetailviewData] = useState([]);
    const getDetailviewHtml = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/detailview/${detailviewId}/?quickadd=true&onlyVisibleAsTab=null`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[QuickDetailviewAdd] getDetailviewHtml response was not ok: ${data.message}`);
            }
            setAllDetailviewData(data.detailViews);
        } catch (error) {
            throw error;
        }
        setLoading(false);
    }, [detailviewId]);

    useEffect(() => {
        getDetailviewHtml();
    }, [getDetailviewHtml]);

    const [fullScreen, setFullScreen] = useState(false);
    const fullScreenIcon = (!fullScreen ? 'fas fa-expand-arrows-alt' : 'fas fa-window-minimize');
    const repaintPopup = useCallback(() => {

        Popup.current.instance().repaint();
    }, []);

    const currentDetailview =
        allDetailviewData.find(({ id }) => id === Number(detailviewId))
        || allDetailviewData[0]; // this is a feature.
    // Sometimes the backend gives data, but the detailviewId itself is not present.
    // relationTable stuff..

    return (
        <Popup
            title="Toevoegen"
            className="bl-popup"
            showTitle={true}
            resizeEnabled={true}
            dragEnabled={true}
            visible={true}
            animation={false}
            showCloseButton={true}
            fullScreen={fullScreen}
            width={"80vw"}
            height={"80vh"}
            onShown={repaintPopup}
            onHiding={(e) => {
                // TODO: throwNotification, but it's handled by the child Content.. :(

                handleClose && handleClose();
                e.cancel = true;
            }}
            toolbarItems={
                [{
                    toolbar: "top",
                    widget: "dxButton",
                    location: "after",
                    // className: "Button",
                    options:
                    {
                        icon: fullScreenIcon,
                        text: 'Volledig scherm',
                        stylingMode: 'text',
                        type: 'default',

                        onClick: (e) => {

                            setFullScreen(!fullScreen);
                        }
                    }
                }
                ]
            }
        //onHiding={() => { setAddConnector(false); }}
        >
            {(loading) ? (
                <div />
            ) : (
                <Content
                    isMobile={isMobile}
                    showTitle={true}
                    allDetailviewData={allDetailviewData}
                    currentDetailview={currentDetailview}
                    isContentForModal={true}
                    isQuickCreate={true}
                    parentControlRelationId={controlRelationId}
                    parentTableitemId={relationTableItemId}
                    connectorStartDate={connectorStartDate}
                    connectorUserId={connectorUserId}
                    connectorEndDate={connectorEndDate}
                    historyBackOnSave={false}
                    handleSaveAndCreateNew={handleSaveAndCreateNew}
                    handleSave={handleSave}
                    handleUserLeaveContinue={handleUserLeaveContinue}
                    handleClose={handleClose}
                    handleUserLeaveSave={handleUserLeaveSave}
                    handleAddNew={handleAddNew}
                    draggableSelectFieldId={draggableSelectFieldId}
                    draggableSelectFieldValue={draggableSelectFieldValue}
                />
            )}
        </Popup>
    );
};

export default QuickDetailviewAdd;
