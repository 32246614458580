import React, { memo, useState, useRef, useCallback } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TextBox } from "devextreme-react";
import toast from "react-hot-toast";
import Loading from "../../loading/Loading";
import { Spinner } from "react-bootstrap";
import { confirm } from 'devextreme/ui/dialog';

const strEndsWith = str => ending => (str || "").toLowerCase().endsWith(ending);

const getFileType = (str) => {
    const endsWith = strEndsWith(str);
    switch (true) {
        case endsWith('.jpg'):
        case endsWith('.jpeg'):
        case endsWith('.png'):
        case endsWith('.gif'):
        case endsWith('.bmp'):
        case endsWith('.tiff'):
            return 'image';
        case endsWith('.xls'):
        case endsWith('.csv'):
            return 'excel';
        case endsWith('.pdf'):
            return 'pdf';
        case endsWith('.ppt'):
            return 'powerpoint';
        case endsWith('.doc'):
        case endsWith('.docx'):
        case endsWith('.odt'):
            return 'word';
        case endsWith('.html'):
        case endsWith('.htm'):
            return 'code';
        case endsWith('.msg'):
            return 'lines';
        default:
            return null;
    }
}

const PopupFile = ({
    tableId,
    label,
    value,
    mandatory,
    readonly,
    columnId,
    openExternalInsteadOfDownload = false,
    onControlUpdate,
    httpFilePath,
    ftp,
    memoField,
    tableItemId,
    overviewId,
    fileViewUrl,
    setFileViewUrl,
    downloadFile,
    refreshGrid,
    inlineEditMode,
}) => {
    const dragCount = useRef(0); // we need this because every element will trigger a leave
    const fileUploadRef = useRef();
    const [fileUploading, setFileUploading] = useState(false);
    const [fileDownloading, setFileDownloadLoading] = useState(false);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [openPreviewImage, setOpenPreviewImage] = useState(false);
    const [dropzoneActive, setDropzoneActive] = useState(false);
    const [FILETYPE, SETFILETYPE] = useState();

    const fileType = getFileType(value);
    const urlUpload = ftp ? `${appSettings.api.endpoint}/api/ftp/upload/${columnId}` : `${appSettings.api.endpoint}/api/file/upload`;

    const uploadFile = useCallback(async (file) => {
        if (!file) {
            return;
        }
        setFileUploading(true);
        const formData = new FormData();
        //formData.append(file.type, file);
        formData.append('file', file);
        let data;
        try {
            const res = await msalFetch(null,
                urlUpload,
                {
                    method: "POST",
                    body: formData,
                }
            );
            data = await res.json();
            if (data && data.fileName) {
                toast.success("Bestand is succesvol opgeslagen");
            } else {
                toast.success("Oeps er ging even iets mis...");
            }
            setFileUploading(false);
            return data;
        } catch (error) {
            console.error(error);
            setFileUploading(false);
            throw error;
        }
    }, [urlUpload]);

    const handleUploadButtonClick = useCallback((e) => {
        e.stopPropagation();
        fileUploadRef.current.click();
    }, []);

    const openPreview = useCallback(() => {
        setOpenPreviewImage(true);
    }, []);

    const handleDragEnter = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCount.current++;
        setDropzoneActive(true);
    }, []);

    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setDropzoneActive(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCount.current--;
        if (dragCount.current > 0) {
            return;
        }
        setDropzoneActive(false);
    }, []);

    const UploadFileNew = useCallback(async (file) => {
        const formData = new FormData();
        formData.append(file.type, file);
        try {
            const res = await msalFetch(null,
                urlUpload,
                {
                    method: "POST",
                    body: formData,
                }
            );
            const json = await res.json();
            if (json && json.fileName) {
                toast.success("Bestand is succesvol opgeslagen");
            } else {
                toast.success("Oeps er ging even iets mis...");
            }
            return json.fileName;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }, [urlUpload])

    const UpdateValue = useCallback(async (fileName) => {
        const params = { Id: columnId, Value: fileName }
        const res = await msalFetch(null,
            `${appSettings.api.endpoint}/api/table/update/${tableId}/${tableItemId}/${overviewId}`,
            {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(params),
            }
        );
        /* const json =  */await res.json();
        if (!res.ok)
            throw "Error deleting file";
    }, [columnId, overviewId, tableId, tableItemId]);

    const DownloadFileNew = useCallback(async (fileName) => {

        try {
            const res = await msalFetch(null,

                `${appSettings.api.endpoint}/api/thumbnail/${columnId}/${tableItemId}/2`
            );
            const json = await res.json();
            //setFileViewUrl(`data:image/png;base64, ${json.base64}`);
            (json.base64.startsWith('data') ? setFileViewUrl(`${json.base64}`) : setFileViewUrl(`data:image/png;base64, ${json.base64}`));

        } catch (error) {
            throw error;
        }
    }, [columnId, tableItemId, setFileViewUrl]);

    const handleFileUpload = useCallback(async (file) => {
        await uploadFile(file);
        setFileViewUrl(null);
        await UpdateValue(file.name);
        await DownloadFileNew(file.name);

    }, [UpdateValue, DownloadFileNew, uploadFile, setFileViewUrl]);

    const handleFileUploadInput = useCallback(async (e) =>
        handleFileUpload(e.target.files[0]), [handleFileUpload]);


    const handleDeleteClick = useCallback(() => {
        try {
            UpdateValue(null);
            setFileViewUrl(null);
            refreshGrid();
            toast.success("Het bestand is verwijderd");
        } catch (error) {
            toast.error(error);
        }

    }, [UpdateValue, setFileViewUrl, refreshGrid]);

    const HANDLEDROPNEW = useCallback(async (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragCount.current = 0;
        const files = [...e.dataTransfer.files];
        const file = files[0];
        const fileName = await UploadFileNew(file);
        SETFILETYPE(getFileType(fileName));
        await UpdateValue(fileName);
        await DownloadFileNew(fileName);
    }, [UploadFileNew, DownloadFileNew, UpdateValue]);



    return (
        <Form.Group>
            <Row>
                <Col md="12">
                    <section
                        className={`bl-file-box ${dropzoneActive ? "dropzone-active" : ""} ${(mandatory && !value) ? "bl-mandatory-field" : ""}`}
                        onDrop={HANDLEDROPNEW}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                    >
                        <div className="">
                            {previewLoading ? (
                                <Loading />
                            ) : (

                                <div
                                    //       className="preview-container"
                                    onClick={openPreview}
                                >

                                    {/*FILETYPE === "image" && imageUrl*/ true ? (
                                        <div className="">
                                            {fileViewUrl && <img src={fileViewUrl} alt={value} className="imagePreview" />}
                                        </div>
                                    ) : value ? (
                                        <span className="">
                                            <i
                                                className={`fa fa-file${FILETYPE ? `-${FILETYPE}` : ""}`}
                                            />
                                        </span>
                                    ) : (
                                        <div
                                            onClick={handleUploadButtonClick}
                                            className=""
                                        >
                                            <i className="fa fa-upload" />
                                            <p>Voeg een bestand toe via de knop of door in dit vak te slepen</p>
                                        </div>
                                    )}
                                </div>
                            )}

                        </div>

                        <Form.Group className="bl-file-field">
                            <TextBox
                                value={value}
                                readOnly={true}
                                className="bl-readonly-field bl-file-label-text"
                            //onInitialized={onInitialized}
                            />

                            <div
                                className={`bl-upload-btn bl-btn  ${!inlineEditMode ? " bl-disabled-btn" : ""}`}
                                onClick={handleUploadButtonClick}
                            >
                                {fileUploading ? (
                                    <Spinner
                                        className="bl-spinner"
                                        animation="border"
                                        role="status"
                                        size="sm"
                                    />
                                ) : (
                                    <i className="fas fa-upload" />
                                )}
                            </div>


                            {!openExternalInsteadOfDownload && (
                                <div
                                    className={`bl-btn bl-download-file ${false ? "bl-disabled-btn" : ""}`}
                                    onClick={downloadFile}
                                >
                                    {fileDownloading ? (
                                        <Spinner
                                            className="bl-spinner"
                                            animation="border"
                                            role="status"
                                            size="sm"
                                        />
                                    ) : (
                                        <i className="fas fa-download" />
                                    )}
                                </div>
                            )}

                            <div
                                className={`bl-btn bl-delete-file ${!inlineEditMode ? "bl-disabled-btn" : ""}`}
                                onClick={async () => {
                                    if (ftp) {
                                        let result = await confirm("Weet u zeker dat u dit item wilt verwijderen? <br /><br /><em>Let op:</em> het bestand wordt <em>direct</em> van de FTP-server verwijderd!", "Item verwijderen?");
                                        if (result)
                                            handleDeleteClick();
                                    } else {
                                        handleDeleteClick();
                                    }
                                }}
                            >
                                <i className="fas fa-trash" />
                            </div>

                            <Form.File
                                ref={fileUploadRef}
                                onChange={handleFileUploadInput}
                            />
                        </Form.Group>
                    </section>
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(PopupFile);
