import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";

import Form from "react-bootstrap/Form";

import { Col, Row } from "react-bootstrap";

import { useHistory } from "react-router";

import ModalControls from "../parts/modalControls/ModalControls";
import { useModalContext } from "../../../context/modalContext";

const ADDTABLE = () => {
    const msal = useMsal();
  const { setIsEditing } = useModalContext();
  const [formValue, setFormValue] = useState({
    tableName: "",
    readonly: false,
    enableLogging: false,
  });

  const [valid, setIsValid] = useState(false);

  const link = useHistory();

  const { tableName, readonly, enableLogging } = formValue;

  const postTable = async () => {
    const res = await msalFetch(msal, 
      `${appSettings.api.endpoint}/api/configurator/dataset/`,
      {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({
          tableName: tableName,
          readonly: readonly,
          enableLogging: enableLogging,
        }),
      }
    );
    const data = await res.json();

    link.push(`/configurator/dataset/${data.id}`);

    if (res) {
      setIsEditing(false);
    }
  };

  const changeHandler = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });

    if (e.target.value.trim() !== "") {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const checkBoxChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.checked });
  };

  const handleSaveClick = (e) => {
    // e.preventDefault();

    postTable();
  };

  return (
    <div>
      <section className="bl-modal-inner">
        {/* Naam van table */}
        <ModalControls
          handleSaveClick={handleSaveClick}
          modalTitle={"Tabel"}
          changeInForm={valid}
        />
        <Form className="bl-card">
          <Form.Group>
            <Row>
              <Col md="4">
                <Form.Label>Naam</Form.Label>
              </Col>
              <Col md="8">
                <Form.Control
                  type="input"
                  name="tableName"
                  className={valid ? "" : `bl-mandatory-field`}
                  placeholder="naam"
                  checked={tableName}
                  onChange={changeHandler}
                />
              </Col>
            </Row>
          </Form.Group>

          {/* Readonly bepalen */}
          <Form.Group>
            <Row>
              <Col md="4">
                <Form.Label>Alleen Lezen</Form.Label>
              </Col>
              <Col md="8">
                <Form.Check
                  name="readonly"
                  type="checkbox"
                  checked={readonly}
                  onChange={checkBoxChange}
                />
              </Col>
            </Row>
          </Form.Group>

          {/* enable Logging */}
          <Form.Group>
            <Row>
              <Col md="4">
                <Form.Label>Alle mutaties loggen?</Form.Label>
              </Col>
              <Col md="8">
                <Form.Check
                  name="enableLogging"
                  type="checkbox"
                  value={enableLogging}
                  onChange={checkBoxChange}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </section>
    </div>
  );
};

export default ADDTABLE;
