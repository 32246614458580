import React, { useState, useContext, useEffect, useCallback } from "react";
import appSettings from "../../appsettings";
import msalFetch, { graphMeFetch } from "../../api/MsalFetch";
//import AnonymousDashboard from "./pages/dashboard/AnonymousDashboard";

export const GraphContext = React.createContext();

export const GraphProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [graphUser, setGraphUser] = useState(null);
    const [profilePicBase64, setProfilePicBase64] = useState("");
    const [profilePicUrl, setProfilePicUrl] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const [userError, setUserError] = useState(null);

    const [nrOfUnreadNewsMessages, setNrOfUnreadNewsMessages] = useState(0);
    const [nrOfUnprocessedNotifications, setNrOfUnprocessedNotifications] = useState(0);
    const [lastHighPriorityNewsItem, setLastHighPriorityNewsItem] = useState();

    const updateUserData = useCallback(async (userData, profilePic) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/user/update`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({ ...userData, profilePic: profilePic })
                }
            );
        } catch (error) {
            throw error;
        }
    }, [])

    const getUser = useCallback(async () => {

        try {
            const response = await msalFetch(undefined, `${appSettings.api.endpoint}/api/getcurrentuser`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[context] getUser response was not ok: ${data.message}`);
            }
            setCurrentUser(data);
            setUserLoading(false);
            return data;
        } catch (error) {
            setUserLoading(false);
            setUserError(error);
            throw error;
        }
    }, []);

    useEffect(() => {
        getUser();
    }, [getUser]);

    if (userError) {
        console.error(userError);
        throw new Error(`Failed to load currentUser: ${userError}`);
    }

    const fetchNrOfNewsItems = useCallback(async () => {
        const response = await msalFetch(undefined, `${appSettings.api.endpoint}/api/news/getnrofunreadnewsitems`);
        const data = await response.json();
        setNrOfUnreadNewsMessages(data);
    }, []);
    useEffect(() => {
        fetchNrOfNewsItems();
    }, [fetchNrOfNewsItems]);

    const fetchNrOfUnprocessedNotifications = useCallback(async () => {
        const response = await msalFetch(undefined, `${appSettings.api.endpoint}/api/notifications/any`);
        const data = await response.json();
        setNrOfUnprocessedNotifications(data.total);
    }, []);
    useEffect(() => {
        fetchNrOfUnprocessedNotifications();
    }, [fetchNrOfUnprocessedNotifications]);

    const fetchLastHighPriorityNewsItem = useCallback(async () => {
        const response = await msalFetch(undefined, `${appSettings.api.endpoint}/api/news/newsitems?unread=True&prio=True`);
        const data = await response.json();
        setLastHighPriorityNewsItem(data.length === 0 ? null : data[0]);
    }, []);
    useEffect(() => {
        fetchLastHighPriorityNewsItem();
    }, [fetchLastHighPriorityNewsItem]);

    const getProfilePic = useCallback(async () => {
        const response = await graphMeFetch('/photo/$value');
        if (response.ok) {
            const blob = await response.blob();
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                var base64data = reader.result;
                setProfilePicBase64(base64data);
                return;
            }
            const profilePic = URL.createObjectURL(blob);
            setProfilePicUrl(profilePic);
        } else {
            setProfilePicBase64(null)
        }
    }, []);
    useEffect(() => {
        getProfilePic();
    }, [getProfilePic]);

    const getGraphUser = useCallback(async () => {
        const response = await graphMeFetch('');
        if (response.ok) {
            const data = await response.json();
            setGraphUser(data);
        }
    }, []);

    useEffect(() => {
        if (graphUser != null && profilePicBase64 !== "") {
            updateUserData(graphUser, profilePicBase64);
        }
    }, [graphUser, profilePicBase64, updateUserData])

    useEffect(() => {
        getGraphUser();
    }, [getGraphUser]);

    return (
        <GraphContext.Provider
            value={{
                userLoading,
                currentUser,
                profilePic: profilePicUrl,
                //heartbeat,
                nrOfUnreadNewsMessages,
                setNrOfUnreadNewsMessages,
                nrOfUnprocessedNotifications,
                setNrOfUnprocessedNotifications,
                lastHighPriorityNewsItem,
                setLastHighPriorityNewsItem,
                fullname: `${graphUser?.givenName || ''} ${graphUser?.surname || ''}`.trim(),
            }}
        >
            {children}
        </GraphContext.Provider>
    );
};

export const useGraphContext = () => {
    return useContext(GraphContext);
};
