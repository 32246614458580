import React from "react";

const LocalFormInValidNotification = ({
    onClose,
    emptyMandatoryColumns,
    nonMatchingRegexColumns,
}) => {
    console.warn('LocalFormInValidNotification because there are mandatory fields open: ', emptyMandatoryColumns.map(({ alias }) => alias).join(', '));
    console.warn('LocalFormInValidNotification because there are regex fields open: ', nonMatchingRegexColumns.map(({ alias }) => alias).join(', '));

    return (
        <div className={`bl-notify-modal`}>
            <div className="bl-notify-modal-inner">
                <header className="bl-notify-modal-header">
                    <h3>Let op!</h3>
                    <div
                        className="header-action"
                        onClick={onClose}
                    >
                        <i className="fas fa-times" />
                    </div>
                </header>
                <article>
                    <p>Niet alle velden zijn correct ingevuld:</p>
                    <strong>{`${emptyMandatoryColumns.map(({ alias }) => alias).join(', ')}`}</strong>
                    <strong>{`${nonMatchingRegexColumns.map(({ alias }) => alias).join(', ') }`}</strong>
                    <footer className="bl-notify-controls">
                        <div
                            className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
                            onClick={onClose}
                        >
                            OK
                        </div>
                    </footer>
                </article>
            </div>
        </div>
    );
};

export default LocalFormInValidNotification;
