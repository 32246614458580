import { useState } from "react";

import { DataGrid } from "devextreme-react";
import { Lookup, DropDownOptions } from "devextreme-react/lookup";
import { RowDragging, Column, Scrolling } from "devextreme-react/data-grid";

const FieldSet = ({
  columns,
  formValue,
  setFormValue,
  setColumns,
  setSetColumns,
  setgridRef,
  checkChanges,
  checkMandatoryField,
  handleReorder,

  addButton,
  isCreate,
}) => {
  const [setKolommen, setSetKolommen] = useState(
    isCreate && setColumns.length > 0 ? setColumns : !isCreate ? setColumns : []
  );

  const deleteColumn = (column) => {
    let tempForm = formValue;

    let target = column.key.sort;
    let tempColumns = formValue.columns.filter((t) => t.sort !== target);

    tempColumns.map((item, index) => (item.sort = index));

    tempForm["columns"] = tempColumns;

    setFormValue(tempForm);

    setSetKolommen(tempForm.columns);
    checkChanges();
  };

  const deleteButton = (column) => {
    return (
      <div className="bl-column-controls">
        <i
          className="fas fa-trash bl-column-control-btn bl-column-delete-btn"
          onClick={() => deleteColumn(column)}
        />
      </div>
    );
  };

    const addField = () => {
    let tempForm = formValue;
    tempForm.columns.push({
      id: tempForm.columns.length + 1,
        sort: tempForm.columns.length + 1,
        controlPropertyId : formValue.id
    });
    setFormValue(tempForm);
    setSetKolommen(tempForm.columns);
    setSetColumns(tempForm.columns);
    console.log(tempForm);

    setgridRef.current.instance().refresh(false);
    checkChanges();
  };

  const addFieldButton = () => {
    return (
      <div
        className="bl-btn bl-add-btn bl-config-header-btn"
        onClick={addField}
      >
        <i className="fas fa-plus" />
      </div>
    );
  };

  const columnRender = (column) => {
    return (
      <div className="bl-select-wrapper">
        <Lookup
          placeholder={"Selecteer..."}
          items={columns}
          className={column.key.columnId ? "" : "bl-mandatory-field"}
          valueExpr="id"
          displayExpr="alias"
          defaultValue={column.key.columnId}
        onValueChanged={(e) => {
            let tempForm = formValue;
            let tempColumn = tempForm.columns.find((t) => t.sort === column.key.sort);
            tempColumn["columnId"] = e.value;
            setFormValue(tempForm);
            checkChanges();
            e.element.classList.remove("bl-mandatory-field");
          }}
        >
          <DropDownOptions showTitle={false} closeOnOutsideClick={true} />
        </Lookup>
      </div>
    );
  }

  return (
    <div>
      <header className="bl-config-section-header">
        <h3>Kolommen</h3>
      </header>

      <DataGrid
        dataSource={setKolommen}
        className={!isCreate && "edit-set-column-grid"}
        showColumnLines={false}
        rowAlternationEnabled={true}
        showRowLines={true}
        ref={setgridRef}
      >
        <Scrolling mode="virtual" />
        <RowDragging allowReordering={true} onReorder={handleReorder} />
        <Column
          caption="Naam"
          allowSorting={false}
          alignment="left"
          dataField="columnId"
          cellRender={columnRender}
        ></Column>
        <Column
          dataField="sort"
          sortOrder="asc"
          width={1}
          className="hidden-column"
        />
        <Column
          alignment="center"
          width={100}
          headerCellRender={addFieldButton}
          cellRender={deleteButton}
        />
      </DataGrid>
    </div>
  );
};

export default FieldSet;
