import React from "react";

import { useModalContext } from "../../context/modalContext";

import EDITMENUCONFIG from "./editMenuConfig/EDITMENUCONFIG";
import NEWMENUCONFIG from "./newMenuConfig/NEWMENUCONFIG";
import "./modals.scss";
import ADDTABLE from "./addTable/ADDTABLE";
//import { EditFtpConfiguration } from "./configurator/FtpConfiguration"
import LOGGING from "./logging/LOGGING";
import EditVeld from "./editVeld/EditVeld";
import EditPage from "./configurator/EditPage";
import EditList from "./configurator/EditList";
import AddNewMenuItem from "./configurator/AddMenuItem";
import AddSearch from "./configurator/AddSearch";
import AddRole from "./configurator/AddRole";
import EditUser from "./configurator/EditUser";
import AddUser from "./configurator/AddUser";
import DuplicateRole from "./configurator/DuplicateRole";
import EditFtpConfiguration from "./configurator/EditFtpConfiguration";

const Modal = () => {
  const { isEditing, modal } = useModalContext();

  let testModalTitle = "";

    if (modal === "EDITOVERVIEW" || modal === "EDITDETAILVIEW") {
        testModalTitle = "Bewerken";
    } else if (
        modal === "EDITMENUCONFIG" ||
        modal === "EDITVELD" ||
        modal === "EDITLIST" ||
        modal === "EDITUSER"
    ) {
        testModalTitle = "Wijzigen";
    } else if (modal === "NEWTMENUCONFIG") {
        testModalTitle = "Toevoegen";
    } else if (
        modal === "ADDTABLE" ||
        modal === "ADDOVERVIEW" ||
        modal === "QUICKADD" ||
        modal === "ADDNEWMENUITEM" ||
        modal === "ADDSEARCH" ||
        modal === "ADDROLE" ||
        modal === "ADDUSER"
    ) {
        testModalTitle = "Toevoegen";
    } else if (modal === "LOGGING") {
        testModalTitle = "Logboek";
    } else if (modal === "COPY") {
        testModalTitle = "Kopie bewerken";
    } else if (modal === "SHOWINPUTLIST") {
        testModalTitle = "Zoeken";
    } else if (modal === "DUPLICATEROLE") {
        testModalTitle = "Dupliceren";
    }

  if(modal === "ADDOVERVIEW") {
    throw new Error('ADDOVERVIEW is removed from the codebase.');
  }
  if(modal === "QUICKADD") {
    throw new Error('QUICKADD is removed from the codebase.');
  }
  if(modal === "COPY") {
    throw new Error('COPY is removed from the codebase.');
  }
  if(modal === "EDITOVERVIEW") {
    throw new Error('EDITOVERVIEW is removed from the codebase.');
  }
  if(modal === "EDITDETAILVIEW") {
    throw new Error('EDITDETAILVIEW is removed from the codebase.');
  }

  return (
    <main className={`bl-modal ${isEditing ? "show-modal" : "hide-modal"}`}>
      <article className="bl-modal-content">
        <header className="bl-modal-header">
          <h3>{testModalTitle}</h3>
          {/* <div className="bl-exit-modal" onClick={() => setIsEditing(false)}>
            <i className="fas fa-times" />
          </div> */}
        </header>
        {modal === "EDITMENUCONFIG" && <EDITMENUCONFIG />}
        {modal === "NEWMENUCONFIG" && <NEWMENUCONFIG />}
        {modal === "ADDTABLE" && <ADDTABLE />}
        {modal === "LOGGING" && <LOGGING />}
        {modal === "EDITVELD" && <EditVeld />}
        {modal === "EDITPAGE" && <EditPage />}
        {modal === "EDITLIST" && <EditList />}
        {modal === "ADDNEWMENUITEM" && <AddNewMenuItem />}
        {modal === "ADDSEARCH" && <AddSearch />}
        {modal === "ADDROLE" && <AddRole />}
        {modal === "DUPLICATEROLE" && <DuplicateRole />}
        {modal === "EDITUSER" && <EditUser />}
        {modal === "ADDUSER" && <AddUser />}
        {modal === "EDITFTPCONFIGURATION" && <EditFtpConfiguration />}
      </article>
    </main>
  );
};

export default Modal;
