import { Popup } from 'devextreme-react/popup';
import HtmlEditor from "devextreme-react/html-editor";
import React, { useState } from "react";
import "../news/News.scss";
import appSettings from "../../appsettings";
import { useMsal } from "@azure/msal-react";
import msalFetch from "../../api/MsalFetch.js";
import { useGraphContext } from '../graph/context';


const NewsitemPopup = ({
    newsItemId,
    isHighPriorityItem,
    selectedTitle,
    setShowNewsItem,
    selectedText,
    showCloseButton,
    refreshNewsItems,
    itemIsRead,
}) => {
    const msal = useMsal();
    const {
        setNrOfUnreadNewsMessages,
        nrOfUnreadNewsMessages,
        setLastHighPriorityNewsItem,
    } = useGraphContext();

    const [fullScreen, setFullScreen] = useState(false);
    const fullScreenIcon = (!fullScreen ? 'fas fa-expand-arrows-alt' : 'fas fa-window-minimize');
    const markAsRead = async (id) => {
        const link = `${appSettings.api.endpoint}/api/news/markasread/${id}`;
        const response = await msalFetch(msal, link);
        setNrOfUnreadNewsMessages(nrOfUnreadNewsMessages - 1);
        if (refreshNewsItems) {
            refreshNewsItems();
        }
    }

    return <Popup
        className="bl-popup"
        showTitle={true}
        title={selectedTitle}
        resizeEnabled={true}
        dragEnabled={true}
        animation={false}
        visible={true}
        showCloseButton={showCloseButton}
        onHiding={(e) => { setShowNewsItem(false); e.cancel = true; }}
        fullScreen={fullScreen}
        toolbarItems={
            [
                {
                    toolbar: "top",
                    widget: "dxButton",
                    location: "after",
                    // className: "Button",
                    options:
                    {
                        icon: fullScreenIcon,
                        text: 'Volledig scherm',
                        stylingMode: 'text',
                        type: 'default',
                        hint: 'Volledig scherm',
                        onClick: (e) => {

                            setFullScreen(!fullScreen);
                        }
                    }
                },
                {
                    toolbar: "bottom",
                    widget: "dxButton",
                    location: "center",

                    options:
                    {
                        icon: 'check',
                        text: 'Als gelezen markeren',
                        onClick: (e) => {
                            e.cancel = true;
                            if (!itemIsRead) {
                                markAsRead(newsItemId);
                            }
                            if (isHighPriorityItem) {
                                setLastHighPriorityNewsItem(null);
                            } else {
                                setShowNewsItem(false);
                            }
                        }
                    }
                }

            ]
        }
    >
        <HtmlEditor
            className="bl-html-editor bl-html-news"
            defaultValue={selectedText}
            valueType="html"
            // height="100%"
            readOnly={true}
        >
        </HtmlEditor>

    </Popup>
}

export default NewsitemPopup;
