import msalFetch from "../../../api/MsalFetch.js";
import { DataGrid } from "devextreme-react";
import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
    useRef
} from "react";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import appSettings from "../../../appsettings.js";
import EditUser from "../../modals/configurator/EditUser.js"
import { useModalContext } from "../../../context/modalContext";
import { Button as ToolbarButton } from 'devextreme-react/button';
import toast from "react-hot-toast";
import { confirm } from 'devextreme/ui/dialog';
import {
    Column,
    FilterRow,
    HeaderFilter,
    FilterPanel,

    Lookup,
    Scrolling,
    Editing,
    Paging,
    LoadPanel,
    Button as DataGridButton,
    Toolbar,
    Item,
    Pager
} from "devextreme-react/data-grid";

const RoleUser = ({ refresh, roleId }) => {
    const [roles, setRoles] = useState(null);
    const userGridRef = useRef();
    const {
        isEditing,
        setIsEditing,
        setModal,
        setModalContent,
    } = useModalContext();

    const handleAddClick = useCallback((e) => {
        setModalContent(null);
        setIsEditing(true);
        setModal("ADDUSER");
    }, [setIsEditing, setModal, setModalContent]);




    const loadUsers = useCallback(async (active) => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/user`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Users] getUsers response was not ok: ${data.message}`);
            }
            if (roleId) {
                return data.filter(rec => rec.role === roleId);
            }
            else
                return data;
        } catch (error) {
            throw error;
        }
    }, [roleId]);

    const updateServerRecord = useCallback(async (userId, val) => {
        console.log("RoleUser - updateServerRecord - userId - val", userId, val);
        try {
            let body = {
                userId: userId,
                value: val[Object.keys(val)[0]]
            };
            const url = `${appSettings.api.endpoint}/api/configurator/user/updatefield/${body.userId}/${Object.keys(val)[0]}/${body.value}`;
            console.log("RoleUser - updateServerRecord - url", url);
            const res = await msalFetch(null, url

                // {
                //     method: "POST",
                //     headers: { "Content-type": "application/json" },
                //     body: JSON.stringify(body),
                // }
            );
            if (Object.keys(val)[0] === 'role') {
                console.log("updateServerRecord - refresh");
                refresh();
            } else {
                console.log("updateServerRecord", Object.keys(val)[0]);
            }
            toast.success("Gebruiker is bijgewerkt");
        } catch (error) {
            //toast.error("error");
            throw error;
        }
    }, [refresh])

    const dataSourceUsers = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: loadUsers,
            update: updateServerRecord,
            //remove: deleteServerRecord,
        })
    }), [loadUsers, updateServerRecord]);

    const loadRoles = useCallback(async () => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/role`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Users] getRoles response was not ok: ${data.message}`);
            }
            setRoles(data);
            return data;
        } catch (error) {
            throw error;
        }
    }, []);

    const refreshUsers = useCallback(() => {
        userGridRef.current.instance().refresh();
    }, []);



    useEffect(() => { loadRoles(); }, [loadRoles])

    return (roles && <div>

        <DataGrid
            dataSource={dataSourceUsers}
            allowColumnReordering={true}
            allowColumnResizing={true}
            noDataText="Selecteer een rol"
            rowAlternationEnabled={true}
            showColumnLines={false}
            showRowLines={true}
            defaultFilterValue={['active', '=', true]}
            columnAutoWidth={false}
            ref={userGridRef}
            height={"70vh"}
        >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <FilterPanel visible={true} />

            <Paging defaultPageSize={120} />
            <Toolbar>
                <Item location="after">
                    <ToolbarButton
                        icon="fas fa-plus"
                        onClick={handleAddClick}
                    />
                </Item>
            </Toolbar>
            <FilterRow visible={true} showAllText="Alles" />
            <Scrolling
                mode="virtual"
                preloadEnable="false"
                showScrollbar="always"
            />
            <Paging defaultPageSize={25} />
            <Pager
                visible={true}
                showPageSizeSelector={true}
                showInfo={true}
                allowedPageSizes={[25, "all"]}
                showNavigationButtons={true}
            />
            <Editing
                mode="cell"
                allowUpdating={true}
                allowAdding={false}
                allowDeleting={false}
            />
            <LoadPanel enabled={true} />
            <Column
                cssClass="config-readonly-field"
                caption="Voornaam"
                dataField="firstname"
                dataType="string"
                sortIndex={1}
                sortOrder="asc"
                allowEditing={false}
            />
            <Column
                cssClass="config-readonly-field"
                caption="Achternaam"
                dataField="name"
                dataType="string"
                sortIndex={2}
                sortOrder="asc"
                minWidth={120}
                allowEditing={false}
            />
            <Column
                caption="Email"
                dataField="email"
                dataType="string"
                minWidth={200}

            />
            <Column
                caption="Admin"
                dataField="admin"
                alignment="center"
                dataType="boolean"

            />
            <Column
                caption="Manuals"
                dataField="documentarian"
                alignment="center"
                dataType="boolean"

            />
            <Column
                caption="Roltester"
                dataField="overruleAdmin"
                alignment="center"
            ></Column>
            <Column
                caption="Actief"
                dataField="active"
                alignment="center"
                sortIndex={0}
                sortOrder="desc"
                dataType="boolean"
            >
            </Column>
            <Column
                alignment="left"
                caption="Rol"
                dataField="role"
                minWidth={70}
            >
                <Lookup
                    dataSource={roles}
                    valueExpr="id"
                    displayExpr="name"
                />
            </Column>
            <Column cssClass="config-readonly-field"
                caption="Laatste login"
                dataField="lastLogin"
                dataType="datetime"
                format="dd-MM-yyyy HH:mm"
                alignment="right"
                allowEditing={false}
            />
            {/* <Column type="buttons" width="auto" caption="Acties">
                <DataGridButton hint="Verwijder dit record" icon="trash" cssClass="trashButton" onClick={handleDelete} />
            </Column> */}
        </DataGrid></div>)
}
export default RoleUser