import { CheckBox, DataGrid } from "devextreme-react";
import {
    Column,
    Lookup,
    FilterRow,
    Paging,
    Pager,
    HeaderFilter,
    LoadPanel,
    ColumnFixing,
    Scrolling,
Editing} from "devextreme-react/data-grid";
import React, { useState, useEffect, useRef } from "react";

import Loading from "../../../loading/Loading";

const TableGrid = ({ checkChanges, form, setForm, tables }) => {
  const [loading, setLoading] = useState(true);
  const dataGridRef = useRef();

  const inlineCheckBox = (data) => {
    let value = data.value;

    if (value === 1) {
      value = true;
    } else {
      value = false;
    }

    let readOnlyCheck;
    if (data.data.readonly === 1 && data.column.dataField !== "read") {
      readOnlyCheck = true;
    } else {
      readOnlyCheck = false;
    }
    return (
      <CheckBox
        defaultValue={value}
        readOnly={readOnlyCheck}
        onValueChanged={(e) => {
          let binair;
          if (e.value) {
            binair = 1;
          } else {
            binair = 0;
          }
          let tempForm = form;
          tempForm.roleTable.find((t) => t.tableId === data.data.tableId)[
            data.column.dataField
          ] = binair;
          checkChanges();
          setForm(tempForm);
        }}
      />
    );
  };

  const checkAllChange = (value, target) => {
    let binair;
    if (value) {
      binair = 1;
    } else {
      binair = 0;
    }
    let tempForm = form;
    let tempRoleTable = [];
    tempForm.roleTable.map((item) => {
      let tempItem = item;
      if (item.readonly && target !== "read") {
        return;
      }
      tempItem[target] = binair;
      return (tempRoleTable = [...tempRoleTable, tempItem]);
    });

    tempForm["roleTable"] = tempRoleTable;
    setForm(tempForm);

    dataGridRef.current.instance().refresh();
    checkChanges();
  };
  const readCellHeader = () => {
    return (
      <div className="bl-grid-select-all">
        <span>
          <CheckBox
            defaultValue={false}
            onValueChanged={(e) => {
              checkAllChange(e.value, "read");
            }}
          />
          <p>Alles selecteren</p>
        </span>
      </div>
    );
  };

  const createCellHeader = () => {
    return (
      <div className="bl-grid-select-all">
        <span>
          <CheckBox
            defaultValue={false}
            onValueChanged={(e) => {
              checkAllChange(e.value, "create");
            }}
          />
          <p>Alles selecteren</p>
        </span>
      </div>
    );
  };

  const updateCellHeader = () => {
    return (
      <div className="bl-grid-select-all">
        <span>
          <CheckBox
            defaultValue={false}
            onValueChanged={(e) => {
              checkAllChange(e.value, "update");
            }}
          />
          <p>Alles selecteren</p>
        </span>
      </div>
    );
  };

  const deleteCellHeader = () => {
    return (
      <div className="bl-grid-select-all">
        <span>
          <CheckBox
            defaultValue={false}
            onValueChanged={(e) => {
              checkAllChange(e.value, "delete");
            }}
          />
          <p>Alles selecteren</p>
        </span>
      </div>
    );
  };

  useEffect(() => {
    setLoading(true);

    let tempRoleTable = form.roleTable;
    let richRoleTable = [];
    let tempForm = form;
    console.log(tables);
    console.log(form.roleTable);

    tables.map((item) => {
      if (tempRoleTable.find((roleTable) => roleTable.tableId === item.id)) {
        let target = tempRoleTable.find(
          (roleTable) => roleTable.tableId === item.id
        );

        target["tableName"] = item.tableName;
        target["readonly"] = item.readonly;

        richRoleTable = [...richRoleTable, target];
      } else {
        richRoleTable = [
          ...richRoleTable,
          {
            tableId: item.id,
            create: 0,
            read: 0,
            delete: 0,
            tableName: item.tableName,
            readonly: item.readonly,
          },
        ];
      }
      return richRoleTable;
    });
    console.log(richRoleTable);

    tempForm["roleTable"] = richRoleTable;
    console.log(tempForm);

    setForm(tempForm);
    setLoading(false);
  }, []);

  const tableNameRender = (data) => {
    let readOnlyLabel = false;
    const targetTable = tables.filter(
      (item) => data.data.tableId === item.id
    )[0];

    if (targetTable.readonly === 1) {
      return (
        <div className="table-name">
          <p>{targetTable.tableName}</p>{" "}
          <span className="readonly-label">
            <p>Alleen lezen</p>
          </span>
        </div>
      );
    } else {
      return (
        <div className="table-name">
          <p>{targetTable.tableName}</p>
        </div>
      );
    }
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <DataGrid
        ref={dataGridRef}
        dataSource={form.roleTable.length > 0 && form.roleTable}
        columnAutoWidth={true}
        showColumnLines={true}
        rowAlternationEnabled={true}
        showRowLines={true}
        className="sorting-disabled"
        >

            <Paging defaultPageSize={50} />
            <Pager
                visible={true}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
            />
            <ColumnFixing enabled={true} />
            <LoadPanel enabled={true} />
            <HeaderFilter visible={true} allowSearch={true} />
            <Scrolling mode="standard" />
            <FilterRow visible={true} showAllText="Alles" />
            <Editing
                mode="cell"
                allowUpdating={true}
                allowAdding={false}
                allowDeleting={false}
            />
        <Column
                alignment="left"
                caption="Tabel"
                dataField="tableName"
                dataType="string"
                cellRender={tableNameRender}
                sortOrder="asc"
                allowSorting={false}
                className="bl-readonly-field"
                allowEditing={false}
        />

        <Column caption="Lezen" alignment="center" allowSorting={false}>
          <Column
            dataField="read"
            headerCellRender={readCellHeader}
            dataType="boolean"
            cellRender={inlineCheckBox}
            allowSorting={false}
          />
        </Column>

        <Column caption="Toevoegen" alignment="center" allowSorting={false}>
          <Column
            dataField="create"
            dataType="boolean"
            headerCellRender={createCellHeader}
            cellRender={inlineCheckBox}
            allowSorting={false}
          />
        </Column>

        <Column caption="Wijzigen" alignment="center" allowSorting={false}>
          <Column
            dataField="update"
            headerCellRender={updateCellHeader}
            cellRender={inlineCheckBox}
            dataType="boolean"
            allowSorting={false}
          />
        </Column>

        <Column caption="Verwijderen" alignment="center" allowSorting={false}>
          <Column
            dataField="delete"
            headerCellRender={deleteCellHeader}
            cellRender={inlineCheckBox}
            dataType="boolean"
            allowSorting={false}
          />
        </Column>
      </DataGrid>
    );
  }
};

export default TableGrid;
