//import { useMsal } from "@azure/msal-react";
import "./News.scss";
import React, { useCallback, useState, useRef, useEffect } from "react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";
import { DataGrid } from "devextreme-react";
import NewsitemPopup from "../../components/Popups/NewsItem";

import Loading from "../loading/Loading";
import {
    Column,
    Grouping,
    GroupPanel,
    Paging,
    Pager,
    Scrolling,
    FilterRow,
} from "devextreme-react/data-grid";
import "./News.scss";
import { useGraphContext } from "../graph/context";

const News = () => {

    const { setNrOfUnreadNewsMessages, nrOfUnreadNewsMessages } = useGraphContext();
    const [loading, setLoading] = useState(true);
    const [news, setNews] = useState();
    const [selectedTitle, setSelectedTitle] = useState();
    const [selectedText, setSelectedText] = useState();
    const [itemSelected, setItemSelected] = useState(false);
    const [onlyUnread, setOnlyUnread] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState();
    const [itemIsRead, setItemIsRead] = useState();
    const newsGridRef = useRef();

    const getNews = useCallback(async () => {
        // setLoading(true);
        try {
            const query = await msalFetch(null,
                `${appSettings.api.endpoint}/api/news/newsitems?unRead=${onlyUnread ? 'true' : 'null'}`
            );
            const data = await query.json();
            setNews(data);
            setLoading(false);
            return data;
        } catch (error) {
            throw error;
        }
    }, [onlyUnread]);

    useEffect(() => {
        getNews();
    }, [onlyUnread, getNews]);

    const onCellClick = (e) => {
        const isRead = e.data.isRead;
        setItemIsRead(isRead);
        if (e.column.name === 'markAsRead') {
            if (isRead) {
                markAsUnread(e.data.id);
            } else {
                markAsRead(e.data.id);
            }
        } else {
            setSelectedTitle(e.data.title);
            setSelectedText(e.data.text);
            setSelectedItemId(e.data.id);
            setItemSelected(true);
        }

    }
    const allowedPageSizes = [10, 20, 50, "all"];
    const refreshGrid = useCallback(() => {
        // Pass true to repaint updated data rows; false to repaint all data rows.
        newsGridRef.current.instance().refresh(true);
    }, []);

    const onCellPrepared = (e) => {

        if (e.rowType === 'data') {
            e.cellElement.className = 'bl-news';
            if (!e.data.isRead) {
                e.cellElement.className += ' bl-news-unread';
            }
        }
    }

    const renderRead = (data) => {
        const isRead = data.data.isRead;
        const className = `fas fa-envelope${isRead ? '-open' : ''} bl-column-control-btn`;
        return <i className={className} />;

    }

    // const renderGroupRead = (data) => {
    //     const isRead = data.data.isRead;
    //     const className = `fas fa-envelope${isRead ? '-open' : ''} bl-column-control-btn`;
    //     return <div><i className={className} />{data.data.isRead} </div>;

    // }

    const markAsRead = useCallback(async (id) => {
        const link = `${appSettings.api.endpoint}/api/news/markasread/${id}`;
        const response = await msalFetch(null, link);
        getNews();
        setNrOfUnreadNewsMessages(nrOfUnreadNewsMessages - 1);
    }, [refreshGrid, setNrOfUnreadNewsMessages, nrOfUnreadNewsMessages]);

    const markAsUnread = async (id) => {
        const link = `${appSettings.api.endpoint}/api/news/markasread/${id}?value=false`;
        const response = await msalFetch(null, link);
        //setReload(true);
        getNews();
        setNrOfUnreadNewsMessages(nrOfUnreadNewsMessages + 1);
    }

    const renderHighPrio = (data) => {

        if (data.data.priority) {
            return <i className="fas  fa-circle-exclamation" style={{ color: "red" }} />;
        }
    };


    return (
        <main className="bl-content">
            <header className=" bl-title-header">
                <h2>Nieuws</h2>
            </header>
            <div className="bl-card">
                {loading && <Loading />}
                {!loading && (
                    <DataGrid
                        dataSource={news}
                        showBorders={true}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        showColumnLines={false}
                        remoteOperations={true}
                        wordWrapEnabled={true}
                        className="bl-news"
                        ref={newsGridRef}
                        //className="no-vertical-align"
                        //onRowClick={onRowClick}
                        onCellClick={onCellClick}
                        onCellPrepared={onCellPrepared}
                    >
                        <Grouping autoExpandAll={true} contextMenuEnabled allowCollapsing={true} />
                        <GroupPanel visible={false} />
                        <FilterRow visible={true} showAllText="Alles" />
                        <Paging defaultPageSize={100} />
                        <Pager
                            visible={true}
                            allowedPageSizes={allowedPageSizes}
                            displayMode="full"
                            showPageSizeSelector={true}
                            showInfo={true}
                            showNavigationButtons={true}
                        />
                        <Scrolling mode="standard" />
                        <Column
                            width={30}
                            cellRender={renderHighPrio}
                            text="right"
                        ></Column>
                        <Column
                            dataField="title"
                            dataType="string"
                            caption="Titel"
                        />
                        {/* <Column
                            dataField="type"
                            dataType="string"
                            caption="Type"
                            width={100}
                        /> */}

                        <Column
                            dataField="publicationDate"
                            dataType="datetime"
                            caption="Publicatiedatum"
                            format="dd-MM-yyyy HH:mm"
                            width={150}
                            defaultSortOrder="desc"
                        />
                        <Column
                            dataField="isRead"
                            dataType="boolean"
                            caption="Gelezen"

                            width={80}
                            cellRender={renderRead}
                            name="markAsRead"
                        ></Column>


                    </DataGrid>
                )}
            </div>

            {itemSelected && (
                <NewsitemPopup
                    selectedTitle={selectedTitle}
                    setShowNewsItem={setItemSelected}
                    selectedText={selectedText}
                    newsItemId={selectedItemId}
                    isHighPriorityItem={false}
                    showCloseButton={true}
                    refreshNewsItems={getNews}
                    itemIsRead={itemIsRead}
                />)}

        </main>


    );
};
export default News;
