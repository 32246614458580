import React from "react";
import { Link } from "react-router-dom";

import "./fallbackComponent.scss";

const NotFound = (e) => {
    return (
        <main className="bl-error-wrapper">
            <section className="bl-card">

                <div className="content-wrapper">
                    <h3>Deze pagina lijkt niet te bestaan</h3>
                    <div className="bl-error-buttons-wrapper">

                        <Link to="/">
                            <div className="bl-btn bl-action-btn">
                                <i className="fas fa-tachometer-alt" />
                                <span className="btn-label">Ga naar je startpagina</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default NotFound;
