import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../appsettings";
import "./Detailview.scss";
import msalFetch from "../../api/MsalFetch.js";
import { useParams, useLocation } from "react-router-dom";
import Content from "./Content";
import Loading from "../loading/Loading";
import { useGlobalContext } from "../../context/context";
import { useGraphContext } from "../graph/context";
import { useHistory } from "react-router-dom";
import "../ErrorHandling/NotFound.js"
import NotFound from "../ErrorHandling/NotFound.js";

const Detailview = () => {
    const history = useHistory();
    const {
        detailviewId: detailviewIdString,
        tableitemId,
    } = useParams();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const detailviewId = Number(detailviewIdString);
    const [loading, setLoading] = useState(true);
    const [allDetailviewData, setAllDetailviewData] = useState([]);
    const [currentDetailview, setCurrentDetailview] = useState(null);
    const [invalidDetail, setInvalidDetail] = useState(false);
    const [instructions, setInstructions] = useState(null);
    const { isMobile } = useGlobalContext();
    const { currentUser } = useGraphContext();

    useEffect(() => {
        setLoading(true);
        //get the detailviewtabs
        (async () => {
            try {

                const res = await msalFetch(null, `${appSettings.api.endpoint}/api/detailview/${detailviewId}/${tableitemId ? tableitemId : ""}?onlyVisibleAsTab=true`);
                const data = await res.json();

                if (!res.ok) {
                    throw new Error(`[Detailview] get response was not ok: ${data.message}`);
                }
                //if load successfull, set detailviewHTML (contains the rows)
                if (data.detailViews.length > 0) {
                    setAllDetailviewData(data.detailViews);
                    // do this inside of the load,
                    // if we do this in the render,
                    // it will change instantly on router changes, changing detailviewId
                    // that will make the Content do all kinds of changes before
                    // going into Loading
                    const current =
                        data.detailViews.find(({ id }) => id === detailviewId)
                        || data.detailViews[0]; // this is a feature.
                    // Sometimes the backend gives data, but the detailviewId itself is not present.
                    // relationTable stuff..
                    setCurrentDetailview(current);
                } else {
                    setInvalidDetail(true);
                    return;
                }
                setInstructions(data.instructions);
                setLoading(false);
            } catch (error) {
                setInvalidDetail(true);
                setLoading(false);
                throw error;
            }
        })();
    }, [detailviewId, tableitemId]);

    const handleDelete = useCallback((e) => {
        if (window.history.length > 1) {
            window.history.back();
        }
        else {
            //to root, which means to the dashboard
            window.location.href = window.location.origin;
        }
    }, [])

    return (
        <main className="bl-content" >
            {loading && <div />}
            {!loading && !invalidDetail && (
                <Content
                    blockHistoryOnDirty
                    isDetailview
                    showTabs
                    showTitle
                    setTitleToHelmet
                    allDetailviewData={allDetailviewData}
                    currentDetailview={currentDetailview}
                    tableitemId={tableitemId}
                    currentUser={currentUser}
                    isMobile={isMobile}
                    parentControlRelationId={searchParams.get('parentControlRelationId')}
                    parentTableitemId={searchParams.get('parentTableitemId')}
                    instructions={instructions}
                    handleAddNew={() => {
                        history.push(`/detail/${detailviewId}/`);
                    }}
                    handleSaveAndCreateNew={() => {
                        setLoading(true);
                        setTimeout(() => {
                            setLoading(false);
                        }, 1);
                    }}
                    handleDelete={handleDelete}
                />
            )}

            {invalidDetail && (

                <NotFound></NotFound>

            )}
        </main>
    );
};

export default Detailview;
