import React from "react";

import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react";

const Webhook = ({ fieldValue, setFieldValue, checkChanges }) => {
  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Overzicht / pagina herladen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={
                fieldValue.action.refresh ? fieldValue.action.refresh : false
              }
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue.action["refresh"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Uri</Form.Label>
          </Col>
          <Col lg="12">
            <TextBox
              className={
                fieldValue.action.uri && fieldValue.action.uri.trim() !== ""
                  ? ""
                  : "bl-mandatory-field"
              }
              type="input"
              placeholder="Actie"
              defaultValue={fieldValue.action.uri && fieldValue.action.uri}
              valueChangeEvent="keyup"
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue.action["uri"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);

                checkChanges();
                if (e.value.trim() === "") {
                  e.element.classList.add("bl-mandatory-field");
                } else {
                  e.element.classList.remove("bl-mandatory-field");
                }
              }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};
export default Webhook;
