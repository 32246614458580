import React from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, ColorBox, SelectBox } from "devextreme-react";

const Kleur = ({ fieldValue, setFieldValue, checkChanges }) => {
  console.log(fieldValue);
  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Alleen lezen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={fieldValue.readonly ? fieldValue.readonly : false}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["readonly"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Verplicht</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={fieldValue.mandatory ? fieldValue.mandatory : false}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["mandatory"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Standaard waarde</Form.Label>
          </Col>
          <Col lg="12">
            <ColorBox
              defaultValue={fieldValue.defaultValue}
              valueChangeEvent="keyup"
              onValueChanged={(e) => {
                // if (e.value.includes("#") && e.value.length === 7) {
                //   e.element.classList.remove("bl-mandatory-field");
                // } else if (e.value === "") {
                //   e.element.classList.remove("bl-mandatory-field");
                // } else {
                //   e.element.classList.add("bl-mandatory-field");
                // }
                let tempFieldValue = fieldValue;
                tempFieldValue["defaultValue"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
            {/* <TextBox
              defaultValue={fieldValue.defaultValue}
              valueChangeEvent="keyup"
              onValueChanged={(e) => {
                if (e.value.includes("#") && e.value.length === 7) {
                  e.element.classList.remove("bl-mandatory-field");
                } else if (e.value === "") {
                  e.element.classList.remove("bl-mandatory-field");
                } else {
                  e.element.classList.add("bl-mandatory-field");
                }
                let tempFieldValue = fieldValue;
                tempFieldValue["defaultValue"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            /> */}
          </Col>
        </Row>
      </Form.Group>

      {/* <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Validatie</Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              className={
                fieldValue.columnValidation && fieldValue.columnValidation.id
                  ? ""
                  : "bl-mandatory-field"
              }
              defaultValue={
                fieldValue.columnValidation && fieldValue.columnValidation.id
              }
              items={fieldValue.columnType.columnValidations}
              valueExpr="id"
              displayExpr="name"
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["columnValidation"] =
                  fieldValue.columnType.columnValidations.filter(
                    (item) => item.id === e.value
                  )[0];
                e.element.classList.remove("bl-mandatory-field");
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group> */}
    </>
  );
};

export default Kleur;
