export const ColumnDisplayValueEuro = {
    EURO: 1
}

export const ColumnDisplayValueDate = {

}

export const ColumnDisplayValueDateTime = {

}

export const ColumnDisplayValueDec = {
    Precision1: 8,
    Precision2: 9,
    Precision3: 10,
}

export const ColumnDisplayValueCalculated = {
    NUM: 11,
    Precision1: 12,
    Precision2: 13,
    Precision3: 14,
    EUPrecison2: 15,
    EUPrecision3: 18,
}

export const ColumnDisplayValueMemo = {
    Flowchart: 19,
    Text: 20,
    Scheduler: 21
}
