import React, { memo, useCallback } from "react";
import { RadioGroup } from "devextreme-react";
import { Tooltip } from 'devextreme-react/tooltip';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FieldLabel from "../detailview/FieldLabel";

const YESNO = ({
    value,
    alias,
    mandatory,
    registerLoaded,
    onControlUpdate,
    readonly,
    columnId,
    memoField,
}) => {
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);


    const handleChange = useCallback((e) => {
        onControlUpdate(columnId, e.value);
    }, [onControlUpdate, columnId]);

    return (
        <Form.Group>
            <Row className="property-row">
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={alias}
                        columnId={columnId}
                        memoField={memoField}
                        mandatory={mandatory}

                    />
                </Col>
                <Col md="8">
                    <RadioGroup
                        value={value}

                        readOnly={readonly}
                        onValueChanged={handleChange}
                        onInitialized={onInitialized}
                        layout="horizontal"
                        valueExpr="value"
                        dataSource={[
                            {
                                "text": "Ja",
                                "value": true
                            }, {
                                "text": "Nee",
                                "value": false
                            }]
                        }
                    />
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(YESNO);
