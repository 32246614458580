import React, { useState, useEffect, useCallback } from "react";
import msalFetch from "../../../../api/MsalFetch.js";
import appSettings from "../../../../appsettings";
import { useParams, useHistory, useLocation } from "react-router-dom";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    ColumnFixing,
    LoadPanel,
    Paging,
    Pager,
    Scrolling,
} from "devextreme-react/data-grid";

import { Lookup } from "devextreme-react/data-grid";
import EditList from "../../../modals/configurator/EditList";
import DeleteItem from "../../../notifications/userLeaveNotifications/notifications/DeleteItem";
import toast from "react-hot-toast";

const allowedPageSizes = [10, 20, 50, "all"];


const ListGrid = ({ columnData }) => {
    const allColumns = columnData;
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { id: dataSetId, activeList } = useParams();
    const isCreate = activeList === '0';
    const [listDataSource, setListDataSource] = useState(null);
    const singleItem = listDataSource?.find(({ id }) => id === Number(activeList));
    const [pageLookups, setPageLookups] = useState([]);
    const [hover, setHover] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState();
    const [deleteNotification, setDeleteNotification] = useState(false);

    const setIsEditing = useCallback(() => {
        push('../../');
    }, [push]);

    const pushToSingle = useCallback((id) => {
        push(`${pathname}${pathname.endsWith('/') ? '' : '/'}list/${id}/`);
    }, [push, pathname]);

    const getListData = useCallback(async () => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/list`
            );
            const data = await response.json();
            setListDataSource(data);
            return data;
        } catch (error) {
            throw "Data Loading Error";
        }
    }, [dataSetId]);

    useEffect(() => {
        getListData();
    }, [getListData]);

    const getPageData = useCallback(async () => {
        try {
            const response = await msalFetch(undefined,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/page`
            );
            const data = await response.json();
            setPageLookups(data.map(({ name }) => name));
        } catch (error) {
            throw "Data Loading Error";
        }
    }, [dataSetId]);

    useEffect(() => {
        getPageData();
    }, [getPageData]);

    const handleAddNewClick = () => {
        pushToSingle(0);
    };

    function addNewButton() {
        return (
            <div className="bl-config-btn-wrapper">
                <div
                    className="bl-btn bl-add-btn bl-config-header-btn"
                    onClick={handleAddNewClick}
                >
                    <i className="fas fa-plus" />
                </div>
            </div>
        );
    }

    const handleDeleteFunction = async () => {
        const url = `${appSettings.api.endpoint}/api/configurator/dataset/${deleteTarget.tableId}/list/${deleteTarget.id}`;
        try {
            const res = await msalFetch(null, url, {
                method: "DELETE",
                headers: { "Content-type": "application/json" },
            });
            if (res.ok) {
                toast.success("Het item is successvol verwijderd...");
                setDeleteNotification(false);
                getListData();
            } else {
                toast.error("De lijst kan niet worden verwijderd. ");
                setDeleteNotification(false);
            }
            return;
        } catch (error) {
            setDeleteNotification(false);
        }
    };

    const handleDeleteClick = (columnData) => {
        setDeleteTarget(columnData);
        setDeleteNotification(true);
    };

    const handleEditColumn = (columnData) => {
        if (hover) {
            return;
        }
        pushToSingle(columnData.data.id);
    };

    const handleDuplicateClick = async (columnData) => {
        console.log("handleDuplicateClick", columnData);
        let duplicateTarget = {};
        duplicateTarget["name"] = `kopie: ${columnData.key.name}`;

        duplicateTarget["NewButton"] = columnData.key.NewButton
            ? columnData.key.NewButton
            : true;
        duplicateTarget["allowCopy"] = columnData.key.allowCopy;
        duplicateTarget["allowExportToExcel"] = columnData.key.allowExportToExcel;
        duplicateTarget["autoRefresh"] = columnData.key.autoRefresh;
        duplicateTarget["expandDetailviewId"] = columnData.key.expandDetailviewId;
        duplicateTarget["filterOnSignedUserColumnId"] =
            columnData.key.filterOnSignedUserColumnId;
        duplicateTarget["join"] = columnData.key.join;
        duplicateTarget["listColumns"] = columnData.key.listColumns;

        duplicateTarget["manualChangeOrderByColumnId"] =
            columnData.key.manualChangeOrderByColumnId;
        duplicateTarget["newButton"] = columnData.key.newButton;
        duplicateTarget["preferredDetailViewId"] =
            columnData.key.preferredDetailViewId;
        duplicateTarget["queryOrderBy"] = columnData.key.queryOrderBy;
        duplicateTarget["queryWhere"] = columnData.key.queryWhere;
        duplicateTarget["scrollHorizontal"] = columnData.key.scrollHorizontal;
        let sortedListColumns = [];

        duplicateTarget.listColumns.map((t) => {
            if (t.sort !== 0) {
                t["id"] = t.sort;
                sortedListColumns.push(t);
            }
        });

        duplicateTarget.listColumns = sortedListColumns;

        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/dataset/${dataSetId}/list/`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(duplicateTarget),
                }
            );

            if (res.ok) {
                const resObject = await res.json();
                toast.success("De gegevens zijn successvol opgeslagen...");
                let targetId = resObject.id;
                // refetch all the data first
                await getListData();
                pushToSingle(targetId);
            } else {
                toast.error('Oeps er ging iets mis...');
            }
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    const columnEditControl = (columnData) => {
        if (columnData.data.name.includes("bl_") || columnData.data.name === "Id") {
            return null;
        } else {
            return (
                <div className="bl-column-controls">
                    <i
                        className={`fas fa-pencil-alt bl-column-control-btn bl-column-edit-btn `}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEditColumn(columnData)
                        }}
                    />

                    <i
                        onMouseEnter={() => {
                            setHover(true);
                        }}
                        onMouseLeave={() => {
                            setHover(false);
                        }}
                        className={`fas fa-clone bl-column-control-btn bl-column-edit-btn bl-column-duplicate-btn `}
                        onClick={() => { handleDuplicateClick(columnData); }}
                    />
                    <i
                        onMouseEnter={() => {
                            setHover(true);
                        }}
                        onMouseLeave={() => {
                            setHover(false);
                        }}
                        className={`fas fa-trash bl-column-control-btn bl-column-delete-btn ${columnData.key.name === "Standaard" && "bl-disabled-btn"
                            }`}
                        onClick={() => handleDeleteClick(columnData.key)}
                    />
                </div>
            );
        }
    };

    const displayPage = (data) => {
        return data.value;
    };

    return (
        <div>
            <DataGrid
                dataSource={listDataSource}
                showBorders={false}
                showRowLines={true}
                showColumnLines={false}
                rowAlternationEnabled={true}
                onRowClick={handleEditColumn}
                width="100%"

            >
                <Paging defaultPageSize={25} />
                <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                />

                <FilterRow visible={true} />
                <ColumnFixing enabled={true} />
                <LoadPanel enabled={true} />
                <HeaderFilter visible={true} allowSearch={true} />
                <Scrolling mode="standard" />
                <FilterRow visible={true} showAllText="Alle" />
                <Column
                    dataField="name"
                    caption="Naam"
                    fixed={true}
                    fixedPosition="left"
                    dataType="string"
                    sortIndex={0}
                    width={175}
                    sortOrder="asc">
                  </Column>
                <Column
                    dataField="detailView.name"
                    caption="Voorkeurspagina"
                    dataType="string"
                    cellRender={displayPage}
                    width={175}
                    allowHeaderFiltering={false}
                >
                </Column>
                <Column
                    dataField="allowExportToExcel"
                    caption="Excel export"
                    width={100}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField="autoRefresh"
                    caption="Vernieuwen"
                    width={100}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField="scrollHorizontal"
                    caption="Horiz. scroll"
                    width={100}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField="inlineEditing"
                    caption="Aanpasbaar"
                    width={100}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField="allowCopy"
                    caption="Kopieerbaar"
                    width={100}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField="queryWhere"
                    caption="Voldoet aan"
                    width={450}
                    allowHeaderFiltering={false}
                />
                <Column
                    dataField="queryOrderBy"
                    caption="Sorteren"
                    width={450}
                    allowHeaderFiltering={false}
                />



                <Column
                    width={120}
                    fixed={true}
                    fixedPosition="right"
                    cellRender={columnEditControl}
                    headerCellRender={addNewButton}
                    alignment="right"
                />
            </DataGrid>

            {activeList && (isCreate || singleItem) && (
                <EditList
                    setIsEditing={setIsEditing}
                    isEditing={activeList}
                    columnData={singleItem}
                    allColumns={allColumns}
                    isCreate={isCreate}
                    dataSetId={dataSetId}
                    getListData={getListData}
                />
            )}

            {deleteNotification && (
                <DeleteItem
                    handleDeleteFunction={handleDeleteFunction}
                    setOpendDeleteNotification={setDeleteNotification}
                />
            )}
        </div>
    );
};

export default ListGrid;
