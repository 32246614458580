import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, SelectBox, TextArea } from "devextreme-react";
import { Popup } from 'devextreme-react/popup';
import React, { memo, useState, useCallback, useEffect, useRef, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings.js";
import DataGrid, {
    Column,
    Button,// as DataGridButton,
    Toolbar,
    Item,
    Editing,
    Lookup as DatagridLookup,
    RowDragging,
} from "devextreme-react/data-grid";
import { Button as ToolbarButton } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import toast from "react-hot-toast";
import { QueryVisualColumnArea, QueryVisualColumnSummaryType, QueryVisualDataFormat, QueryVisualDataType } from "../../../enums/queryVisual.js";
import { Lookup } from "devextreme-react";
import { DropDownOptions } from "devextreme-react/lookup";
import IndexQueryVisuals from "./indexQueryVisuals.js";

const UpsertQueryVisual = ({
    showInsertQueryVisual,
    showEditQueryVisual,
    editQueryVisualId,
    showPopup
}) => {
    console.log("UpsertQueryVisual", showInsertQueryVisual, "showEditQueryVisual", showEditQueryVisual, editQueryVisualId);
    const [organisations, setOrganisations] = useState();
    const [formData, setFormData] = useState(null);
    const [detailviewArray, setDetailviewArray] = useState(null);

    const isInsert = editQueryVisualId == null;
    console.log("FormData", formData, formData != null, detailviewArray);

    const defaultFormData = useMemo(() => {
        return {
            title: '',
            query: '',
            showChart: false,
            showPivotGrid: true,
            showFieldChooser: true,
            columnsRow: [],
            columnsColumn: [],
            columnsData: [],
        }
    }, []);

    /*const columnAreaArray = Object.entries(QueryVisualColumnArea).map(([key, value]) => ({
        id: value,
        name: key,
    }));*/

    const columnSummaryTypeArray = Object.entries(QueryVisualColumnSummaryType).map(([key, value]) => ({
        id: value,
        name: key,
    }));

    const columnDataTypeArray = Object.entries(QueryVisualDataFormat).map(([key, value]) => ({
        id: value,
        name: key,
    }));


    const columnDataFormatArray = Object.entries(QueryVisualDataType).map(([key, value]) => ({
        id: value,
        name: key,
    }));

    console.log("UpsertQueryVisual", formData);
    const [fullScreen, setFullScreen] = useState(false);



    const insert = useCallback(async (e) => {
        let newData = { ...formData };
        newData.columnsRow.forEach(cd => cd.id = null);
        newData.columnsColumn.forEach(cd => cd.id = null);
        newData.columnsData.forEach(cd => cd.id = null);
        try {
            // debugger;
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/query-visual/create`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(newData),
                }
            );
            showPopup(false);
        } catch (error) {
            throw error;
        }
    }, [formData, showPopup]);

    const edit = useCallback(async (e) => {
        let newData = { ...formData };
        newData.columnsRow.forEach(cd => cd.id = null);
        newData.columnsColumn.forEach(cd => cd.id = null);
        newData.columnsData.forEach(cd => cd.id = null);
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/query-visual/edit`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(formData),
                }
            );
            showPopup(false);
        } catch (error) {
            throw error;
        }
    }, [formData, showPopup]);

    const handleSave = useCallback(async (e) => {
        if (isInsert) {
            await insert(e);
        } else {
            await edit(e);
        }
    }, [isInsert, insert, edit]);

    const handleInputChange = (name) => (e) => {
        console.log("handleInputChange", e);
        setFormData((prevData) => ({
            ...prevData,
            [name]: e.value,
        }));
    };

    const handleOrganisationChange = useCallback(async (e) => {
        setFormData((prevData) => ({
            ...prevData,
            organisationId: e.selectedItem.id,
        }));
    }, []);

    const onHiding = useCallback(() => { showPopup(false); }, [showPopup]);

    useEffect(() => {
        const loadOrganisations = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/organisation/list/True`);
            const json = await res.json();
            setOrganisations(json)
        };
        loadOrganisations();
    }, []);

    useEffect(() => {
        const initializeQueryVisual = async () => {
            if (isInsert) {
                setFormData(defaultFormData);
            } else {
                const res = await msalFetch(null, `${appSettings.api.endpoint}/api/query-visual/get/${editQueryVisualId}`);
                const json = await res.json();
                console.log("json", json);
                /*return {
            title: '',
            query: '',
            showChart: false,
            showPivotGrid: true,
            showFieldChooser: true,
            columns: []
        }*/
                setFormData(json);
            }
        }
        initializeQueryVisual();

    }, [defaultFormData, isInsert, editQueryVisualId]);

    useEffect(() => {
        if (formData?.organisationId == null)
            return;
        const loadDetailviews = async () => {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/detailview/list/${formData.organisationId}`);
            const json = await res.json();
            const detailViews = json.map(item => { return { name: item.table.tableName + ' - ' + item.name, id: item.id, tableName: item.table.tableName } });
            setDetailviewArray(detailViews);
        };
        loadDetailviews();
    }, [formData?.organisationId]);

    const updateGridOrder = useCallback((area) => (e) => {
        console.log("updateGridOrder", e, formData);
        const newIndex = e.toIndex;
        const oldIndex = e.fromIndex;
        let array;
        switch (area) {
            case 'row':
                array = [...formData.columnsRow];
                break;
            case 'column':
                array = [...formData.columnsColumn];
                break;
            case 'data':
                array = [...formData.columnsData];
                break;

            default:
                break;
        }
        // Move the record in formData array based on newIndex
        const movedRecord = array.splice(oldIndex, 1)[0]; // Remove the record from the old position
        array.splice(newIndex, 0, movedRecord);
        array.forEach((item, index) => item.seqNr = index); // Insert it at the new position
        // console.log("array", array);
        // console.log("Updated formData order: ", formData);
        let result = { ...formData }
        switch (area) {
            case 'row':
                result.columnsRow = array;
                break;
            case 'column':
                result.columnsColumn = array;
                break;
            case 'data':
                result.columnsData = array;
                break;

            default:
                break;
        }
        console.log("result", result);
        setFormData(result);
    }, [formData]);

    const onRowInserted = useCallback((area) => (e) => {
        let result = {...formData};
        let array;
        switch (area) {
            case 'row':
                array = [...formData.columnsRow];
                break;
            case 'column':
                array = [...formData.columnsColumn];
                break;
            case 'data':
                array = [...formData.columnsData];
                break;

            default:
                break;
        }
        array.forEach((item, index) => item.seqNr = index); // Insert it at the new position
        switch (area) {
            case 'row':
                result.columnsRow = array;
                break;
            case 'column':
                result.columnsColumn = array;
                break;
            case 'data':
                result.columnsData = array;
                break;

            default:
                break;
        }
        console.log("formData", formData);
    }, [formData])

    const onRowRemoved = useCallback((area) => (e) => {
        console.log("onRowRemoved")
        let result = {...formData};
        let array;
        switch (area) {
            case 'row':
                array = [...formData.columnsRow];
                break;
            case 'column':
                array = [...formData.columnsColumn];
                break;
            case 'data':
                array = [...formData.columnsData];
                break;

            default:
                break;
        }
        array.forEach((item, index) => item.seqNr = index); // Insert it at the new position
        switch (area) {
            case 'row':
                result.columnsRow = array;
                break;
            case 'column':
                result.columnsColumn = array;
                break;
            case 'data':
                result.columnsData = array;
                break;

            default:
                break;
        }
        console.log("formData", formData);
    }, [formData]);

    return (<Popup
        className="bl-popup"
        showTitle={true}
        animation={false}
        hideOnOutsideClick={true}
        title={`${isInsert ? 'Toevoegen' : 'Bijwerken'} queryvisual`}
        resizeEnabled={true}
        visible={true}
        showCloseButton={true}
        onHiding={onHiding}
        fullScreen={fullScreen}
        toolbarItems={[
            {
                toolbar: "bottom",
                widget: "dxButton",
                location: "after",
                options:
                {
                    icon: 'check',
                    text: 'Opslaan en sluiten',
                    useSubmitBehavior: true,
                    onClick: handleSave
                }


            },
            {
                toolbar: "bottom",
                icon: "email",
                stylingMode: 'contained',
                widget: "dxButton",
                location: "before",
                options:
                {
                    icon: 'fullscreen',
                    text: 'Volledig scherm',
                    useSubmitBehavior: false,
                    onClick: (e) => { setFullScreen(!fullScreen) }

                }


            }]}
    >
        {formData != null && <><Form.Group>
            <Row>
                <Col lg="3">
                    <Form.Label>Titel</Form.Label>
                </Col>
                <Col lg="12">
                    <TextBox
                        type="input"
                        value={formData.title}
                        valueChangeEvent="keyup"
                        onValueChanged={handleInputChange('title')}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg="3">
                    Organisatie
                </Col>
                <Col lg="12">
                    {organisations && <Lookup
                        //defaultValue={defaultValue}
                        className="select-search-lookup"
                        dataSource={organisations}
                        //value={initialValue}
                        showCancelButton={false}
                        showClearButton={true}
                        noDataText="Geen resultaat"
                        displayExpr="name"
                        valueExpr="id"
                        //minSearchLength={typeAheadCharacters}
                        searchEnabled={true}
                        cleanSearchOnOpening={true}
                        searchPlaceholder="Zoeken..."
                        //readOnly={readonly}
                        stylingMode="outlined"
                        // itemRender={itemRender}
                        value={formData.organisationId}
                        onSelectionChanged={handleOrganisationChange}
                    //fieldRender={fieldRender({ deprecatedOption, columnId, isDetailview: true, columnHasImage })}
                    >
                        <DropDownOptions
                            // minHeight="400px"
                            maxHeight="60vh"
                            showTitle={true}
                            showCloseButton
                            hideOnOutsideClick={true}
                            minWidth="350px"
                            title={`Selecteer organisatie`}
                        />
                    </Lookup>}

                </Col>
            </Row>
            <Row>
                <Col lg="3">
                    <Form.Label>SQL-query</Form.Label>
                </Col>
                <Col lg="12">
                    <TextArea
                        value={formData.query}
                        valueChangeEvent="keyup"
                        height={"40vh"}
                        resizeEnabled={true}
                        onValueChanged={handleInputChange('query')}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg="3">
                    <Form.Label>Toon draaitabel</Form.Label>
                </Col>
                <Col lg="12">
                    <CheckBox
                        value={formData.showPivotGrid}
                        onValueChanged={handleInputChange('showPivotGrid')}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg="3">
                    <Form.Label>Toon grafiek</Form.Label>
                </Col>
                <Col lg="12">
                    <CheckBox
                        value={formData.showChart}
                        onValueChanged={handleInputChange('showChart')}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg="3">
                    <Form.Label>Toon kolomkiezer</Form.Label>
                </Col>
                <Col lg="12">
                    <CheckBox
                        value={formData.showFieldChooser}
                        onValueChanged={handleInputChange('showFieldChooser')}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    Kolommen (rij)
                </Col>
            </Row>
            {formData.organisationId && <><Row>
                <Col lg="12">
                    <DataGrid
                        dataSource={formData.columnsRow}
                        //title="Queryvisuals"
                        showBorders={true}
                        hoverStateEnabled={true}
                        showRowLines={false}
                        showColumnLines={true}
                        //id="queryVisualId"
                        rowAlternationEnabled={true}
                        keyExpr="id"
                        onRowInserted={onRowInserted('row')}
                        onRowRemoved={onRowRemoved('row')}
                    >

                        <Column caption="Dataveld" dataField="datafield" />
                        <Column caption="Column summary type" dataField="columnSummaryTypeId">
                            <DatagridLookup
                                dataSource={columnSummaryTypeArray}
                                valueExpr="id"
                                displayExpr="name"
                            />
                        </Column>
                        <Column caption="Datatype" dataField="columnDataTypeId">
                            <DatagridLookup
                                dataSource={columnDataTypeArray}
                                valueExpr="id"
                                displayExpr="name"
                            />
                        </Column>
                        <Column caption="Weergave" dataField="columnDataFormatId">
                            <DatagridLookup
                                dataSource={columnDataFormatArray}
                                valueExpr="id"
                                displayExpr="name"
                            />
                        </Column>
                        <Column caption="Detailview" dataField="detailviewId">
                            <DatagridLookup
                                dataSource={detailviewArray}
                                valueExpr="id"
                                displayExpr="name"
                                group="tableName"
                            />
                        </Column>
                        <Column caption="Dataveld" dataField="keyPropertyName" />
                        <Column caption="Breedte" dataType="number" dataField="width" />

                        <Editing
                            mode="row"
                            allowUpdating={true}
                            allowAdding={formData.organisationId != null}
                            allowDeleting={true}
                        />
                        <RowDragging
                            allowReordering={true}
                            onReorder={updateGridOrder('row')}
                            showDragIcons={true}
                            autoScroll={true} />
                    </DataGrid>
                </Col>
            </Row>
                <Row>
                    <Col lg="12">
                        Kolommen (kolom)
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <DataGrid
                            dataSource={formData.columnsColumn}
                            //title="Queryvisuals"
                            showBorders={true}
                            hoverStateEnabled={true}
                            showRowLines={false}
                            showColumnLines={true}
                            //id="queryVisualId"
                            rowAlternationEnabled={true}
                            keyExpr="id"
                            onRowInserted={onRowInserted('column')}

                        >

                            <Column caption="Dataveld" dataField="datafield" />
                            <Column caption="Column summary type" dataField="columnSummaryTypeId">
                                <DatagridLookup
                                    dataSource={columnSummaryTypeArray}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>
                            <Column caption="Datatype" dataField="columnDataTypeId">
                                <DatagridLookup
                                    dataSource={columnDataTypeArray}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>
                            <Column caption="Weergave" dataField="columnDataFormatId">
                                <DatagridLookup
                                    dataSource={columnDataFormatArray}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>
                            <Column caption="Breedte" dataType="number" dataField="width" />

                            <Editing
                                mode="row"
                                allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}
                            />
                            <RowDragging
                                allowReordering={true}
                                onReorder={updateGridOrder('column')}
                                showDragIcons={true}
                                autoScroll={true} />
                        </DataGrid>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        Kolommen (data)
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <DataGrid
                            dataSource={formData.columnsData}
                            //title="Queryvisuals"
                            showBorders={true}
                            hoverStateEnabled={true}
                            showRowLines={false}
                            showColumnLines={true}
                            //id="queryVisualId"
                            rowAlternationEnabled={true}
                            keyExpr="id"
                            onRowInserted={onRowInserted('data')}
                        >

                            <Column caption="Dataveld" dataField="datafield" />
                            <Column caption="Column summary type" dataField="columnSummaryTypeId">
                                <DatagridLookup
                                    dataSource={columnSummaryTypeArray}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>
                            <Column caption="Datatype" dataField="columnDataTypeId">
                                <DatagridLookup
                                    dataSource={columnDataTypeArray}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>
                            <Column caption="Weergave" dataField="columnDataFormatId">
                                <DatagridLookup
                                    dataSource={columnDataFormatArray}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>
                            <Column caption="Breedte" dataType="number" dataField="width" />
                            <Editing
                                mode="row"
                                allowUpdating={true}
                                allowAdding={true}
                                allowDeleting={true}
                            />
                            <RowDragging
                                allowReordering={true}
                                onReorder={updateGridOrder('data')}
                                showDragIcons={true}
                                autoScroll={true} />
                        </DataGrid>
                    </Col>
                </Row></>}
        </Form.Group></>}
        hallooo!!!
    </Popup >)
}

export default UpsertQueryVisual;