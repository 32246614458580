import { useState, useEffect } from "react";

import { DataGrid, TextBox } from "devextreme-react";

import { RowDragging, Column, Scrolling } from "devextreme-react/data-grid";
import { SelectBox } from "devextreme-react";

const TabSet = ({
  columns,
  hasTabs,
  formValue,
  setFormValue,
  setColumns,
  setSetColumns,
  setgridRef,
  checkChanges,
  checkMandatoryField,
  handleReorder,

  setChangeInForm,

  tabRelations,
}) => {
  const [tabGridRows, setTabGridRows] = useState(
    formValue.columns ? formValue.columns : []
  );
  const addColumn = () => {
    let tempForm = formValue;

    tempForm.columns.push({
      controlTabId: tempForm.id,
      controlRelationId: null,
      name: "",
      columnId: 0,
      sort: tempForm.columns.length + 1,
    });

    setFormValue(tempForm);
    setTabGridRows(tempForm.columns);

    setgridRef.current.instance().refresh(false);
    checkChanges();
  };

  const addButton = () => {
    return (
      <div
        className="bl-btn bl-add-btn bl-config-header-btn"
        onClick={addColumn}
      >
        <i className="fas fa-plus" />
      </div>
    );
  };

  const deleteColumn = (row) => {
    let tempForm = formValue;
    let sortedColumns = tempForm.columns.filter(
      (t) => t.sort !== row.data.sort
    );

    sortedColumns.map((t, index) => (t.sort = index));
    tempForm.columns = sortedColumns;
    setFormValue(tempForm);
    setTabGridRows(sortedColumns);
    setgridRef.current.instance().refresh(false);
    checkChanges();
  };

  const deleteButton = (column) => {
    return (
      <div className="bl-column-controls">
        <i
          className="fas fa-trash bl-column-control-btn bl-column-delete-btn"
          onClick={() => deleteColumn(column)}
        />
      </div>
    );
  };

  const columnSelectBox = (column) => {
    return (
      <SelectBox
        searchEnabled
        items={tabRelations}
        className={column.key.controlRelationId ? "" : "bl-mandatory-field"}
        valueExpr="id"
        displayExpr="name"
        defaultValue={column.key.controlRelationId}
        onValueChanged={(e) => {
          let tempForm = formValue;
          tempForm.columns.find((t) => t.sort === column.key.sort)[
            "controlRelationId"
          ] = e.value;
          setFormValue(tempForm);
          checkChanges();
          e.element.classList.remove("bl-mandatory-field");
        }}
      />
    );
  };

  const inlineTextBox = (data) => {
    return (
      <TextBox
        defaultValue={data.value ? data.value : ""}
        className={
          (data && data.value && data.value.trim()) === ""
            ? "bl-mandatory-field"
            : ""
        }
        valueChangeEvent="keyup"
        onValueChanged={(e) => {
          if (e.value.trim() === "") {
            e.element.classList.add("bl-mandatory-field");
          } else {
            e.element.classList.remove("bl-mandatory-field");
          }
          let tempFormValue = formValue;
          tempFormValue.columns.find((t) => t.sort === data.key.sort).name =
            e.value;

          setFormValue(tempFormValue);
          checkChanges();
        }}
      />
    );
  };

  return (
    <div>
      <header className="bl-config-section-header">
        <h3>Controls van het type 'Gerelateerd'</h3>
      </header>

      <DataGrid
        dataSource={tabGridRows}
        showColumnLines={false}
        rowAlternationEnabled={true}
        showRowLines={true}
        ref={setgridRef}

        // repaintChangesOnly={true}
      >
        {/* <Editing mode="popup">
                <Texts confirmDeleteMessage="" />
              </Editing> */}
        <Scrolling mode="virtual" />
        <RowDragging allowReordering={true} onReorder={handleReorder} />
        <Column
          caption="Naam"
          allowSorting={false}
          alignment="left"
          dataField="name"
          cellRender={inlineTextBox}
        ></Column>
        <Column
          caption="Set"
          allowSorting={false}
          alignment="left"
          dataField="controlRelationId"
          cellRender={columnSelectBox}
        ></Column>
        <Column
          dataField="sort"
          sortOrder="asc"
          width={1}
          className="hidden-column"
        />
        <Column
          alignment="center"
          width={100}
          headerCellRender={addButton}
          cellRender={deleteButton}
        />
      </DataGrid>
    </div>
  );
};

export default TabSet;
