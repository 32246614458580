import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import Loading from "../../../loading/Loading";
import appSettings from "../../../../appsettings";
import msalFetch from "../../../../api/MsalFetch.js";
import Form from "react-bootstrap/Form";
import { Col, Row, Spinner } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { CheckBox, SelectBox } from "devextreme-react";

const AVAK = ({
  fieldValue,
  setFieldValue,
  checkChanges,
  zoekbaar,
  isCreate,
  datasetId,
  currentDataset,
}) => {
  const msal = useMsal();
  const [columnLoading, setColumnLoading] = useState(true);
  const [datasetsLoading, setDatasetsLoading] = useState(true);
  const [weergaveLoading, setWeergaveLoading] = useState(
    isCreate ? false : true
  );
  const [refereceColumnLoading, setReferecenCOlumnLoading] = useState(
    isCreate ? false : true
  );

  // data states
  const [datasets, setDatasets] = useState([]);
  const [dependendColumns, setDependendColumns] = useState([]);
  const [weergaves, setWeergaves] = useState([]);
  const [waardes, setWaardes] = useState([]);
  const [colourColumns, setColourColumns] = useState([]);
  const [referenceColumns, setReferenceColumns] = useState([]);

  //bools
  const [hasWeergave, setHasWeergave] = useState(isCreate ? false : true);
  const [hasWaarde, setHasWaarde] = useState(false);
  const [hasAfhankelijkKolom, setHasAfhankelijkKolom] = useState(false);

  //main column query
  const getColumn = async () => {
    setColumnLoading(true);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/dataset/${datasetId.id}/column/`
      );
      const data = await response.json();

      const afhankelijkvanKolommenFilter = data.filter(
        (t) =>
          t.columnType !== null &&
          !t.name.includes("bl_") &&
          (t.columnType.id === 11 ||
            t.columnType.id === 13 ||
            t.columnType.id === 14 ||
            t.columnType.id === 15 ||
            t.columnType.id === 27)
      );
      console.log("datasetId/column:");
      console.log(data);

      setDependendColumns(afhankelijkvanKolommenFilter);
      setColumnLoading(false);
      return data;
    } catch (error) {
      setColumnLoading(false);
      throw "Data Loading Error";
    }
  };

  const getDatasets = async () => {
    setColumnLoading(true);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/dataset/`
      );
      const data = await response.json();
      setDatasets(data);

      console.log("configurator/dataset");
      console.log(data);
      setDatasetsLoading(false);
      return data;
    } catch (error) {
      setDatasetsLoading(false);
      throw "Data Loading Error";
    }
  };

  const getWeergaves = async (tableId) => {
    let kleurenKolommenFilter = [];
    console.log(tableId);
    setWeergaveLoading(true);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/dataset/${tableId}/column/`
      );
      const data = await response.json();

      const weergaveFilter = data.filter(
        (t) =>
          t.primairyKey === false &&
          t.type !== "uniqueidentifier" &&
          t.columnType !== null &&
          t.columnType.code !== "COLOR"
      );

      if (
        data.filter(
          (t) =>
            t.columnType && t.columnType.code && t.columnType.code === "COLOR"
        )
      ) {
        data.map((t) => {
          if (
            t.columnType &&
            t.columnType.code &&
            t.columnType.code === "COLOR"
          ) {
            return kleurenKolommenFilter.push(t);
          }
        });
      }

      const waardeKollommenFilter = data.filter(
        (t) => t.type === "int" || t.type === "uniqueidentifier"
      );

      console.log(waardeKollommenFilter);
      setWeergaves(weergaveFilter);
      setWaardes(waardeKollommenFilter);

      if (kleurenKolommenFilter.length > 0) {
        setColourColumns(kleurenKolommenFilter);
      }

      setWeergaveLoading(false);

      return data;
    } catch (error) {
      console.log(error);
      setWeergaveLoading(false);
      throw "Data Loading Error";
    }
  };

  const checkVerwijzing = async (passedInputTableId) => {
    console.log(passedInputTableId);
    if (!passedInputTableId) return;

    setReferecenCOlumnLoading(true);
    console.log(fieldValue);

    // const target = dependendColumns.find(
    //   (t) => t.id === cascadingParentColumnId
    // );

    // console.log(target);

    // const tempVerwijzingColumns = column.filter(
    //   (t) =>
    //     t.inputTableId === target.inputTableId &&
    //     t.id !== cascadingParentColumnId
    // );
    // console.log(tempVerwijzingColumns);

    // const verwijzingKolommenFilter = tempVerwijzingColumns.filter(
    //   (t) =>
    //     t.columnType !== null &&
    //     !t.name.includes("bl_") &&
    //     (t.columnType.id === 11 ||
    //       t.columnType.id === 13 ||
    //       t.columnType.id === 14 ||
    //       t.columnType.id === 15 ||
    //       t.columnType.id === 27)
    // );

    // console.log(verwijzingKolommenFilter);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/dataset/${passedInputTableId}/column`
      );
      const data = await response.json();
      console.log(data);

      let tempVerwijzingKolommen = data.filter(
        (t) =>
          t.columnType !== null &&
          !t.name.includes("bl_") &&
          t.columnType.id !== 12 &&
          t.columnType.id !== 14 &&
          t.columnType.id !== 15 &&
          t.columnType.id !== 19 &&
          t.columnType.id !== 21 &&
          t.columnType.id !== 23 &&
          t.columnType.id !== 24 &&
          t.type === "int"
      );

      console.log(tempVerwijzingKolommen);

      setReferenceColumns(tempVerwijzingKolommen);
      setReferecenCOlumnLoading(false);
    } catch (error) {
      setReferecenCOlumnLoading(false);
    }

    // const tryout = column.filter(
    //   (t) => t.dependColumnId === cascadingParentColumnId
    // );
    // console.log(tryout);
  };

  useEffect(() => {
    console.log(datasetId);
    console.log(fieldValue);
    getColumn();
    getDatasets();
    if (!isCreate) {
      getWeergaves(fieldValue.inputTableId);
      checkVerwijzing(fieldValue.inputTableId);

      if (fieldValue.inputTableId) {
        setHasAfhankelijkKolom(true);
      }
      //   getWeergaves(fieldValue.inputTableId);
    }
  }, []);

  if (datasetsLoading) {
    return <Loading />;
  } else {
    return (
      <div>
        {/* Alleen lezen veld */}
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Alleen lezen</Form.Label>
            </Col>
            <Col lg="3">
              <CheckBox
                defaultValue={
                  !isCreate && fieldValue.readonly ? fieldValue.readonly : false
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["readonly"] = e.value;
                  setFieldValue(tempFieldValue);
                  console.log(fieldValue);
                  checkChanges();
                }}
              />
            </Col>
            <Col lg="3">
              <Form.Label>Verplicht</Form.Label>
            </Col>
            <Col lg="3">
              <CheckBox
                defaultValue={fieldValue.mandatory ? fieldValue.mandatory : false}
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["mandatory"] = e.value;
                  setFieldValue(tempFieldValue);
                  console.log(fieldValue);
                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>

        {/* Mandatory veld */}


        <header className="bl-config-section-header">
          <h2> Selecteer een tabel</h2>
        </header>

        {/* Tabel dropdown veld */}
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Tabel</Form.Label>
            </Col>
            <Col lg="9">
              <SelectBox
                searchEnabled
                items={datasets}
                valueExpr="id"
                displayExpr="tableName"
                defaultValue={fieldValue.inputTableId}
                onValueChanged={async (e) => {
                  console.log(e.value);
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputTableId"] = e.value;
                  tempFieldValue["inputDisplayColumnId"] = null;
                  tempFieldValue["inputValueColumnId"] = null;
                  tempFieldValue["columnDefaultValue"] = null;
                  tempFieldValue["inputColorColumnId"] = null;
                  tempFieldValue["cascadingColumnId"] = null;
                  tempFieldValue["cascadingParentColumnId"] = null;

                  setFieldValue(tempFieldValue);
                  getWeergaves(e.value);
                  setHasWeergave(false);
                  checkVerwijzing(e.value);

                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>

        {/* Weergave Dropdown veld */}
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Weergave</Form.Label>

              {weergaveLoading && (
                <Spinner
                  className="bl-spinner selectbox-spinner"
                  animation="border"
                  role="status"
                  size="md"
                />
              )}
            </Col>
            <Col lg="3">
              <SelectBox
                searchEnabled
                items={weergaves}
                valueExpr="id"
                displayExpr="alias"
                className={`${isCreate && "bl-mandatory-field"} ${weergaveLoading ? "bl-readonly-field" : ""}`}
                defaultValue={
                  fieldValue.inputDisplayColumnId &&
                  fieldValue.inputDisplayColumnId
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  e.element.classList.remove("bl-mandatory-field");

                  tempFieldValue["inputDisplayColumnId"] = e.value;

                  setFieldValue(tempFieldValue);
                  if (!hasWeergave) {
                    setHasWeergave(true);
                  }

                  checkChanges();
                }}
              />
            </Col>
            <Col lg="3">
              <Form.Label>Waarde</Form.Label>
            </Col>
            <Col lg="3">
              <SelectBox
                searchEnabled
                items={waardes}
                valueExpr="id"
                displayExpr="name"
                className={`${isCreate && "bl-mandatory-field"} ${(weergaveLoading || !hasWeergave) &&
                  "bl-readonly-field"
                  }`}
                defaultValue={
                  fieldValue.inputValueColumnId && fieldValue.inputValueColumnId
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  e.element.classList.remove("bl-mandatory-field");

                  tempFieldValue["inputValueColumnId"] = e.value;

                  setFieldValue(tempFieldValue);
                  if (!hasWaarde) {
                    setHasWaarde(true);
                  }

                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>


        {/* Label input veld */}
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Label </Form.Label>
            </Col>
            <Col lg="3">
              <TextBox
                type="input"
                placeholder="Bijvoorbeeld: [Naam] <br />[AdressLine1]"
                defaultValue={fieldValue.inputLabel}
                valueChangeEvent="keyup"
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputLabel"] = e.value;
                  setFieldValue(tempFieldValue);
                  checkChanges();
                }}
              />
            </Col>
            <Col lg="3">
              <Form.Label>Kleur</Form.Label>
            </Col>
            <Col lg="3">
              <SelectBox
                searchEnabled
                items={colourColumns}
                valueExpr="id"
                displayExpr="name"
                defaultValue={
                  fieldValue.inputColorColumnId && fieldValue.inputColorColumnId
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;

                  tempFieldValue["inputColorColumnId"] = e.value;

                  setFieldValue(tempFieldValue);

                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>



        <header className="bl-config-section-header">
          <h2> Afhankelijk van</h2>
        </header>

        {/* Afhankelijk kolommen dropdown veld */}
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>
                Kolom in tabel: {currentDataset.tableName}
              </Form.Label>
            </Col>
            <Col lg="9">
              <SelectBox
                searchEnabled
                items={dependendColumns}
                valueExpr="id"
                displayExpr="alias"
                className={`${isCreate && "bl-mandatory-field"} ${isCreate &&
                  !hasWaarde &&
                  "bl-readonly-field"
                  }`}
                defaultValue={
                  fieldValue.cascadingParentColumnId &&
                  fieldValue.cascadingParentColumnId
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  e.element.classList.remove("bl-mandatory-field");

                  tempFieldValue["cascadingParentColumnId"] = e.value;

                  setFieldValue(tempFieldValue);
                  if (!hasAfhankelijkKolom) {
                    setHasAfhankelijkKolom(true);
                  }

                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>

        {/* Verwijzing kolommen dropdown veld */}
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>
                Verwijzing kolom in tabel:{" "}
                {fieldValue.inputTableId &&
                  datasets.find((t) => t.id === fieldValue.inputTableId)
                  ? datasets.find((t) => t.id === fieldValue.inputTableId)
                    .tableName
                  : "..."}{" "}
              </Form.Label>
              {refereceColumnLoading && (
                <Spinner
                  className="bl-spinner selectbox-spinner"
                  animation="border"
                  role="status"
                  size="md"
                />
              )}
            </Col>
            <Col lg="9">
              <SelectBox
                searchEnabled
                items={referenceColumns}
                valueExpr="id"
                displayExpr="name"
                className={`${isCreate && "bl-mandatory-field"} ${((isCreate && !hasAfhankelijkKolom) ||
                    refereceColumnLoading) &&
                  "bl-readonly-field"
                  }`}
                defaultValue={
                  fieldValue.cascadingColumnId && fieldValue.cascadingColumnId
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  e.element.classList.remove("bl-mandatory-field");

                  tempFieldValue["cascadingColumnId"] = e.value;

                  setFieldValue(tempFieldValue);
                  console.log(fieldValue);

                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Voldoet aan</Form.Label>
            </Col>
            <Col lg="9">
              <TextBox
                type="input"
                placeholder="Voldoet aan (SQL WHERE)"
                defaultValue={
                  fieldValue.inputTableQueryWhere &&
                  fieldValue.inputTableQueryWhere
                }
                valueChangeEvent="keyup"
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputTableQueryWhere"] = e.value;
                  setFieldValue(tempFieldValue);
                  //console.log(fieldValue);
                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col lg="3">
              <Form.Label>Sorteren op</Form.Label>
            </Col>
            <Col lg="9">
              <TextBox
                type="input"
                placeholder="Sorteren op (SQL ORDER)"
                defaultValue={
                  fieldValue.inputTableQueryOrderBy &&
                  fieldValue.inputTableQueryOrderBy
                }
                valueChangeEvent="keyup"
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue["inputTableQueryOrderBy"] = e.value;
                  setFieldValue(tempFieldValue);
                  //console.log(fieldValue);
                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>
      </div>
    );
  }
};

export default AVAK;
