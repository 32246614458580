import "./configurator.scss";

const Configurator = () => {
  return (
    <main className="bl-content bl-configurator">
      <h1>configurator</h1>
    </main>
  );
};

export default Configurator;
