import React from "react";
import Logo from "../../assets/images/logo.svg";
import "./dashboard.scss";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { triggerMsalLogin } from '../../api/MsalFetch';

const handleLogin = async () => {
    await triggerMsalLogin();
};

const AnonymousDashboard = () => {
    const { inProgress } = useMsal();
    const loginState = inProgress === InteractionStatus.Login;

    return (
        <main className="bl-content">
            <div>
                <div className="panel">
                    <div className="dashboard-card">
                        <article className="welcome-user">
                            <h3 >Je bent niet ingelogd</h3>
                            <div className="dashboard-brand">
                                <img src={Logo} alt="logo" />
                            </div>
                            <br />
                            <br />
                            <br />
                            <div
                                className={`bl-btn bl-action-btn ${loginState ? "executing-action-btn" : ""} `}
                                onClick={handleLogin}
                                title="Inloggen"
                            >
                                {loginState ? 'Bezig met inloggen' : 'Inloggen'}
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AnonymousDashboard;
