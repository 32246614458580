
import React, { memo, useState, useEffect, useCallback } from "react";
import appSettings from "../../appsettings";
import { Col } from "react-bootstrap";
import msalFetch from "../../api/MsalFetch.js";
import { ReactComponent as LogoGrey } from "../../assets/images/logo_grey.svg";
import Loading from "../../components/loading/Loading";
import PreviewImage from "../modals/Previews/PreviewImage";
import getQueryString from './getQueryString';

const ImageBlock = ({
  width,
  name,
  controlImageId,
  columnNumber,
  detailviewId,
  tableitemId,
  addColumnsToState,
  getColumnByColumnId,
  forceReloadTimestamp,
}) => {
  const [blockColumnIds, setBlockColumnIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [previews, setPreviews] = useState({});
  const [previewsLoading, setPreviewsLoading] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState(null);

  const getPreviewForId = useCallback(async (id, value) => {
    if (!value) {
      return;
    }
    setPreviewsLoading(state => ({
      ...state,
      [id]: true,
    }));
    try {
      const res = await msalFetch(null, `${appSettings.api.endpoint}/api/thumbnail/${id}/${tableitemId}/2`);
      const json = await res.json();
      if (!json.base64) {
        return false;
      }
      setPreviews(state => ({
        ...state,
        [id]: {
          value,

          src: (json.base64.startsWith('data') ? json.base64 : (`data:image/png;base64, ${json.base64}`)),
        },
      }));
    } catch (error) {
      throw error;
    }
    setPreviewsLoading(state => ({
      ...state,
      [id]: false,
    }));
  }, [tableitemId]);

  //fetches the imageblock data
  const getBlockData = useCallback(async () => {
    setLoading(true);
    try {
      const params = getQueryString({ timestamp: forceReloadTimestamp });
      const url = `${appSettings.api.endpoint}/api/${detailviewId}/image/${controlImageId}/${tableitemId}${params ? `?${params}` : ''}`;

      const res = await msalFetch(null, url);
      const data = await res.json();
      setTitle(data.name);

      if (!res.ok) {
        throw new Error(`[ImageBlock] getBlockData response was not ok: ${data.message}`);
      }
      const columns = data.columns.filter((column) => {
        if (column.value === "" || column.primairyKey) {
          return false;
        }
        return true;
      });
      setBlockColumnIds(columns.map(({ id }) => id));
      // this allows columns that are not in a property still being displayed, because we load them into the state
      addColumnsToState(columns, {
        newEntry: false,
        forced: Boolean(forceReloadTimestamp),
      });
    } catch (error) {
      throw error;
    }

    setLoading(false);
  }, [detailviewId, controlImageId, tableitemId, addColumnsToState, forceReloadTimestamp]);

  useEffect(() => {
    getBlockData();
  }, [getBlockData]);


  const stateColumns = blockColumnIds
    .map((id) => getColumnByColumnId(id))
    .filter(Boolean);

  const hasColumns = stateColumns.length > 0;
  const hasSingleColumn = stateColumns.length === 1;


  // show previous image control
  const handlePrevImage = useCallback(() => {
    let prev = activeIndex;
    if (prev === 0) {
      setActiveIndex(stateColumns.length - 1);
    } else {
      setActiveIndex(prev - 1);
    }
  }, [stateColumns, activeIndex]);

  // show next image control
  const handleNextImage = useCallback(() => {
    let prev = activeIndex;
    if (prev === stateColumns.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(prev + 1);
    }
  }, [stateColumns, activeIndex]);

  useEffect(() => {
    if (stateColumns.length > 0) {
      stateColumns.forEach((column) => {
        if (!column.value) {
          return;
        }
        if (!column.columnTypeCode === 'FILE') {
          return;
        }
        // if the value is not loaded into a preview, load it
        if (previews[column.id]?.value !== column.value) {
          // if its loading, dont load again, to stop infinte loop.
          if (previewsLoading[column.id]) {
            return;
          }
          getPreviewForId(column.id, column.value);
        }
      });
    }
  }, [stateColumns, previews, getPreviewForId, previewsLoading]);



  if (!loading) {
    return (
      <Col lg={width} md={12} className={`bl-col-padding`}>
        <section className="bl-detail-column bl-card">
          <header className="bl-card-header">
            <h3>{title}</h3>
          </header>

          <div className="bl-carousel-wrapper">
            {hasColumns && !hasSingleColumn && (
              <div className="bl-image-control bl-prev-image">
                <i className="fas fa-caret-left" onClick={handlePrevImage} />
              </div>
            )}
            <div className="bl-image-section-wrapper">
              {!hasColumns ? (
                <div className="no-data-block">
                  <div className="no-data-content">
                    <LogoGrey />
                    <p>Nog geen data om te tonen...</p>
                  </div>
                </div>
              ) : (
                stateColumns.map((column, index) => {
                  const { id, alias, value } = column;
                  const isFileColumn = column.columnTypeCode === 'FILE';
                  return (
                    <div
                      key={id}
                      className={activeIndex === index ? "bl-active-image" : ""}
                    >
                      {(() => {
                        if (!value) {
                          return `"${alias}" bevat geen afbeelding`;
                        }
                        if (!isFileColumn && !value.startsWith('http')) {
                          return `"${alias}" bevat geen link naar een afbeelding`;

                        }
                        if (isFileColumn && previewsLoading[id] !== false) {
                          return <Loading />;
                        }
                        return (
                          <img
                            src={!isFileColumn ? value : previews[id].src}
                            alt={alias}
                            onClick={() => setOpenModal(true)}
                          />
                        );
                      })()}
                    </div>
                  );
                })
              )}
            </div>
            {hasColumns && !hasSingleColumn && (
              <div className="bl-image-control bl-next-image">
                <i className="fas fa-caret-right" onClick={handleNextImage} />
              </div>
            )}
          </div>
          {openModal && (
            <PreviewImage
              url={
                stateColumns[activeIndex].columnTypeCode === 'FILE'
                  ? previews[stateColumns[activeIndex].id].src
                  : stateColumns[activeIndex].value
              }
              title={stateColumns[activeIndex].value}
              closeFunction={() => setOpenModal(false)}
            />
          )}
        </section>
      </Col>
    );
  } else {
    return <Loading />;
  }
};

export default memo(ImageBlock);
