import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import msalFetch from "../../../../../api/MsalFetch.js";
import appSettings from "../../../../../appsettings";

import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import {
  CheckBox,
  DateBox,
  NumberBox,
  Resizable,
  SelectBox,
  TextArea,
} from "devextreme-react";
import Loading from "../../../../loading/Loading";
// import { tSParameterProperty } from "@babel/types";

const DownloadAndPreview = ({
  fieldValue,
  setFieldValue,
  checkChanges,
  isCreate,
}) => {
  const msal = useMsal();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState();
  const [reportFiles, setReportFiles] = useState();
  const [recurring, setRecurring] = useState();
  const [startDate, setStartDate] = useState();
  const [displayNextLabel, setDisplayNextLabe] = useState();
  const [nextExecution, setNextExecution] = useState({
    start: "",
    interval: "",
    specification: "",
    output: "",
  });
  const reportFormats = [
    { value: "XLSX", display: "Excel" },
    { value: "PDF", display: "PDF" },
  ];

  const intervalSpecifications = [
    { v: "minute", d: "kwartier" },
    { v: "hour", d: "uur" },
    { v: "day", d: "dag" },
    { v: "week", d: "week" },
    { v: "month", d: "maand" },
  ];

  const calculateNextDate = (startDateTime, interval, specification) => {
    let tempStartDate = new Date(startDateTime);

    let nextDate;

    let tempFieldValue = fieldValue;

    if (specification === "minute") {
      nextDate = new Date(tempStartDate.getTime() + interval * 15 * 60000);

      //   return tempNextExecution;
    } else if (specification === "hour") {
      nextDate = new Date(tempStartDate.getTime() + interval * 60 * 60 * 1000);
    } else if (specification === "day") {
      nextDate = new Date(
        tempStartDate.getTime() + interval * 24 * 60 * 60 * 1000
      );
    } else if (specification === "week") {
      nextDate = new Date(
        tempStartDate.getTime() + interval * 7 * 24 * 60 * 60 * 1000
      );
    } else if (specification === "month") {
      nextDate = tempStartDate;
      nextDate.setMonth(nextDate.getMonth() + interval);
    }

    updateDisplayLabel(nextDate);
  };

  const getUsers = async () => {
    let tempUsers = [{ name: "Ingelogde gebruiker", id: null }];
    setLoading(true);
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/user`
      );
      const data = await response.json();
      data.map((user) => {
        return (tempUsers = [
          ...tempUsers,
          { name: `${user.firstname} ${user.name}`, id: user.id },
        ]);
      });

      setUsers(tempUsers);

      setLoading(false);
      return tempUsers;
    } catch (error) {
      setLoading(false);
      throw "Data Loading Error";
    }
  };

  useEffect(() => {
    if (fieldValue.action && fieldValue.action.nextExecution) {
      let tempString = fieldValue.action.nextExecution.replace("T", " ");

      const nextDate = new Date(tempString);

      const specification = fieldValue.action.intervalSpecification;
      const interval = fieldValue.action.interval;
      // if (!recurring) return;
      setStartDate(fieldValue.action.nextExecution);

      calculateNextDate(nextDate, interval, specification);
    }
  }, []);

  const checkReportin = (value) => {
    setReport(value);
  };

  const checkRecurring = (value) => {
    setRecurring(value);
  };
  const getReports = async () => {
    try {
      const response = await msalFetch(msal,
        `${appSettings.api.endpoint}/api/configurator/report/all`
      );
      const data = await response.json();

      setReportFiles(data);
      return;
    } catch (error) {
      throw "Data Loading Error";
    }
  };
  const styleObj = { height: '300px' };
  const updateDisplayLabel = (output) => {
    const dt = new Date(output);

    setDisplayNextLabe(
      `Volgende: ${dt.getDate()}-${("0" + (dt.getMonth() + 1)).slice(
        -2
      )}-${dt.getFullYear()} ${("0" + dt.getHours()).slice(-2)}:${(
        "0" + dt.getMinutes()
      ).slice(-2)}`
    );
  };

  useEffect(() => {
    getUsers();
    getReports();
    if (fieldValue.action) {
      checkReportin(fieldValue.action.report);
      checkRecurring(fieldValue.action.recurring);
    }
  }, []);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <header className="bl-config-section-header">
          <h2> Queries</h2>
        </header>

        <Form.Group>
          <Row>
            <Col lg="12">
              <Form.Label>Query</Form.Label>
            </Col>
            <Col lg="12">
              <Resizable
                minHeight={100}
                style={styleObj}
                maxHeight={600}
                handles={"bottom"}

              >
                <TextArea
                  height={'100%'}
                  defaultValue={
                    fieldValue.action &&
                    fieldValue.action.actionQuery &&
                    fieldValue.action.actionQuery
                  }
                  className={
                    isCreate ||
                      fieldValue.action.actionQuery === null ||
                      fieldValue.action.actionQuery === ""
                      ? "bl-mandatory-field"
                      : ""
                  }
                  placeholder="SELECT Id, Naam FROM Table WHERE Id = [Id]"
                  valueChangeEvent="keyup"
                  onValueChanged={(e) => {
                    if (
                      e.value.trim() === "" &&
                      !e.element.classList.contains("bl-mandatory-field")
                    ) {
                      e.element.classList.add("bl-mandatory-field");
                    } else {
                      e.element.classList.remove("bl-mandatory-field");
                    }
                    let tempFieldValue = fieldValue;
                    tempFieldValue.action["actionQuery"] = e.value;
                    setFieldValue(tempFieldValue);

                    checkChanges();
                  }}
                />
              </Resizable>
              <footer className="bl-label-footer">
                LET OP! Deze query moet 1 resultaat opleveren. Dit is te
                bereiken door `WHERE Id = [Id]` toe te voegen.
              </footer>

            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Row>
            <Col lg="12">
              <Form.Label>Query na actie</Form.Label>
            </Col>
            <Col lg="12">
              <Resizable
                minHeight={100}
                style={styleObj}
                maxHeight={600}
                handles={"bottom"}

              >
                <TextArea
                  height={'100%'}
                  defaultValue={
                    fieldValue.action &&
                    fieldValue.action.actionQueryNaActie &&
                    fieldValue.action.actionQueryNaActie
                  }
                  placeholder="UPDATE Table SET Status = 9 WHERE Id = [Id]"
                  valueChangeEvent="keyup"
                  onValueChanged={(e) => {
                    let tempFieldValue = fieldValue;
                    tempFieldValue.action["actionQueryNaActie"] = e.value;
                    setFieldValue(tempFieldValue);

                    checkChanges();
                  }}
                />
              </Resizable>
              <footer className="bl-label-footer">
                LET OP! Deze query moet 1 resultaat opleveren. Dit is te
                bereiken door `WHERE Id = [Id]` toe te voegen.
              </footer>

            </Col>
          </Row>
        </Form.Group>

        <header className="bl-config-section-header">
          <h2> Rapport</h2>
        </header>

        <Form.Group>
          <Row>
            <Col lg="12">
              <Form.Label>Report</Form.Label>
            </Col>
            <Col lg="12">
              <CheckBox
                defaultValue={
                  fieldValue.action && fieldValue.action.report
                    ? fieldValue.action.report
                    : false
                }
                onValueChanged={(e) => {
                  let tempFieldValue = fieldValue;
                  tempFieldValue.action["report"] = e.value;
                  setFieldValue(tempFieldValue);
                  setReport(e.value);

                  checkChanges();
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <section
          key={report}
          className={`bl-accordion ${report && "bl-accordion-expanded"}`}
        >
          <div className="bl-accordion-content">
            <Form.Group>
              <Row>
                <Col lg="12">
                  <Form.Label>Naam</Form.Label>
                </Col>
                <Col lg="12">
                  <SelectBox
                    searchEnabled
                    className={
                      report &&
                        (isCreate || fieldValue.action.reportId === null)
                        ? "bl-mandatory-field"
                        : ""
                    }
                    defaultValue={
                      fieldValue.action && fieldValue.action.reportId
                    }
                    items={reportFiles}
                    valueExpr="reportId"
                    displayExpr="displayName"
                    onValueChanged={(e) => {
                      let tempFieldValue = fieldValue;
                      tempFieldValue.action["reportId"] = e.value;
                      setFieldValue(tempFieldValue);

                      checkChanges();
                      e.element.classList.remove("bl-mandatory-field");
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col lg="12">
                  <Form.Label>Parameters</Form.Label>
                </Col>
                <Col lg="12">
                  <TextBox
                    defaultValue={
                      fieldValue.action &&
                      fieldValue.action.reportParameters &&
                      fieldValue.action.reportParameters
                    }
                    placeholder={`Parameter1=[Parameter1]&Parameter2=[Parameter2]`}
                    valueChangeEvent="keyup"
                    onValueChanged={(e) => {
                      let tempFieldValue = fieldValue;
                      tempFieldValue.action["reportParameters"] = e.value;
                      setFieldValue(tempFieldValue);

                      checkChanges();
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col lg="12">
                  <Form.Label>Filename</Form.Label>
                </Col>
                <Col lg="12">
                  <TextBox
                    defaultValue={
                      fieldValue.action &&
                      fieldValue.action.reportFileName &&
                      fieldValue.action.reportFileName
                    }
                    className={
                      report &&
                        (isCreate ||
                          fieldValue.action.reportFileName === null ||
                          fieldValue.action.reportFileName === "")
                        ? "bl-mandatory-field"
                        : ""
                    }
                    placeholder="[Bestandsnaame]_[Jaartal]"
                    valueChangeEvent="keyup"
                    onValueChanged={(e) => {
                      if (
                        e.value.trim() === "" &&
                        !e.element.classList.contains("bl-mandatory-field")
                      ) {
                        e.element.classList.add("bl-mandatory-field");
                      } else {
                        e.element.classList.remove("bl-mandatory-field");
                      }
                      let tempFieldValue = fieldValue;
                      tempFieldValue.action["reportFileName"] = e.value;
                      setFieldValue(tempFieldValue);

                      checkChanges();
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col lg="12">
                  <Form.Label>Formaat</Form.Label>
                </Col>
                <Col lg="12">
                  <SelectBox
                    searchEnabled
                    key={report}
                    className={
                      report &&
                        (isCreate ||
                          fieldValue.action.reportOutputFormat === null)
                        ? "bl-mandatory-field"
                        : ""
                    }
                    defaultValue={
                      fieldValue.action && fieldValue.action.reportOutputFormat
                    }
                    items={reportFormats}
                    valueExpr="value"
                    displayExpr="display"
                    onValueChanged={(e) => {
                      let tempFieldValue = fieldValue;
                      tempFieldValue.action["reportOutputFormat"] = e.value;
                      setFieldValue(tempFieldValue);

                      checkChanges();
                      e.element.classList.remove("bl-mandatory-field");
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
          </div>
        </section>
      </>
    );
  }
};
export default DownloadAndPreview;
