import React, { useState, useEffect, useCallback } from "react";
import { Popup } from 'devextreme-react/popup';
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import Content from "../../detailview/Content";
import Loading from "../../loading/Loading";

const DUPLICATEDETAILVIEW = ({
    isOpen,
    duplicateData,
    handleSaveAndCreateNew,
    handleSave,
    handleUserLeaveContinue,
    handleClose,
    handleDelete,
    handleUserLeaveSave,
    handleAddNew,
}) => {
    const [loading, setLoading] = useState(true);
    const [allDetailviewData, setDetailviewData] = useState([]);

    const { detailviewId, tableId, duplicateId } = duplicateData;

    const getDetailview = useCallback(async () => {
        setLoading(true);
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/detailview/${detailviewId}/?duplicate=true&onlyVisibleAsTabonlyVisibleAsTab&onlyVisibleAsTab=null`);
            const data = await response.json();
            setDetailviewData(data.detailViews);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }, [detailviewId]);

    useEffect(() => {
        if (tableId) {
            getDetailview();
        }
    }, [tableId, getDetailview]);

    const currentDetailview =
        allDetailviewData.find(({ id }) => id === Number(detailviewId))
        || allDetailviewData[0]; // this is a feature.
    // Sometimes the backend gives data, but the detailviewId itself is not present.
    // relationTable stuff..

    return (
        <Popup
            title="Gekopieerd! U kunt nog wijzigingen toevoegen."
            className="bl-popup"
            showTitle={true}
            resizeEnabled={true}
            dragEnabled={true}
            animation={false}

            visible={true}
            showCloseButton={true}
            onHiding={(e) => {
                // TODO: throwNotification, but it's handled by the child Content.. :(
                handleClose && handleClose();
                e.cancel = true;
            }}
        >
            {loading && <Loading />}
            {!loading && currentDetailview && (
                <Content
                    showTitle={true}
                    allDetailviewData={allDetailviewData}
                    currentDetailview={currentDetailview}
                    detailviewId={detailviewId}
                    tableitemId={duplicateId}
                    isContentForModal={true}
                    handleSaveAndCreateNew={handleSaveAndCreateNew}
                    handleSave={handleSave}
                    handleUserLeaveContinue={handleUserLeaveContinue}
                    handleClose={handleClose}
                    handleUserLeaveSave={handleUserLeaveSave}
                    handleAddNew={handleAddNew}
                    handleDelete={handleDelete}
                />
            )}
        </Popup>
    );
};

export default DUPLICATEDETAILVIEW;
