import React from "react";

const DeleteItem = ({
  handleDeleteFunction,
  setOpendDeleteNotification,
}) => {
  return (
    <div className={`bl-notify-modal`}>
      <div className="bl-notify-modal-inner">
        <header className="bl-notify-modal-header">
          <h3>Let op!</h3>
          <div
            className="header-action"
            onClick={() => {
              setOpendDeleteNotification(false);
            }}
          >
            <i className="fas fa-times" />
          </div>
        </header>
        <article>
          <p>Wilt u dit item verwijderen?</p>
          <footer className="bl-notify-controls">
            <div
              className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
              onClick={() => {
                handleDeleteFunction();
                console.log("saveFunction en modal sluiten");
                setOpendDeleteNotification(false);
              }}
            >
              Verwijderen
            </div>

            <div
              className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
              onClick={() => {
                setOpendDeleteNotification(false);
              }}
            >
              Annuleren
            </div>
          </footer>
        </article>
      </div>
    </div>
  );
};

export default DeleteItem;
