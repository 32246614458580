import React, { useMemo, memo, useState, useEffect, useCallback, useRef } from "react";
import Loading from "../../components/loading/Loading";
import { Scheduler } from 'devextreme-react/scheduler';
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store";
import appSettings from "../../appsettings.js";
import msalFetch from "../../api/MsalFetch.js";


const SCHEDULER = ({
    label,
    value,
    alias,
    keyNumber,
    columnIndex,
    rowIndex,
    height,
    mandatory,
    columnId,
    registarLoaded,
    onControlUpdate,
    memoField,
    readOnly,
}) => {
    const [loading, setLoading] = useState(false);
    const [currentValue, setCurrentValue] = useState(value === '' ? null : JSON.parse(value));
    const schedulerRef = useRef();

    const [currentDate, setCurrentDate] = useState(new Date(2021, 4, 25));

    const handlePropertyChange = useCallback((e) => {
        if (e.name === 'currentDate') {
            setCurrentDate(e.value);
        }
    }, [])

    const update = useCallback(async (e) => {

    }, [currentValue]);

    const insert = useCallback(async (e) => {

        const newValue = currentValue == null ? [e] : currentValue.concat([e]);
        setCurrentValue(newValue);
        onControlUpdate(columnId, JSON.stringify(newValue));
    }, [currentValue, columnId, onControlUpdate]);

    const load = useCallback(async (e) => {

        return currentValue;
    }, [currentValue]);


    const dataSource = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: load,
            update: update,
            insert: insert
            // remove: deleteServerRecord,
        })
    }), [update, load, insert]);

    if (!loading) {
        return (

            <Scheduler
                id={`scheduler_${columnId}`}
                ref={schedulerRef}
                dataSource={dataSource}
                currentDate={currentDate}
                onOptionChanged={handlePropertyChange}
            />

        );
    } else {
        console.log("[SCHEDULER] loading");
        return <Loading />;
    }
};

export default memo(SCHEDULER);
