import React from "react";

const SaveChanges = ({
    handleCancel,
    handleSaveAndContinue,
    handleDiscardAndContinue,
    handleContinue,
}) => {
    return (
        <div className={`bl-notify-modal`}>
            <div className="bl-notify-modal-inner">
                <header className="bl-notify-modal-header">
                    <h3>Let op</h3>
                    <div
                        className="header-action"
                        onClick={() => {
                            handleCancel && handleCancel();
                        }}
                    >
                        <i className="fas fa-times" />
                    </div>
                </header>
                <article>
                    <p>Wilt u de wijzigingen opslaan?</p>
                    <footer className="bl-notify-controls">
                        <div
                            className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
                            onClick={async () => {
                                if (handleSaveAndContinue && (await handleSaveAndContinue() === false)) {
                                    return false;
                                }
                                handleContinue && handleContinue();
                            }}
                        >
                            Opslaan
                        </div>

                        <div
                            className="bl-notify-modal-btn bl-btn bl-notify-fail-btn"
                            onClick={() => {
                                handleDiscardAndContinue && handleDiscardAndContinue();
                                handleContinue && handleContinue();
                            }}
                        >
                            Niet opslaan
                        </div>
                    </footer>
                </article>
            </div>
        </div>
    );
};

export default SaveChanges;
