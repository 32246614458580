import React, { memo, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { Button } from 'devextreme-react/button';
import appSettings from "../../appsettings";
import Loading from "../loading/Loading";
import msalFetch from "../../api/MsalFetch.js";
import getControl from '../properties/get-control';
import { useGraphContext } from "../graph/context";
import getQueryString from './getQueryString';

function DetailViewBlock({
    columnIndex,
    rowIndex,
    width,
    controlPropertyId,
    detailviewId,
    tableId,
    tableitemId,
    addColumnsToState,
    registerDetailsViewBlockLoaded,
    handleControlUpdate,
    getValueByColumnId,
    getColumnByColumnId,
    getValueByColumnName,
    changeInForm,
    forceReload,
    forceReloadTimestamp,
    // relationProperty:
    defaultName = null,
    defaultColumnIds = [],
    parentTableitemId,
    parentControlRelationId,
    allDetailviewBlocksAreLoaded,
    handleSaveClick,
    isCreate,
    draggableSelectFieldId,
    draggableSelectFieldValue
}) {
    const { currentUser } = useGraphContext();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [name, setName] = useState(defaultName);
    const [columnIds, setColumnIds] = useState(defaultColumnIds);
    const [controlsLoaded, setControlsLoaded] = useState({});

    const params = getQueryString({ parentTableitemId, parentControlRelationId, timestamp: forceReloadTimestamp });
    const url = `${appSettings.api.endpoint}/api/${detailviewId}/property/${controlPropertyId}/${tableitemId ? tableitemId : ""}${params ? `?${params}` : ''}`;
    useEffect(() => {
        const getPropertyItem = async () => {
            setLoading(true);
            const isCreate = Boolean(!tableitemId);

            let data;
            try {
                const response = await msalFetch(null, url);
                data = await response.json();
                if (!response.ok) {
                    setError('Er ging iets fout.');
                    setLoading(false);
                    throw new Error(`[DetailViewBlock] useEffect response was not ok: ${data.message}`);
                }
            } catch (error) {
                throw error;
            }
            setName(data.name);

            addColumnsToState(data.columns, {
                newEntry: isCreate,
                forced: isCreate || Boolean(forceReloadTimestamp),
            });
            setColumnIds(data.columns.map(({ id }) => id));
            const allControlsWithLoadedFalse = data.columns.filter(
                (item) => item.inputType.name !== "NONE"
            ).reduce((acc, column) => ({
                ...acc,
                [column.id]: false,
            }), {});
            setControlsLoaded((state) => ({
                ...state,
                ...allControlsWithLoadedFalse,
            }));
            setLoading(false);
        };
        getPropertyItem();
    }, [tableitemId, addColumnsToState, url, forceReloadTimestamp]);

    const registerLoaded = useCallback((controlPropertyColumnId) => {
        setControlsLoaded((state) => {
            return {
                ...state,
                [controlPropertyColumnId]: true,
            };
        });
    }, []);

    const isEverythingLoaded = Object.values(controlsLoaded).every(val => val === true);
    useEffect(() => {
        if (isEverythingLoaded) {
            registerDetailsViewBlockLoaded(controlPropertyId);
        }
    }, [isEverythingLoaded, registerDetailsViewBlockLoaded, controlPropertyId]);

    const activeColumns = columnIds.reduce((acc, columnId) => {
        // if(columnId === 11104)
        //     debugger;
        // get the columns from the state, not from the fetching
        const column = getColumnByColumnId(columnId);
        if (!column) {
            console.warn('Did not find a column in the state for', columnId);
            return acc;
        }
        return acc.concat(column);
    }, []);
    return (
        <Col
            lg={width}
            md={12}
            className={`bl-col-padding`}

        >
            <section className="bl-detail-column bl-card">
                {error && <div>{error}</div>}
                {loading ? (
                    <div />
                ) : (
                    <div>

                        <h3 className="bl-set-title" >
                            {name}

                        </h3>
                        <section className="propertyWrapper" >
                            {activeColumns.map((column, index) => {

                                if (isCreate && draggableSelectFieldId != null && draggableSelectFieldValue != null && column.id === draggableSelectFieldId) {

                                    column.value = draggableSelectFieldValue;
                                }

                                const control = getControl({
                                    column,
                                    index,
                                    columnIndex,
                                    rowIndex,
                                    detailviewId,
                                    tableitemId,
                                    controlPropertyId,
                                    registerLoaded,
                                    handleControlUpdate,
                                    getValueByColumnId,
                                    getColumnByColumnId,
                                    getValueByColumnName,
                                    allDetailviewBlocksAreLoaded,
                                    changeInForm,
                                    forceReload,
                                    handleSaveClick,
                                    isCreate
                                });
                                if (control) {
                                    return control;
                                }
                                if (control === false) {
                                    // f.e. ID doesn't need to return
                                    return null;
                                }
                                console.warn('Did not receive a control from getControl in DetailViewBlock on column', column);
                                return null;
                            })}
                        </section>
                    </div>
                )}
                {currentUser?.admin === 1 && (
                    <Link tabIndex="-1"
                        to={`/configurator/dataset/${tableId}/1/set/${controlPropertyId}/`}
                    >
                        <div className="control-property-configurator-icon"><i className={"fa-regular fa-cog"} /></div>

                    </Link>
                )}
            </section>
        </Col>
    );
}

export default memo(DetailViewBlock);
