import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Drawer from 'devextreme-react/drawer';
import { Template } from 'devextreme-react/core/template';
import Header from './header/header';
import ContentMenu from './menu/content-menu';
import ConfiguratorMenu from './menu/configurator-menu';
import { useGlobalContext } from "../../../src/context/context";

import './layout.scss';

const Layout = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(true);
    const { pathname } = useLocation();
    const { isMobile, screenWidth } = useGlobalContext();
    const toggleMenu = useCallback(({ event }) => {
        setMenuOpen((prevMenuStatus) => !prevMenuStatus);
        event?.stopPropagation();
    }, []);
    useEffect(() => {
        if (isMobile) {
            // if mobile, and pathname changed, close the menu.
            setMenuOpen(false);
        }
    }, [pathname, isMobile]);

    return (
        <main className="bloom-main-layout">
            <Header toggleMenu={toggleMenu} />
            <Drawer
                className="bloom-drawer"
                position='before'
                // closeOnOutsideClick={onOutsideClick}
                openedStateMode={isMobile ? "overlap" : "shrink"}
                revealMode={isMobile ? "expand" : "slide"}
                minSize={0}
                maxSize={isMobile ? screenWidth : 250}
                shading={isMobile}
                opened={menuOpen}
                animationDuration={400}
                template='menu'
            >
                <div className={!isMobile ? "bloom-content" : "bloom-mobile-content"}>{children}</div>
                <Template name='menu'>
                    {/* {pathname.startsWith('/configurator') ? (
                        <div>

                            <ConfiguratorMenu />
                        </div>
                    ) : (
                        <ContentMenu />
                    )} */}
                    <ContentMenu />
                </Template>
            </Drawer>
        </main>
    );
}

export default Layout;
