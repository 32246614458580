import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import React, { useState, useEffect } from "react";

import Loading from "../../../loading/Loading";

const UserList = ({ users, setShowUserList, roleName }) => {
  const [loading, setLoading] = useState(true);

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      setShowUserList(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    if (users) {
      setLoading(false);
    }
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [users, handleKeyDown]);

  return (
    <div className={`bl-notify-modal`}>
      <div className="bl-notify-modal-inner">
        {loading && <Loading />}

        {!loading && (
          <div className="list-content">
            <header>
              <h2>Gebruikers met rol: {roleName}</h2>
              <div
                className="bl-exit-btn"
                onClick={() => {
                  setShowUserList(false);
                }}
              >
                <i className="fas fa-times" />
              </div>
            </header>
            <DataGrid
              dataSource={users}
              className="sorting-disabled"
              rowAlternationEnabled={true}
              showColumnLines={false}
            >
              <Column caption="Naam" dataField="firstname" sortOrder="asc" />
              <Column dataField="name" caption="" />
              <Column dataField="email" caption="Email" />
            </DataGrid>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserList;
