import React, { useMemo } from 'react';
import List from 'devextreme-react/list';
import { useHistory } from "react-router-dom";
import { useGraphContext } from '../../graph/context';

import './user-menu-section.scss';

export const UserMenuSection = ({ showAvatar, listRef }) => {
    const { push } = useHistory();
    const { currentUser } = useGraphContext();
    const { profilePic, fullname } = useGraphContext();

    const userMenuItems = useMemo(
        () => [
            {
                text: 'Wijzig foto',
                icon: 'user',
                onClick: () => window.open('https://outlook.office365.com/ecp/PersonalSettings/EditAccount.aspx?chgPhoto=1', 'left=100,top=100,width=320,height=320'),
            },
            {
                text: 'Uitloggen',
                icon: 'runner',
                onClick: () => push('/logout'),
            }
        ]

        ,
        [push]
    );

    const listElementAttr = {
        class: 'user-info-list'
    };

    return (
        <>
            <div className='user-info'>
                {showAvatar && (
                    <div className='image-container'>
                        <div
                            style={{
                                backgroundImage: `url(${profilePic})`,
                            }}
                            className='user-image'
                        />
                    </div>
                )}
                <div className='user-name'>{fullname}</div>
            </div>
            <List
                ref={listRef}
                elementAttr={listElementAttr}
                items={userMenuItems}
            />
        </>
    );
};
