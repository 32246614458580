// @TODO: allow fullscreen

import React, { memo, useCallback } from "react";
import HtmlEditor, {
    Toolbar,
    Item,
    MediaResizing,
} from "devextreme-react/html-editor";
import FieldLabel from "../detailview/FieldLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tooltip } from 'devextreme-react/tooltip';

const headerValues = [false, 1, 2, 3, 4, 5];

const HTMLEDITOR = ({
    value,
    label,
    readonly,
    registerLoaded,
    onControlUpdate,
    columnId,
    memoField,
    mandatory
}) => {
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const handleValueChange = useCallback((e) => {
        onControlUpdate(columnId, e.value);
    }, [onControlUpdate, columnId]);

    return (
        <Form.Group>
            <Row>
                <Col md="4" className="bl-justify-top">
                    <FieldLabel
                        value={value}
                        label={label}
                        columnId={columnId}
                        memoField={memoField}
                        mandatory={mandatory}

                    />
                </Col>
                <Col md="12">
                    <div className="widget-container">
                        <HtmlEditor
                            defaultValue={value}
                            valueType="html"
                            allowSoftLineBreak={true}
                            stylingMode={readonly ? "filled" : "outlined"}
                            readOnly={readonly}
                            onValueChanged={handleValueChange}
                            className="bl-html-editor"
                            onInitialized={onInitialized}
                        >
                            <MediaResizing enabled={true} />
                            <Toolbar multiline={true}>
                                <Item name="undo" />
                                <Item name="redo" />

                                <Item name="separator" />
                                <Item name="bold" />
                                <Item name="italic" />
                                <Item name="strike" />
                                <Item name="underline" />
                                <Item name="separator" />
                                <Item name="header" acceptedValues={headerValues} />
                                <Item name="separator" />

                                <Item name="orderedList" />
                                <Item name="bulletList" />
                            </Toolbar>
                        </HtmlEditor>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(HTMLEDITOR);
