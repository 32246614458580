import { parseNumber } from "devextreme/localization";
import { getFormat } from './formats';

const replacer = (getValueByColumnName, alias, asString) => (match, p1, offset, string) => {

    const val = getValueByColumnName(p1) ?? 0;
    if (!val) {

        //throw new Error(`Did not receive a value for "${p1}" in CALCULATED "${alias}"`);
    }
    if (asString) {
        return String(val);
    }

    //correct the val, too long strings make devExtreme's parsedNumber return NaN
    let parsedNumber;
    if (typeof val === 'string') {

        parsedNumber = Number(val);

    } else {
        parsedNumber = val;
    }

    return parsedNumber;
}

const calculate = ({
    alias,
    getValueByColumnName,
    defaultValue,
    displayFormat,
    asString = false,
    returnComputedValue = false,
}) => {
    const replacerFn = replacer(getValueByColumnName, alias, asString);

    let replacedString;
    try {
        replacedString = defaultValue.replace(/\[(.*?)\]/g, replacerFn).replace('--', '+');
    } catch (err) {
        console.error(err);
        throw err;
    }

    let computedValue;
    try {
        if (asString) {
            // strings are done, no need to eval them
            computedValue = replacedString;
        } else {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/eval#never_use_eval!
            // eslint-disable-next-line no-new-func
            computedValue = Function(`"use strict";return (${replacedString})`)();
        }
    } catch (err) {

        throw err;
    }

    if (returnComputedValue) {
        return computedValue;
    }

    const outcome = asString ? String(computedValue) : getFormat(displayFormat).formatter(computedValue);

    return outcome;
}

export default calculate;
