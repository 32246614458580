import React, { memo, useState, useEffect, useCallback, useRef } from "react";
import Loading from "../../components/loading/Loading";
import Diagram from 'devextreme-react/diagram';
import "../../../node_modules/devexpress-diagram/dist/dx-diagram.min.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tooltip } from 'devextreme-react/tooltip';
import FieldLabel from "../detailview/FieldLabel";

const FLOWCHART = ({
    label,
    value,
    alias,
    keyNumber,
    columnIndex,
    rowIndex,
    height,
    mandatory,
    columnId,
    registarLoaded,
    onControlUpdate,
    memoField,
    readOnly,
}) => {
    const [loading, setLoading] = useState(false);
    const [isImported, setIsImported] = useState(false);
    const [originalValue, setOriginalValue] = useState(null);

    const flowchartRef = useRef();

    const update = useCallback((val) => {
        onControlUpdate(columnId, val);
    }, [onControlUpdate, columnId/* handleControlUpdate , columnId*/])

    useEffect(() => {
        //console.log("[FLOWCHART] isImported", isImported);
        console.log("[FLOWCHART] import", value, value === '');
        if (value !== '') {
            flowchartRef.current.instance().import(value);
        }
        setIsImported(true);

    }, [value/*, isImported*/]);

    const onOptionChanged = useCallback((e) => {
        //bad luck, but the Diagram initializes with a lot of data
        //console.log("MEMO", "ACTUAL CHANGE", e.component.export() !== value, e.component.export(), value);
        if (e.name === "hasChanges" && e.value) {
            // handle the property change here
            console.log("[FLOWCHART] hasChanges true", isImported);
            if (isImported) {
                console.log("[FLOWCHART] update")
                update(e.component.export());
            }
            //console.log("[FLOWCHART] hasChanges", e, isImported, value/*, e.component.export()*/);

            e.component.option("hasChanges", false);
        }
    }, [update, isImported/*, value*/])

    if (!loading) {
        return (
            <Form.Group>
                <Row>
                    <Col md="4">
                        <FieldLabel
                            value={value}
                            label={alias}
                            columnId={columnId}
                            memoField={memoField}


                        />
                    </Col>
                    <Col
                        md="12"
                        className={``}
                    >
                        <Diagram
                            id={`flowchart_${columnId}`}
                            ref={flowchartRef}
                            readOnly={readOnly}
                            onOptionChanged={onOptionChanged}
                        />

                    </Col>
                </Row>
            </Form.Group>


        );
    } else {
        return <Loading />;
    }
};

export default memo(FLOWCHART);
