import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useRef, useCallback } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import appSettings from "../../../appsettings";
import DataGrid, { Column, Pager, Paging } from "devextreme-react/data-grid";
import { RowDragging } from "devextreme-react/data-grid";

import Loading from "../../loading/Loading";
import "./menu.scss";
import ConfigPageControls from "../parts/ConfigPageControls";
import { useModalContext } from "../../../context/modalContext";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import BloomFooter from "../../footer/BloomFooter";
import DeleteItem from "../../notifications/userLeaveNotifications/notifications/DeleteItem";
import UserLeave from "../../notifications/userLeaveNotifications/UserLeave";

const MenuConfig = () => {
    const msal = useMsal();
    const [loading, setLoading] = useState(true);

    const link = useHistory();
    const [deleteTarget, setDeleteTarget] = useState({});

    const [form, setForm] = useState([]);
    const [deleteNotification, setDeleteNotification] = useState(false);
    const [userLeaveNotification, setUserLeaveNotification] = useState(false);

    const [changeInForm, setChangeInForm] = useState(false);
    const [deleteButtonHover, setDeleteButtonHover] = useState(false);
    const {
        setIsEditing,
        isEditing,
        setModal,
        setDeleteProceedNotification,
        setNotificationData,
        deleteProceedNotifaction,
    } = useModalContext();

    const dataGridRef = useRef();
    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const getConfigMenu = (needsLoading) => {
        if (needsLoading) {
            setLoading(true);
        }
        msalFetch(msal, `${appSettings.api.endpoint}/api/configurator/menu`)
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                console.log(data);
                setForm(data);
            })
            .catch(() => {
                setLoading(false);
                throw "Data Loading Error";
            });
    };

    useEffect(() => {
        getConfigMenu();
        setDeleteButtonHover(false);
    }, [isEditing, deleteProceedNotifaction]);

    useEffect(() => {
        getConfigMenu();
    }, [deleteProceedNotifaction]);

    useEffect(() => {
        dataGridRef.current?.instance().refresh();
    }, [deleteProceedNotifaction]);

    const handleRowDrag = (e) => {
        let tempFormValue = form;

        tempFormValue.splice(e.fromIndex, 1);
        tempFormValue.splice(e.toIndex, 0, e.itemData);

        let newSortedList = [];
        tempFormValue.map((item, index) => {
            let tempItem = item;
            tempItem.sort = index;
            newSortedList = [...newSortedList, tempItem];
            return newSortedList;
        });

        setForm(newSortedList);
        dataGridRef.current.instance().refresh();
        checkChanges();
        console.log(form);
    };

    const addNewMenuItem = () => {
        setIsEditing(true);
        setModal("ADDNEWMENUITEM");
    };

    function addNewButton() {
        return (
            <div className="bl-config-btn-wrapper">
                <div
                    className="bl-btn bl-add-btn bl-config-header-btn"
                    onClick={addNewMenuItem}
                    title="Nieuw menu item toevoegen"
                >
                    <i className="fas fa-plus" />
                </div>
            </div>
        );
    }
    const handleDeleteFunction = async () => {
        const url = `${appSettings.api.endpoint}/api/configurator/menu/${deleteTarget.id}`;
        console.log(url);

        try {
            const res = await msalFetch(msal, url, {
                method: "DELETE",
                headers: { "Content-type": "application/json" },
            });
            if (res.ok) {
                console.log(res);
                setDeleteNotification(false);
                setDeleteTarget({});
                getConfigMenu();
                toast.success("De gegevens zijn successvol opgeslagen...");
            }
            else {
                toast.error("Oeps er ging iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    const handleDeleteMenuClick = (data) => {
        const base = `${appSettings.api.endpoint}/api/configurator/menu/`;
        const target = data.data.id;
        // setDeleteProceedNotification(true);
        // setNotificationData({ deleteUrl: `${base}${target}` });
        setDeleteTarget(data.key);
        setDeleteNotification(true);
    };

    const handleEditItemClick = (id) => {
        if (deleteButtonHover) return;
        const url = `/configurator/menuitem/${id}`;
        link.push(url);
    };

    const handleSaveClick = async () => {
        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/menu`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(form),
                }
            );
            if (res.ok) {
                setChangeInForm(false);
                getConfigMenu();
                toast.success("De gegevens zijn successvol opgeslagen...");
            } else {
                toast.error("Oeps er ging iets mis...");
            }
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    const exitPageFunction = () => {
        console.log("exit");
        console.log(userLeaveNotification);
        if (changeInForm) {
            setUserLeaveNotification(true);
        } else {
            getConfigMenu(true);
        }
    };

    const refreshFunction = () => {
        if (changeInForm) {
            setUserLeaveNotification(true);
        } else {
            getConfigMenu(true);
        }
    };

    const handleDiscardAndContinue = useCallback(() => {
        getConfigMenu(true);
        setUserLeaveNotification(false);
    }, [setIsEditing]);

    const handleSaveAndContinue = () => {
        handleSaveClick();
        setUserLeaveNotification(false);
    };

    const LengthLabel = (data) => {
        if (data.data.menuItemsCount > 0);
        return data.data.menuItemsCount;


    };


    return (
        <main className="bl-content bl-config-menu-page">
            <header className="bl-config-page-header">
                <h2>Menu</h2>
                <ConfigPageControls
                    saveFunction={handleSaveClick}
                    changeInForm={changeInForm}
                    hasRefreshButton={true}
                    refreshFunction={refreshFunction}
                    noExitButton={true}
                />
            </header>

            <section className="bl-card">
                {loading && <Loading />}
                {!loading && (
                    <div>
                        <DataGrid
                            dataSource={form}
                            showBorders={false}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            showColumnLines={false}
                            ref={dataGridRef}
                            onRowClick={(column) => handleEditItemClick(column.key.id)}
                        >
                            <Pager />
                            <Paging pageSize={100} />
                            {/* <Scrolling rowRenderingMode="virtual" /> */}
                            <RowDragging
                                allowReordering={true}
                                dropFeedbackMode="push"
                                onDragEnd={handleRowDrag}
                            />

                            <Column dataField="name" dataType="string" caption="Menu item" />
                            <Column
                                cellRender={LengthLabel}
                                dataType="number"
                                alignment="right"

                                width={100}
                                caption="# Items"
                            />

                            <Column
                                cellRender={cellRender}
                                width={100}
                                alignment="right"
                                allowSorting={false}
                                headerCellRender={addNewButton}
                            />
                        </DataGrid>
                    </div>
                )}
            </section>
            <BloomFooter />

            {deleteNotification && (
                <DeleteItem
                    handleDeleteFunction={handleDeleteFunction}
                    refreshFunction={getConfigMenu}
                    setOpendDeleteNotification={setDeleteNotification}
                />
            )}
            {userLeaveNotification && (
                <UserLeave
                    handleCancel={() => setUserLeaveNotification(false)}
                    handleSaveAndContinue={handleSaveAndContinue}
                    handleDiscardAndContinue={handleDiscardAndContinue}
                />
            )}
        </main>
    );

    function cellRender(item) {
        return (
            <div className="bl-column-controls ">
                <i
                    className="fas fa-pencil-alt bl-edit-pencil bl-column-control-btn bl-column-edit-btn"
                    onClick={() => handleEditItemClick(item.key.id)}
                />

                <i
                    onMouseEnter={() => setDeleteButtonHover(true)}
                    onMouseLeave={() => setDeleteButtonHover(false)}
                    className="fas fa-trash-alt bl-edit-pencil bl-column-control-btn bl-column-delete-btn"
                    onClick={() => handleDeleteMenuClick(item)}
                />
            </div>
        );
    }
};



export default MenuConfig;
