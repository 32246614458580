const rawIcons = [
  { label: "fa-address-book", value: "" },
  { label: "fa-address-card", value: "" },
  { label: "fa-adjust", value: "" },
  { label: "fa-align-center", value: "" },
  { label: "fa-align-justify", value: "" },
  { label: "fa-align-left", value: "" },
  { label: "fa-align-right", value: "" },
  { label: "fa-allergies", value: "" },
  { label: "fa-ambulance", value: "" },
  { label: "fa-american-sign-language-interpreting", value: "" },
  { label: "fa-anchor", value: "" },
  { label: "fa-angle-double-down", value: "" },
  { label: "fa-angle-double-left", value: "" },
  { label: "fa-angle-double-right", value: "" },
  { label: "fa-angle-double-up", value: "" },
  { label: "fa-angle-down", value: "" },
  { label: "fa-angle-left", value: "" },
  { label: "fa-angle-right", value: "" },
  { label: "fa-angle-up", value: "" },
  { label: "fa-archive", value: "" },
  { label: "fa-arrow-alt-circle-down", value: "" },
  { label: "fa-arrow-alt-circle-left", value: "" },
  { label: "fa-arrow-alt-circle-right", value: "" },
  { label: "fa-arrow-alt-circle-up", value: "" },
  { label: "fa-arrow-circle-down", value: "" },
  { label: "fa-arrow-circle-left", value: "" },
  { label: "fa-arrow-circle-right", value: "" },
  { label: "fa-arrow-circle-up", value: "" },
  { label: "fa-arrow-down", value: "" },
  { label: "fa-arrow-left", value: "" },
  { label: "fa-arrow-right", value: "" },
  { label: "fa-arrow-up", value: "" },
  { label: "fa-arrows-alt", value: "" },
  { label: "fa-arrows-alt-h", value: "" },
  { label: "fa-arrows-alt-v", value: "" },
  { label: "fa-assistive-listening-systems", value: "" },
  { label: "fa-asterisk", value: "" },
  { label: "fa-at", value: "" },
  { label: "fa-audio-description", value: "" },
  { label: "fa-backward", value: "" },
  { label: "fa-balance-scale", value: "" },
  { label: "fa-ban", value: "" },
  { label: "fa-band-aid", value: "" },
  { label: "fa-barcode", value: "" },
  { label: "fa-bars", value: "" },
  { label: "fa-baseball-ball", value: "" },
  { label: "fa-basketball-ball", value: "" },
  { label: "fa-bath", value: "" },
  { label: "fa-battery-empty", value: "" },
  { label: "fa-battery-full", value: "" },
  { label: "fa-battery-half", value: "" },
  { label: "fa-battery-quarter", value: "" },
  { label: "fa-battery-three-quarters", value: "" },
  { label: "fa-bed", value: "" },
  { label: "fa-beer", value: "" },
  { label: "fa-bell", value: "" },
  { label: "fa-bell-slash", value: "" },
  { label: "fa-bicycle", value: "" },
  { label: "fa-binoculars", value: "" },
  { label: "fa-birthday-cake", value: "" },
  { label: "fa-blender", value: "" },
  { label: "fa-blind", value: "" },
  { label: "fa-bold", value: "" },
  { label: "fa-bolt", value: "" },
  { label: "fa-bomb", value: "" },
  { label: "fa-book", value: "" },
  { label: "fa-book-open", value: "" },
  { label: "fa-bookmark", value: "" },
  { label: "fa-bowling-ball", value: "" },
  { label: "fa-box", value: "" },
  { label: "fa-box-open", value: "" },
  { label: "fa-boxes", value: "" },
  { label: "fa-braille", value: "" },
  { label: "fa-briefcase", value: "" },
  { label: "fa-briefcase-medical", value: "" },
  { label: "fa-broadcast-tower", value: "" },
  { label: "fa-broom", value: "" },
  { label: "fa-bug", value: "" },
  { label: "fa-building", value: "" },
  { label: "fa-bullhorn", value: "" },
  { label: "fa-bullseye", value: "" },
  { label: "fa-burn", value: "" },
  { label: "fa-bus", value: "" },
  { label: "fa-calculator", value: "" },
  { label: "fa-calendar", value: "" },
  { label: "fa-calendar-alt", value: "" },
  { label: "fa-calendar-check", value: "" },
  { label: "fa-calendar-minus", value: "" },
  { label: "fa-calendar-plus", value: "" },
  { label: "fa-calendar-times", value: "" },
  { label: "fa-camera", value: "" },
  { label: "fa-camera-retro", value: "" },
  { label: "fa-capsules", value: "" },
  { label: "fa-car", value: "" },
  { label: "fa-caret-down", value: "" },
  { label: "fa-caret-left", value: "" },
  { label: "fa-caret-right", value: "" },
  { label: "fa-caret-square-down", value: "" },
  { label: "fa-caret-square-left", value: "" },
  { label: "fa-caret-square-right", value: "" },
  { label: "fa-caret-square-up", value: "" },
  { label: "fa-caret-up", value: "" },
  { label: "fa-cart-arrow-down", value: "" },
  { label: "fa-cart-plus", value: "" },
  { label: "fa-certificate", value: "" },
  { label: "fa-chalkboard", value: "" },
  { label: "fa-chalkboard-teacher", value: "" },
  { label: "fa-chart-area", value: "" },
  { label: "fa-chart-bar", value: "" },
  { label: "fa-chart-line", value: "" },
  { label: "fa-chart-pie", value: "" },
  { label: "fa-check", value: "" },
  { label: "fa-check-circle", value: "" },
  { label: "fa-check-square", value: "" },
  { label: "fa-chess", value: "" },
  { label: "fa-chess-bishop", value: "" },
  { label: "fa-chess-board", value: "" },
  { label: "fa-chess-king", value: "" },
  { label: "fa-chess-knight", value: "" },
  { label: "fa-chess-pawn", value: "" },
  { label: "fa-chess-queen", value: "" },
  { label: "fa-chess-rook", value: "" },
  { label: "fa-chevron-circle-down", value: "" },
  { label: "fa-chevron-circle-left", value: "" },
  { label: "fa-chevron-circle-right", value: "" },
  { label: "fa-chevron-circle-up", value: "" },
  { label: "fa-chevron-down", value: "" },
  { label: "fa-chevron-left", value: "" },
  { label: "fa-chevron-right", value: "" },
  { label: "fa-chevron-up", value: "" },
  { label: "fa-child", value: "" },
  { label: "fa-church", value: "" },
  { label: "fa-circle", value: "" },
  { label: "fa-circle-notch", value: "" },
  { label: "fa-clipboard", value: "" },
  { label: "fa-clipboard-check", value: "" },
  { label: "fa-clipboard-list", value: "" },
  { label: "fa-clock", value: "" },
  { label: "fa-clone", value: "" },
  { label: "fa-closed-captioning", value: "" },
  { label: "fa-cloud", value: "" },
  { label: "fa-cloud-download-alt", value: "" },
  { label: "fa-cloud-upload-alt", value: "" },
  { label: "fa-code", value: "" },
  { label: "fa-code-branch", value: "" },
  { label: "fa-coffee", value: "" },
  { label: "fa-cog", value: "" },
  { label: "fa-cogs", value: "" },
  { label: "fa-coins", value: "" },
  { label: "fa-columns", value: "" },
  { label: "fa-comment", value: "" },
  { label: "fa-comment-alt", value: "" },
  { label: "fa-comment-dots", value: "" },
  { label: "fa-comment-slash", value: "" },
  { label: "fa-comments", value: "" },
  { label: "fa-compact-disc", value: "" },
  { label: "fa-compass", value: "" },
  { label: "fa-compress", value: "" },
  { label: "fa-copy", value: "" },
  { label: "fa-copyright", value: "" },
  { label: "fa-couch", value: "" },
  { label: "fa-credit-card", value: "" },
  { label: "fa-crop", value: "" },
  { label: "fa-crosshairs", value: "" },
  { label: "fa-crow", value: "" },
  { label: "fa-crown", value: "" },
  { label: "fa-cube", value: "" },
  { label: "fa-cubes", value: "" },
  { label: "fa-cut", value: "" },
  { label: "fa-database", value: "" },
  { label: "fa-deaf", value: "" },
  { label: "fa-desktop", value: "" },
  { label: "fa-diagnoses", value: "" },
  { label: "fa-dice", value: "" },
  { label: "fa-dice-five", value: "" },
  { label: "fa-dice-four", value: "" },
  { label: "fa-dice-one", value: "" },
  { label: "fa-dice-six", value: "" },
  { label: "fa-dice-three", value: "" },
  { label: "fa-dice-two", value: "" },
  { label: "fa-divide", value: "" },
  { label: "fa-dna", value: "" },
  { label: "fa-dollar-sign", value: "" },
  { label: "fa-dolly", value: "" },
  { label: "fa-dolly-flatbed", value: "" },
  { label: "fa-donate", value: "" },
  { label: "fa-door-closed", value: "" },
  { label: "fa-door-open", value: "" },
  { label: "fa-dot-circle", value: "" },
  { label: "fa-dove", value: "" },
  { label: "fa-download", value: "" },
  { label: "fa-dumbbell", value: "" },
  { label: "fa-edit", value: "" },
  { label: "fa-eject", value: "" },
  { label: "fa-ellipsis-h", value: "" },
  { label: "fa-ellipsis-v", value: "" },
  { label: "fa-envelope", value: "" },
  { label: "fa-envelope-open", value: "" },
  { label: "fa-envelope-square", value: "" },
  { label: "fa-equals", value: "" },
  { label: "fa-eraser", value: "" },
  { label: "fa-euro-sign", value: "" },
  { label: "fa-exchange-alt", value: "" },
  { label: "fa-exclamation", value: "" },
  { label: "fa-exclamation-circle", value: "" },
  { label: "fa-exclamation-triangle", value: "" },
  { label: "fa-expand", value: "" },
  { label: "fa-expand-arrows-alt", value: "" },
  { label: "fa-external-link-alt", value: "" },
  { label: "fa-external-link-square-alt", value: "" },
  { label: "fa-eye", value: "" },
  { label: "fa-eye-dropper", value: "" },
  { label: "fa-eye-slash", value: "" },
  { label: "fa-fast-backward", value: "" },
  { label: "fa-fast-forward", value: "" },
  { label: "fa-fax", value: "" },
  { label: "fa-feather", value: "" },
  { label: "fa-female", value: "" },
  { label: "fa-fighter-jet", value: "" },
  { label: "fa-file", value: "" },
  { label: "fa-file-alt", value: "" },
  { label: "fa-file-archive", value: "" },
  { label: "fa-file-audio", value: "" },
  { label: "fa-file-code", value: "" },
  { label: "fa-file-excel", value: "" },
  { label: "fa-file-image", value: "" },
  { label: "fa-file-medical", value: "" },
  { label: "fa-file-medical-alt", value: "" },
  { label: "fa-file-pdf", value: "" },
  { label: "fa-file-powerpoint", value: "" },
  { label: "fa-file-video", value: "" },
  { label: "fa-file-word", value: "" },
  { label: "fa-film", value: "" },
  { label: "fa-filter", value: "" },
  { label: "fa-fire", value: "" },
  { label: "fa-fire-extinguisher", value: "" },
  { label: "fa-first-aid", value: "" },
  { label: "fa-flag", value: "" },
  { label: "fa-flag-checkered", value: "" },
  { label: "fa-flask", value: "" },
  { label: "fa-folder", value: "" },
  { label: "fa-folder-open", value: "" },
  { label: "fa-font", value: "" },
  { label: "fa-font-awesome-logo-full", value: "" },
  { label: "fa-football-ball", value: "" },
  { label: "fa-forward", value: "" },
  { label: "fa-frog", value: "" },
  { label: "fa-frown", value: "" },
  { label: "fa-futbol", value: "" },
  { label: "fa-gamepad", value: "" },
  { label: "fa-gas-pump", value: "" },
  { label: "fa-gavel", value: "" },
  { label: "fa-gem", value: "" },
  { label: "fa-genderless", value: "" },
  { label: "fa-gift", value: "" },
  { label: "fa-glass-martini", value: "" },
  { label: "fa-glasses", value: "" },
  { label: "fa-globe", value: "" },
  { label: "fa-golf-ball", value: "" },
  { label: "fa-graduation-cap", value: "" },
  { label: "fa-greater-than", value: "" },
  { label: "fa-greater-than-equal", value: "" },
  { label: "fa-h-square", value: "" },
  { label: "fa-hand-holding", value: "" },
  { label: "fa-hand-holding-heart", value: "" },
  { label: "fa-hand-holding-usd", value: "" },
  { label: "fa-hand-lizard", value: "" },
  { label: "fa-hand-paper", value: "" },
  { label: "fa-hand-peace", value: "" },
  { label: "fa-hand-point-down", value: "" },
  { label: "fa-hand-point-left", value: "" },
  { label: "fa-hand-point-right", value: "" },
  { label: "fa-hand-point-up", value: "" },
  { label: "fa-hand-pointer", value: "" },
  { label: "fa-hand-rock", value: "" },
  { label: "fa-hand-scissors", value: "" },
  { label: "fa-hand-spock", value: "" },
  { label: "fa-hands", value: "" },
  { label: "fa-hands-helping", value: "" },
  { label: "fa-handshake", value: "" },
  { label: "fa-hashtag", value: "" },
  { label: "fa-hdd", value: "" },
  { label: "fa-heading", value: "" },
  { label: "fa-headphones", value: "" },
  { label: "fa-heart", value: "" },
  { label: "fa-heartbeat", value: "" },
  { label: "fa-helicopter", value: "" },
  { label: "fa-history", value: "" },
  { label: "fa-hockey-puck", value: "" },
  { label: "fa-home", value: "" },
  { label: "fa-hospital", value: "" },
  { label: "fa-hospital-alt", value: "" },
  { label: "fa-hospital-symbol", value: "" },
  { label: "fa-hourglass", value: "" },
  { label: "fa-hourglass-end", value: "" },
  { label: "fa-hourglass-half", value: "" },
  { label: "fa-hourglass-start", value: "" },
  { label: "fa-i-cursor", value: "" },
  { label: "fa-id-badge", value: "" },
  { label: "fa-id-card", value: "" },
  { label: "fa-id-card-alt", value: "" },
  { label: "fa-image", value: "" },
  { label: "fa-images", value: "" },
  { label: "fa-inbox", value: "" },
  { label: "fa-indent", value: "" },
  { label: "fa-industry", value: "" },
  { label: "fa-infinity", value: "" },
  { label: "fa-info", value: "" },
  { label: "fa-info-circle", value: "" },
  { label: "fa-italic", value: "" },
  { label: "fa-key", value: "" },
  { label: "fa-keyboard", value: "" },
  { label: "fa-kiwi-bird", value: "" },
  { label: "fa-language", value: "" },
  { label: "fa-laptop", value: "" },
  { label: "fa-leaf", value: "" },
  { label: "fa-lemon", value: "" },
  { label: "fa-less-than", value: "" },
  { label: "fa-less-than-equal", value: "" },
  { label: "fa-level-down-alt", value: "" },
  { label: "fa-level-up-alt", value: "" },
  { label: "fa-life-ring", value: "" },
  { label: "fa-lightbulb", value: "" },
  { label: "fa-link", value: "" },
  { label: "fa-lira-sign", value: "" },
  { label: "fa-list", value: "" },
  { label: "fa-list-alt", value: "" },
  { label: "fa-list-ol", value: "" },
  { label: "fa-list-ul", value: "" },
  { label: "fa-location-arrow", value: "" },
  { label: "fa-lock", value: "" },
  { label: "fa-lock-open", value: "" },
  { label: "fa-long-arrow-alt-down", value: "" },
  { label: "fa-long-arrow-alt-left", value: "" },
  { label: "fa-long-arrow-alt-right", value: "" },
  { label: "fa-long-arrow-alt-up", value: "" },
  { label: "fa-low-vision", value: "" },
  { label: "fa-magic", value: "" },
  { label: "fa-magnet", value: "" },
  { label: "fa-male", value: "" },
  { label: "fa-map", value: "" },
  { label: "fa-map-marker", value: "" },
  { label: "fa-map-marker-alt", value: "" },
  { label: "fa-map-pin", value: "" },
  { label: "fa-map-signs", value: "" },
  { label: "fa-mars", value: "" },
  { label: "fa-mars-double", value: "" },
  { label: "fa-mars-stroke", value: "" },
  { label: "fa-mars-stroke-h", value: "" },
  { label: "fa-mars-stroke-v", value: "" },
  { label: "fa-medkit", value: "" },
  { label: "fa-meh", value: "" },
  { label: "fa-memory", value: "" },
  { label: "fa-mercury", value: "" },
  { label: "fa-microchip", value: "" },
  { label: "fa-microphone", value: "" },
  { label: "fa-microphone-alt", value: "" },
  { label: "fa-microphone-alt-slash", value: "" },
  { label: "fa-microphone-slash", value: "" },
  { label: "fa-minus", value: "" },
  { label: "fa-minus-circle", value: "" },
  { label: "fa-minus-square", value: "" },
  { label: "fa-mobile", value: "" },
  { label: "fa-mobile-alt", value: "" },
  { label: "fa-money-bill", value: "" },
  { label: "fa-money-bill-alt", value: "" },
  { label: "fa-money-bill-wave", value: "" },
  { label: "fa-money-bill-wave-alt", value: "" },
  { label: "fa-money-check", value: "" },
  { label: "fa-money-check-alt", value: "" },
  { label: "fa-moon", value: "" },
  { label: "fa-motorcycle", value: "" },
  { label: "fa-mouse-pointer", value: "" },
  { label: "fa-music", value: "" },
  { label: "fa-neuter", value: "" },
  { label: "fa-newspaper", value: "" },
  { label: "fa-not-equal", value: "" },
  { label: "fa-notes-medical", value: "" },
  { label: "fa-object-group", value: "" },
  { label: "fa-object-ungroup", value: "" },
  { label: "fa-outdent", value: "" },
  { label: "fa-paint-brush", value: "" },
  { label: "fa-palette", value: "" },
  { label: "fa-pallet", value: "" },
  { label: "fa-paper-plane", value: "" },
  { label: "fa-paperclip", value: "" },
  { label: "fa-parachute-box", value: "" },
  { label: "fa-paragraph", value: "" },
  { label: "fa-parking", value: "" },
  { label: "fa-paste", value: "" },
  { label: "fa-pause", value: "" },
  { label: "fa-pause-circle", value: "" },
  { label: "fa-paw", value: "" },
  { label: "fa-pen-square", value: "" },
  { label: "fa-pencil-alt", value: "" },
  { label: "fa-people-carry", value: "" },
  { label: "fa-percent", value: "" },
  { label: "fa-percentage", value: "" },
  { label: "fa-phone", value: "" },
  { label: "fa-phone-slash", value: "" },
  { label: "fa-phone-square", value: "" },
  { label: "fa-phone-volume", value: "" },
  { label: "fa-piggy-bank", value: "" },
  { label: "fa-pills", value: "" },
  { label: "fa-plane", value: "" },
  { label: "fa-play", value: "" },
  { label: "fa-play-circle", value: "" },
  { label: "fa-plug", value: "" },
  { label: "fa-plus", value: "" },
  { label: "fa-plus-circle", value: "" },
  { label: "fa-plus-square", value: "" },
  { label: "fa-podcast", value: "" },
  { label: "fa-poo", value: "" },
  { label: "fa-portrait", value: "" },
  { label: "fa-pound-sign", value: "" },
  { label: "fa-power-off", value: "" },
  { label: "fa-prescription-bottle", value: "" },
  { label: "fa-prescription-bottle-alt", value: "" },
  { label: "fa-print", value: "" },
  { label: "fa-procedures", value: "" },
  { label: "fa-project-diagram", value: "" },
  { label: "fa-puzzle-piece", value: "" },
  { label: "fa-qrcode", value: "" },
  { label: "fa-question", value: "" },
  { label: "fa-question-circle", value: "" },
  { label: "fa-quidditch", value: "" },
  { label: "fa-quote-left", value: "" },
  { label: "fa-quote-right", value: "" },
  { label: "fa-random", value: "" },
  { label: "fa-receipt", value: "" },
  { label: "fa-recycle", value: "" },
  { label: "fa-redo", value: "" },
  { label: "fa-redo-alt", value: "" },
  { label: "fa-registered", value: "" },
  { label: "fa-reply", value: "" },
  { label: "fa-reply-all", value: "" },
  { label: "fa-retweet", value: "" },
  { label: "fa-ribbon", value: "" },
  { label: "fa-road", value: "" },
  { label: "fa-robot", value: "" },
  { label: "fa-rocket", value: "" },
  { label: "fa-rss", value: "" },
  { label: "fa-rss-square", value: "" },
  { label: "fa-ruble-sign", value: "" },
  { label: "fa-ruler", value: "" },
  { label: "fa-ruler-combined", value: "" },
  { label: "fa-ruler-horizontal", value: "" },
  { label: "fa-ruler-vertical", value: "" },
  { label: "fa-rupee-sign", value: "" },
  { label: "fa-save", value: "" },
  { label: "fa-school", value: "" },
  { label: "fa-screwdriver", value: "" },
  { label: "fa-search", value: "" },
  { label: "fa-search-minus", value: "" },
  { label: "fa-search-plus", value: "" },
  { label: "fa-seedling", value: "" },
  { label: "fa-server", value: "" },
  { label: "fa-share", value: "" },
  { label: "fa-share-alt", value: "" },
  { label: "fa-share-alt-square", value: "" },
  { label: "fa-share-square", value: "" },
  { label: "fa-shekel-sign", value: "" },
  { label: "fa-shield-alt", value: "" },
  { label: "fa-ship", value: "" },
  { label: "fa-shipping-fast", value: "" },
  { label: "fa-shoe-prints", value: "" },
  { label: "fa-shopping-bag", value: "" },
  { label: "fa-shopping-basket", value: "" },
  { label: "fa-shopping-cart", value: "" },
  { label: "fa-shower", value: "" },
  { label: "fa-sign", value: "" },
  { label: "fa-sign-in-alt", value: "" },
  { label: "fa-sign-language", value: "" },
  { label: "fa-sign-out-alt", value: "" },
  { label: "fa-signal", value: "" },
  { label: "fa-sitemap", value: "" },
  { label: "fa-skull", value: "" },
  { label: "fa-sliders-h", value: "" },
  { label: "fa-smile", value: "" },
  { label: "fa-smoking", value: "" },
  { label: "fa-smoking-ban", value: "" },
  { label: "fa-snowflake", value: "" },
  { label: "fa-sort", value: "" },
  { label: "fa-sort-alpha-down", value: "" },
  { label: "fa-sort-alpha-up", value: "" },
  { label: "fa-sort-amount-down", value: "" },
  { label: "fa-sort-amount-up", value: "" },
  { label: "fa-sort-down", value: "" },
  { label: "fa-sort-numeric-down", value: "" },
  { label: "fa-sort-numeric-up", value: "" },
  { label: "fa-sort-up", value: "" },
  { label: "fa-space-shuttle", value: "" },
  { label: "fa-spinner", value: "" },
  { label: "fa-square", value: "" },
  { label: "fa-square-full", value: "" },
  { label: "fa-star", value: "" },
  { label: "fa-star-half", value: "" },
  { label: "fa-step-backward", value: "" },
  { label: "fa-step-forward", value: "" },
  { label: "fa-stethoscope", value: "" },
  { label: "fa-sticky-note", value: "" },
  { label: "fa-stop", value: "" },
  { label: "fa-stop-circle", value: "" },
  { label: "fa-stopwatch", value: "" },
  { label: "fa-store", value: "" },
  { label: "fa-store-alt", value: "" },
  { label: "fa-stream", value: "" },
  { label: "fa-street-view", value: "" },
  { label: "fa-strikethrough", value: "" },
  { label: "fa-stroopwafel", value: "" },
  { label: "fa-subscript", value: "" },
  { label: "fa-subway", value: "" },
  { label: "fa-suitcase", value: "" },
  { label: "fa-sun", value: "" },
  { label: "fa-superscript", value: "" },
  { label: "fa-sync", value: "" },
  { label: "fa-sync-alt", value: "" },
  { label: "fa-syringe", value: "" },
  { label: "fa-table", value: "" },
  { label: "fa-table-tennis", value: "" },
  { label: "fa-tablet", value: "" },
  { label: "fa-tablet-alt", value: "" },
  { label: "fa-tablets", value: "" },
  { label: "fa-tachometer-alt", value: "" },
  { label: "fa-tag", value: "" },
  { label: "fa-tags", value: "" },
  { label: "fa-tape", value: "" },
  { label: "fa-tasks", value: "" },
  { label: "fa-taxi", value: "" },
  { label: "fa-terminal", value: "" },
  { label: "fa-text-height", value: "" },
  { label: "fa-text-width", value: "" },
  { label: "fa-th", value: "" },
  { label: "fa-th-large", value: "" },
  { label: "fa-th-list", value: "" },
  { label: "fa-thermometer", value: "" },
  { label: "fa-thermometer-empty", value: "" },
  { label: "fa-thermometer-full", value: "" },
  { label: "fa-thermometer-half", value: "" },
  { label: "fa-thermometer-quarter", value: "" },
  { label: "fa-thermometer-three-quarters", value: "" },
  { label: "fa-thumbs-down", value: "" },
  { label: "fa-thumbs-up", value: "" },
  { label: "fa-thumbtack", value: "" },
  { label: "fa-ticket-alt", value: "" },
  { label: "fa-times", value: "" },
  { label: "fa-times-circle", value: "" },
  { label: "fa-tint", value: "" },
  { label: "fa-toggle-off", value: "" },
  { label: "fa-toggle-on", value: "" },
  { label: "fa-toolbox", value: "" },
  { label: "fa-trademark", value: "" },
  { label: "fa-train", value: "" },
  { label: "fa-transgender", value: "" },
  { label: "fa-transgender-alt", value: "" },
  { label: "fa-trash", value: "" },
  { label: "fa-trash-alt", value: "" },
  { label: "fa-tree", value: "" },
  { label: "fa-trophy", value: "" },
  { label: "fa-truck", value: "" },
  { label: "fa-truck-loading", value: "" },
  { label: "fa-truck-moving", value: "" },
  { label: "fa-tshirt", value: "" },
  { label: "fa-tty", value: "" },
  { label: "fa-tv", value: "" },
  { label: "fa-umbrella", value: "" },
  { label: "fa-underline", value: "" },
  { label: "fa-undo", value: "" },
  { label: "fa-undo-alt", value: "" },
  { label: "fa-universal-access", value: "" },
  { label: "fa-university", value: "" },
  { label: "fa-unlink", value: "" },
  { label: "fa-unlock", value: "" },
  { label: "fa-unlock-alt", value: "" },
  { label: "fa-upload", value: "" },
  { label: "fa-user", value: "" },
  { label: "fa-user-alt", value: "" },
  { label: "fa-user-alt-slash", value: "" },
  { label: "fa-user-astronaut", value: "" },
  { label: "fa-user-check", value: "" },
  { label: "fa-user-circle", value: "" },
  { label: "fa-user-clock", value: "" },
  { label: "fa-user-cog", value: "" },
  { label: "fa-user-edit", value: "" },
  { label: "fa-user-friends", value: "" },
  { label: "fa-user-graduate", value: "" },
  { label: "fa-user-lock", value: "" },
  { label: "fa-user-md", value: "" },
  { label: "fa-user-minus", value: "" },
  { label: "fa-user-ninja", value: "" },
  { label: "fa-user-plus", value: "" },
  { label: "fa-user-secret", value: "" },
  { label: "fa-user-shield", value: "" },
  { label: "fa-user-slash", value: "" },
  { label: "fa-user-tag", value: "" },
  { label: "fa-user-tie", value: "" },
  { label: "fa-user-times", value: "" },
  { label: "fa-users", value: "" },
  { label: "fa-users-cog", value: "" },
  { label: "fa-utensil-spoon", value: "" },
  { label: "fa-utensils", value: "" },
  { label: "fa-venus", value: "" },
  { label: "fa-venus-double", value: "" },
  { label: "fa-venus-mars", value: "" },
  { label: "fa-vial", value: "" },
  { label: "fa-vials", value: "" },
  { label: "fa-video", value: "" },
  { label: "fa-video-slash", value: "" },
  { label: "fa-volleyball-ball", value: "" },
  { label: "fa-volume-down", value: "" },
  { label: "fa-volume-off", value: "" },
  { label: "fa-volume-up", value: "" },
  { label: "fa-walking", value: "" },
  { label: "fa-wallet", value: "" },
  { label: "fa-warehouse", value: "" },
  { label: "fa-weight", value: "" },
  { label: "fa-wheelchair", value: "" },
  { label: "fa-wifi", value: "" },
  { label: "fa-window-close", value: "" },
  { label: "fa-window-maximize", value: "" },
  { label: "fa-window-minimize", value: "" },
  { label: "fa-window-restore", value: "" },
  { label: "fa-wine-glass", value: "" },
  { label: "fa-won-sign", value: "" },
  { label: "fa-wrench", value: "" },
  { label: "fa-x-ray", value: "" },
  { label: "fa-yen-sign", value: "" },
];
const validatedIcons = rawIcons.map((i) => {
  return { label: i.label, value: i.label };
});
export default validatedIcons;
