import React, { memo, useState, useEffect, useCallback } from "react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import Tabs from 'devextreme-react/tabs';
import ControlRelationDataFetcher from "./ControlRelationDataFetcher";
import { useGraphContext } from "../graph/context";

const TabBlock = ({
    width,
    controlTabId,
    detailviewId,
    tableitemId,
    tableId,
}) => {
    const [loading, setLoading] = useState(true);
    const [blockData, setBlockData] = useState({}); // this {} contains columns[] which holds the ids and names of the tabs
    const [activeControlRelationId, setActiveControlRelationId] = useState(null);
    const { currentUser } = useGraphContext();
    const [inlineEditMode, setInlineEditMode] = useState();

    const getTabBlockData = useCallback(async () => {
        setLoading(true);
        try {
            const res = await msalFetch(null, `${appSettings.api.endpoint}/api/${detailviewId}/tab/${controlTabId}/`);
            const data = await res.json();
            if (!res.ok) {
                throw new Error(`[TabBlock] getTabBlockData response was not ok: ${data.message}`);
            }
            setBlockData(data);
            setActiveControlRelationId(data.columns[0].controlRelationId); // set initial tab
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }, [detailviewId, controlTabId]);

    useEffect(() => {
        getTabBlockData();
    }, [getTabBlockData]);

    const handleTabClick = useCallback((controlRelationId) => {
        setInlineEditMode(false);
        setActiveControlRelationId(controlRelationId);
    }, []);

    // we cannot trust sort. A new entry will NOT be added zero-based, while re-sorting does set zero-based numbers
    // so we can end up with [0, 1, 2, 4]
    const tabs = blockData.columns?.map(({ name, controlRelationId }) => ({
        id: controlRelationId,
        text: name,

    })) || [];
    const currentTab = tabs.find(({ id }) => id === activeControlRelationId);

    if (loading) {
        return (
            <Col lg={width} md={12} key={controlTabId} className={`bl-col-padding`}>
                <section className="bl-card Relationblockplaceholder">

                </section>
            </Col>
        );
    } else {
        return (
            <Col lg={width} md={12} key={controlTabId} className={`bl-col-padding`}>
                <section className={`bl-detail-column bl-card ${inlineEditMode ? "inlineEditMode" : ""}`}>
                    {tabs.length > 1 && (
                        <header className="bloom-tabs">
                            <nav className="">
                                <Tabs
                                    selectedItem={currentTab}
                                    items={tabs}
                                    keyExpr="id"

                                    scrollByContent

                                    stylingMode="primary"
                                    onItemClick={({ itemData }) => {
                                        handleTabClick(itemData.id);
                                    }}
                                />
                            </nav>
                        </header>
                    )}

                    <section className="bl-tab-block-content-wrapper">
                        <ControlRelationDataFetcher
                            detailviewId={detailviewId}
                            controlRelationId={activeControlRelationId}
                            tableItemId={tableitemId}
                            setInlineEditMode={setInlineEditMode}
                            inlineEditMode={inlineEditMode}
                        />

                    </section>
                    {currentUser?.admin === 1 && (
                        <Link
                            to={`/configurator/dataset/${tableId}/1/set/${controlTabId}/`}
                        >
                            <div className="control-property-configurator-icon"><i className={"fa-regular fa-cog"} /></div>

                        </Link>
                    )}
                </section>
            </Col>
        );
    }
};

export default memo(TabBlock);
