import { Column, RequiredRule, } from "devextreme-react/data-grid";
import { getFormatter } from '../../formats';

const renderDecimalColumn = (name, alias, width, key, readonly, mandatory, inlineEditMode, displayFormat, sortIndex, sortDirection, groupIndex) => {
    const format = getFormatter(displayFormat);
    // console.log(renderDecimalColumn)
    return (
        <Column
            key={key}
            dataField={name.toLowerCase()}
            format={format}
            alignment="right"
            caption={alias}
            allowSearch={true}
            dataType="number"
            allowEditing={!readonly}
            width={width}

            editorOptions={{ format: format }}
            mandatory={mandatory}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            sortIndex={sortIndex}
            sortOrder={sortDirection}
            allowGrouping={true}
            groupIndex={groupIndex}
        >
            {mandatory && <RequiredRule />}
        </Column>
    );
};

export default renderDecimalColumn;
