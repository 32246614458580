import React, { memo, useState, useEffect, useRef, useCallback } from "react";
import { Tooltip } from 'devextreme-react/tooltip';
import { Resizable } from "devextreme-react";
import { TextArea, Button as textAreaButton } from "devextreme-react/text-area";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from 'devextreme-react/button';
import Col from "react-bootstrap/Col";
import { useGraphContext } from "../graph/context";
import FieldLabel from "../detailview/FieldLabel";

import "./properties.scss";

const TEXTAREA = ({
    value,
    alias,
    height,
    keyNumber,
    columnId,
    readonly,
    mandatory,
    registerLoaded,
    onControlUpdate,
    memoField,
}) => {
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const textAreaFocus = useRef(null);


    const [shouldSetCursorTo, setShouldSetCursorTo] = useState(false);
    const { currentUser } = useGraphContext();

    //when users types value, function that updates the formobject
    const handleValueChange = useCallback((e) => {
        onControlUpdate(columnId, e.value || null);
    }, [onControlUpdate, columnId]);

    //when timestamp has been created, function that sets the cursor after the timestamp
    const setCursorPosition = (pos) => {
        const ctrl = textAreaFocus.current?.instance().element().querySelector('.dx-texteditor-input');
        // Modern browsers
        if (ctrl.setSelectionRange) {
            ctrl.setSelectionRange(pos, pos);
            ctrl.focus();

            // IE8 and below
        } else if (ctrl.createTextRange) {
            var range = ctrl.createTextRange();
            range.collapse(true);
            range.moveEnd("character", pos);
            range.moveStart("character", pos);
            range.select();
        }
    };

    // Creates a timestamp of users name, date and time
    const createTimeStamp = useCallback(() => {
        let quickDate = new Date().toLocaleDateString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });
        let quickTime = new Date().toLocaleTimeString();
        let stampString = `[${currentUser?.firstname} ${currentUser?.name} @ ${quickDate} ${quickTime}]`;
        // prettier-ignore
        let newValue = `${stampString}


${value ? value : ''}`; // THIS SHOULD BE FORMATTED TO THE EXTREME LEFT!! Please don't commit whitespace here.

        onControlUpdate(columnId, newValue)

        setShouldSetCursorTo(stampString.length + 1);
    }, [columnId, currentUser?.firstname, currentUser?.name, value, onControlUpdate]);

    useEffect(() => {
        if (shouldSetCursorTo) {
            setCursorPosition(shouldSetCursorTo);
            textAreaFocus.current.scrollTop = 0;
            setShouldSetCursorTo(null);
        }
    }, [shouldSetCursorTo]);

    const handleExpandMemo = () => {
        textAreaFocus.current.rows = 30;
    };
    const handleCollapseMemo = () => {
        textAreaFocus.current.rows = height;
    };
    const stylObj = { height: (height * 22) + 'px' }

    useEffect(() => {
        onInitialized()
    }, [onInitialized]);

    return (
        <Form.Group>
            <Row>
                <Col md="4" >
                    <FieldLabel
                        value={value}
                        label={alias}
                        columnId={columnId}
                        memoField={memoField}

                    />

                </Col>
                <Col md="8" className="bloom-memo-header">
                    <Button
                        stylingMode="text"
                        tabIndex="-1"
                        type="normal"
                        hint={"Voeg een tijdstempel toe"}
                        icon="fa-regular fa-clock"
                        accessKey="T"
                        onClick={createTimeStamp}

                    ></Button>
                </Col>
                <Col md="12">
                    <Resizable
                        minHeight={100}
                        style={stylObj}
                        maxHeight={600}
                        handles={"bottom"}
                        area='.widget-container .dx-field'
                    >
                        <TextArea
                            value={value}
                            height={"100%"}
                            spellcheck={true}
                            placeholder={alias}
                            onValueChanged={handleValueChange}
                            valueChangeEvent="input"
                            readOnly={readonly}
                            stylingMode={readonly ? "filled" : "outlined"}

                            ref={textAreaFocus}
                        />
                    </Resizable>
                </Col>
            </Row>
        </Form.Group >
    );
}

export default memo(TEXTAREA);
