import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState, useCallback } from "react";
import msalFetch from "../../../../api/MsalFetch.js";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import TextBox from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react";
import appSettings from "../../../../appsettings";

const FTP = ({ fieldValue, setFieldValue, checkChanges }) => {
    const msal = useMsal();
    const [ftpServers, setFtpServers] = useState([]);

    const getFtpServers = useCallback(async () => {
        try {
            const response = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/organisationSettings/ftpSettings/True`
            );
            const data = await response.json();
            //let newData = [...data];
            const newData = data.map(item => { return { ...item, name: `${item.name} (${item.server})` } });
            setFtpServers(newData);
            return data;
        } catch (error) {
            throw "Data Loading Error";
        }
    }, []);

    useEffect(() => {
        getFtpServers();
        let tempFieldValue = fieldValue;
        setFieldValue(tempFieldValue);
    }, [fieldValue, getFtpServers, setFieldValue]);

    return (
        <>
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Server</Form.Label>
                    </Col>
                    <Col lg="12">
                        <SelectBox
                            defaultValue={fieldValue.ftpServerId }
                            onValueChanged={async (e) => {
                                console.log("FtpServer value changed - ", e.value);
                                let tempFieldValue = fieldValue;
                                tempFieldValue["ftpServerId"] = e.value;
                                setFieldValue(tempFieldValue);
                                checkChanges();
                            }}
                            items={ftpServers}
                            valueExpr="id"
                            displayExpr="name"
                        />
                    </Col>
                </Row>
            </Form.Group><Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>FTP-filepath</Form.Label>
                    </Col>
                    <Col lg="12">
                        <TextBox
                            defaultValue={fieldValue.ftpFilePath}
                            type="input"
                            valueChangeEvent="keyup"
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["ftpFilePath"] = e.value;
                                setFieldValue(tempFieldValue);
                                console.log("ftpFilePath = ", fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>HTTP-filepath</Form.Label>
                    </Col>
                    <Col lg="12">
                        <TextBox
                            defaultValue={fieldValue.httpFilePath}
                            type="input"
                            valueChangeEvent="keyup"
                            onValueChanged={(e) => {
                                let tempFieldValue = fieldValue;
                                tempFieldValue["httpFilePath"] = e.value;
                                setFieldValue(tempFieldValue);
                                console.log("ftpFilePath = ", fieldValue);
                                checkChanges();
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>
        </>
    );
};

export default FTP;
