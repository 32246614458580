import React, { useState, useEffect } from "react";
import appSettings from "../../../appsettings";
import ModalControls from "../parts/modalControls/ModalControls";
import { Col, Row } from "react-bootstrap";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest, graphConfig } from "../../../msalConfig";
import Form from "react-bootstrap/Form";
import msalFetch from "../../../api/MsalFetch.js";

import toast, { Toaster } from "react-hot-toast";

import { useModalContext } from "../../../context/modalContext";
import { TextBox, SelectBox, CheckBox } from "devextreme-react";

import Loading from "../../loading/Loading";
import { min } from "moment";

const AddUser = () => {
    const msal = useMsal();
    const [changeInForm, setChangeInForm] = useState(false);
    const [roles, setRoles] = useState();
    const [loading, setLoading] = useState(true);

    const {
        setIsEditing,
        setFormInValidNotification,
        setNotificationData,
        isEditing,
    } = useModalContext();

    const getRoles = async () => {
        setLoading(true);
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/role`
            );
            const data = await response.json();

            setRoles(data);

            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    };

    const [form, setForm] = useState({ admin: 0 });
    //console.log(form);
    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const saveFunction = async () => {
        let allDocument = document.getElementsByClassName("bl-mandatory-field");
        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);

            return null;
        }

        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/user/`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(form),
                }
            );
            if (res.ok) {
                setChangeInForm(false);
                setIsEditing(false);
                return toast.success("De gegevens zijn successvol opgeslagen");
            }
            else {
                toast.error("Oeps er is iets mis gegaan...");
            }
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    useEffect(() => {
        getRoles();
    }, []);

    if (loading) {
        return <Loading />;
    } else {
        return (
            <main className="bl-modal-inner">
                <ModalControls
                    modalTitle="Gebruiker toevoegen"
                    changeInForm={changeInForm}
                    handleSaveClick={saveFunction}
                />
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Email</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Email"
                                className="bl-mandatory-field"
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    if (e.value.includes("@" && ".")) {
                                        e.element.classList.remove("bl-mandatory-field");
                                    } else {
                                        e.element.classList.add("bl-mandatory-field");
                                    }

                                    let tempForm = form;
                                    tempForm["email"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Voornaam</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Voornaam"
                                className="bl-mandatory-field"
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    if (e.value.trim() !== "") {
                                        e.element.classList.remove("bl-mandatory-field");
                                    } else {
                                        e.element.classList.add("bl-mandatory-field");
                                    }

                                    let tempForm = form;
                                    tempForm["firstname"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Achternaam</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Achternaam"
                                className="bl-mandatory-field"
                                valueChangeEvent="keyup"
                                onValueChanged={(e) => {
                                    if (e.value.trim() !== "") {
                                        e.element.classList.remove("bl-mandatory-field");
                                    } else {
                                        e.element.classList.add("bl-mandatory-field");
                                    }

                                    let tempForm = form;
                                    tempForm["name"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                    console.log(form);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Rol</Form.Label>
                        </Col>
                        <Col lg="12">
                            <SelectBox
                                searchEnabled
                                items={roles}
                                valueExpr="id"
                                displayExpr="name"
                                className="bl-mandatory-field"
                                //defaultValue={modalContent.role}
                                onValueChanged={(e) => {
                                    console.log(e);
                                    if (e.value != null) {
                                        e.element.classList.remove("bl-mandatory-field");
                                    } else {
                                        e.element.classList.add("bl-mandatory-field");
                                    }
                                    let tempForm = form;
                                    tempForm["role"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Admin</Form.Label>
                        </Col>
                        <Col lg="12">
                            <CheckBox
                                defaultValue={false}
                                onValueChanged={(e) => {
                                    console.log(e);

                                    let tempForm = form;
                                    tempForm["admin"] = e.value === true ? 1 : 0;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>

                </Form.Group>
            </main>
        );
    }
};
export default AddUser;
