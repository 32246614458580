export const ColumnType = {
    TXT: 1,
    EMAIL: 2,
    Id: 3,
    MEMO: 4,
    NUM: 5,
    EU: 6,
    JN: 7,
    AU: 8,
    D: 9,
    DT: 10,
    KL: 11,
    ZKL: 13,
    AVAK: 14,
    AVAKZ: 15,
    FILE: 16,
    DEC: 17,
    GUID: 18,
    USRS: 19,
    COLOR: 20,
    COMPOSITE: 21,
    HTMLVIEWER: 22,
    AUTONUMMERING: 23,
    CALCULATED: 24,
    SIGNATURE: 25,
    ACTION: 26,
    AVEV: 27,
    MULTIPLEKEUZELIJST: 28,
    HTMLEDITOR: 31,
    HTMLEDITORADVANCED: 58,
    PERC: 61,
    FTP: 62,
    SELECT: 63,
}
