import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import appSettings from "../../../appsettings";
import ModalControls from "../parts/modalControls/ModalControls";
import { Col, Row } from "react-bootstrap";

import Form from "react-bootstrap/Form";

import { useHistory } from "react-router";
import msalFetch from "../../../api/MsalFetch.js";
import toast, { Toaster } from "react-hot-toast";

import { useModalContext } from "../../../context/modalContext";
import { TextBox } from "devextreme-react";
import Loading from "../../loading/Loading";

const DuplicateRole = () => {
    const msal = useMsal();
    const {
        setIsEditing,
        setFormInValidNotification,
        setNotificationData,
        isEditing,
        modalContent,
    } = useModalContext();
    const link = useHistory();
    const [changeInForm, setChangeInForm] = useState(false);
    const [form, setForm] = useState({
        detailview: modalContent.detailview,
        overview: modalContent.overview,
        roleTable: modalContent.roleTable,
    });

    const [roleUpdateLoading, setRoleUpdateLoading] = useState(false);

    console.log(form);

    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    //Update the new role with data of duplicate origin
    const updateRole = async (id, tempForm) => {
        setRoleUpdateLoading(true);
        try {
            const requestOptions = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/role/${id}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(tempForm),
                }
            );
            const response = await requestOptions.json();
            if (requestOptions.ok) {
                toast.success("De gegevens zijn successvol opgeslagen...");
                setChangeInForm(false);
                setIsEditing(false);
                link.push(`/configurator/roles/${id}`);
                setRoleUpdateLoading(false);
            } else {
                toast.error("Oeps er is iets mis gegaan...");
            }
            console.log(response);
            return;
        } catch (error) {
            setRoleUpdateLoading(false);
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    // Post a new table and if OK -> update the role with a new body
    const saveFunction = async () => {
        let allDocument = document.getElementsByClassName("bl-mandatory-field");
        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);

            return null;
        }
        let tempForm = form;

        console.log(tempForm);

        try {
            const requestOptions = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/role`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(form),
                }
            );
            if (requestOptions.ok) {
                const response = await requestOptions.json();
                console.log(response);
                let tempForm = form;
                tempForm["id"] = response.id;
                tempForm["name"] = response.name;
                updateRole(response.id, tempForm);
            } else {
                toast.error("Oeps er is iets mis gegaan...");
            }
            
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    return (
        <main className="bl-modal-inner">
            <ModalControls
                modalTitle="Rol kopie"
                changeInForm={changeInForm}
                handleSaveClick={saveFunction}
            />
            <Form.Group>
                <Row>
                    <Col lg="12">
                        <Form.Label>Naam</Form.Label>
                    </Col>
                    <Col lg="12">
                        <TextBox
                            placeholder="Naam..."
                            className="bl-mandatory-field"
                            valueChangeEvent="keyup"
                            onValueChanged={(e) => {
                                let tempForm = form;
                                tempForm["name"] = e.value;
                                checkChanges();
                                setForm(tempForm);

                                e.element.classList.remove("bl-mandatory-field");
                            }}
                        />
                    </Col>
                </Row>
            </Form.Group>

            {roleUpdateLoading && <Loading />}
        </main>
    );
};
export default DuplicateRole;
