import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Popup } from 'devextreme-react/popup';
import appSettings from "../../../appsettings";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { Lookup, TextBox } from "devextreme-react";
import { DropDownOptions } from "devextreme-react/lookup";
import Loading from "../../../../src/components/loading/Loading";
import msalFetch from "../../../../src/api/MsalFetch.js";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import toast from "react-hot-toast";
import { DataGrid } from "devextreme-react";
import { Button as ToolbarButton } from 'devextreme-react/button';
import {
    Column,
    FilterRow,
    HeaderFilter,
    FilterPanel,
    FilterBuilderPopup,
    //Lookup,
    Scrolling,
    Editing,
    Paging,
    LoadPanel,
    Button as DataGridButton,
    Toolbar,
    Item,
} from "devextreme-react/data-grid";

const PopupEditConnector = ({

    selectedConnectorId,
    setSelectedConnectorId,
    schedulerFields,
    submit,
    selectedValues,
    setSelectedValues,
    selectedTableId,
    refresh,
}) => {
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState(null);
    const [name, setName] = useState(selectedConnectorId.name);

    useEffect(() => {
        if (selectedConnectorId?.connectorColumns != null) {
            let temp = {}
            selectedConnectorId.connectorColumns.forEach(item => temp[item.propertyName] = item.columnId);
            setSelectedValues(temp);

        }

    }, [setSelectedValues, selectedConnectorId.connectorColumns])

    const onValueChanged = useCallback((field) => (e) => {

        let temp = { ...selectedValues };
        temp[field] = e.value;
        setSelectedValues(temp);

    }, [selectedValues, setSelectedValues]);

    const itemRender = useCallback((data, index) => {

        return <div className="navsearch-result"><p>{data.name}</p><em>{' (' + data.columnType.name + ')'}</em></div>;
    }, []);

    const loadColumns = useCallback(async () => {

        const url = `${appSettings.api.endpoint}/api/configurator/dataset/${selectedTableId}/column`
        const response = await msalFetch(null, url);
        const json = await response.json();
        setColumns(json);
        return json;
    }, [selectedTableId]);

    useEffect(() => { loadColumns(); }, [loadColumns]);

    const loadUsers = useCallback(async (active) => {
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/connector/loadusers/${selectedConnectorId.id}`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Users] getUsers response was not ok: ${data.message}`);
            }
            return data;
        } catch (error) {
            throw error;
        }
    }, [selectedConnectorId]);
    const popupTitle = selectedConnectorId.name;
    const updateServerRecord = useCallback(async (userId, val) => {

        try {
            let body = {
                userId: userId,
                value: val[Object.keys(val)[0]]
            };
            const url = `${appSettings.api.endpoint}/api/configurator/connector/updateuser/${selectedConnectorId.id}/${body.userId}/${body.value}`;
            console.log("RoleUser - updateServerRecord - url", url);
            const res = await msalFetch(null, url);

            toast.success("Gebruiker is bijgewerkt");
        } catch (error) {
            //toast.error("error");
            throw error;
        }
    }, [selectedConnectorId])

    const dataSourceUsers = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'userId',
            load: loadUsers,
            update: updateServerRecord,
            //remove: deleteServerRecord,
        })
    }), [loadUsers, updateServerRecord]);

    return <Popup
        className="bl-popup"
        title="Connector"
        animation={false}
        showTitle={true}
        resizeEnabled={true}
        visible={true}
        showCloseButton={true}
        onHiding={() => { setSelectedConnectorId(null); }}
        toolbarItems={
            [
                {
                    toolbar: "bottom",
                    widget: "dxButton",
                    location: "center",
                    options:
                    {
                        text: 'Opslaan',
                        onClick: async (e) => {
                            // e.cancel = true;
                            try {
                                await submit(name);
                                toast.success("De connector is opgeslagen");
                                refresh();
                                setSelectedConnectorId(null);
                            } catch (error) {
                                toast.error("Er ging iets mis");
                            }

                        }
                    }
                }

            ]
        }
    >
        {
            (<div>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <h2>Connector</h2>
                        </Col>


                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="4">
                            <Form.Label>Connectornaam</Form.Label>
                        </Col>
                        <Col lg="8">
                            <TextBox
                                placeholder="Naam"
                                value={name}
                                onValueChanged={(e) => { setName(e.value) }}
                                showClearButton={true}
                                valueChangeEvent="input"
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <h2>Kolommen koppelen</h2>
                        </Col>


                    </Row>
                </Form.Group>
                {schedulerFields.map(field => {
                    const connectorColumn = selectedConnectorId.connectorColumns.find(col => col.propertyName.toLowerCase() === field.toLowerCase());

                    return <Form.Group><Row>
                        <Col lg="4">
                            <Form.Label>{field}</Form.Label>
                        </Col>
                        <Col lg="8">
                            <Lookup
                                className="overview-lookup"
                                defaultValue={connectorColumn?.columnId}
                                // value={connectorColumn?.columnId/* 26872 */}
                                dataSource={columns}
                                // onSelectionChanged={(e) => { console.log("onSelectionChanged", e); }}
                                onValueChanged={onValueChanged(field)}
                                cancelButtonText="Annuleren"
                                showCancelButton={false}
                                showClearButton={false}
                                noDataText=""
                                //searchPlaceholder={isSelectSearch ? `Minimaal 1 karakter van ${alias}` : `${alias} zoeken`}
                                valueExpr="id"
                                displayExpr="name"
                                placeholder={/* cell.displayValue */ "Selecteer kolom"}
                                showDataBeforeSearch={true}
                                // minSearchLength={}
                                cleanSearchOnOpening={true}
                                itemRender={itemRender}
                                width="100%"
                            >
                                <DropDownOptions
                                    minHeight="400px"
                                    maxHeight="500px"
                                    showTitle={true}
                                    showCloseButton
                                    title={"Selecteer velden"}
                                    closeOnOutsideClick={true}
                                    minWidth="450px"
                                    maxWidth="650px"

                                />
                            </Lookup>
                        </Col>
                    </Row></Form.Group>

                })}
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <h2>Gebruikers</h2>
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <DataGrid
                                dataSource={dataSourceUsers}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                noDataText="Selecteer een connector"
                                rowAlternationEnabled={true}
                                showColumnLines={false}
                                showRowLines={true}
                                defaultFilterValue={['active', '=', true]}
                                columnAutoWidth={false}

                            >
                                <Editing
                                    mode="cell"
                                    allowUpdating={true}
                                    allowAdding={false}
                                    allowDeleting={false}
                                />

                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                <FilterPanel visible={true} />
                                <Column
                                    caption="Gekoppeld"
                                    dataField="hasConnector"
                                    alignment="center"
                                    dataType="boolean"
                                    allowEditing={true}
                                />
                                <Column
                                    caption="Actief"
                                    dataField="active"
                                    alignment="center"
                                    sortIndex={0}
                                    sortOrder="desc"
                                    dataType="boolean"
                                    allowEditing={false}
                                />
                                <Column
                                    caption="Voornaam"
                                    dataField="firstName"
                                    dataType="string"
                                    minWidth={200}
                                    sortIndex={1}
                                    sortOrder="asc"
                                    allowEditing={false}
                                />
                                <Column
                                    caption="Achternaam"
                                    dataField="name"
                                    dataType="string"
                                    minWidth={200}
                                    allowEditing={false}
                                />
                                <Column
                                    caption="Email"
                                    dataField="email"
                                    dataType="string"
                                    minWidth={200}
                                    allowEditing={false}
                                />
                                <Column cssClass="config-readonly-field"
                                    caption="Laatste sync initieel"
                                    dataField="syncedInitial"
                                    dataType="datetime"
                                    format="dd-MM-yyyy HH:mm"
                                    alignment="right"
                                    allowEditing={false}
                                />
                                <Column cssClass="config-readonly-field"
                                    caption="Laatste sync in"
                                    dataField="lastSyncedIn"
                                    dataType="datetime"
                                    format="dd-MM-yyyy HH:mm"
                                    alignment="right"
                                    allowEditing={false}
                                />
                                <Column cssClass="config-readonly-field"
                                    caption="Laatste sync out"
                                    dataField="lastSyncedOut"
                                    dataType="datetime"
                                    format="dd-MM-yyyy HH:mm"
                                    alignment="right"
                                    allowEditing={false}
                                />

                            </DataGrid>
                        </Col>
                    </Row>
                </Form.Group></div>)
        }
    </Popup >
};

export default PopupEditConnector;
