import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import appSettings from "../../../appsettings";
import ModalControls from "../parts/modalControls/ModalControls";
import { Col, Row } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";
import msalFetch from "../../../api/MsalFetch.js";

import { useModalContext } from "../../../context/modalContext";
import { SelectBox, TextBox, CheckBox } from "devextreme-react";
import Loading from "../../loading/Loading";

const EditUser = () => {
    const msal = useMsal();
    const [changeInForm, setChangeInForm] = useState(false);
    const [roles, setRoles] = useState();
    const [loading, setLoading] = useState();

    const {
        setIsEditing,
        setFormInValidNotification,
        setNotificationData,
        isEditing,
        modalContent,
    } = useModalContext();

    const getRoles = async () => {
        setLoading(true);
        try {
            const response = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/role`
            );
            const data = await response.json();

            setRoles(data);

            setLoading(false);
            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    };

    const [form, setForm] = useState(modalContent);

    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const saveFunction = async () => {
        let allDocument = document.getElementsByClassName("bl-mandatory-field");
        if (allDocument.length > 0) {
            setFormInValidNotification(true);
            setNotificationData(allDocument);

            return null;
        }

        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/user/${modalContent.id}`,
                {
                    method: "POST",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(form),
                }
            );
            if (res.ok) {
                setChangeInForm(false);
                setIsEditing(false);
                return toast.success("De gegevens zijn successvol opgeslagen...");
            } else {
                toast.error("Oeps er is iets mis gegaan...");
            }
            
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            throw error;
        }
    };

    useEffect(() => {
        setForm(modalContent);
        console.log(modalContent);
    }, [isEditing]);

    useEffect(() => {
        getRoles();
    }, []);

    if (loading) {
        return <Loading />;
    } else {
        return (
            <main className="bl-modal-inner" key={isEditing}>
                <ModalControls
                    modalTitle="Gebruiker aanpassen"
                    changeInForm={changeInForm}
                    handleSaveClick={saveFunction}
                />
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Actief</Form.Label>
                        </Col>
                        <Col lg="12">
                            <CheckBox
                                defaultValue={modalContent.active}
                                onValueChanged={(e) => {
                                    console.log(e);

                                    let tempForm = form;
                                    tempForm["active"] = e.value === true ? 1 : 0;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Email</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Email"
                                className={
                                    modalContent.email === "" ? "bl-mandatory-field" : ""
                                }
                                valueChangeEvent="keyup"
                                defaultValue={modalContent.email}
                                onValueChanged={(e) => {
                                    if (e.value.contains("@" && ".")) {
                                        e.element.classList.remove("bl-mandatory-field");
                                    } else {
                                        e.element.classList.add("bl-mandatory-field");
                                    }

                                    let tempForm = form;
                                    tempForm["email"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Voornaam</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Voornaam..."
                                className={
                                    modalContent.firstname === "" ? "bl-mandatory-field" : ""
                                }
                                valueChangeEvent="keyup"
                                defaultValue={modalContent.firstname}
                                onValueChanged={(e) => {
                                    if (e.value.trim() !== "") {
                                        e.element.classList.remove("bl-mandatory-field");
                                    } else {
                                        e.element.classList.add("bl-mandatory-field");
                                    }

                                    let tempForm = form;
                                    tempForm["firstname"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Achternaam</Form.Label>
                        </Col>
                        <Col lg="12">
                            <TextBox
                                placeholder="Achternaam..."
                                className={modalContent.name === "" ? "bl-mandatory-field" : ""}
                                valueChangeEvent="keyup"
                                defaultValue={modalContent.name}
                                onValueChanged={(e) => {
                                    if (e.value.trim() !== "") {
                                        e.element.classList.remove("bl-mandatory-field");
                                    } else {
                                        e.element.classList.add("bl-mandatory-field");
                                    }

                                    let tempForm = form;
                                    tempForm["name"] = e.value;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>

                <Form.Group>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Rol</Form.Label>
                        </Col>
                        <Col lg="12">
                            <SelectBox
                                searchEnabled
                                items={roles}


                                valueExpr="id"
                                displayExpr="name"
                                defaultValue={modalContent.role}
                                onValueChanged={(e) => {
                                    let tempUser = form;
                                    tempUser["role"] = e.value;
                                    checkChanges();
                                    setForm(tempUser);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Admin</Form.Label>
                        </Col>
                        <Col lg="12">
                            <CheckBox
                                defaultValue={modalContent.admin}
                                onValueChanged={(e) => {
                                    console.log(e);

                                    let tempForm = form;
                                    tempForm["admin"] = e.value === true ? 1 : 0;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Form.Label>Documentalist</Form.Label>
                        </Col>
                        <Col lg="12">
                            <CheckBox
                                defaultValue={modalContent.documentarian}
                                onValueChanged={(e) => {
                                    let tempForm = form;
                                    tempForm["documentarian"] = e.value === true ? 1 : 0;
                                    checkChanges();
                                    setForm(tempForm);
                                }}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </main>
        );
    }
};
export default EditUser;
