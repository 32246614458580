// @TODO: validateAgainstColumnId

import React, { memo, useCallback } from "react";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";
import moment from "moment";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useGlobalContext } from "../../context/context";
import { Tooltip } from 'devextreme-react/tooltip';
import FieldLabel from "../detailview/FieldLabel";

const DATE = ({
    type = 'date',
    label,
    displayFormat,
    value,
    readonly,
    mandatory,
    columnId,
    registerLoaded,
    onControlUpdate,
    memoField,
}) => {

    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const handleDateChange = useCallback((e) => {
        console.log("handleDateChange", e.value);
        if (!e.value) {
            return onControlUpdate(columnId, null);
        }
        // parse it as utc, so the hours will not be shifted by moment
        const newValue = moment.parseZone(e.value).utc(true);
        if (type === 'date') {
            newValue.hour(0).minute(0).second(0).millisecond(0);
        }
        const newValueIso = newValue.format('YYYY-MM-DDTHH:mm:ss');
        console.log("e.value", e.value, "newValueIso", newValueIso, "typeof value", typeof e.value);
        onControlUpdate(columnId, newValueIso);
        //onControlUpdate(columnId, newValue);
    }, [onControlUpdate, columnId, type]);
    const { isMobile } = useGlobalContext();
    const todayButton = {
        icon: 'clock',
        stylingMode: "text",
        onClick: () => {
            const newDate = new Date();
            handleDateChange({ value: newDate });
        },
    };

    return (
        <Form.Group>
            <Row>

                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={label}
                        columnId={columnId}
                        memoField={memoField}
                        mandatory={mandatory}

                    />
                </Col>
                <Col md="8">
                    <DateBox

                        calendarOptions={{
                            showWeekNumbers: true,
                        }}

                        applyValueMode={type === "datetime" ? "useButtons" : "instantly"}
                        type={type}
                        showTodayButton={true}
                        todayButtonText="Vandaag"
                        readOnly={readonly}
                        mandatory={mandatory}
                        stylingMode={readonly ? "filled" : "outlined"}
                        value={value}
                        showAnalogClock={true}
                        showClearButton={true}
                        displayFormat={displayFormat ? displayFormat : "dd-MM-yyyy"}
                        onValueChanged={handleDateChange}
                        pickerType={isMobile ? "rollers" : "calendar"}

                        onInitialized={onInitialized}
                    >
                        <DateBoxButton name="clear" location="after" />
                        <DateBoxButton
                            name="vandaag"
                            location="after"
                            options={todayButton}
                        />
                        <DateBoxButton name="dropDown" />
                    </DateBox>
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(DATE);
