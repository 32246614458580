import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGraphContext } from "../graph/context";

import { useGlobalContext } from "../../../src/context/context";
import ButtonGroup, { Item as ButtonGroupItem } from 'devextreme-react/button-group';
import "./Detailview.scss";

const DetailviewControlsNew = ({
    table,
    handleAddNew,
    handleSaveClick,
    handleSave,
    handleSaveAndCreateNew,
    handleClose,
    handleDelete,
    isContentForModal,
    logging,
    setOpenLogging,
    isCreate,
    detailviewId,
    changeInForm = false,
    saveDisabled = false,
    instructions,
    setThrowNotification,
    isMasterDetail,
    refreshGrid,
    forceReload,
}) => {

    const { isMobile, screenWidth } = useGlobalContext();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    return (
        <div className={`${isContentForModal ? "bl-detailview-control-popup" : "bloom-detailview-control"}`}>

            <ButtonGroup
                selectionMode="none"
                stylingMode={isMasterDetail ? "text" : "outlined"}
                focusStateEnabled={false}
                onItemClick={({ event, itemElement }) => {
                    event.preventDefault();
                }}
            >
                {table.permission.create === 1 && !changeInForm && !isCreate && !isMasterDetail && (
                    <ButtonGroupItem
                        icon="fa-solid fa-plus fa-lg"
                        disabled={isCreate}
                        hint="Nieuw item aanmaken"
                        onClick={handleAddNew}
                        type="default"
                    />)}

                {table.permission.update === 1 && changeInForm && (
                    <ButtonGroupItem
                        icon={loading ? "fas fa-pulse fa-spinner" : "fa-solid fa-save fa-lg"}
                        hint="Opslaan"
                        onClick={async () => {
                            setLoading(true);
                            const saveResponse = await handleSaveClick();
                            if (!saveResponse) {
                                setLoading(false);
                                return false;
                            }

                            if (isCreate && !isContentForModal) { // this should not be here, this is not the responsibility of the controls
                                // push to the newly create tableitemId url
                                history.push(`/detail/${detailviewId}/${saveResponse}`);

                            }
                            if (isMasterDetail) {
                                refreshGrid();
                            }
                            else {
                                forceReload(); // f.e. images get thumbnails
                                handleSave && handleSave(saveResponse);
                            }
                            setLoading(false);
                        }}
                        type="default"
                    />)}
                {!isMobile && table.permission.update === 1 && changeInForm && !isMasterDetail && (
                    <ButtonGroupItem
                        icon={loading ? "fas fa-pulse fa-spinner" : "fa-solid fa-floppy-disk-circle-xmark fa-lg"}
                        hint="Opslaan en terug"
                        onClick={async () => {
                            setLoading(true);
                            if (await handleSaveClick()) {
                                if (isContentForModal) {
                                    handleClose && handleClose();
                                } else {
                                    history.goBack();
                                }
                            }
                            setLoading(false);
                        }}
                        type="default"
                    />)}
                {!isMobile && table.permission.update === 1 && table.permission.create === 1 && changeInForm && !isMasterDetail && (
                    <ButtonGroupItem
                        icon={loading ? "fas fa-pulse fa-spinner" : "fa-solid fa-floppy-disk-circle-arrow-right fa-lg"}
                        hint="Opslaan en nieuw"
                        onClick={async () => {
                            setLoading(true);
                            if (await handleSaveClick()) {
                                handleSaveAndCreateNew && handleSaveAndCreateNew();
                            }
                            setLoading(false);
                        }}
                        type="default"
                    />)}
                {!isMasterDetail && (
                    <ButtonGroupItem
                        icon="fa-solid fa-arrow-left"
                        hint="Ga terug"
                        onClick={() => {
                            if (changeInForm) {
                                if (isContentForModal) {
                                    setThrowNotification(true);
                                } else {
                                    setThrowNotification('goBack');
                                }
                            } else if (isContentForModal) {
                                handleClose && handleClose();
                            } else {
                                history.goBack();
                            }
                        }}
                        type="default"
                    />)}

                {table.permission.delete === 1 && !isCreate && !isMasterDetail && (
                    <ButtonGroupItem
                        icon="fas fa-trash"
                        hint="Verwijder dit item"
                        onClick={handleDelete}
                        type="default"

                    />)}
            </ButtonGroup>

        </div >
    );
};

export default DetailviewControlsNew;
