import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Popup } from 'devextreme-react/popup';
import appSettings from "../../../appsettings";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { DropDownOptions } from "devextreme-react/lookup";
import Loading from "../../../../src/components/loading/Loading";
import msalFetch from "../../../../src/api/MsalFetch.js";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import toast from "react-hot-toast";
import { Lookup, TextBox } from "devextreme-react";

const PopupAddConnector = ({
    setAddConnector,
    selectedTableId,
    setSelectedTableId,
    loadConnectors,
}) => {
    // const [loading, setLoading] = useState(false);
    // const [columns, setColumns] = useState(null);
    const [addName, setAddName] = useState();
    const [addType, setAddType] = useState('OFFICE365');
    const [addEntity, setAddEntity] = useState('CALENDAR');


    const insert = async () => {
        const url = `${appSettings.api.endpoint}/api/configurator/connector/insert`;
        try {
            const res = await msalFetch(null,
                url,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify({
                        type: addType,
                        entity: addEntity,
                        tableId: selectedTableId,
                        name: addName
                    }),
                }
            );
            setAddConnector(false);
            loadConnectors();
            toast.success("Connector toegevoegd");
        } catch (error) {
            toast.error(error);
        }

    };

    const loadTables = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/configurator/table`;
        const response = await msalFetch(null, url);
        const json = await response.json();
        // setTables(json);
        return json;
    }, []);

    const dataSourceTables = useMemo(() => new DataSource({
        store: new CustomStore({
            key: 'id',
            load: loadTables,
            // update: updateServerRecord,
            // remove: deleteServerRecord,
        })
    }), [loadTables]);

    const onSelectedTableChanged = useCallback(async (e) => {
        setSelectedTableId(e.value);
    }, [setSelectedTableId])

    const handleAddName = useCallback((e) => { setAddName(e.value); }, []);

    const handleAddType = useCallback((e) => { setAddType(e.value); }, []);

    const handleAddEntity = useCallback((e) => { setAddEntity(e.value) }, []);

    return <Popup
        title="Toevoegen Connector"
        className="bl-popup"
        showTitle={true}
        resizeEnabled={true}
        dragEnabled={true}
        animation={false}
        visible={true}
        showCloseButton={true}
        onHiding={() => { setAddConnector(false); }}
        toolbarItems={
            [
                {
                    toolbar: "bottom",
                    widget: "dxButton",
                    location: "center",
                    options:
                    {
                        text: 'Opslaan',
                        onClick: (e) => {
                            // e.cancel = true;
                            insert();
                        }
                    }
                }

            ]
        }
    >
        <Form.Group>
            <Row>
                <Col lg="12">
                    <Form.Label>Naam</Form.Label>
                </Col>
                <Col lg="12">
                    <TextBox
                        placeholder="Type"
                        value={addName}
                        onValueChanged={handleAddName}
                        showClearButton={false}
                        valueChangeEvent="input"
                    />
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Form.Label>Tabel</Form.Label>
                </Col>
                <Col lg="12">
                    <Lookup
                        className="overview-lookup"
                        dataSource={dataSourceTables}
                        onSelectionChanged={(e) => { console.log("onSelectionChanged", e); }}
                        onValueChanged={onSelectedTableChanged}
                        cancelButtonText="Annuleren"
                        showCancelButton={false}
                        showClearButton={false}
                        noDataText=""
                        valueExpr="id"
                        displayExpr="tableName"
                        placeholder={"Selecteer tabel..."}
                        showDataBeforeSearch={true}
                        cleanSearchOnOpening={true}
                        width="100%"
                    >
                        <DropDownOptions
                            minHeight="400px"
                            maxHeight="500px"
                            showTitle={true}
                            showCloseButton
                            title={"Selecteer tabel"}
                            closeOnOutsideClick={true}
                            minWidth="450px"
                            maxWidth="650px"

                        />
                    </Lookup>
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Form.Label>Type</Form.Label>
                </Col>
                <Col lg="12">
                    <TextBox
                        placeholder="Type"
                        value={addType}
                        onValueChanged={handleAddType}
                        showClearButton={false}
                        valueChangeEvent="input"
                    />
                </Col>
            </Row>
            <Row>
                <Col lg="12">
                    <Form.Label>Entity</Form.Label>
                </Col>
                <Col lg="12">
                    <TextBox
                        placeholder="Entity"
                        value={addEntity}
                        onValueChanged={handleAddEntity}
                        showClearButton={false}
                        valueChangeEvent="input"
                    />
                </Col>
            </Row>
        </Form.Group>
    </Popup>
};

export default PopupAddConnector;
