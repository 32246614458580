import React, { useEffect, useState } from "react";
import SaveChanges from "./notifications/SaveChanges";

const UserLeave = ({
    handleCancel,
    handleSaveAndContinue,
    handleDiscardAndContinue,
    handleContinue,
}) => (
    <SaveChanges
        handleCancel={handleCancel}
        handleSaveAndContinue={handleSaveAndContinue}
        handleDiscardAndContinue={handleDiscardAndContinue}
        handleContinue={handleContinue}
    />
);

export default UserLeave;
