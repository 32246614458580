//return renderHtmlColumn(name, alias, width);
import { Column, Lookup } from "devextreme-react/data-grid";
import { DropDownOptions } from "devextreme-react/lookup";

//overweeg om 'm bij edit in edit te openen en anders in readonly
const renderDependentColumnEdit = (name, alias, width, key, columnId) => (cell) => {
    
};

const renderHtmlCell = (columnData) => {
    var color = `${(columnData.value ? '#ff9727' : '#ababab')}`;
    return <i className="fa-solid fa-file-code" style={{ color: color, }}  />
}

const renderHtmlColumn = (name, alias, width, key, columnId, inlineEditMode) => {
    return (
        <Column
            key={key}
            dataField={name.toLowerCase()}
            caption={alias}
            fixed={false}
            allowSearch={false}
            dataType="string"
            allowEditing={true}
            //sortIndex={sortIndex}
            width={width}
            //editCellRender={renderDependentColumnEdit(datasources)}
            cellRender={renderHtmlCell}
            columnId={columnId}
            cssClass={inlineEditMode ? "disabled-column-controls": ""}
        />
    );
};

export default renderHtmlColumn;
