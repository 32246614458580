import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import React, { useState, useCallback, useMemo, useEffect } from "react";
import CustomStore from "devextreme/data/custom_store"
import appSettings from "../../appsettings.js";
import msalFetch from "../../api/MsalFetch.js";
import PivotGridWrapper from './PivotGridWrapper.js';
import { useParams } from "react-router-dom";

const PivotGridDataFetcher = () => {
    const [renderGrid, setRenderGrid] = useState(false);
    const [fields, setFields] = useState();
    const [showPivotGrid, setShowPivotGrid] = useState();
    const [showChart, setShowChart] = useState();
    const [showFieldChooser, setShowFieldChooser] = useState();

    const { queryVisualId } = useParams();

    const getPivotGrid = useCallback(async () => {
        const url = `${appSettings.api.endpoint}/api/query-visual/get/${queryVisualId}`;
        const response = await msalFetch(null, url);
        const json = await response.json();
        debugger;
        const parsedFields = [...json.columnsRow, ...json.columnsColumn, ...json.columnsData].map(item => {
            return {
                dataField: item.datafield,
                width: item.width,
                seqNr: item.seqNr,
                area: item.columnArea?.toLowerCase(),
                areaId: item.columnAreaId,
                summaryType: item.columnSummaryType?.toLowerCase(),
                dataType: item.columnDataType?.toLowerCase(),
                dataFormat: item.columnDataFormat?.toLowerCase(),
                detailviewId: item.detailviewId,
                detailviewName: item.detailviewName,
                keyPropertyName: item.keyPropertyName

            }
        });
        const sortedFields = parsedFields.sort((a, b) => {
            if (a.areaId < b.AreaId) { return -1; }
            if (a.areaId > b.AreaId) { return 1; }
            if (a.areaId === b.areaId) {
                if (a.seqNr < b.seqNr) { return -1 }
                if (a.seqNr > b.seqNr) { return 1 }
            }
            return 0;
        });
        //setFields(parsedFields);
        console.log("sortedFields", sortedFields);

        setFields(sortedFields);
        setShowPivotGrid(json.showPivotGrid);
        setShowChart(json.showChart);
        setShowFieldChooser(json.showFieldChooser)
        setRenderGrid(true);
    }, [queryVisualId]);

    const loadDataSource = useCallback(async (loadOptions) => {
        console.log("[PIVOTGRID] - loadDataSource", loadOptions);
        const url = `${appSettings.api.endpoint}/api/query-visual/${queryVisualId}/data`;

        const options = {
            method: "POST",
            headers: { "Content-type": "application/json" },
            body: JSON.stringify(loadOptions)
        }
        const response = await msalFetch(null, url/*, options*/);
        const json = await response.json();
        return json;
    }, [queryVisualId])



    useEffect(() => { getPivotGrid() }, [getPivotGrid])

    const dataSource = useMemo(() => new PivotGridDataSource({
        fields: fields,
        store: new CustomStore({
            key: 'id',
            load: loadDataSource
        })
    }), [loadDataSource, fields]);

    if (!renderGrid) {
        return <div />;
    }

    return (<PivotGridWrapper
        dataSource={dataSource}
        showChart={showChart}
        showPivotGrid={showPivotGrid}
        showFieldChooser={showFieldChooser}
    />)
}

export default (PivotGridDataFetcher)