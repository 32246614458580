import renderStringColumn from './string';
import renderCalculatedColumn from './calculated';
import renderPercentageColumn from './percentage';
import renderNumberColumn from './number';
import renderCurrencyColumn from './currency';
import renderDecimalColumn from './decimal';
import renderBoolColumn from './bool';
import renderDateTimeColumn from './datetime';
import renderFileColumn from './file';
import renderColorColumn from './color';
import RenderActionColumn from './action';
import renderMultipleSelectColumn from './multiple-select';
import renderSelectSearchColumn from './select-search';
import renderHtmlColumn from './html';
import renderUsersColumn from './users';

const renderColumn = ({
    columnId,
    columnTypeCode,
    name,
    alias,
    columnWidth,
    displayValueId,
    readonly,
    mandatory,
    options,
    sortIndex,
    sortDirection,
    displayFormat,
    inputColorColumnId,
    //datasources,
    actionId,
    actionType,
    oneTime,
    tableId,
    actionRefresh,
    refreshGrid,
    actionIcon,
    columnTypeCodeOfDependColumn,
    inlineEditMode,
    overviewId,
    columnValidationId,
    groupIndex,
    strExtraField1,
    typeAheadCharacters,
    getCascadingParentId,
    columnHasImage,
    columnHasGroup,
}) => {
    switch (columnTypeCode) {
        case 'TXT':
        case 'EMAIL':
        case 'MEMO':
        case 'GUID':
        case 'COMPOSITE':
            {
                const allowHeaderFiltering = columnTypeCode !== 'MEMO';
                const alignment = columnTypeCode === 'GUID ' || columnTypeCode === 'NUM' || columnTypeCode === 'AUTONUMMERING' ? "right" : "left";
                return renderStringColumn(name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, allowHeaderFiltering, alignment, groupIndex);
            }
        case 'AVEV':
            return renderColumn({
                columnId,
                columnTypeCode: columnTypeCodeOfDependColumn,
                name,
                alias,
                columnWidth,
                displayValueId,
                readonly,
                mandatory,
                options,
                sortIndex,
                sortDirection,
                displayFormat,
                inputColorColumnId,
                //datasources,
                actionId,
                actionType,
                oneTime,
                tableId,
                actionRefresh,
                refreshGrid,
                actionIcon,
                columnTypeCodeOfDependColumn,
                inlineEditMode,
                overviewId,
                columnValidationId,
                groupIndex,
                strExtraField1,
                typeAheadCharacters,
                getCascadingParentId,
            });
        case 'CALCULATED':
            return renderCalculatedColumn(name, alias, columnWidth, columnId, inlineEditMode, displayValueId, sortIndex, sortDirection, groupIndex);
        case 'PERC':
            return renderPercentageColumn(name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex);
        case 'Id':
            return renderNumberColumn(name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex);
        case 'EU':
            return renderCurrencyColumn(name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, displayFormat, columnValidationId, sortIndex, sortDirection, groupIndex);
        case 'DEC':
        case 'NUM':
        case 'AUTONUMMERING':
            return renderDecimalColumn(name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, displayFormat, sortIndex, sortDirection, groupIndex);
        case 'JN':
        case 'AU':
            return renderBoolColumn(name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex);
        case 'D':
        case 'DT':
            return renderDateTimeColumn(name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, columnTypeCode === 'DT', sortIndex, sortDirection, groupIndex);
        case 'FILE':
        case 'FTP':
            return renderFileColumn(name, alias, columnWidth, columnId, columnId, readonly, mandatory, columnTypeCode === 'FTP', inlineEditMode, tableId, overviewId, refreshGrid, sortIndex, sortDirection, groupIndex);
        case 'COLOR':
            return renderColorColumn(name, alias, columnWidth, columnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex);
        case 'ACTION':
            return RenderActionColumn(name, alias, columnWidth, columnId, columnId, actionId, actionType, oneTime, tableId, actionRefresh, refreshGrid, actionIcon, sortIndex, sortDirection, groupIndex);
        case 'MULTIPLEKEUZELIJST':
            //(name, alias, width, key, options, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex) => {
            return renderMultipleSelectColumn({name, alias, columnWidth, columnId, options, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex});
        case 'SELECT':
            {
                //const isSelectSearch = columnTypeCode === 'ZKL' || columnTypeCode === 'AVAKZ';
                //(name, alias, columnWidth, columnId, inputColorColumnId, readonly, mandatory, inlineEditMode, sortIndex, sortDirection, groupIndex, typeAheadCharacters, getCascadingParentId)
                return renderSelectSearchColumn({
                    name,
                    alias,
                    columnWidth,
                    columnId,
                    inputColorColumnId,
                    readonly,
                    mandatory,
                    inlineEditMode,
                    sortIndex,
                    sortDirection,
                    groupIndex,
                    typeAheadCharacters,
                    getCascadingParentId,
                    columnHasImage,
                    columnHasGroup,
                });
            }
        case 'HTMLVIEWER':
        case 'HTMLEDITOR':
        case 'HTMLEDITORADVANCED':
            return renderHtmlColumn(name, alias, columnWidth, columnId, columnId, inlineEditMode);
        default:
            return null;
    }
};

export default renderColumn;
