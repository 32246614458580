import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import { ColumnValidationNumber } from "../../../../enums/columnValidation";

import { CheckBox, NumberBox, SelectBox } from "devextreme-react";

const Numeriek = ({ fieldValue, setFieldValue, checkChanges, isCreate }) => {
  useEffect(() => {
    let tempFieldValue = fieldValue;
    console.log(tempFieldValue);
    if (isCreate) {
      console.log("hoi");
      tempFieldValue["columnValidation"] =
        tempFieldValue.columnType.columnValidations[0];
      tempFieldValue["columnDefaultValue"] =
        tempFieldValue.columnType.columnDefaultValues[0];
      setFieldValue(tempFieldValue);
      console.log(tempFieldValue);
    }
  }, []);

  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Alleen lezen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={fieldValue.readonly ? fieldValue.readonly : false}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["readonly"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Verplicht</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={fieldValue.mandatory ? fieldValue.mandatory : false}
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["mandatory"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Standaard waarde</Form.Label>
          </Col>
          <Col lg="12">
            <NumberBox
              type="input"
              defaultValue={fieldValue.defaultValue}
              valueChangeEvent="keyup"
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["defaultValue"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);

                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Validatie</Form.Label>
          </Col>
          <Col lg="12">
            <SelectBox
              searchEnabled
              items={fieldValue.columnType.columnValidations}
              valueExpr="id"
              displayExpr="name"
              defaultValue={ fieldValue.columnValidation?.id ?? ColumnValidationNumber.Number }
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["columnValidation"] =
                  fieldValue.columnType.columnValidations.find(
                    (i) => i.id === e.value
                  );

                setFieldValue(tempFieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default Numeriek;
