import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGraphContext } from "../graph/context";
import { useGlobalContext } from "../../context/context";
import Instructions from '../Popups/Instructions';
import ButtonGroup, { Item as ButtonGroupItem } from 'devextreme-react/button-group';
import { Button as DetailviewButton } from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';


import "./Detailview.scss";

const DetailviewInfoControls = ({
    isContentForModal,
    logging,
    setOpenLogging,
    detailviewId,
    instructions,

}) => {
    const { currentUser } = useGraphContext();
    const isDocumentarian = currentUser?.documentarian === 1;
    const { isMobile, screenWidth } = useGlobalContext();

    const [showUserGuide, setShowUserGuide] = useState(false);
    const [hasInstructions, setHasInstructions] = useState(Boolean(instructions));

    return (
        <div className={`${isContentForModal ? "bl-detailview-control-popup" : "bloom-detailview-control"}`}>

            <ButtonGroup
                selectionMode="none"
                stylingMode="outlined"
                focusStateEnabled={false}
                onItemClick={({ event, itemElement }) => {
                    event.preventDefault();
                }}
            >
                {hasInstructions && (
                    <ButtonGroupItem
                        icon="fa-solid fa-question fa-lg"

                        hint="Bekijk handleiding"
                        onClick={() => { setShowUserGuide(true); }}
                        type="default"
                    />)}

                {!hasInstructions && isDocumentarian && !isMobile && (
                    <ButtonGroupItem
                        icon="fa-solid fa-clipboard-question fa-lg"

                        hint="Maak handleiding"
                        onClick={() => { setShowUserGuide(true); }}
                        type="default"
                    />)}
                {logging && (
                    <ButtonGroupItem
                        icon="fa-solid fa-book fa-lg"

                        hint="Open het logboek"
                        onClick={() => setOpenLogging(true)}
                        type="default"
                    />)}

            </ButtonGroup>

            {
                showUserGuide && (
                    <Instructions
                        onClose={(hasSavedInstructions) => {
                            setShowUserGuide(false);
                            setHasInstructions(hasSavedInstructions);
                        }}
                        detailviewId={detailviewId}
                    />
                )
            }

        </div >
    );
};

export default DetailviewInfoControls;
