import React, { useState, useEffect, useCallback, useMemo } from "react";
import CustomStore from "devextreme/data/custom_store";
import appSettings from "../../../appsettings.js"
import msalFetch from "../../../api/MsalFetch.js";
import DataGrid, {
    Column,
    Button,// as DataGridButton,
    Toolbar,
    Item,
    Editing,
} from "devextreme-react/data-grid";
import { Button as ToolbarButton } from 'devextreme-react/button';
import { confirm } from 'devextreme/ui/dialog';
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import UpsertQueryVisual from "./upsertQueryVisual.js";

const IndexQueryVisuals = () => {
    const [showInsertQueryVisual, setShowInsertQueryVisual] = useState(false);
    const [showEditQueryVisual, setShowEditQueryVisual] = useState(false);
    const [editQueryVisualId, setEditQueryVisualId] = useState(null);
    

    const load = useCallback(async (loadOptions) => {
        try {
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/query-visual/index`);

            const data = await res.json();
            console.log("load", data);
            return data;
        } catch (error) {
            throw error;
        }
    }, []);

    const remove = useCallback(async (e) => {
        /*try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/action/listactionlogs`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[Action] getUsers response was not ok: ${data.message}`);
            }
            setLoading(false);
            return data;
        } catch (error) {
            throw error;
        }*/
        try {
            console.log(e);
            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/query-visual/delete/${e}`,
                {
                    method: "DELETE",
                    headers: { "Content-type": "application/json" }
                }
            );
            //const json = await res.json();
            if (!res.ok) {
                throw new Error();
            }
            toast.success('De draaitabel is verwijderd')
        } catch (error) {
            toast.error("Error bij verwijderen draaitabel");
        }
    }, [])

    const onAddQueryVisual = useCallback(async (e) => {
        // e.event.preventDefault();
        // const url = '/configurator/query-visuals/add'

        // push(url);
        console.log("onAddQueryVisual");
        setShowInsertQueryVisual(true);
    }, []);

    const onEditingStart = useCallback(async (e) => {
        console.log(e);
        e.cancel = true;
        setShowEditQueryVisual(true);
        setEditQueryVisualId(e.key);
    }, [])

    const dataStore = useMemo(() => {
        return new CustomStore({
            key: 'id',
            load: load,
            remove: remove
        });
    }, [load, remove]);


    return (<><DataGrid
        dataSource={dataStore}
        title="Queryvisuals"
        showBorders={true}
        hoverStateEnabled={true}
        showRowLines={false}
        showColumnLines={true}
        id="queryVisualId"
        rowAlternationEnabled={true}
        onEditingStart={onEditingStart}
    >
        <Toolbar>
            <Item location="after">
                <ToolbarButton
                    icon="fas fa-plus"
                    onClick={onAddQueryVisual}
                />
            </Item>
        </Toolbar>
        <Column
            caption="Naam"
            dataField="title"
        />
        <Column
            caption="Organisatie"
            dataField="organisation"
        />
        <Column
            caption="Toon draaitabel"
            dataType="boolean"
            dataField="showPivotGrid"
        />
        <Column
            caption="Toon grafiek"
            dataType="boolean"
            dataField="showChart"
        />
        <Column
            caption="Toon veldenkiezer"
            dataType="boolean"
            dataField="showFieldChooser"
        />
        <Editing
            mode="row"
            allowUpdating={true}
            allowAdding={false}
            allowDeleting={true}
        />
    </DataGrid>
        {/* {showInsertQueryVisual && <Popup>
            <UpsertQueryVisual />
        </Popup>} */}
        {showInsertQueryVisual && <UpsertQueryVisual
            showInsertQueryVisual={showInsertQueryVisual}
            showPopup={setShowInsertQueryVisual}
        />}
        {showEditQueryVisual && <UpsertQueryVisual
            showEditQueryVisual={showEditQueryVisual}
            showPopup={setShowEditQueryVisual}
            editQueryVisualId={editQueryVisualId}
            
        />}
    </>)
}

export default IndexQueryVisuals;