import React from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import { Template } from 'devextreme-react/core/template';
import { useGraphContext } from '../../graph/context';
import NewsitemPopup from "../../Popups/NewsItem";
import NavSearch from '../../UserNav/NavSearch';
import UserPanel from './user-panel';
import { ThemeSwitcher } from './theme-switcher';
import { Link, useHistory } from "react-router-dom";

import './header.scss';

export const AppHeader = ({ menuToggleEnabled, title, toggleMenu, className }) => {
    const {
        currentUser,
        nrOfUnreadNewsMessages,
        nrOfUnprocessedNotifications,
        lastHighPriorityNewsItem,
    } = useGraphContext();
    const organisationName = currentUser?.organisation?.name;
    const { push } = useHistory();
    // const {
    //     nrOfUnreadNewsMessages,
    //     lastHighPriorityNewsItem,
    //     currentUser,
    //     profilePic,
    //     fullname,
    //     nrOfUnprocessedNotifications,
    //     setNrOfUnprocessedNotifications,
    // } = useGraphContext();
    return (

        <header className={`header-component ${className || ""}`}>
            {organisationName && (
                <Toolbar className='header-toolbar'>
                    <Item visible={menuToggleEnabled} location='before' widget='dxButton' cssClass='menu-button'>
                        <Button icon='menu' stylingMode='text' onClick={toggleMenu} />
                    </Item>
                    <Item location='before' cssClass='header-title' text={organisationName} visible={!!organisationName} />
                    <Item location="after" widget="dxAutocomplete" locateInMenu="auto">
                        <NavSearch />
                    </Item>

                    <Item location='after' locateInMenu='never'>
                        <ThemeSwitcher />
                    </Item>

                    {currentUser?.admin === 1 && nrOfUnprocessedNotifications > 0 && (
                        <Item location="after" locateInMenu="auto">
                            <div className="messages">
                                <Link to="/notifications">
                                    <Button icon="belloutline" stylingMode="text" hint="Ga naar meldingen" />
                                    <div className="dx-badge">
                                        {nrOfUnprocessedNotifications}
                                    </div>
                                </Link>
                            </div>
                        </Item>
                    )}
                    {currentUser?.admin === 1 && (
                        <Item location="after" locateInMenu="auto">
                            <div className="messages">
                                <Link to="/actions">
                                    <Button icon="fas fa-bolt" stylingMode="text" hint="Ga naar acties" />
                                </Link>
                            </div>
                        </Item>
                    )}
                    <Item location="after" locateInMenu="auto">
                        <div className="messages">
                            <Link to="/news">
                                <Button icon="fas fa-newspaper" stylingMode="text" hint="Ga naar nieuwsberichten" />
                                {nrOfUnreadNewsMessages > 0 &&
                                    <div className="dx-badge">
                                        {nrOfUnreadNewsMessages}
                                    </div>
                                }
                            </Link>
                        </div>
                    </Item>
                    <Item location='after' locateInMenu='auto' menuItemTemplate='userPanelTemplate'>
                        <UserPanel menuMode='context' />
                    </Item>
                    <Template name='userPanelTemplate'>
                        <UserPanel menuMode='list' />
                    </Template>
                </Toolbar>
            )}

            {lastHighPriorityNewsItem != null && (
                <NewsitemPopup
                    selectedTitle={lastHighPriorityNewsItem?.title}
                    selectedText={lastHighPriorityNewsItem?.text}
                    newsItemId={lastHighPriorityNewsItem?.id}
                    isHighPriorityItem={true}
                    showCloseButton={false}
                    itemIsRead={false}
                />
            )}
        </header>
    );
};

export default AppHeader;
