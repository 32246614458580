import React, { useState, useCallback, useMemo } from "react";
import msalFetch from "../../../api/MsalFetch.js";
import DataSource from 'devextreme/data/data_source';
import CustomStore from "devextreme/data/custom_store"
import appSettings from "../../../appsettings.js";
import toast from "react-hot-toast";
import {
    DataGrid,
    Column,
    Editing,
    Paging,
    FilterRow,
    Pager
} from "devextreme-react/data-grid";
const RoleDetailview = ({
    roleId
}) => {
    console.log("RoleDetailview - RoleID - Initialize", roleId);
    const loadDetailviews = useCallback(async (loadOptions) => {
        console.log("loadDetailviews", roleId, loadOptions)

        if (roleId == null)
            return;
        const url = `${appSettings.api.endpoint}/api/configurator/detailviewsbyrole/${roleId}`;
        const response = await msalFetch(null, url);
        const json = await response.json();
        console.log("detailviewsbyrole - JSON", json);
        return json;
    }, [roleId]);

    const updateServerRecord = useCallback(async (rec, val) => {
        console.log("RoleDetailview - updateServerRecord - rec - val", rec, val);
        try {
            let body = {
                roleId: roleId,
                detailviewId: rec.detailviewId,
                value: val[Object.keys(val)[0]]
            };
            //[HttpGet, Route("api/configurator/role/updatetablepermission/{roleId}/{detailviewId}/{value}")]

            const res = await msalFetch(null,
                `${appSettings.api.endpoint}/api/configurator/role/updatedetailviewvisibility/${roleId}/${body.detailviewId}/${body.value}/`
                // {
                //     method: "POST",
                //     headers: { "Content-type": "application/json" },
                //     body: JSON.stringify(body),
                // }
            );
            console.log("updateServerRecord - res", res);
            toast.success("Toegang bijgewerkt");
        } catch (error) {
            toast.error("error");
            throw error;
        }
    }, [roleId])

    const dataSource = useMemo(() => new DataSource({
        store: new CustomStore({
            load: loadDetailviews,
            update: updateServerRecord,
            //remove: deleteServerRecord,
        })
    }), [loadDetailviews, updateServerRecord]);

    const onEditingStart = useCallback((e) => {
        if (e.data.detailviewId == null)
            e.cancel = true;
    }, []);

    return <div>

        <DataGrid
            dataSource={dataSource}
            onEditingStart={onEditingStart}
            rowAlternationEnabled
            showRowLines={true}
            noDataText="Klik op een rol om de rechten in te stellen"
            showColumnLines={false}
            height={"70vh"}
        >
            <Paging defaultPageSize={25} />
            <Pager
                visible={true}
                showPageSizeSelector={true}
                showInfo={true}
                allowedPageSizes={[25, "all"]}
                showNavigationButtons={true}
            />
            <FilterRow
                visible={true}
                applyFilter={'auto'} />
            <Editing
                mode="cell"
                allowUpdating={true}
                refreshMode={"repaint"}
            />
            <Column
                allowEditing={false}
                caption="Tabelnaam"
                dataField="tableName"
                dataType="string"
                defaultSortOrder="asc"
                sortIndex={1}
                allowSorting={false}
            />
            <Column
                allowEditing={false}
                caption="Pagina"
                dataField="detailviewName"
                dataType="string"
                defaultSortOrder="asc"
                sortIndex={2}
                allowSorting={false}
            />
            <Column
                allowEditing={false}
                caption="Zichtbaar als tab"
                dataField="detailviewVisibleAsTab"
                dataType="boolean"
                allowSorting={false}
            />
            <Column
                allowEditing={true}
                caption="Zichtbaar"
                dataType="boolean"
                dataField="visible"
            />

        </DataGrid></div>
}

export default RoleDetailview;