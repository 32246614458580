import React, { memo, useCallback, useEffect, useRef } from "react";
import HtmlEditor, {
    Toolbar,
    MediaResizing,
    Item,
} from "devextreme-react/html-editor";
import FieldLabel from "../detailview/FieldLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tooltip } from 'devextreme-react/tooltip';


const headerValues = [false, 1, 2, 3, 4, 5];
const sizeValues = ["8pt", "9pt", "10pt", "11pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontSizeOptions = {
    value: '12pt',
    inputAttr: {
        'aria-label': 'Font size',

    },
};
const fontValues = ["Arial", "Courier New", "Georgia", "Impact", "Lucida"];
const fontFamilyOptions = { value: 'Arial' };


const HTMLEDITORADVANCED = ({
    value,
    label,
    readonly,
    columnId,
    registerLoaded,
    onControlUpdate,
    memoField,
    mandatory
}) => {
    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const htmlEditorRef = useRef();
    const handleValueChange = useCallback((e) => {
        onControlUpdate(columnId, e.value);
    }, [onControlUpdate, columnId]);
    const onContentReady = useCallback((e) => {
        e.component.format("font", "Arial");
        e.component.format("size", "12pt");
        // e.component.getQuillInstance().blur();
        // e.component.getQuillInstance().focus();
    }, []);

    return (
        <Form.Group>
            <Row>
                <Col md="4" className="bl-justify-top">
                    <FieldLabel
                        value={value}
                        label={label}
                        columnId={columnId}
                        memoField={memoField}
                        mandatory={mandatory}

                    />
                </Col>
                <Col md="12">
                    <div className="widget-container">
                        <HtmlEditor
                            ref={htmlEditorRef}
                            defaultValue={value}
                            valueType="html"
                            readOnly={readonly}
                            stylingMode={readonly ? "filled" : "outlined"}
                            allowSoftLineBreak={true}
                            onValueChanged={handleValueChange}
                            className="bl-html-editor"
                            onInitialized={onInitialized}
                            onContentReady={onContentReady}

                        >
                            <MediaResizing enabled={true} />
                            <Toolbar multiline={true}>
                                <Item name="undo" />
                                <Item name="redo" />
                                <Item name="separator" />
                                <Item name="size" acceptedValues={sizeValues} options={fontSizeOptions} />
                                <Item name="font" acceptedValues={fontValues} options={fontFamilyOptions} />
                                <Item name="separator" />
                                <Item name="bold" />
                                <Item name="italic" />
                                <Item name="strike" />
                                <Item name="underline" />
                                <Item name="separator" />
                                <Item name="alignLeft" />
                                <Item name="alignCenter" />
                                <Item name="alignRight" />
                                <Item name="alignJustify" />
                                <Item name="separator" />
                                <Item name="orderedList" />
                                <Item name="bulletList" />
                                <Item name="separator" />
                                <Item name="header" acceptedValues={headerValues} />
                                <Item name="separator" />
                                <Item name="color" />
                                <Item name="background" />
                                <Item name="separator" />
                                <Item name="link" />
                                <Item name="image" />
                                <Item name="separator" />
                                <Item name="clear" />
                                <Item name="codeBlock" />
                                <Item name="blockquote" />
                                <Item name="separator" />
                                <Item name="insertTable" />
                                <Item name="deleteTable" />
                                <Item name="insertRowAbove" />
                                <Item name="insertRowBelow" />
                                <Item name="deleteRow" />
                                <Item name="insertColumnLeft" />
                                <Item name="insertColumnRight" />
                                <Item name="deleteColumn" />
                            </Toolbar>
                        </HtmlEditor>
                    </div>
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(HTMLEDITORADVANCED);
