import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import Loading from "../../loading/Loading";
import Content from "../../detailview/Content";
import { Popup } from 'devextreme-react/popup';

const QuickEdit = ({
    targetId: tableitemId, // is tableitemId
    isEditing,
    setIsEditing,
    refreshGrid,
    isRelation,
    controlRelationId,
    currentOverview,
    isMasterDetail,
    expandDetailviewId,
    overrideDetailViewId,
    handleSaveAndCreateNew,
    handleSave,
    handleUserLeaveContinue,
    handleClose,
    handleUserLeaveSave,
    handleDelete,
    handleAddNew,
}) => {
    const [loading, setLoading] = useState(true);
    const [allDetailviewData, setAllDetailviewData] = useState([]);
    let detailviewId;
    if (overrideDetailViewId) {
        detailviewId = overrideDetailViewId;
    } else if (expandDetailviewId) {
        detailviewId = expandDetailviewId
    } else if (isRelation) {
        detailviewId = currentOverview.relationTable.preferredDetailviewId;
    } else if (currentOverview.preferredDetailViewId) {
        detailviewId = currentOverview.preferredDetailViewId;
    } else {
        detailviewId = currentOverview.table.preferredDetailviewId;
    }

    const getDetailviewHtml = useCallback(async () => {
        setLoading(true);
        if (!detailviewId) {
            setAllDetailviewData(null);
            setLoading(false);
            return;
        }

        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/detailview/${detailviewId}/${tableitemId}/?quickedit=true&onlyVisibleAsTab=null`);
            const data = await response.json();
            if (!response.ok) {
                throw new Error(`[QuickEdit] getDetailviewHtml response was not ok: ${data.message}`);
            }
            setAllDetailviewData(data.detailViews);
        } catch (error) {
            throw error;
        }
        setLoading(false);
    }, [detailviewId, tableitemId]);

    useEffect(() => {
        getDetailviewHtml();
    }, [getDetailviewHtml]);
    const repaintPopup = useCallback(() => {

        Popup.current.instance().repaint();
    }, []);

    const [fullScreen, setFullScreen] = useState(false);
    const fullScreenIcon = (!fullScreen ? 'fas fa-expand-arrows-alt' : 'fas fa-window-minimize');

    const currentDetailview =
        allDetailviewData.find(({ id }) => id === Number(detailviewId))
        || allDetailviewData[0]; // this is a feature.
    // Sometimes the backend gives data, but the detailviewId itself is not present.
    // relationTable stuff..

    if (!isMasterDetail) {
        return (
            <Popup
                title="Bewerken"
                className="bl-popup"
                showTitle={true}
                resizeEnabled={true}
                dragEnabled={true}
                fullScreen={fullScreen}
                animation={false}

                visible={true}
                showCloseButton={true}
                onShown={repaintPopup}
                onHiding={(e) => {
                    // TODO: throwNotification, but it's handled by the child Content.. :(
                    handleClose && handleClose();
                    e.cancel = true;
                }}
                toolbarItems={
                    [{
                        toolbar: "top",
                        widget: "dxButton",
                        location: "after",
                        // className: "Button",
                        options:
                        {
                            icon: fullScreenIcon,
                            text: 'Volledig scherm',
                            stylingMode: 'text',
                            type: 'default',

                            onClick: (e) => {

                                setFullScreen(!fullScreen);
                            }
                        }
                    }
                    ]
                }
            >
                {loading ? (
                    <Loading />
                ) : (
                    <Content
                        showTitle={true}
                        allDetailviewData={allDetailviewData}
                        currentDetailview={currentDetailview}
                        tableitemId={tableitemId}
                        isContentForModal={true}
                        isQuickEdit={true}
                        handleSave={handleSave}
                        handleSaveAndCreateNew={handleSaveAndCreateNew}
                        handleClose={handleClose}
                        handleUserLeaveSave={handleUserLeaveSave}
                        handleUserLeaveContinue={handleUserLeaveContinue}
                        handleDelete={handleDelete}
                        handleAddNew={handleAddNew}
                    />
                )}
            </Popup>
        );
    } else if (isMasterDetail) {
        return (
            <div className="bl-master-detail-inner">
                {loading && (
                    <Loading />
                )}
                {!loading && !currentDetailview && (
                    <span>Geen pagina uitklapbaar</span>
                )}
                {!loading && (
                    <Content
                        showTitle={true}
                        currentDetailview={currentDetailview}
                        tableitemId={tableitemId}
                        isContentForModal={false}
                        isQuickEdit={true}
                        isMasterDetail={true}
                        handleMasterDetailSave={() => {
                            setIsEditing && setIsEditing(false);
                            refreshGrid && refreshGrid();
                        }}
                        handleSave={handleSave}
                        refreshGrid={refreshGrid}
                    />
                )}
            </div>
        );
    }
};

export default QuickEdit;
