import React, { memo, useCallback } from "react";
import { ColorBox } from "devextreme-react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Tooltip } from 'devextreme-react/tooltip';
import FieldLabel from "../detailview/FieldLabel";

const COLOR = ({
    value,
    alias,
    mandatory,
    registerLoaded,
    onControlUpdate,
    columnId,
    memoField,
    readonly,
}) => {
    const valid = value !== "";

    const onInitialized = useCallback(() => registerLoaded(columnId), [registerLoaded, columnId]);
    const onValueChanged = useCallback((e) => {
        onControlUpdate(columnId, e.value);
    }, [onControlUpdate, columnId]);

    return (
        <Form.Group>
            <Row>
                <Col md="4">
                    <FieldLabel
                        value={value}
                        label={alias}
                        columnId={columnId}
                        memoField={memoField}
                        mandatory={mandatory}

                    />
                </Col>
                <Col md="8">
                    <ColorBox
                        value={value}

                        onValueChanged={onValueChanged}
                        readOnly={readonly}
                        stylingMode={readonly ? "filled" : "outlined"}
                        onInitialized={onInitialized}
                    />
                </Col>
            </Row>
        </Form.Group>
    );
};

export default memo(COLOR);
