import React, { useState, useCallback } from "react";
import { Column, RequiredRule, } from "devextreme-react/data-grid";
import "../select-search.scss";
import SelectionList, { itemRender, fieldRender } from "../../Fields/SelectionList.js";

const renderSelectSearchColumnEdit = ({ columnHasGroup, columnId, alias, typeAheadCharacters, readonly, getCascadingParentId, columnHasImage }) => (cell) => {
    const [initialValue, setInitialValue] = useState(cell.value);
    const cascadingParentItemId = getCascadingParentId(columnId);
    // console.log("getCascadingParentId(columnId)", cascadingParentItemId);

    const onValueChanged = (cell, e) => {
        console.log("onValueChanged", e);
        if (e.value == null) {
            cell.setValue(null);
        }
        else {
            // const newValue = e.selectedItem == null ? null : parseInt(e.selectedItem.value);
            // setInitialValue(newValue);
            // cell.setValue(newValue);
        }
    }
    const onSelectionChanged = useCallback((cell, e) => {

        const newValue = e.selectedItem == null ? null : parseInt(e.selectedItem.value);
        if (newValue !== initialValue) {
            setInitialValue(newValue);
            cell.setValue(newValue);
        }
    }, [initialValue]);

    const localOnSelectionChanged = onSelectionChanged.bind(this, cell);
    const localOnValueChanged = onValueChanged.bind(this, cell);
    return <SelectionList
        columnId={columnId}
        defaultValue={cell.Value}
        className='overview-lookup'
        //valueExpr='id'
        typeAheadCharacters={typeAheadCharacters}
        label={alias}
        readOnly={readonly}
        //getCascadingParentId={getCascadingParentId}
        cascadingParentItemId={cascadingParentItemId}
        onSelectionChanged={localOnSelectionChanged}
        onValueChanged={localOnValueChanged}
        initialValue={initialValue}
        columnHasImage={columnHasImage}
        columnHasGroup={columnHasGroup}
    />
};



const renderSelectColumnCell = ({ inputColorColumnId, columnId, columnHasImage }) => (columnData) => {
    //console.log("columnData", columnData);
    const columnName = columnData.column.name;
    const nameStrippedFromIdTag = columnName;
    const colorPropertyName = nameStrippedFromIdTag + '_' + inputColorColumnId;
    const color = columnData.data[colorPropertyName];
    const thumbnail = columnData.data[`thumbnail_${columnName}`];
    const functionRender = fieldRender({ deprecatedOption: null, columnId, isOverview: true, columnHasImage });
    const result = functionRender({ label: columnData.displayValue, color: color, thumbnailSmall: thumbnail, value: columnData.data.id })
    return result;
}


const renderSelectSearchColumn = ({
    name,
    alias,
    columnWidth,
    columnId,
    inputColorColumnId,
    readonly,
    mandatory,
    inlineEditMode,
    sortIndex,
    sortDirection,
    groupIndex,
    typeAheadCharacters,
    getCascadingParentId,
    columnHasImage,
    columnHasGroup,
}) => {
    return (
        <Column
            key={columnId}
            dataField={`${name.toLowerCase()}_id`}
            caption={alias}
            name={name.toLowerCase()}
            calculateDisplayValue={name.toLowerCase()}
            fixed={false}
            dataType="string"
            allowEditing={!readonly}
            showWhenGrouped={true}
            width={columnWidth}//{columnId, alias, typeAheadCharacters, readonly, getCascadingParentId}
            editCellRender={renderSelectSearchColumnEdit({ columnHasGroup, columnId, alias, typeAheadCharacters, readonly, getCascadingParentId, columnHasImage })}
            cellRender={renderSelectColumnCell({ inputColorColumnId, columnId, columnHasImage })}
            columnId={columnId}
            mandatory={mandatory}
            cssClass={readonly && inlineEditMode ? "disabled-column-controls" : ""}
            groupIndex={groupIndex}
            sortIndex={sortIndex}
            allowGrouping={true}
            sortOrder={sortDirection}
        >
            {mandatory && <RequiredRule />}
        </Column>
    );
};

export default renderSelectSearchColumn;
