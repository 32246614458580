import React from 'react';
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import { AppProvider, useGlobalContext } from "./context/context";
import { ModalProvider } from "./context/modalContext";
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "./components/ErrorHandling/FallbackComponent";
import { MsalProvider } from "@azure/msal-react";
import CustomNavigationClient from './CustomNavigationClient';
import nlMessages from "devextreme/localization/messages/nl.json";
import { locale, loadMessages } from "devextreme/localization";
import config from 'devextreme/core/config';
import "./App.scss";
import Auth from "./Auth";
import { useThemeContext, ThemeContext } from './theme/theme';

// because we consume context, this needs to be a seperate component mount
const AppComponent = () => {
    const { menuToggle, isMobile, theme } = useGlobalContext();
      const themeContext = useThemeContext();

    return (
        <div
            className={`App ${!menuToggle && !isMobile ? "expanded-view" : ""} ${isMobile ? "bl-mobile" : ""} ${theme}`}
        >
            <ThemeContext.Provider value={themeContext}>
                <Helmet
                    defaultTitle="Bloom"
                    titleTemplate="%s - Bloom"
                />
                {themeContext.isLoaded ? <Auth /> : ''}
                <Toaster position="bottom-right" />
            </ThemeContext.Provider>
        </div>
    );
}

const errorHandler = (error, errorInfo) => {
    console.error("Logging error from errorHandler", error, errorInfo);
};

const App = ({ pca }) => {
    // The next 3 lines are optional.
    // This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    pca.setNavigationClient(navigationClient);

    loadMessages(nlMessages);
    locale('nl');
    config({ defaultCurrency: 'EUR' });

    return (
        <MsalProvider instance={pca}>
            <ModalProvider>
                <AppProvider>
                    <ErrorBoundary
                        FallbackComponent={FallbackComponent}
                        onError={errorHandler}
                    >
                        <AppComponent />
                    </ErrorBoundary>
                </AppProvider>
            </ModalProvider>
        </MsalProvider>
    );
}



export default App;
