// @TODO: all || cases should be removed when deployment uses env vars


const data = {
    "api": {
        "endpoint": process.env.REACT_APP_API_ENDPOINT || "https://bloom-v2-api.azurewebsites.net",
    },
    "reporting": {
        "endpoint": process.env.REACT_APP_REPORTING_ENDPOINT || "https://bloom-v2-reporting.azurewebsites.net",
    },
    "registration": {
        "registrationCompleteUrl": process.env.REACT_APP_REGISTRATION_REGISTRATIONCOMPLETEURL
    },
    "default": {
        "api": {
            "audience": process.env.REACT_APP_DEFAULT_API_AUDIENCE || "https://bloomnloutlook.onmicrosoft.com/237b4b3f-4d7d-4eff-939e-a6fb2085a752",
        },
        "app": {
            "clientid": process.env.REACT_APP_DEFAULT_APP_CLIENTID || "80b7688e-6945-4c7f-bc37-d1d88038e53a",
        },
        "msal": {
            "redirecturl": process.env.REACT_APP_DEFAULT_MSAL_REDIRECTURL,
        }
    },
    "licensing": {
        "devextreme": process.env.DEVEXTREME_KEY,

    }
};

export default data;

