import React from "react";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";

import { CheckBox } from "devextreme-react";

const StandaardVeld = ({
  fieldValue,
  setFieldValue,
  checkChanges,
  isCreate,
}) => {
  return (
    <>
      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Alleen lezen</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={
                !isCreate && fieldValue.readonly ? fieldValue.readonly : false
              }
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["readonly"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg="12">
            <Form.Label>Verplicht</Form.Label>
          </Col>
          <Col lg="12">
            <CheckBox
              defaultValue={
                !isCreate && fieldValue.mandatory ? fieldValue.mandatory : false
              }
              onValueChanged={(e) => {
                let tempFieldValue = fieldValue;
                tempFieldValue["mandatory"] = e.value;
                setFieldValue(tempFieldValue);
                console.log(fieldValue);
                checkChanges();
              }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
};

export default StandaardVeld;
