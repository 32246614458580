import React, { useEffect } from "react";
import appSettings from "../appsettings";
import Loading from "../components/loading/Loading";

const Register = () => {
    useEffect(() => {
        const link = `https://login.microsoftonline.com/common/adminconsent?client_id=${appSettings.default.app.clientid}&state=emptyState`
        window.location.replace(link);
    }, []);

   // ofcourse, this useless return statement must be removed and the redirect must be handled in a bit better way, currently no time to figure out how - Paul (2023-04-18)
    return (
        <main className="bl-content">
            <section className="bl-card">
                <Loading />
            </section>
        </main>
    );
};

export default Register;
