import { Popup } from 'devextreme-react/popup';
import toast from "react-hot-toast";
import HtmlEditor, {
    Toolbar,
    Item,
    MediaResizing,
} from "devextreme-react/html-editor";
import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../appsettings";
import msalFetch from "../../api/MsalFetch.js";
const headerValues = [false, 1, 2, 3, 4, 5];
const sizeValues = ["8pt", "9pt", "10pt", "11pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Courier New", "Georgia", "Impact", "Lucida"];

const EditHtmlField = ({
    rowIndex,
    columnIndex,
    alias,
    tableId,
    tableItemId,
    columnId,
    readOnly,
    setSelectedHtmlTableItemId,
    isHtmlViewer,
    isHtmlEditor,
    isHtmlEditorAdvanced,
    refreshFunction,
    dataGridRef,
}) => {
    const [fullScreenIcon, setFullScreenIcon] = useState('fas fa-expand-arrows-alt');
    const [fullScreen, setFullScreen] = useState(false);
    const [html, setHtml] = useState();

    const fetchHtml = useCallback(async () => {
        const response = await msalFetch(undefined,
            `${appSettings.api.endpoint}/api/table/${tableId}/value/${tableItemId}/${columnId}/get`
        );
        const data = await response.json();
        setHtml(data);
    }, [tableId, tableItemId, columnId])

    const updateHtml = useCallback(async () => {
        const response = await msalFetch(null,
            `${appSettings.api.endpoint}/api/table/${tableId}/value/${tableItemId}/${columnId}/update`,
            {
                method: "POST",
                headers: { "Content-type": "application/json" },
                body: JSON.stringify(html),
            });
        if (response.ok) {
            setSelectedHtmlTableItemId(null);
            //the manual change of the cell value triggers an automatic update as the cell value has changed
            //dataGridRef.current.instance().cellValue(rowIndex, columnIndex, html !== '')
            refreshFunction();
            toast.success('Wijziging opgeslagen');
        } else {
            toast.error("Oeps er ging iets mis...");
        }

    }, [tableId, tableItemId, columnId, html, setSelectedHtmlTableItemId, refreshFunction])

    const onHtmlValueChanged = useCallback((e) => {
        setHtml(e.value);
    }, []);

    useEffect(() => {
        fetchHtml();
    }, [fetchHtml])

    return <Popup
        className="bl-popup"
        showTitle={true}
        title={readOnly ? alias : `Wijzigen ${alias}`}
        resizeEnabled={true}
        dragEnabled={true}
        animation={false}
        visible={true}
        showCloseButton={true}
        onHiding={(e) => {
            setSelectedHtmlTableItemId(null);
            e.cancel = true;
        }}
        fullScreen={fullScreen}
        toolbarItems={
            [
                {
                    toolbar: "top",
                    widget: "dxButton",
                    location: "after",
                    className: "Button",
                    options:
                    {
                        icon: 'fullscreen',
                        onClick: (e) => {
                            setFullScreenIcon(fullScreen ? 'fas fa-expand-arrows-alt' : 'fas fa-window-minimize');
                            setFullScreen(!fullScreen);
                        }
                    }
                },
                {
                    toolbar: "bottom",
                    widget: "dxButton",
                    location: "center",
                    visible: !readOnly,
                    options:
                    {
                        text: 'Opslaan en sluiten',
                        onClick: (e) => {
                            e.cancel = true;
                            updateHtml(html);
                        }
                    }
                }
            ]
        }
    >

        <HtmlEditor
            className="bl-html-editor"
            //defaultValue={html}
            name={alias}
            value={html}
            valueType="html"
            readOnly={readOnly}
            stylingMode="outlined"
            onValueChanged={onHtmlValueChanged}
        >
            {!readOnly && isHtmlEditor &&
                <Toolbar multiline={true}>
                    <Item name="undo" />
                    <Item name="redo" />

                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="header" acceptedValues={headerValues} />
                    <Item name="separator" />

                    <Item name="orderedList" />
                    <Item name="bulletList" />

                </Toolbar>
            }
            {!readOnly && isHtmlEditorAdvanced &&
                <Toolbar multiline={true}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item name="size" acceptedValues={sizeValues} />
                    <Item name="font" acceptedValues={fontValues} />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item name="header" acceptedValues={headerValues} />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    <Item name="separator" />
                    <Item name="insertTable" />
                    <Item name="deleteTable" />
                    <Item name="insertRowAbove" />
                    <Item name="insertRowBelow" />
                    <Item name="deleteRow" />
                    <Item name="insertColumnLeft" />
                    <Item name="insertColumnRight" />
                    <Item name="deleteColumn" />
                </Toolbar>
            }

        </HtmlEditor>
    </Popup>
}

export default EditHtmlField;
