import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect, useCallback } from "react";
import appSettings from "../../../appsettings";
import msalFetch from "../../../api/MsalFetch.js";
import { useParams } from "react-router";
import Loading from "../../loading/Loading";
import { Col, Form, Row } from "react-bootstrap";
import TabGrid from "./TabGrid";
import toast from "react-hot-toast";
import ConfigPageControls from "../parts/ConfigPageControls";
import { CheckBox, TextBox } from "devextreme-react";
import BloomFooter from "../../footer/BloomFooter";

const Dataset = () => {
    const msal = useMsal();
    const [loading, setLoading] = useState(true);
    const [currentDataset, setCurrentDataset] = useState();
    const [changeInForm, setChangeInForm] = useState(false);
    const [isSystemTable, setIsSystemTable] = useState();

    const { id } = useParams();
    const checkChanges = () => {
        if (changeInForm) {
            return;
        } else {
            setChangeInForm(true);
        }
    };

    const userLeaveHandler = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        window.addEventListener("onbeforeunload", userLeaveHandler);
        return () => {
            window.removeEventListener("onbeforeunload", userLeaveHandler);
        };
    }, []);

    const getDatasetData = useCallback(async () => {
        setLoading(true);
        if(id == null)
            return;
        try {
            const response = await msalFetch(null, `${appSettings.api.endpoint}/api/configurator/dataset/${id}`);
            const data = await response.json();
            console.log("getDatasetData", data);
            setCurrentDataset(data);
            setIsSystemTable(data.isSystemTable);
            setLoading(false);

            return data;
        } catch (error) {
            setLoading(false);
            throw "Data Loading Error";
        }
    }, [id]);

    useEffect(() => {
        getDatasetData();
    }, [getDatasetData]);

    const handleSaveClick = async () => {
        try {
            const res = await msalFetch(msal,
                `${appSettings.api.endpoint}/api/configurator/dataset/${id}`,
                {
                    method: "PUT",
                    headers: { "Content-type": "application/json" },
                    body: JSON.stringify(currentDataset),
                }
            );
            if (!res.ok) {
                toast.error("Oeps er is iets mis gegaan...");
                return;
            }
            const data = await res.json();
            setChangeInForm(false);
            toast.success("De gegevens zijn successvol opgeslagen...");
        } catch (error) {
            toast.error("Oeps er is iets mis gegaan...");
            console.log(error);
            throw error;
        }
    };

    return (
        <main className="bl-content">
            {loading && <Loading />}
            {!loading && (
                <>
                    <header className="bl-config-page-header">
                        <h1>{currentDataset.tableName}</h1>
                    </header>

                    <Row>
                        <Col lg="12">
                            <div className="bl-card">
                                <div className="bl-config-title-header">
                                    <div className="bl-config-title-name">
                                        <Form.Group>
                                            <Col md="12">
                                                <Form.Label>
                                                    <h2>Tabel</h2>
                                                </Form.Label>
                                            </Col>
                                            <Col md="12">
                                                <TextBox
                                                    type="input"
                                                    value={currentDataset.tableName}
                                                    placeholder="naam"
                                                    className="bl-readonly-field"
                                                    readOnly={isSystemTable}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>

                                    {/* Readonly bepalen */}

                                    <div className="bl-config-title-checks">
                                        <Form.Group>
                                            <Row>
                                                <Col md="2">
                                                    <CheckBox
                                                        defaultValue={currentDataset.readonly}
                                                        readOnly={isSystemTable}
                                                        onValueChanged={(e) => {
                                                            let tempform = currentDataset;
                                                            tempform["readonly"] = e.value;
                                                            console.log(tempform);
                                                            checkChanges();
                                                            setCurrentDataset(tempform);
                                                        }}
                                                    />
                                                </Col>
                                                <Col md="10">
                                                    <Form.Label>Alleen Lezen</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        {/* enable Logging */}
                                        <Form.Group>
                                            <Row>
                                                <Col md="2">
                                                    <CheckBox
                                                        defaultValue={currentDataset.enableLogging}
                                                        readOnly={isSystemTable}
                                                        onValueChanged={(e) => {
                                                            let tempform = currentDataset;
                                                            tempform["enableLogging"] = e.value;
                                                            console.log(tempform);
                                                            checkChanges();
                                                            setCurrentDataset(tempform);
                                                        }}
                                                    />
                                                </Col>
                                                <Col md="10">
                                                    <Form.Label>Alle mutaties loggen?</Form.Label>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </div>

                                    <div className="bl-config-title-controls">
                                        <ConfigPageControls
                                            saveFunction={handleSaveClick}
                                            changeInForm={changeInForm}
                                            customCancelClick="refresh"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="bl-card " style={{ marginTop: "15px" }}>
                                <header>
                                    <h2>Opties</h2>
                                </header>
                                <TabGrid
                                    getDatasetData={getDatasetData}
                                    currentDataset={currentDataset}
                                    datasetDisplayName={currentDataset.tableName}
                                />
                            </div>
                        </Col>
                    </Row>
                    <BloomFooter />
                </>
            )}
        </main>
    );
};

export default Dataset;
